import React, { useEffect, useState } from 'react';
import { useFrame, useThree, useLoader } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { useRef, forwardRef, useContext } from "react";
import { useControls } from 'leva';
import * as THREE from 'three'
import { SceneContext } from './Nome';
import CustomShaderMaterial from 'three-custom-shader-material'
import truncateEthAddress from 'truncate-eth-address';
import { lerp } from 'three/src/math/MathUtils';
import { useGLTF } from '@react-three/drei';
import { RigidBody, Attractor, vec3, MeshCollider, BallCollider, InstancedRigidBodies } from '@react-three/rapier';
import { Sphere } from '@react-three/drei';

export default function Gigi({}) {

    const numBalls = 25;

    const { scene, camera } = useThree();

    const messageRef = useRef(null);
    const messageFadeTimer = useRef(null);

    const typingInProgress = useRef(false);
    const currentMessage = useRef('');

    const attractorRef = useRef(null);

    const angleRef = useRef(0);
    const angle2Ref = useRef(0);

    const gigiRef = useRef();

    const targetPosRef = useRef(null);

    const tornadoMatRef = useRef();
    const tornadoMat2Ref = useRef();
    const tornadoMat3Ref = useRef();

    const instancedFishRef = useRef();

    const setGigiStartRef = useRef(false);

    const groundColliderRef = useRef(null);

    const { 
        colliderLayer, 
        avatarDataRef,
        gigiMessageRef,
    } = useContext(SceneContext);

    const ballRefs = useRef([]);

    const [balls, setBalls] = useState();
    const [gigiWorldPos, setGigiWorldPos] = useState(null);

    const gigiCapTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/gigicap.jpg');

    const gigiGLB = useGLTF(process.env.PUBLIC_URL + '/models/wanderlust/gigi.glb');

    const fishGLB = useGLTF(process.env.PUBLIC_URL + '/models/nome/fish.glb');

    // const keyDiffuse = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/key_diffuse.jpg');

    const gigiCurPosRef = useRef(new THREE.Vector3(0, 0, 0));

    const vertexShader = `
    uniform float time;
    
    uniform float layerIndex;

    varying vec2 myUV;

    float noiseScaleX = 20.0;
    float noiseScaleY = 20.0;
    float distortAmp = 0.6;
    float twistIntensity = 3.0;
    float swirlSpeed = 5.5;

    float hash(float n) { return fract(sin(n) * 1e4); }
    float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }

    float noise(float x) {
        float i = floor(x);
        float f = fract(x);
        float u = f * f * (3.0 - 2.0 * f);
        return mix(hash(i), hash(i + 1.0), u);
    }

    float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);

        float a = hash(i);
        float b = hash(i + vec2(1.0, 0.0));
        float c = hash(i + vec2(0.0, 1.0));
        float d = hash(i + vec2(1.0, 1.0));

        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    void main() {
        myUV = uv;

        vec3 noiseDisplacement = normal * noise(vec2(uv.x * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp;
        vec3 upDisplacement = vec3(0.0, 1.0, 0.0) * noise(vec2(uv.x * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp;

        // Tornado animation
        float twist = twistIntensity * uv.y * sin(time * swirlSpeed + uv.x * 10.0);
        vec3 tornadoDisplacement = vec3(sin(twist), 0.0, cos(twist));

        vec3 chaos = vec3(
            noise(vec2(0.0, uv.y * 3.0 + time * 5.0)),
            noise(vec2(0.0 + time, uv.y * 4.0)),
            noise(vec2(0.0 - time, uv.y * 6.0))
        );

        vec3 finalPosition = position + noiseDisplacement + upDisplacement + tornadoDisplacement * chaos;

        csm_Position = finalPosition;
    }
    `;

    const fragShader = `
    varying vec2 myUV;
    uniform float time;

    uniform float layerIndex;

    float hash(float n) { return fract(sin(n) * 1e4); }
    float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }
    
    float noise(float x) {
        float i = floor(x);
        float f = fract(x);
        float u = f * f * (3.0 - 2.0 * f);
        return mix(hash(i), hash(i + 1.0), u);
    }
    
    float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);
    
        // Four corners in 2D of a tile
        float a = hash(i);
        float b = hash(i + vec2(1.0, 0.0));
        float c = hash(i + vec2(0.0, 1.0));
        float d = hash(i + vec2(1.0, 1.0));
    
        // Simple 2D lerp using smoothstep envelope between the values.
        // return vec3(mix(mix(a, b, smoothstep(0.0, 1.0, f.x)),
        //			mix(c, d, smoothstep(0.0, 1.0, f.x)),
        //			smoothstep(0.0, 1.0, f.y)));
    
        // Same code, with the clamps in smoothstep and common subexpressions
        // optimized away.
        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }
    
    void main() {

        float tornadoScaleY = 45.0;
        float tornadoScaleX = 15.0;
        float tornadoSpeedX = 120.0;
        float tornadoSpeedY = 20.0;

        //get noise value at uv offset by time
        float n = noise(vec2(myUV.x * tornadoScaleX + time * tornadoSpeedX * (layerIndex + 1.0), myUV.y * tornadoScaleY + time * tornadoSpeedY * (layerIndex + 1.0)));

        float c = clamp((layerIndex + 1.0) * 0.7, 0.0, 1.0);

        float opacity = pow(n, 3.0 - layerIndex) * 0.6;

        csm_DiffuseColor = vec4(c, c, c, opacity); 

        if(opacity < 0.05) {
            discard;
        }
    }
    `;

    const fishVert = `
        uniform float time; // time uniform to animate the vertices
        varying vec2 vUv;

        void main() {
            vUv = uv;

            // Animate the vertex along the x-axis
            vec3 animatedPosition = position + vec3(0.0, 0.0, sin(time + position.x*1.5) * 0.3);

            // Apply the instance matrix
            vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(animatedPosition, 1.0);
            gl_Position = projectionMatrix * mvPosition;
        }
    `;

    const fishFrag = `
        uniform sampler2D map; // Texture sampler
        varying vec2 vUv;

        void main() {
            vec4 texColor = texture2D(map, vUv);
            gl_FragColor = texColor;
        }
    `;

    
    function trySetGigiStart() {
        let gigiStart = scene.getObjectByName('gigi_start_pos');
        
        if(gigiStart) {
            //
            
            let gigiWorldPosTemp = gigiStart.getWorldPosition(new THREE.Vector3());
            
            // console.log('moving gigi to start position');
            gigiRef.current.position.copy(gigiWorldPosTemp);
            
            //rotate 180 degrees on the y axis
            gigiRef.current.rotation.y = Math.PI;

            setGigiStartRef.current = true;
            
            setGigiWorldPos(gigiWorldPosTemp);
        }
    }

    function mapRange(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }

    function smoothstep(min, max, value) {
        let x = Math.max(0, Math.min(1, (value - min) / (max - min)));
        return x * x * (3 - 2 * x);
    }
          
    
    function followAround() {
        //get keys of avatarDataRef
        let socketIDs = Object.keys(avatarDataRef.current);

        //make a list of all socketIDs that have a message that includes 'gigi' including lastGigiTimestamp for each one, and sort them by lastGigiTimestamp
        let gigiSocketIDs = [];
        for(let i = 0; i < socketIDs.length; i++) {

            let lastGigiTime = avatarDataRef.current[socketIDs[i]].lastGigiTimestamp;

            //check if time was in the last minute
            
            // if(avatarDataRef.current[socketIDs[i]].message.toLowerCase().includes('gigi')) {
            if(Date.now() - lastGigiTime < 60000) {
                gigiSocketIDs.push({socketID: socketIDs[i], lastGigiTimestamp: avatarDataRef.current[socketIDs[i]].lastGigiTimestamp});
            }
        }

        if(gigiSocketIDs.length == 0) {
            return;
        }

        gigiSocketIDs.sort((a, b) => {
            return a.lastGigiTimestamp - b.lastGigiTimestamp;
        });

        //get socketID with the most recent gigi message
        let mostRecentSocketID = gigiSocketIDs[gigiSocketIDs.length - 1].socketID;

        let callingAvatar = scene.getObjectByName(mostRecentSocketID.toString());


        //MOVE GIGI TO CALLING AVATAR
        if(callingAvatar) {
            let callingAvatarWorldPos = callingAvatar.getWorldPosition(new THREE.Vector3());

            if(Math.random() > 0.99 || targetPosRef.current == null) {  

                //offset callingAvatarWorldPos by a random amount in the x and z axis
                let offset = new THREE.Vector3(Math.random() * 10.0, 0, Math.random() * 10.);
                callingAvatarWorldPos.add(offset);
                targetPosRef.current = callingAvatarWorldPos;
            }


            //SPIRAL MOVEMENT
            let dist = callingAvatarWorldPos.distanceTo(gigiRef.current.position);
            let minRad = 2.0;
            let maxRad = 40.0;
            let circleRad = mapRange(dist, 10, 30, minRad, maxRad);
            // angle2Ref.current += mapRange(dist, 10, 30, 0.1, 0.3);
            angle2Ref.current += mapRange(smoothstep(10, 30, dist), 0, 1, 0.1, 0.3);

            circleRad = Math.min(circleRad, maxRad);
            circleRad = Math.max(circleRad, minRad);

            let offsetX = Math.sin(angle2Ref.current) * circleRad;
            let offsetZ = Math.cos(angle2Ref.current) * circleRad;

            let targetPos = targetPosRef.current.clone();
            targetPos.x += offsetX;
            targetPos.z += offsetZ;

            // let velocity = mapRange(dist, 5, 30, 0.0, 0.8);
            let velocity = mapRange(smoothstep(5, 30, dist), 0, 1, 0.0, 0.8);

            // gigiRef.current.position.lerp(targetPos, 0.02);
            let gigiCurPos = gigiRef.current.position.clone();
            let gigiCurPosToTargetPos = targetPos.clone().sub(gigiCurPos);
            gigiCurPosToTargetPos.normalize();
            gigiCurPosToTargetPos.multiplyScalar(velocity);
            gigiCurPos.add(gigiCurPosToTargetPos);

            gigiRef.current.position.copy(gigiCurPos);


            //rotate on y axis to face calling avatar
            let gigiWorldPosToCallingAvatar = callingAvatarWorldPos.clone().sub(gigiCurPosRef.current);
            gigiWorldPosToCallingAvatar.y = 0;
            gigiWorldPosToCallingAvatar.normalize();

            let gigiWorldPosToCallingAvatarAngle = Math.atan2(gigiWorldPosToCallingAvatar.x, gigiWorldPosToCallingAvatar.z);
            gigiRef.current.rotation.y += (gigiWorldPosToCallingAvatarAngle - gigiRef.current.rotation.y) * 0.1;

        }
    }

    function animateAttractor() {
        if(attractorRef.current) {
            angleRef.current += 0.01;
            let offsetY = Math.sin(angleRef.current * 10.0) * 0.5 + 0.5;
            offsetY *= 5.0;

            //spin position around in a circle of radius 10
            let x = Math.cos(angleRef.current * 100.0) * 3;
            let z = Math.sin(angleRef.current * 100.0) * 3;

            attractorRef.current.position.set(x, offsetY + 2.0, z);
        }
    }

    function typeMessage(message, element, delay, callback) {
        let index = 0;
      
        function typeChar() {
          if (index < message.length) {
            const charSpan = document.createElement('span');
            charSpan.innerText = message[index];
            element.appendChild(charSpan);
            index++;
            setTimeout(typeChar, delay);
          } else {
            typingInProgress.current = false;
            if (typeof callback === 'function') {
              callback();
            }
          }
        }
      
        typeChar();
    }
      
            
    function attractBalls() {
        //add force to balls to attract them to gigi
        for(let i = 0; i < ballRefs.current.length; i++) {
            let ball = ballRefs.current[i];

            if(ball) {
                let ballPosition = ball.translation();

                let ballWorldPos = new THREE.Vector3(ballPosition.x, ballPosition.y, ballPosition.z);

                // console.log(ballWorldPos);
                let ballToGigi = gigiCurPosRef.current.clone().sub(ballWorldPos);
                ballToGigi.normalize();

                //scale force based on distance, greater distance = greater force
                let distance = ballWorldPos.distanceTo(gigiCurPosRef.current);
                ballToGigi.multiplyScalar(distance * 0.05);

                let forceX = ballToGigi.x;
                let forceY = ballToGigi.y;
                let forceZ = ballToGigi.z;

                //clamp force
                forceX = Math.min(Math.max(forceX, -1), 1);
                forceY = Math.min(Math.max(forceY, -1), 1);
                forceZ = Math.min(Math.max(forceZ, -1), 1);

                ball.addForce({x: forceX, y: forceY, z: forceZ}, true);

                // // console.log(ballToGigi);

                // ball.addForce({x: ballToGigi.x, y: ballToGigi.y, z: ballToGigi.z}, true);
            }
        }
    }

    useEffect(() => {
        if (gigiWorldPos && fishGLB.nodes) {
          const instances = [];
      
          let scale = 0.35;
          for (let i = 0; i < numBalls; i++) {
            let startPos = new THREE.Vector3(
              gigiWorldPos.x,
              gigiWorldPos.y + 5 * i,
              gigiWorldPos.z
            );
      
            let randMass = Math.random() * 10.0 + 10.0;
            let randLinearDamping = Math.random() * 0.4 + 0.3;
            let randScale = Math.random() * 0.6 + 0.2;
      
            instances.push({
              key: `instance_${i}`,
              position: startPos,
              linearDamping: randLinearDamping,
              mass: randMass,
              scale: [randScale, randScale, randScale],
            });
          }

          let fishMap = fishGLB.nodes.fish.material.map;

          const fishMat = new THREE.ShaderMaterial({
                uniforms: {
                    time: { value: 0.0 },
                    map: { value: fishMap },
                },
                vertexShader: fishVert,
                fragmentShader: fishFrag,
          });

        //   fishMatRef.current = fishMat;
      
          setBalls(
            <InstancedRigidBodies 
            ref={ballRefs} 
            instances={instances} 
            // linearDamping={0.8}
            // mass={80}
            colliders={'ball'}
            // colliderNodes={[
            //     <BallCollider args={[scale*3]} />,
            //   ]}        
            >
              <instancedMesh
                args={[fishGLB.nodes.fish.geometry, fishMat, numBalls]}
                count={numBalls}
                ref={instancedFishRef}
              />
            </InstancedRigidBodies>
          );
        }
      }, [gigiWorldPos, fishGLB]);
      
    useFrame(() => {

        if(instancedFishRef.current) {
            // console.log(instancedFishRef.current);
            instancedFishRef.current.material.uniforms.time.value += 0.2;
        }

        if(tornadoMatRef.current) {
            tornadoMatRef.current.uniforms.time.value += 0.01;
        }

        if(tornadoMat2Ref.current) {
            tornadoMat2Ref.current.uniforms.time.value += 0.01;
        }

        if(tornadoMat3Ref.current) {
            tornadoMat3Ref.current.uniforms.time.value += 0.01;
        }

        if(!setGigiStartRef.current) {
            trySetGigiStart();
            // console.log('setting gig start');
        }

        else if(groundColliderRef.current == null) {
            groundColliderRef.current = scene.getObjectByName('collider_ground');
            // console.log('setting ground collider');
        }

        else {
            // console.log('animating gigi');
            //search avatarDataRef for messages that include 'gigi'
            gigiCurPosRef.current.copy(gigiRef.current.position);
        
            followAround();
            // attractBalls();

            animateAttractor();

            //tether Gigi to the ground
            let raycaster = new THREE.Raycaster();
            raycaster.layers.set(colliderLayer);
            //set raycaster origin to gigi's position
            let posAboveGigi = gigiRef.current.position.clone();
            posAboveGigi.y += 10;

            raycaster.set(posAboveGigi, new THREE.Vector3(0, -1, 0));
            let intersects = raycaster.intersectObject(groundColliderRef.current);

            if(intersects.length > 0) {
                //set gigi's position to 5 units above collider
                gigiRef.current.position.copy(intersects[0].point);
                // gigiRef.current.position.y += 2;
            }
        }

        //HANDLE MESSAGE
        if (gigiMessageRef.current !== '') {
            let message = gigiMessageRef.current;
          
            if (message != undefined && messageRef.current && !typingInProgress.current) {
              if (currentMessage.current !== message) {
                // message changed
                console.log('message changed: ' + message);
                currentMessage.current = message;
          
                // set opacity to 1
                messageRef.current.style.opacity = 1.0;
                messageRef.current.innerText = '';
          
                if (messageFadeTimer.current != null) {
                  // clear last timer
                  clearTimeout(messageFadeTimer.current);
                }
          
                typingInProgress.current = true;
          
                // type out the message slowly
                const typingDelay = 50; // adjust this value to change typing speed (in milliseconds)
                typeMessage(message, messageRef.current, typingDelay, () => {
                  // in 20 seconds, animate opacity to 0
                  messageFadeTimer.current = setTimeout(() => {
                    console.log('fade timer');
          
                    if (messageRef.current != null) {
                      messageRef.current.style.opacity = 0.0;
                    } else {
                      console.log('message ref is null?');
                    }
                  }, 20000);
                });
              }
            }
          }
          
    });

    return(
        <>
        
        {balls}

        {gigiGLB &&
        <group
        ref={gigiRef}
        scale={[0.9, 0.9, 0.9]}
        >
            <Html
                ref={messageRef}
                zIndexRange={[0, 5]}
                center
                position={[0, 3, 0]}
                style={{
                    color: '#47003f',
                    fontFamily: 'Duke',
                    background: 'rgba(255,255,255,0.5)',
                    width: '250px',
                    height: 'auto',
                    borderRadius: '10px',
                    fontSize: '1.0rem',
                    filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',
                    padding: '10px',
                    textAlign: 'left',
                    lineHeight: '1.0',
                    fontWeight: 'normal',
                    pointerEvents: 'none',
                    textShadow: '0px 0px 5px rgba(255,255,255,1.0), 0px 0px 10px rgba(255,255,255,1.0), 0px 0px 15px rgba(255,255,255,1.0)',
                    transform: "translate(-50%, -50%)",
                    WebkitTouchCallout: "none", 
                    transition: "opacity 2.0s",
                    WebkitUserSelect: "none", 
                    KhtmlUserSelect: "none",
                    MozUserSelect: "none", 
                    MsUserSelect: "none", 
                    UserSelect: "none",
                    opacity: 0.0
                }}
            />


            <mesh ref={attractorRef}>
            <Attractor range={40} strength={3} type="linear"/>
            </mesh>

            {/* add 100 rigidbody componentts */}
            <mesh 
            geometry={gigiGLB.nodes.cap.geometry}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            >
                <meshStandardMaterial color="pink" map={gigiCapTex} map-flipY={false} />
            </mesh>
            <mesh
            geometry={gigiGLB.nodes.tornado.geometry}
            position={[0, 0, 0]}
            renderOrder={300}
            >
                {/* <meshStandardMaterial></meshStandardMaterial> */}
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader={vertexShader}
                fragmentShader={fragShader}
                ref={tornadoMatRef}
                transparent={true}
                side={THREE.DoubleSide}
                depthWrite={false}
                depthTest={false}
                uniforms={{
                    time: { value: 0 },
                    layerIndex: { value: 0.0 }
                }}
                />
            </mesh>
            <mesh
            geometry={gigiGLB.nodes.tornado.geometry}
            position={[0, 0, 0]}
            scale={[0.8, 0.9, 0.8]}
            renderOrder={300}
            >
                {/* <meshStandardMaterial></meshStandardMaterial> */}
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader={vertexShader}
                fragmentShader={fragShader} 
                ref={tornadoMat2Ref}
                transparent={true}
                side={THREE.DoubleSide}
                depthWrite={false}
                depthTest={false}
                uniforms={{
                    time: { value: 0 },
                    layerIndex: { value: 1.0 }
                }}
                />
            </mesh>
            <mesh
            geometry={gigiGLB.nodes.tornado.geometry}
            position={[0, 0, 0]}
            scale={[0.7, 0.8, 0.7]}
            renderOrder={300}
            >
                {/* <meshStandardMaterial></meshStandardMaterial> */}
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader={vertexShader}
                fragmentShader={fragShader}
                ref={tornadoMat3Ref}
                transparent={true}
                side={THREE.DoubleSide}
                depthWrite={false}
                depthTest={false}
                uniforms={{
                    time: { value: 0 },
                    layerIndex: { value: 2.0 }
                }}
                />
            </mesh>

        </group>}
        </>

        // <mesh
        // position={[0, 0, 0]}
        // ref={gigiRef}
        // >
        //     <sphereGeometry args={[5, 32, 32]} />
        //     <meshBasicMaterial color="blue" />
        // </mesh>
    );
}
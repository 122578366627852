import React from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { SceneContext } from './Lunar';
import { isMobile } from 'react-device-detect';
import Switch from "react-switch";
import { useIsWideView } from './detectWideView';
import './Lunar.css';
import { Contract, providers, utils } from 'ethers';
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import AUCTION_ABI from './auctionabi.json';

//TODO CHANGE 
const AUCTION_ADDRESS = '0x454A91351f41e5311Aec4A5De205b725F1EFfa9b';
const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

const arweaveBaseURLImg = "https://arweave.net/mkRBmKQtUm6q63mBHy6TRTOIKHM-sr68yoX3zOmObTE/";
const arweaveBaseURLVid = "https://arweave.net/EKrbn-uVoj1ldVoF193zBqPvgyElWYoV-76FYL0ks9Q/";

function CountdownTimer({endTime, showDarkYecheMessage}) {
    const [timeLeft, setTimeLeft] = useState(null);

    const { darkYecheEnabled } = useContext(SceneContext);
  
    useEffect(() => {
        let intervalId;
    
        // Immediately update the time left if endTime has changed
        const timeLeft = Math.max(0, endTime - Date.now());
        setTimeLeft(timeLeft);
    
        // Update the time left every second
        intervalId = setInterval(() => {
          const newTimeLeft = Math.max(0, endTime - Date.now());
          setTimeLeft(newTimeLeft);
        }, 1000);
    
        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, [endTime]);    
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the hours, minutes, and seconds left
    const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);
    const hours = Math.floor(timeLeft / 1000 / 60 / 60) % 24;
    const minutes = Math.floor(timeLeft / 1000 / 60) % 60;
    const seconds = Math.floor(timeLeft / 1000) % 60;
  
    return (
        <div className="TimerContainer">
        {(timeLeft != 0 && darkYecheEnabled && showDarkYecheMessage)  &&    
        <div className="OvertimeTimerText">
        We are now in <div className="DarkYecheOvertimeTitle">Dark Yeche Overtime.</div> The timer for each work will conclude at exactly 10 days after first bid was placed. Each artwork will be transferred to the winning bidder's wallet within 24 hours of the individual artwork’s auction close.
        </div>}

        {timeLeft == 0 ? "" : `Auction ends in ${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`}
        </div>
    );
}  

export default function LunarOverlay() {

    const AUCTION_DURATION = 864000; // 10 days
    const DARK_MODE_TIME = 1678058899;

    const MESSAGE_CHAR_LIMIT = 150;

    const [connectText, setConnectText] = useState("wallet connect");
    const [connected, setConnected] = useState(false);

    const [humanAddress, setHumanAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const infuraProviderRef = useRef(null);

    const [contract, setContract] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [auctionEnd, setAuctionEnd] = useState(null);

    const wideView = useIsWideView();

    const loadedAuctionDataRef = useRef(false);

    const auctionDataRef = useRef([]);

    const showOverlayRef = useRef(false);
    
    const autoTourPlayingRef = useRef(false);
    const autoTourIntervalRef = useRef(null);

    const progressBarRef = useRef(null);
    const progressBarIntervalRef = useRef(null);

    const autoTourStartTimeRef = useRef(null);
    const autoTourButtonRef = useRef(null);

    const artworkBidInfoRef = useRef({});
    const artistListTimerRef = useRef({});

    const [showBidsOverlay, setShowBidsOverlay] = useState(false);
    const [avatarVisible, setAvatarVisible] = useState(false);


    const [bidButtonText, setBidButtonText] = useState("Bid");

    const showOverlayClass = !wideView ? "showOverlayMobile" : "showOverlayDesktop";
    const hideOverlayClass = !wideView ? "hideOverlayMobile" : "hideOverlayDesktop";

    const curArtworkRef = useRef("");

    const showedSkipIntro = useRef(false);

    const navigatorRef = useRef(null);
    const overlayRef = useRef(null);

    const bidBoxRef = useRef(null);
    const bidInputRef = useRef(null);
    const messageInputRef = useRef(null);

    const highestBidInfoRef = useRef(null);

    const arweaveLinkRef = useRef(null);

    const artDescriptionRef = useRef(null);
    const bidHistoryRef = useRef(null);
    const artThumbnailRef = useRef(null);
    const videoThumbnailRef = useRef(null);

    const crossfadeOverlayRef = useRef(null);

    const showingArtistListRef = useRef(false);

    const teleportingRef = useRef(false);

    // const videosMutedRef = useRef(true);
    const muteIconRef = useRef(null);
    const arrowIconRef = useRef(null);

    const showBidsOverlayRef = useRef(null);

    const artistListToggleRef = useRef(null);

    const artistListRef = useRef(null);
    const artistListInnerRef = useRef(null);

    const artHeaderRef = useRef(null);
    const curBidInfoRef = useRef(null);
    const overlayPaneInfoRef = useRef(null);

    const timerRef = useRef(null);

    const paneIsDraggingRef = useRef(false);

    const skipIntroRef = useRef(null);
    const skippedIntro = useRef(false);

    const [artworkTitle, setArtworkTitle] = useState("");
    const [artistName, setArtistName] = useState("");

    const { 
        crosshairArtworkRef, 
        fullDisplayRef,
        artPlanesRef,
        switchArtInstantRef,
        curPlaneIndexRef,
        artworkInfoRef,
        cameraPosRef,
        crosshairRef,
        joystickRef,
        jumpRef,
        videoElemsRef,
        videosMutedRef,
        oceanAudioRef,
        windAudioRef,
        oceanWindAudioRef,
        ridingWormRef,
        shouldTeleportWormRef,
        fellInWaterRef,
        introCamPctRef,
        tutorialVisibleRef,
        tutorialRef,
        userAddressRef, 
        userENSRef,
        numUserBidsRef,
        avatarVisibleRef,
        messageRef,
        darkYecheEnabled,
        darkYecheVideoRef
    } = useContext(SceneContext);

    const lastFullDisplayRef = useRef(fullDisplayRef.current);

    async function loadAuctionData() {

        // if(artPlanesRef.current.length == 0) {
        //     //call tihs function again in 1 second
        //     setTimeout(loadAuctionData, 1000);
        //     return;
        // }

        // else {
        //     console.log("loading auction data");
        //     console.log(artPlanesRef.current);
        // }


        for(let i = 0; i < artPlanesRef.current.length; i++) {
            let artworkName = artPlanesRef.current[i].artName;
            let auctionID = artPlanesRef.current[i].auctionID;

            attemptRefreshAuction(auctionID);

            //todo - get bid history for each artwork
        }
    }

    async function updateOverlay() {

        if(!loadedAuctionDataRef.current && artPlanesRef.current.length > 0) {
            console.log("loading auction data");
            console.log(artPlanesRef.current.length);
            loadAuctionData();
            loadedAuctionDataRef.current = true;
        }


        if(introCamPctRef.current > 0.1 && !showedSkipIntro.current) {
            //show skip intro button
            skipIntroRef.current.style.display = "block";
            showedSkipIntro.current = true;
        }

        //if introCamPctRef.current >=1.0 and skipIntro button is still visible, hide it
        if(introCamPctRef.current >= 1.0 && !skippedIntro.current) {
            //hide skip intro button
            skipIntroRef.current.style.display = "none";
            skippedIntro.current = true;
        }

        if (crosshairArtworkRef.current != curArtworkRef.current) {

            if(fullDisplayRef.current) {
                curArtworkRef.current = fullDisplayRef.current;
            }

            else {
                curArtworkRef.current = crosshairArtworkRef.current;
            }

            const artwork = artworkInfoRef.current.find(artwork => artwork.filename === curArtworkRef.current);

            if(artwork) {
                //fade out artwork
                artHeaderRef.current.classList.remove("fadeIn");
                artHeaderRef.current.classList.add("fadeOut");

                //fade out info pane
                // if(showOverlayRef.current) {
                overlayPaneInfoRef.current.classList.remove("fadeIn");
                overlayPaneInfoRef.current.classList.add("fadeOut");
                // }

                //fade out timer
                timerRef.current.classList.remove("fadeIn");
                timerRef.current.classList.add("fadeOut");

                //fade out bid box
                bidBoxRef.current.classList.remove("fadeIn");
                bidBoxRef.current.classList.add("fadeOut");
                
                //update artworkTitle and artistName after 2s
                await new Promise(r => setTimeout(r, 500));
                
                //TODO change info pane 
                updatePaneInfo();

                setArtworkTitle(artwork.title);
                setArtistName(artwork.name);

                //fade in timer
                timerRef.current.classList.remove("fadeOut");
                timerRef.current.classList.add("fadeIn");

                //fade in artwork
                artHeaderRef.current.classList.remove("fadeOut");
                artHeaderRef.current.classList.add("fadeIn");

                //fade in info pane
                // if(showOverlayRef.current) {
                overlayPaneInfoRef.current.classList.remove("fadeOut");
                overlayPaneInfoRef.current.classList.add("fadeIn");
                // }
            }
        }

        if(fullDisplayRef.current != "" && lastFullDisplayRef.current == "" && !showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            updatePaneInfo();
        }

        else if(fullDisplayRef.current == "" && lastFullDisplayRef.current != "" && showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            if(autoTourPlayingRef.current) {
                console.log("autotour playing, stopping");
                toggleAutoTour();
            }
        }

        lastFullDisplayRef.current = fullDisplayRef.current;
    }

    function setRidingWorm(riding) {
        ridingWormRef.current = riding;

        if(jumpRef.current) {
            if(riding) {
                jumpRef.current.classList.add('Uturn');
                jumpRef.current.classList.remove('Jump');
            }

            else {
                jumpRef.current.classList.add('Jump');
                jumpRef.current.classList.remove('Uturn');    
            }
        }
    }

    //todo
    function initArtistList() {
        //parse through artworkInfoRef and create a list of artists, for each artist create a list of artworks. so artworkList['artistName'] = [artwork1, artwork2, ...]
        console.log('generating artist list..');

        //create a list of artists
        let artistList = [];
        artworkInfoRef.current.forEach(artwork => {
            if(!artistList.includes(artwork.name)) {
                artistList.push(artwork.name);
            }
        });

        //create a list of artworks for each artist
        let artworkList = {};
        artistList.forEach(artist => {
            artworkList[artist] = [];
        });

        artworkInfoRef.current.forEach(artwork => {
            artworkList[artwork.name].push([artwork.title, artwork.filename, artwork.auctionID]);
        });

        //for each artwork add a child div to the artistListRef of class Artist-list-item
        // set the inner text to artist name - artwork title
        for(let artist in artworkList) {
            //create a div for the artist of class Artist-buttons

            let artistGroup = document.createElement("div");
            artistGroup.classList.add("Artist-group");

            let artistButtons = document.createElement("div");
            artistButtons.classList.add("Artist-buttons");

            let artistNameDiv = document.createElement("div");
            artistNameDiv.classList.add("Artist-list-name");
            artistNameDiv.innerText = artist;

            artistGroup.appendChild(artistNameDiv);
            artistGroup.appendChild(artistButtons);

            // //add div to artistListRef
            artistListInnerRef.current.appendChild(artistGroup);

            artworkList[artist].forEach(artInfo => {
                let artworkTitle = artInfo[0];
                let artworkFilename = artInfo[1];
                let auctionID = artInfo[2];

                let artworkButton = document.createElement("button");
                artworkButton.classList.add("Artist-list-item");

                //add a child of class Artist-list-title to artworkButton
                let artworkTitleDiv = document.createElement("div");
                artworkTitleDiv.classList.add("Artist-list-title");
                artworkTitleDiv.innerText = artworkTitle;

                let artworkTimerDiv = document.createElement("div");
                artworkTimerDiv.classList.add("Artist-list-timer");
                artworkTimerDiv.innerText = "auction hasn't started";

                //add a selector called artworFilename-endTime to the artworkTimerDiv, so that it is <div class="Artist-list-timer" artworkFilename-auctionEndTime="endTime" ">auction hasn't started</div>
                artworkTimerDiv.setAttribute('work-' + auctionID + '-auctionEnd', 0);

                //create a setInterval that updates every second and uses the artworkFilename-auctionEndTime attribute to update the timer
                let timerInterval = setInterval(() => {
                    let auctionEnd = artworkTimerDiv.getAttribute('work-' + auctionID + '-auctionEnd');
                    let timeLeft = auctionEnd - Math.floor(Date.now() / 1000);

                    let showTimers = Date.now() > DARK_MODE_TIME * 1000;

                    if(timeLeft > 0) {
                        let days = Math.floor(timeLeft / (3600 * 24));
                        let hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
                        let minutes = Math.floor((timeLeft % 3600) / 60);
                        let seconds = Math.floor(timeLeft % 60);

                        let timeString = "";

                        if(days > 0) {
                            timeString += days + "d ";
                        }

                        if(hours > 0) {
                            timeString += hours + "h ";
                        }

                        if(minutes > 0) {
                            timeString += minutes + "m ";
                        }

                        if(seconds > 0) {
                            timeString += seconds + "s";
                        }

                        // if(darkYecheEnabled != undefined && darkYecheEnabled) {
                        if(showTimers) {
                            artworkTimerDiv.innerText = timeString;

                            //set display style to block if it is none
                            artworkTimerDiv.style.display = "block";

                        }
                    }

                    else {
                        artworkTimerDiv.innerText = "";

                        //set display style to none if it is block
                        artworkTimerDiv.style.display = "none";

                        // clearInterval(timerInterval);
                    }
                }, 1000);

                artistListTimerRef.current[auctionID] = artworkTimerDiv;

                let artworkBidInfo = document.createElement("div");
                artworkBidInfo.classList.add("Artist-list-bid-info");

                artworkBidInfoRef.current[artworkFilename] = artworkBidInfo;

                // artworkButton.appendChild(artistNameDiv);
                artworkButton.appendChild(artworkTitleDiv);
                artworkButton.appendChild(artworkTimerDiv);
                artworkButton.appendChild(artworkBidInfo);

                // artworkButton.innerText = artist + " - " + artworkTitle;

                //set onclick to switch to the artwork
                artworkButton.addEventListener("click", () => {
                    // switchArtInstantRef.current(artworkFilename);

                    //fade in crossfade overlay
                    crossfadeOverlayRef.current.classList.remove("fadeOut");
                    crossfadeOverlayRef.current.classList.add("fadeIn");

                    //fade out artist list
                    artistListRef.current.classList.add("fadeOut");

                    //wait 0.5 seconds, then switch to the artwork
                    setTimeout(() => {
                        fullDisplayRef.current = artworkFilename;
                        crosshairArtworkRef.current = artworkFilename;

                        switchArtInstantRef.current = true;

                        let planeIndex = artPlanesRef.current.findIndex(plane => plane && plane.artName == artworkFilename);

                        curPlaneIndexRef.current = planeIndex;

                        // ridingWormRef.current = false;
                        setRidingWorm(false);

                        if(artPlanesRef.current[planeIndex].inTower) {
                            shouldTeleportWormRef.current = true;
                        }

                        else {
                            shouldTeleportWormRef.current = false;
                        }

                        //fade out crossfade overlay
                        crossfadeOverlayRef.current.classList.remove("fadeIn");
                        crossfadeOverlayRef.current.classList.add("fadeOut");

                        //remove fade out class
                        artistListRef.current.classList.remove("fadeOut");
                        //set opacity to 1
                        artistListRef.current.style.opacity = 1;

                        //toggle artist list
                        toggleArtistList();

                    }, 500);
                });

                //add the artwork button to the artist buttons
                artistButtons.appendChild(artworkButton);

                // //add the artwork div to the artist list
                // artistListRef.current.appendChild(artworkButton);
            });

        }
        // console.log(artistList);
    }

    function handlePaneDrag(e) {
        //get the height of the navigator
        let navigatorHeight = navigatorRef.current.getBoundingClientRect().height;

        //set top value so that the pointer is in the vertical center of the navigator

        let top;

        if(isMobile) {
            top = e.touches[0].clientY - navigatorHeight/2;
        }

        else {
            top = e.clientY - navigatorHeight/2;
        }

        //constrain top to be greater than 15vh
        let constrainTop = 0.15 * window.innerHeight;
        if(top < constrainTop) {
            top = constrainTop;
        }

        //constrain top to be less than 95vh
        let constrainBottom = 0.94 * window.innerHeight;
        if(top > constrainBottom) {
            top = constrainBottom;
        }

        // overlayRef.current.style.animation = "none";
        // navigatorRef.current.style.animation = "none";

        if(!wideView) {
            overlayRef.current.classList.remove('showOverlayMobileHeight');
            overlayRef.current.classList.remove('hideOverlayMobileHeight');
        }

        overlayRef.current.classList.remove(showOverlayClass);
        navigatorRef.current.classList.remove(showOverlayClass);
        overlayRef.current.classList.remove(hideOverlayClass);
        navigatorRef.current.classList.remove(hideOverlayClass);

        //set transition values to instant
        overlayRef.current.style.transition = "none";
        navigatorRef.current.style.transition = "none";

        overlayRef.current.style.top = top + "px";
        navigatorRef.current.style.top = top + "px";

        //set height of overlay
        overlayRef.current.style.height = "calc(" + "100vh - " + top + "px)";

        e.stopPropagation();
        e.preventDefault();

    }

    function teleportToRandomArtwork() {
        teleportingRef.current = true;
        crossfadeOverlayRef.current.classList.remove("fadeOut");
        crossfadeOverlayRef.current.classList.add("fadeIn");

        let planeIndex = Math.floor(Math.random() * artPlanesRef.current.length);
        let artworkFilename = artPlanesRef.current[planeIndex].artName;

        console.log('teleporting to ' + artworkFilename + ' at index ' + planeIndex );

        //wait 0.5 seconds, then switch to the artwork
        setTimeout(() => {
            fullDisplayRef.current = artworkFilename;
            crosshairArtworkRef.current = artworkFilename;

            switchArtInstantRef.current = true;

            curPlaneIndexRef.current = planeIndex;

            // ridingWormRef.current = false;
            setRidingWorm(false);

            if(artPlanesRef.current[planeIndex].inTower) {
                shouldTeleportWormRef.current = true;
                setRidingWorm(true);
            }

            else {
                shouldTeleportWormRef.current = false;
            }

            //fade out crossfade overlay
            crossfadeOverlayRef.current.classList.remove("fadeIn");
            crossfadeOverlayRef.current.classList.add("fadeOut");

            fellInWaterRef.current = false;
            teleportingRef.current = false;
        }, 500);
    }

    //GET ON CHAIN DATA - todo, do this when you look at a work 
    // useEffect(() => {
    //     //for each artwork, get numBids + bid history - each bid is bidder, timestamp, amount

    //     //order by time, most recent is also highest bid

    //     //save into ref
    //     async function loadContractData() {
    //         // console.log('refreshing on chain data...');

    //         const numArtworks = artPlanesRef.current.length;
            
    //         if(numArtworks == 0 ) return;

    //         else {

    //             let auctionDataTemp = [];
    //             //auctionData is an array of objects
    //             //each object has {numBids: number, bidHistory: array of objects, containing bidder, reserve price, timestamp, amount}, firstBidTime, duration

    //             for(let i = 0; i < numArtworks; i++) {
    //                 const auction = await contract.auctions(i);
    //                 // console.log(auction);

    //                 if(!auction.approved) {
    //                     auctionDataTemp[i] = {
    //                         reservePrice: 0,
    //                         lastBidder: '0x0000000000000000000000000000000000000000',
    //                         lastBidAmount: 0
    //                     }
    //                 }

    //                 else {
    //                     let reservePrice = auction.reservePrice;
    //                     // console.log('reservePrice ' + reservePrice)
    //                     let lastBidder = auction.bidder;
    //                     // console.log('lastBidder ' + lastBidder)
    //                     let lastBidAmount = auction.amount;
    //                     // console.log('lastBidAmount ' + lastBidAmount)

    //                     let lastBidderName = await infuraProvider.lookupAddress(lastBidder);

    //                     if(lastBidderName != null) {
    //                         lastBidder = lastBidderName;
    //                     }

    //                     auctionDataTemp[i] = {
    //                         reservePrice: reservePrice,
    //                         lastBidder: lastBidder,
    //                         lastBidAmount: lastBidAmount
    //                     };
    //                 }
    //             }

    //             auctionDataRef.current = auctionDataTemp;
    //         }
    //     }


    //     //in the beginning 
    //     if(contract != null && infuraProvider != null) {
    //         // loadContractData();

    //         const firstLoad = setInterval(() => {
    //             if(artPlanesRef.current.length > 0) {
    //                 console.log('getting initial contract data...')
    //                 loadContractData();
    //                 clearInterval(firstLoad);
    //             }
    //         }, 1000);
    //     }

    //     //refresh every 30 seconds
    //     if(contract != null && infuraProvider != null) {

    //         //call this function every 20 seconds
    //         const interval = setInterval(() => {
    //             console.log('refreshing contract data...')
    //             loadContractData();
    //         }
    //         , 30000);
    //     }

    // }, [contract, infuraProvider]);


    async function initWeb3() {

        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);
        // setInfuraProvider(infuraProviderTemp);
        infuraProviderRef.current = infuraProviderTemp;


        //TEST
        let contractTemp = new Contract(AUCTION_ADDRESS, AUCTION_ABI, infuraProviderTemp);

        setContract(contractTemp);
        // const ownerTest = await contract.owner();
        // console.log('owner: ' + ownerTest);


        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);
    }

    
    useEffect(() => {
        initWeb3();

        initArtistList();

        loadAuctionData();

        const end = new Date(0);
        end.setUTCSeconds(1678058899);

        if(!darkYecheEnabled) {
            setAuctionEnd(end);
        }

        //this won't work if the user resizes the window
        if(!isMobile) {
        //set up onMouseMove event listener
            window.addEventListener("mousemove", (e) => {
                if(crosshairRef.current) {
                    // console.log("mousemove")
                    // crosshairRef.current.style.left = e.clientX + "px";
                    // crosshairRef.current.style.top = e.clientY + "px";

                    // //update the crosshair so the mouse is always in the center

                    let newLeft = e.clientX - (crosshairRef.current.offsetWidth / 2);

                    let constrainMarginWidth = crosshairRef.current.offsetWidth * 1.2;
                    let constrainMarginHeight = crosshairRef.current.offsetHeight * 1.2;

                    //constrain left to be within the window
                    if(newLeft < constrainMarginWidth  - (crosshairRef.current.offsetWidth)) {
                        newLeft = constrainMarginWidth - (crosshairRef.current.offsetWidth);
                    }
                    else if(newLeft > window.innerWidth - constrainMarginWidth) {
                        newLeft = window.innerWidth - constrainMarginWidth;
                    }

                    let newTop = e.clientY - (crosshairRef.current.offsetHeight / 2);

                    //constrain top to be within the window
                    if(newTop < constrainMarginHeight - (crosshairRef.current.offsetHeight)) {
                        newTop = constrainMarginHeight - (crosshairRef.current.offsetHeight);
                    }
                    else if(newTop > window.innerHeight - constrainMarginHeight) {
                        newTop = window.innerHeight - constrainMarginHeight;
                    }


                    crosshairRef.current.style.left = newLeft + "px";
                    crosshairRef.current.style.top = newTop + "px";
                }

                if(wideView) return;

                if(paneIsDraggingRef.current) {
                    handlePaneDrag(e);
                }
            });
        }

        else {
            window.addEventListener("touchmove", (e) => {
                if(wideView) return;

                if(paneIsDraggingRef.current) {
                    handlePaneDrag(e);
                }
            });
        }

        //set updateOverlay to run every 300ms -- this is not ideal
        const interval = setInterval(() => {

            if(fellInWaterRef.current && !teleportingRef.current) {
                teleportToRandomArtwork();
            }


            updateOverlay();
        }, 300);
    }, []);

    function scrollOverlayToTop() {
        overlayRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    function toggleOverlay() {
        if (showOverlayRef.current) {
            //set transition values to all 0.5s ease-in-out
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(showOverlayClass);
            navigatorRef.current.classList.add(hideOverlayClass);

            overlayRef.current.classList.remove(showOverlayClass);
            overlayRef.current.classList.add(hideOverlayClass);

            if(wideView) {
                overlayPaneInfoRef.current.classList.remove("fadeIn");
                overlayPaneInfoRef.current.classList.add("fadeOut");

                overlayRef.current.classList.remove('showOverlayDesktopHeight')
                overlayRef.current.classList.add('hideOverlayDesktopHeight');
            }

            else {
                overlayRef.current.classList.remove('showOverlayMobileHeight')
                overlayRef.current.classList.add('hideOverlayMobileHeight');
            }

            scrollOverlayToTop();

            showOverlayRef.current = false;
        } else {
            //set transition values to all 0.5s ease-in-out
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(hideOverlayClass);
            navigatorRef.current.classList.add(showOverlayClass);

            overlayRef.current.classList.remove(hideOverlayClass);
            overlayRef.current.classList.add(showOverlayClass);

            if(wideView) {
                overlayPaneInfoRef.current.classList.remove("fadeOut");
                overlayPaneInfoRef.current.classList.add("fadeIn");

                overlayRef.current.classList.remove('hideOverlayDesktopHeight');
                overlayRef.current.classList.add('showOverlayDesktopHeight')
            }

            else {
                overlayRef.current.classList.remove('hideOverlayMobileHeight');
                overlayRef.current.classList.add('showOverlayMobileHeight')
            }

            scrollOverlayToTop();

            showOverlayRef.current = true;
        }
    }

    function hideCrosshair() {
        console.log('hiding crosshair');

        crosshairRef.current.classList.remove("hueAndPulse")
        crosshairRef.current.classList.remove("hueAnim");

        crosshairRef.current.classList.add("fadeOut");
    }

    async function incrementArtwork(forward) {        
        if(fullDisplayRef.current == "") {
            selectClosestPlane();
            return;
        }

        await new Promise(r => setTimeout(r, 500));

        if(fullDisplayRef.current == "") return;

        let index = curPlaneIndexRef.current;
        console.log('old index: ' + index );

        let direction = forward ? 1 : -1;

        let nextIndex = index + direction;

        //wrap around 
        if(nextIndex < 0) {
            curPlaneIndexRef.current = artPlanesRef.current.length - 1;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        } 
        
        else if(nextIndex >= artPlanesRef.current.length) {
            curPlaneIndexRef.current = 0;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }
        
        else {
            curPlaneIndexRef.current = nextIndex;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }

        scrollOverlayToTop();

        // ridingWormRef.current = false;
        setRidingWorm(false);

        if(artPlanesRef.current[curPlaneIndexRef.current].inTower) {
            shouldTeleportWormRef.current = true;
        }

        else {
            shouldTeleportWormRef.current = false;
        }

        console.log('new index: ' + curPlaneIndexRef.current );
    }

    function startPaneDrag(e) {
        if(wideView) return;

        console.log('starting pane drag');

        paneIsDraggingRef.current = true;

    }

    function endPaneDrag() {
        if(wideView) return;

        paneIsDraggingRef.current = false;
    }

    function selectClosestPlane() {
        console.log('finding closest plane');

        let closestPlaneIndex = 0;

        let closestPlaneDist = Infinity;

        let camPos = cameraPosRef.current.clone();

        for(let i = 0; i < artPlanesRef.current.length; i++) {
            let plane = artPlanesRef.current[i].plane;
            let planeWorld = new THREE.Vector3();
            plane.getWorldPosition(planeWorld); 
            
            let dist = camPos.distanceTo(planeWorld);

            if(dist < closestPlaneDist) {
                closestPlaneDist = dist;
                closestPlaneIndex = i;
            }
        }

        curPlaneIndexRef.current = closestPlaneIndex;
        fullDisplayRef.current = artPlanesRef.current[closestPlaneIndex].artName;
        switchArtInstantRef.current = false;

        // ridingWormRef.current = false;
        setRidingWorm(false);

        if(artPlanesRef.current[closestPlaneIndex].inTower) {
            shouldTeleportWormRef.current = true;
        }

        else {
            shouldTeleportWormRef.current = false;
        }

        // if(!wideView) hideCrosshair();
        if(isMobile) hideCrosshair();
    }

    function toggleAutoTour() {
        if(!autoTourPlayingRef.current) {

            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_pause.png";

            //remove PlayTour class from autoTourButtonRef and add PauseTour class
            autoTourButtonRef.current.classList.remove("PlayTour");
            autoTourButtonRef.current.classList.add("PauseTour");


            autoTourPlayingRef.current = true;
            // autoTourButtonRef.current.innerText = "■";

            if(fullDisplayRef.current == "") {
                //START WITH CLOSEST TO THE USER 

                //find closest plane to camera.position
                selectClosestPlane();
            }

            let showWorkTime = 8000;
            let camTransitionTime = 2000;
            autoTourStartTimeRef.current = Date.now();

            //run incrementArtwork every 2 seconds
            autoTourIntervalRef.current = setInterval(() => {
                incrementArtwork(true);
            }, showWorkTime + camTransitionTime);

            //set up an interval to map the time the work has been shown from a percent, then set the width of progressBarRef based on that
            progressBarIntervalRef.current = setInterval(() => { 
                let curTime = Date.now();
                let timeElapsed = (curTime - autoTourStartTimeRef.current) % (showWorkTime + camTransitionTime);

                if(timeElapsed < camTransitionTime) {
                    timeElapsed = 0;
                } else {
                    timeElapsed -= camTransitionTime;
                }

                let percent = timeElapsed / showWorkTime;

                if(percent >= 1) {
                    percent = 1;
                }

                progressBarRef.current.style.width = percent * 100 + "%";
            }, 25);

        } else {
            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_play.png";

            //remove PauseTour class from autoTourButtonRef and add PlayTour class
            autoTourButtonRef.current.classList.remove("PauseTour");
            autoTourButtonRef.current.classList.add("PlayTour");

            autoTourPlayingRef.current = false;
            // autoTourButtonRef.current.innerText = "▷";

            progressBarRef.current.style.width = 0 + "%";

            //clear the interval
            clearInterval(autoTourIntervalRef.current);
            clearInterval(progressBarIntervalRef.current);
        }
    }

    function hideTutorial() {
        tutorialVisibleRef.current = false;
        tutorialRef.current.classList.add("fadeOut");

        // if(darkYecheEnabled && darkYecheVideoRef.current) {
        //     //pause the video
        //     darkYecheVideoRef.current.pause();
        // }

        setTimeout(() => {
            // tutorialRef.current.remove();
            //set display to none
            tutorialRef.current.style.display = "none";
            if(darkYecheEnabled) {
                //remove the video from the dom
                darkYecheVideoRef.current.remove();
            }
        }, 500);
    }

    function toggleArtistList() {
        if(tutorialVisibleRef.current) {
            hideTutorial();
        }

        //hide artist list
        if(showingArtistListRef.current) {
            if(isMobile) crosshairRef.current.style.display = "block";

            artistListRef.current.style.display = "none";
            showingArtistListRef.current = false;

            //change inner html of artist list toggle button to "Artists<br/>Index"
            artistListToggleRef.current.innerHTML = "Artist<br/>Index";

            //show overlayRef and navigatorRef
            navigatorRef.current.style.display = "flex";
            overlayRef.current.style.display = "flex";

            //show joystick and jump button
            if(isMobile) {
                joystickRef.current.style.display = "block";
                jumpRef.current.style.display = "block";
            }

            //fade out showBidsOverlayRef
            showBidsOverlayRef.current.classList.remove("fadeIn");
            showBidsOverlayRef.current.classList.add("fadeOut");

            //hide in 500ms
            setTimeout(() => {
                showBidsOverlayRef.current.style.display = "none";
            } , 500);
        }

        //show artist list
        else {
            if(isMobile) crosshairRef.current.style.display = "none";

            artistListRef.current.style.display = "flex";
            showingArtistListRef.current = true;

            //change inner text of artist list toggle button
            artistListToggleRef.current.innerHTML = "Back";

            //hide overlayRef and navigatorRef
            navigatorRef.current.style.display = "none";
            overlayRef.current.style.display = "none";

            //hide joystick and jump button
            if(isMobile) {
                joystickRef.current.style.display = "none";
                jumpRef.current.style.display = "none";
            }

            //fade in showBidsOverlayRef
            showBidsOverlayRef.current.style.display = "flex";
            showBidsOverlayRef.current.classList.remove("fadeOut");
            showBidsOverlayRef.current.classList.add("fadeIn");
        }
    }

    async function attemptRefreshAuction(auctionID) {
        let shouldRequest = false;

        //check if auctionDataRef.current[auctionID] is defined
        if(auctionDataRef.current[auctionID] != undefined) {
            //check if curTimestamp - auctionDataRef.current[auctionID].fetchedDataAt > 60
            let curTimestamp = Math.floor(Date.now() / 1000);
            let timeSinceLastFetch = curTimestamp - auctionDataRef.current[auctionID].fetchedDataAt;

            let tooRecent = timeSinceLastFetch < 600;

            if(!tooRecent) {
                //fetch data again
                // await refreshAuctionData(auctionID);
                shouldRequest = true;
            }

        }

        else {
            //fetch data for the first time
            shouldRequest = true;
        }

        if(shouldRequest) {
            if(infuraProviderRef.current == null) {
                console.log('infura is null')
                return;
            }

            // console.log('making infura request..');

            const contractTemp = new Contract(AUCTION_ADDRESS, AUCTION_ABI, infuraProviderRef.current);

            // console.log('refreshing auction data..');

            const auction = await contractTemp.auctions(auctionID);

            const bidHistory = await contractTemp.getBidHistory(auctionID);

            //print the address of every bidder in bidHistory
            console.log('bidhistory for auction ' + auctionID + ':');
            for(let i = 0; i < bidHistory.length; i++) {
                console.log(bidHistory[i].bidder);
            }



            const auctionData = {};

            //get current timestamp in seconds
            const curTimestamp = Math.floor(Date.now() / 1000);

            if(!auction.approved) {
                let lastBidder = '0x0000000000000000000000000000000000000000';
                let lastBidAmount = 0;

                //try to get lastBidder and lastBidAmount from bidHistory, if it exists. for auctions that ended and still have bid history
                if(bidHistory.length > 0) {
                    // auctionData.lastBidder = bidHistory[bidHistory.length - 1].bidder;
                    // auctionData.lastBidAmount = bidHistory[bidHistory.length - 1].amount;

                    //get last bid from bidHistory
                    const numBids = bidHistory.length;
                    const lastBid = bidHistory[numBids - 1];

                    //get lastBidder and lastBidAmount
                    lastBidder = lastBid.bidder;
                    lastBidAmount = lastBid.amount;
                }

                let lastBidderName = await infuraProviderRef.current.lookupAddress(lastBidder);

                if(lastBidderName != null) {
                    lastBidder = lastBidderName;
                }
                  
                auctionDataRef.current[auctionID] = {
                    reservePrice: 0,
                    lastBidder: lastBidder,
                    lastBidAmount: lastBidAmount,
                    auctionEndTime: new Date(0),
                    fetchedDataAt: 0,
                    bidHistory: bidHistory,
                    auctionApproved: false
                }
            }

            else {
                let reservePrice = auction.reservePrice;
                // console.log('reservePrice ' + reservePrice)
                let lastBidder = auction.bidder;
                // console.log('lastBidder ' + lastBidder)
                let lastBidAmount = auction.amount;
                // console.log('lastBidAmount ' + lastBidAmount)

                let auctionEndTime = new Date(0);

                if(auction.firstBidTime != 0) {

                    let firstBidTime = parseInt(auction.firstBidTime);

                    let auctionEndSeconds = firstBidTime + AUCTION_DURATION;
                    // console.log('auctionEndSeconds ' + auctionEndSeconds)
                    auctionEndTime.setUTCSeconds(auctionEndSeconds);
                    // console.log('auctionEndTime ' + auctionEndTime);


                    //TODO update timer in artist list 
                    let artworkTimerDiv = artistListTimerRef.current[auctionID];
                    artworkTimerDiv.setAttribute('work-' + auctionID + '-auctionEnd', auctionEndSeconds);
                }

                let lastBidderName = await infuraProviderRef.current.lookupAddress(lastBidder);

                if(lastBidderName != null) {
                    lastBidder = lastBidderName;
                }

                //getting this auction data for the first time
                if(shouldRequest && userAddressRef.current != null) { 
                    //search bidHistory for userAddressRef.current
                    let numBidsByUser = 0;

                    for(let i = 0; i < bidHistory.length; i++) {
                        if(bidHistory[i].bidder == userAddressRef.current) {
                            numBidsByUser++;
                        }
                    }

                    numUserBidsRef.current += numBidsByUser;

                    // console.log('adding ' + numBidsByUser + ' to numUserBidsRef.current, now ' + numUserBidsRef.current);
                }

                auctionDataRef.current[auctionID] = {
                    reservePrice: reservePrice,
                    lastBidder: lastBidder,
                    lastBidAmount: lastBidAmount,
                    auctionEndTime: auctionEndTime,
                    fetchedDataAt: curTimestamp,
                    bidHistory: bidHistory,
                    auctionApproved: true
                };
            }
        }

        else {
            console.log('requested too recently, not making infura request..');
        }
    }



    //TODO call this whenever art changes 
    async function updatePaneInfo() {

        let curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;

        //get index of curArtworkRef.current in artPlanesRef.current
        let curPlaneIndex = artPlanesRef.current.findIndex(artPlane => artPlane.artName == curArtName);

        let curArt = artPlanesRef.current[curPlaneIndex];

        if(curArt == undefined) return;

        if(!curArt.isVideo) {
            let fileURL = process.env.PUBLIC_URL + '/textures/lunar_artwork/' + curArt.artName + '.jpg';

            // console.log('loading image ' + fileURL);

            artThumbnailRef.current.src = fileURL;
            /* show art thumbnail */
            artThumbnailRef.current.style.display = "block";

            /* hide video thumbnail */
            videoThumbnailRef.current.style.display = "none";
        }
        else {
            //search for video in videoElemsRef.current 
            let fileURL = process.env.PUBLIC_URL + '/textures/lunar_artwork/' + curArt.artName + '.mp4';

            // console.log('loading video ' + fileURL);

            videoThumbnailRef.current.src = fileURL;

            /* show video thumbnail */
            videoThumbnailRef.current.style.display = "block";

            /* hide art thumbnail */
            artThumbnailRef.current.style.display = "none";
        }


        if(curArt.isVideo) {
            //hide arweave link
            // arweaveLinkRef.current.style.display = "none";
            let arweaveLink = arweaveBaseURLVid + curArt.artName + ".mp4";
            arweaveLinkRef.current.href = arweaveLink;

            // arweaveLinkRef.current.innerHTML = "Full resolution video metadata update coming 24.2.23";
            // arweaveLinkRef.current.href = "#";
            // arweaveLinkRef.current.onclick = () => false
            //make link unclickable
        }

        else {
            //show arweave link
            // arweaveLinkRef.current.style.display = "block";
            let arweaveLink = arweaveBaseURLImg + curArt.artName + ".jpg";
            arweaveLinkRef.current.href = arweaveLink;

            // arweaveLinkRef.current.innerHTML = "View full resolution on Arweave";
            // arweaveLinkRef.current.onclick = () => true;
        }

        //update description
        let curDescriptionHTML = curArt.description;
        artDescriptionRef.current.innerHTML = curDescriptionHTML;

        //update bid info
        let auctionID = curArt.auctionID;

        highestBidInfoRef.current.innerHTML = "loading...";
        bidHistoryRef.current.innerHTML = "loading...";

        await attemptRefreshAuction(auctionID);

        let auctionInfo = auctionDataRef.current[auctionID];

        
        let timeLeft = Math.max(0, auctionInfo.auctionEndTime - Date.now());
        
        if(darkYecheEnabled) {
            console.log('updating auction end time for ' + auctionID);
            setAuctionEnd(auctionInfo.auctionEndTime);
        }


        if(auctionInfo.auctionApproved && timeLeft > 0) {
            // console.log('fading in bid box');
            bidBoxRef.current.classList.remove("fadeOut");
            bidBoxRef.current.classList.add("fadeIn");
        }

        // else {
        //     console.log(auctionInfo);
        // }

        //update bid history
        if(auctionInfo.bidHistory != undefined) {
            let bidHistory = [];

            for(let i = 0; i < auctionInfo.bidHistory.length; i++) {
                let bid = auctionInfo.bidHistory[i];
                bidHistory.push(bid);
            }

            //sort bidHistory by timestamp
            bidHistory.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1);

            //for each bid, add a line to bidHistoryRef.current
            let bidHistoryHTML = "";

            for(let i = 0; i < bidHistory.length; i++) {
                let bid = bidHistory[i];

                let bidder = bid.bidder;

                let bidderName = await infuraProviderRef.current.lookupAddress(bidder);

                if(bidderName != null) {
                    bidder = bidderName;
                }

                let bidAmount = utils.formatEther(bid.amount);
                let bidTimestamp = bid.timestamp;

                //convert utc timestamp to local time
                let date = new Date(bidTimestamp * 1000);

                //get date and hours and minutes
                let formattedTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();

                bidHistoryHTML += bidder + " bid " + bidAmount + "ETH<br/>";
                bidHistoryHTML += "<div class='BidDate'>" + formattedTime + "</div><br/>";
            }

            bidHistoryRef.current.innerHTML = bidHistoryHTML;
        }



        //Reset bid button
        setBidButtonText("bid");

        //get highest bid from auctionDataRef.current
        let lastBid = utils.formatEther(auctionInfo.lastBidAmount); 
        let lastBidder = truncateEthAddress(auctionInfo.lastBidder); 
        let reservePrice = utils.formatEther(auctionInfo.reservePrice);
        
        //truncate highestBid to 2 decimal places
        if(lastBid == 0) {
            //show reserve price
            highestBidInfoRef.current.innerHTML = "Reserve price: " + reservePrice + "ETH";
        }

        else {
            highestBidInfoRef.current.innerHTML = "Last bid: " + lastBid + "ETH by " + lastBidder;
        }    

        // if(auctionDataRef.current == null) {
        //     return;
        // }
    }

    function toggleArrow(shouldToggleOverlay) {
        if(tutorialVisibleRef.current) {
            hideTutorial();
        }

        if(shouldToggleOverlay) toggleOverlay();

        if(showOverlayRef.current) {
            //add cross class to arrowRef
            arrowIconRef.current.classList.add("Cross");
            //remove arrow class from arrowRef
            arrowIconRef.current.classList.remove("Arrow");
        }
        
        else {
            //add arrow class to arrowRef
            arrowIconRef.current.classList.add("Arrow");
            //remove cross class from arrowRef
            arrowIconRef.current.classList.remove("Cross");
        }
    }

    //todo - make this work for mobile
    function toggleMute() {
        console.log('trying to unmute');
        videoElemsRef.current.forEach(video => {
            console.log('unmuting');
            video.muted = !video.muted;
            console.log('setting video ' + video.src + ' to ' + video.muted);
        });

        videosMutedRef.current = !videosMutedRef.current;

        //set inner text of mute button
        // muteButtonRef.current.innerText = videosMutedRef.current ? "🔇" : "🔈";
        //if muted, change image of mute button to process.env.PUBLIC_URL + "/textures/soundoff.png" otherwise process.env.PUBLIC_URL + "/textures/soundon.png"

        // videosMutedRef.current ? muteIconRef.current.src = process.env.PUBLIC_URL + "/textures/soundoff.png" : muteIconRef.current.src = process.env.PUBLIC_URL + "/textures/soundon.png";

        if(videosMutedRef.current) {
            //add SoundOff class to muteIconRef and remove SoundOn class
            muteIconRef.current.classList.add("SoundOff");
            muteIconRef.current.classList.remove("SoundOn");
        }

        else {
            muteIconRef.current.classList.remove("SoundOff");
            muteIconRef.current.classList.add("SoundOn");
        }

        //TODO TOGGLE MUTE OF ALL ENV AUDIO 
        if(isMobile && oceanWindAudioRef.current) {
            oceanWindAudioRef.current.muted = videosMutedRef.current;

            //play wind audio if not playing
            if(!oceanWindAudioRef.current.isPlaying) {
                oceanWindAudioRef.current.play();
            }
        }

        else if(!isMobile && oceanAudioRef.current && windAudioRef.current) {
            oceanAudioRef.current.muted = videosMutedRef.current;
            windAudioRef.current.muted = videosMutedRef.current;

            //play wind audio if not playing
            if(!oceanAudioRef.current.isPlaying) {
                oceanAudioRef.current.play();
            }

            if(!windAudioRef.current.isPlaying) {
                windAudioRef.current.play();
            }
        }
    }

    async function bid() {
        //get value in bidInputRef
        let bidAmountEth = bidInputRef.current.value;

        if(!connected) {
            alert("Please connect your wallet to bid");
            bidInputRef.current.value = "";
            return;
        }

        let curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;
        console.log("bidding on: " + curArtName);

        let planeIndex = artPlanesRef.current.findIndex(plane => plane && plane.artName == curArtName);

        //get auction ID of current art
        let auctionID = artPlanesRef.current[planeIndex].auctionID;
        console.log("auctionID: " + auctionID);
        
        const signer = ethersProvider.getSigner();
        const auctionContract = new Contract(AUCTION_ADDRESS, AUCTION_ABI, signer);
        const userAddress = await ethersProvider.getSigner().getAddress();

        const thisAuction = await auctionContract.auctions(auctionID);

        //make sure auction has been approved 
        if(!thisAuction.approved) {
            alert("This auction has not started yet");
            bidInputRef.current.value = "";
            return;
        }

        const reservePriceEth = utils.formatEther(thisAuction.reservePrice);
        const lastBidAmt = utils.formatEther(thisAuction.amount);

        //make sure bid is higher than last bid or reserve price
        if(bidAmountEth <= lastBidAmt || bidAmountEth < reservePriceEth) {
            alert("Your bid must be higher than the last bid or the reserve price");
            bidInputRef.current.value = "";
            return;
        }

        //convert bidAmount to wei
        let bidAmountWei = utils.parseEther(bidAmountEth);
        const tx = await auctionContract.createBid(auctionID, bidAmountWei, {value: bidAmountWei});

        setBidButtonText("Bidding...");

        await tx.wait();

        setBidButtonText("done!");

        //clean the input field
        bidInputRef.current.value = "";

        //update auctionDataRef.current
        auctionDataRef.current[auctionID].lastBidder = userAddress;
        auctionDataRef.current[auctionID].lastBidAmount = bidAmountWei;

        numUserBidsRef.current++;
        console.log("numUserBids: " + numUserBidsRef.current);

        updatePaneInfo();
    }

    //this won't be correct if bids haven't been loaded yet
    function updateNumBids() {
        //loop through auctionDataRef.current and count number of bids in bidHistory that match userAddressRef.current
        let numBids = 0;

        auctionDataRef.current.forEach(auction => {
            auction.bidHistory.forEach(bid => {
                if(bid.bidder == userAddressRef.current) numBids++;
            });
        });

        numUserBidsRef.current = numBids;

        console.log("numUserBids: " + numUserBidsRef.current);
    }

    //todo 
    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();
        const auctionContract = new Contract(AUCTION_ADDRESS, AUCTION_ABI, signer);

        userAddressRef.current = userAddress;
        updateNumBids();
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        if(name) {
          setHumanAddress(name);
          userENSRef.current = name;
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    function skipIntro() {
        introCamPctRef.current = 0.99;
        skipIntroRef.current.style.display = "none";
        skippedIntro.current = true;
    }   

    async function toggleShowBids() {
        if(showBidsOverlay && artPlanesRef.current.length > 0) {
            setShowBidsOverlay(false);

            //hide all bids
            //loop through all artwork titles
            for(let i = 0; i < artPlanesRef.current.length; i++) {
                let artworkName = artPlanesRef.current[i].artName;
                let bidInfoDiv = artworkBidInfoRef.current[artworkName];

                bidInfoDiv.innerHTML = '';

                //remove fadeIn class and add fadeOut class
                bidInfoDiv.classList.remove("fadeIn");
                bidInfoDiv.classList.add("fadeOut");
            }
        }

        else {
            setShowBidsOverlay(true);

            //fade in all bidInfoDivs 
            for(let i = 0; i < artPlanesRef.current.length; i++) {
                let artworkName = artPlanesRef.current[i].artName;
                let bidInfoDiv = artworkBidInfoRef.current[artworkName];

                bidInfoDiv.classList.remove("fadeOut");
                bidInfoDiv.classList.add("fadeIn");

                //set text to 'loading' 
                bidInfoDiv.innerHTML = 'loading...';
            }

            //show all bids, and get all bids
            for(let i = 0; i < artPlanesRef.current.length; i++) {
                let artworkName = artPlanesRef.current[i].artName;
                let bidInfoDiv = artworkBidInfoRef.current[artworkName];
                let auctionID = artPlanesRef.current[i].auctionID;

                attemptRefreshAuction(auctionID);

                // bidInfoDiv.innerHTML = 'loading';

                let auctionInfo = auctionDataRef.current[auctionID];

                let lastBid = utils.formatEther(auctionInfo.lastBidAmount); 
                let lastBidder = truncateEthAddress(auctionInfo.lastBidder); 
                let reservePrice = utils.formatEther(auctionInfo.reservePrice);
                
                //truncate highestBid to 2 decimal places
                if(lastBid == 0) {
                    //show reserve price
                    bidInfoDiv.innerHTML = "Reserve: " + reservePrice + "ETH";
                }
        
                else {
                    bidInfoDiv.innerHTML = "Last bid: " + lastBid + "ETH<br/> by " + lastBidder;
                }    
        
                //remove fadeIn class and add fadeOut class
            }
        }
    }

    function toggleAvatarVisible() {
        avatarVisibleRef.current = !avatarVisibleRef.current;
        setAvatarVisible(avatarVisibleRef.current);
    }

    //todo 
    function sendChatMessage() {
        //clear input field
        messageRef.current = messageInputRef.current.value;
        messageInputRef.current.value = "";
    }

    function handleMessageKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        
        if(e.key === "Backspace") {
            //deleted all selected text
            if(messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }

            //deleted last character
            else {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.value.length - 1);
            }
        }

        //if key is left arrow, move cursor left
        else if(e.key === "ArrowLeft") {
            //make sure new selectionStart is not negative
            if(messageInputRef.current.selectionStart > 0) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart - 1;
            }
        }

        //if key is right arrow, move cursor right
        else if(e.key === "ArrowRight") {
            //make sure new selectionStart is not negative
            if(messageInputRef.current.selectionStart < messageInputRef.current.value.length) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart + 1;
            }
        }
  
        if(e.key === "Enter") {
            sendChatMessage();
        }


        else if(e.key.length === 1 && messageInputRef.current.value.length < MESSAGE_CHAR_LIMIT) {

            //delete any selected text
            if(messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }


            messageInputRef.current.value += e.key;
        }
    }

    function handleAvatarSwitchKeydown(e) {
        //check if the user pressed the space bar
        if(e.key === " ") {
            e.preventDefault();
        }
    }

    return(
        <>

        <button className={isMobile ? "Skip-intro Skip-intro-mobile" : "Skip-intro Skip-intro-desktop"} ref={skipIntroRef} onClick={skipIntro}>Skip Intro</button>

        {!isMobile && <audio ref={windAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/wind1-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}
        {!isMobile && <audio ref={oceanAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/ocean-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}

        {isMobile && <audio ref={oceanWindAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/oceanwind-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}

        <button className={isMobile ? "Artist-list-toggle Artist-list-toggle-mobile" :  "Artist-list-toggle Artist-list-toggle-desktop"} ref={artistListToggleRef} onClick={toggleArtistList}>Artist<br/>Index</button>

        <div className={isMobile ? "switchContainer switchContainer-mobile" : "switchContainer switchContainer-desktop"} ref={showBidsOverlayRef}>
            <Switch 
            className="showBidsSwitch"
            onChange={toggleShowBids} 
            checked={showBidsOverlay} 
            checkedIcon={false}
            uncheckedIcon={false}
            />
            <div className="switchLabel">Show Bids</div>
        </div>

        {/* <div className="Artist-list" ref={artistListRef}>
            {wideView && <div className="Artist-list-inner-desktop" ref={artistListInnerRef} />}

            {!wideView && <div className="Artist-list-inner-mobile" ref={artistListInnerRef} />}
        </div> */}

        {wideView && 
        <div className="Artist-list-desktop-container" ref={artistListRef}>
            <div className="Desktop-blur-overlay"/>
            <div className="Artist-list-desktop">
                <div className="Artist-list-inner-desktop" ref={artistListInnerRef} />
            </div>
        </div>}

        {!wideView &&
        <div className="Artist-list-mobile-container" ref={artistListRef}>
            <div className="Mobile-blur-overlay"/>
            <div className="Artist-list-mobile">
                <div className="Artist-list-inner-mobile" ref={artistListInnerRef} />
            </div>
        </div>}

        {connected && <div className="Address-pill-lunar">{humanAddress}</div>}
        {!connected && <button className={isMobile ? "Connect-button-lunar Connect-button-lunar-mobile" : "Connect-button-lunar Connect-button-lunar-desktop"} onClick={() => connectWallet()}>{connectText}</button>}

        <div className="Crossfade-overlay" ref={crossfadeOverlayRef}/>


        <div className={!wideView ? "WorkNavigatorMobile" : "WorkNavigatorDesktop"} ref={navigatorRef}
            // onPointerMove={handlePaneDrag}
            onPointerDown={(e) => startPaneDrag(e)}
            onPointerUp={endPaneDrag}
        >

            <div className={"AvatarController"}>

                <div className={"MessageBar"}>
                    <input ref={messageInputRef} className={"MessageBox"} type="text" name="messagebox" placeholder="Say hello..." onKeyDown={handleMessageKeyDown}/>
                    <button className={"SendMessageButton"} onClick={sendChatMessage}/>
                </div>

                <Switch
                    onColor={'#888'}
                    onChange={toggleAvatarVisible} 
                    checked={avatarVisible}        
                    checkedIcon={false}
                    uncheckedIcon={false}             
                    className={"AvatarSwitch"}
                    onKeyDown={handleAvatarSwitchKeydown}

                    uncheckedHandleIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 20,
                            animation: "glow-switch 8s infinite",
                            borderRadius: "50%"
                          }}
                        />
                    }
                      checkedHandleIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            color: "red",
                            fontSize: 18,
                            animation: "glow-switch 8s infinite",
                            borderRadius: "50%"
                          }}
                        />
                    }
                />
            </div>

            <div className={"NavigatorButtonRow"}>
                <div className="WorkNavigatorProgressBar" ref={progressBarRef}/>


                <div className="WorkNavigatorButtonPrev" onClick={() => { incrementArtwork(false) }} />

                <div className="WorkNavigatorButtonPlay PlayTour" onClick={toggleAutoTour} ref={autoTourButtonRef} />

                <div className="WorkNavigatorButtonNext" onClick={() => { incrementArtwork(true) }} />

                <div className="WorkNavigatorButtonMute SoundOff" onClick={toggleMute} ref={muteIconRef} />

                <div className="WorkNavigatorButtonArrow Arrow" onClick={() => toggleArrow(true)} ref={arrowIconRef} />
            </div>

        </div>
        <div className={!wideView ? "OverlayMobile" : "OverlayDesktop"} ref={overlayRef}>
            <div className={!isMobile ? "OverlayInnerDesktop" : "OverlayInnerMobile"}>
                <div className="OverlayPane">

                    <div className="OverlayPaneItem">
                        <div className="OverlayPaneArtHeader" ref={artHeaderRef}>
                            <div className='ArtHeaderTitleName'>
                                {artworkTitle}<br/>
                                {artistName}
                            </div>

                            <div className="OverlayCurrentBidInfo" ref={curBidInfoRef}>
                                <div className="BidBox">
                                    <div className="BidInput" ref={bidBoxRef}>
                                        <input ref={bidInputRef} className="BidInputBox" type="text" name="bid" placeholder="0.00" />
                                        <button className="BidButton" onClick={bid}>{bidButtonText}</button>
                                    </div>
                                    <div className='HighestBidInfo' ref={highestBidInfoRef}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="OverlayPaneItem">
                        <div className="AuctionInfo">
                            <div className={"CountdownTimer"} ref={timerRef}>
                            <CountdownTimer endTime={auctionEnd} showDarkYecheMessage={true} />
                            </div>
                            <div className="Etherscan-link">
                                <a href="https://etherscan.io/address/0x454a91351f41e5311aec4a5de205b725f1effa9b#code" target="_blank">
                                    <img src={require('../images/etherscan_black.png')} />
                                </a>
                            </div>
                            <div className="Opensea-link">
                                <a href="https://opensea.io/collection/yechelangeschooloftruth" target="_blank">
                                    <img src={require('../images/opensea_black.png')} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={!wideView ? "OverlayPaneInfoMobile" : "OverlayPaneInfoDesktop"} ref={overlayPaneInfoRef}>

                        {/* <div className="SeparatorLine" /> */}

                        <div className="OverlayPaneItem">
                            <div className="ArtThumbnailContainer">
                                <img className="ArtThumbnailImg" ref={artThumbnailRef}/>
                                <video className="ArtVideoThumbnail" ref={videoThumbnailRef} muted playsInline loop controls preload='false' />

                                <a href="www.google.com" ref={arweaveLinkRef} target="_blank">View full resolution on Arweave</a>
                            </div>
                        </div>

                        <div className="OverlayPaneItem" ref={artDescriptionRef}/>

                        <div className="OverlayPaneItem">
                            <div className="BidHistoryHeader">Bid History</div>
                            <div className="BidHistoryList" ref={bidHistoryRef}/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF } from "@react-three/drei";
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { lerp } from 'three/src/math/MathUtils';
import { useControls, Leva, folder } from 'leva';
import { SceneContext } from './Lunar';
import CustomShaderMaterial from 'three-custom-shader-material'


export default function LunarSkyDome(props) {

    const skyScale = 1500;

    const gradientTexDark = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/lunar_sky_dark.jpg');
    const gradientTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/lunar_sky4.jpg');
    // const normalMap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/mud/mudmat_Normal.jpg');
    const normalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/sky_normals.jpg');

    const domeGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/skydome.glb');

    const domeRef = useRef();

    const [texturesReady, setTexturesReady] = useState(false);

    const { introCamPctRef, darkYecheEnabled } = useContext(SceneContext);

    const curDomeAlpha = useRef(0.0);

    const { cloudAnimSpeed, 
            cloudPow, 
            metallicAmt, 
            roughnessAmt, 
            envMapIntensity, 
            skyNormalTiling,
            brightenAmt,
            cloudscale,
            renderOrder,
            distortNoiseStrength,
            distortNoiseScale,
            distortNoiseSpeed,
            // offset
        } = useControls('sky dome',{
        cloudAnimSpeed: { value: 0.11, min: 0.0, max: 10.0, step: 0.0001 },
        cloudPow: { value: 1.87, min: 0, max: 5.0, step: 0.01 },
        metallicAmt: { value: 0.62, min: 0.0, max: 1.0, step: 0.01 },
        roughnessAmt: { value: 0.0, min: 0.0, max: 1.0, step: 0.01 },
        envMapIntensity: { value: 3.0, min: 0.0, max: 10.0, step: 0.01 },
        skyNormalTiling: { value: 0.92, min: 0.0, max: 50.0, step: 0.01 },
        brightenAmt: { value: 0.0, min: 0.0, max: 1.0, step: 0.01 },
        cloudscale: { value: 5.0, min: 0.0, max: 50.0, step: 0.01 },
        renderOrder: { value: -2, min: -10, max: 10, step: 1 },
        distortNoiseStrength: { value: 0.61, min: 0.0, max: 1.0, step: 0.01 },
        distortNoiseScale: { value: 12.1, min: 0.0, max: 80.0, step: 0.01 },
        distortNoiseSpeed: { value: 0.45, min: 0.0, max: 10.0, step: 0.01 },
        // offset: { value: 0.001, min: 0.0, max: 0.1, step: 0.0001 },
    });


    useEffect(() => {
        if(gradientTex.image && normalTex.image && gradientTexDark.image) {
            gradientTex.flipY = false;
            gradientTexDark.flipY = false;
            
            normalTex.wrapS = normalTex.wrapT = THREE.RepeatWrapping;
            normalTex.repeat.set(skyNormalTiling, skyNormalTiling);  

   
            setTexturesReady(true);
        }

    }, [gradientTex, gradientTexDark, skyNormalTiling]);

    useFrame(({ clock }) => {
        if (domeRef.current) {
            //update time uniform
            domeRef.current.material.uniforms.time.value = clock.getElapsedTime() * cloudAnimSpeed;
            domeRef.current.material.uniforms.noiseTime.value = clock.getElapsedTime();
            domeRef.current.material.uniforms.cloudPow.value = cloudPow;

            if(domeRef.current.material.uniforms.globalAlpha.value < 1.0 && introCamPctRef.current > 0.7) {
                curDomeAlpha.current = lerp(curDomeAlpha.current, 1.0, 0.01);

                domeRef.current.material.uniforms.globalAlpha.value = curDomeAlpha.current;
            }

            // else {
            //     domeRef.current.material.uniforms.globalAlpha.value = 1.0;
            // }
        }
    });

    return(
        <>        
        {texturesReady && 
            (<mesh
            position={[0, 30, 0]}
            scale={[skyScale, skyScale, skyScale]}
            geometry={domeGLB.nodes.Sphere.geometry}
            ref={domeRef}
            renderOrder={renderOrder}
            >
            <CustomShaderMaterial
                vertexShader={
                    `
                    uniform float noiseTime;
                    uniform float time;
                    uniform float distortNoiseStrength;
                    uniform float distortNoiseScale;
                    uniform float distortNoiseSpeed;
                    uniform float offset;
                    varying vec2 myUv;

                    float rand(float n){return fract(sin(n) * 43758.5453123);}

                    float noise(float p){
                        float fl = floor(p);
                        float fc = fract(p);
                        return mix(rand(fl), rand(fl + 1.0), fc);
                    }
                        
                    float noise(vec2 n) {
                        const vec2 d = vec2(0.0, 1.0);
                        vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
                        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
                    }

                    // vec3 orthogonal(vec3 v) {
                    //     return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
                    //     : vec3(0.0, -v.z, v.y));
                    // }
                

                    vec3 displace(vec3 point, float strength) {
                        float n = noise(vec2(point.x * distortNoiseScale + noiseTime * distortNoiseSpeed, point.y * distortNoiseScale + noiseTime * distortNoiseSpeed * 2.345));
                        return point + normal * n * strength;
                    }
                
                    float map(float value, float min1, float max1, float min2, float max2) {
                        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
                    }
                      

                    void main() {
                        myUv = uv;

                        float distortStrength = distortNoiseStrength;
                    
                        // distortStrength = distortStrength * clamp(map(1.0 - myUv.y, 0.5, 0.7, 1.0, 0.3), 0.0, 1.0);
                    
                        vec3 newPosition = displace(position, distortStrength);
                        csm_Position = newPosition;
                    
                        //RECALCULATE NORMAL
                        if(distortStrength > 0.0) {
                    
                            // Approximate tangent and bitangent using cross product
                            vec3 upVector = vec3(0.0, 1.0, 0.0);
                            vec3 tangent = normalize(cross(normal, upVector));
                            vec3 bitangent = normalize(cross(normal, tangent));
                    
                            vec3 neighbour1 = position + tangent * offset;
                            vec3 neighbour2 = position + bitangent * offset;
                            vec3 displacedNeighbour1 = neighbour1 + normal * displace(neighbour1, distortStrength);
                            vec3 displacedNeighbour2 = neighbour2 + normal * displace(neighbour2, distortStrength);
                    
                            vec3 displacedTangent = displacedNeighbour1 - newPosition;
                            vec3 displacedBitangent = displacedNeighbour2 - newPosition;
                    
                            vec3 displacedNormal = normalize(cross(displacedTangent, displacedBitangent));
                    
                            csm_Normal = displacedNormal;
                        }
                        else {
                            csm_Normal = normal;
                        }
                    }                        
                    `
                }
                fragmentShader={
                ` 
                    uniform float time;
                    uniform float cloudPow;
                    uniform sampler2D gradient;

                    uniform float brightenAmt;

                    uniform float cloudscale;

                    varying vec2 myUv;

                    // const float cloudscale = 1.1;
                    const float speed = 0.03;
                    const float clouddark = 0.5;
                    const float cloudlight = 0.3;
                    const float cloudcover = 0.2;
                    const float cloudalpha = 8.0;
                    const float skytint = 0.5;

                    uniform float globalAlpha;

                    const mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );

                    vec2 hash( vec2 p ) {
                        p = vec2(dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)));
                        return -1.0 + 2.0*fract(sin(p)*43758.5453123);
                    }

                    float noise( in vec2 p ) {
                        const float K1 = 0.366025404; // (sqrt(3)-1)/2;
                        const float K2 = 0.211324865; // (3-sqrt(3))/6;
                        vec2 i = floor(p + (p.x+p.y)*K1);	
                        vec2 a = p - i + (i.x+i.y)*K2;
                        vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0); //vec2 of = 0.5 + 0.5*vec2(sign(a.x-a.y), sign(a.y-a.x));
                        vec2 b = a - o + K2;
                        vec2 c = a - 1.0 + 2.0*K2;
                        vec3 h = max(0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
                        vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
                        return dot(n, vec3(70.0));	
                    }

                    float fbm(vec2 n) {
                        float total = 0.0, amplitude = 0.1;
                        for (int i = 0; i < 7; i++) {
                            total += noise(n) * amplitude;
                            n = m * n;
                            amplitude *= 0.4;
                        }
                        return total;
                    }

                    vec3 getCloudStrength(vec2 uvIn) {

                        vec2 p = gl_FragCoord.xy / 1024.0;
                        vec2 uv = uvIn;
                        // uv.x = mod(uv.x, 1.0);

                        float q = fbm(uv * cloudscale * 0.5);
                        float speed = 0.03;
                        float cloudTime = time * speed; 
                        
                        //ridged noise shape
                        float r = 0.0;
                        uv *= cloudscale;
                        uv -= q - cloudTime;
                        float weight = 0.8;
                        for (int i=0; i<8; i++){
                            r += abs(weight*noise( uv ));
                            uv = m*uv + cloudTime;
                            weight *= 0.7;
                        }
                        
                        //noise shape
                        float f = 0.0;
                        uv = uvIn;

                        uv *= cloudscale;
                        uv -= q - time;
                        weight = 0.7;
                        for (int i=0; i<8; i++){
                            f += weight*noise( uv );
                            uv = m*uv + time;
                            weight *= 0.6;
                        }
                        
                        f *= r + f;
                        
                        //noise colour
                        float c = 0.0;
                        cloudTime = time * speed * 2.0;
                        uv = uvIn;

                        uv *= cloudscale*2.0;
                        uv -= q - cloudTime;
                        weight = 0.4;
                        for (int i=0; i<7; i++){
                            c += weight*noise( uv );
                            uv = m*uv + cloudTime;
                            weight *= 0.6;
                        }
                        
                        //noise ridge colour
                        float c1 = 0.0;
                        cloudTime = time * speed * 3.0;
                        uv = uvIn;

                        uv *= cloudscale*3.0;
                        uv -= q - cloudTime;
                        weight = 0.4;
                        for (int i=0; i<7; i++){
                            c1 += abs(weight*noise( uv ));
                            uv = m*uv + cloudTime;
                            weight *= 0.6;
                        }
                        
                        c += c1;

                        return vec3(f, c, r);
                    }


                    void main() {
                        vec4 gradientCol = texture2D(gradient, myUv);
                        vec3 cloudCol = texture2D(gradient, vec2(0.5, 1.0)).rgb;

                        vec3 cloudStrength = getCloudStrength(myUv);

                        //remove ugly seam
                        if(myUv.x > 0.95 || myUv.x < 0.05) {
                            //get cloudStrength for 0.0

                            float distFromSeam = myUv.x > 0.95 ? 1.0 - myUv.x : myUv.x;

                            vec3 cloudStrength2 = getCloudStrength(vec2(1.0 - myUv.x, myUv.y));
                            //average the two
                            vec3 cloudAvg = (cloudStrength + cloudStrength2) * 0.5;

                            //fade to average based on distance from seam
                            cloudStrength = mix(cloudAvg, cloudStrength, distFromSeam * 20.0);
                        }

                        vec3 skycolour = gradientCol.rgb;
                        skycolour = mix(skycolour, vec3(1.0), brightenAmt);
                        // vec3 cloudCol = vec3(0.271,0.659,0.961) * clamp((clouddark + cloudlight*cloudStrength.y), 0.0, 1.0);
                       
                        float f = cloudcover + cloudalpha*cloudStrength.x*cloudStrength.z;
                    
                        float fadeFactor = 1.0;

                        if(1.0 - myUv.y > 0.5) {
                            fadeFactor = 1.0 - smoothstep(0.5, 0.7, 1.0 - myUv.y);
                        } 

                        else if(1.0 - myUv.y < 0.1) {
                            //fade down near horizon
                            fadeFactor = smoothstep(0.0, 0.1, 1.0 - myUv.y);
                        }

                        f = pow(f, cloudPow);
                        f *= fadeFactor;
                        //clamp to 0.0 - 1.0
                        f = clamp(f, 0.0, 1.0);

                        vec3 c = mix(cloudCol, vec3(1.0), cloudStrength.z * 0.5);

                        vec4 color = vec4(mix(skycolour, c, f), 1.0);

                        // csm_FragColor = color;
                        // csm_FragColor.a = globalAlpha;

                        csm_DiffuseColor = color;
                        csm_DiffuseColor.a = globalAlpha;
                    }
                `
                }
                uniforms={{
                    gradient: {
                        value: darkYecheEnabled ? gradientTexDark : gradientTex
                    },
                    time: {
                        value: 0
                    },
                    noiseTime: {
                        value: 0.0
                    },
                    cloudPow: {
                        value: cloudPow
                    },
                    brightenAmt: {
                        value: brightenAmt
                    },
                    cloudscale: {
                        value: cloudscale
                    },
                    globalAlpha : {
                        value: 0.0
                    },
                    distortNoiseStrength: {
                        value: distortNoiseStrength
                    },
                    distortNoiseScale: {
                        value: distortNoiseScale
                    },
                    distortNoiseSpeed: {
                        value: distortNoiseSpeed
                    },
                    offset: {
                        value: 0.001
                    }
                }}
                baseMaterial={THREE.MeshStandardMaterial}
                flatShading={false}
                depthWrite={false}
                fog={false}
                side={THREE.BackSide}
                color={0xffffff}
                normalMap={normalTex}
                metalness={metallicAmt}
                roughness={roughnessAmt}
                envMapIntensity={envMapIntensity}
                // opacity={0.0}
                transparent={true}
                attach="material" 
                // ...
            />
        </mesh>)}
        </>
    );
}
//COW V3
import * as THREE from 'three';
import React, { useRef, useEffect, useState, useMemo, forwardRef } from 'react';
import { useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF, MarchingCubes, MarchingCube, MeshTransmissionMaterial, Environment, Bounds, Text } from '@react-three/drei';
import CustomShaderMaterial from 'three-custom-shader-material';
import { assetVert, assetFrag } from './AssetShader.js'
import { Physics, RigidBody, BallCollider, CuboidCollider, MeshCollider } from '@react-three/rapier'

const Halo = forwardRef((props, ref) => {
    //load halo.png 
    const [haloTex] = useLoader(THREE.TextureLoader, [process.env.PUBLIC_URL + '/textures/leche/halo2.png']);
    const [innerTex] = useLoader(THREE.TextureLoader, [process.env.PUBLIC_URL + '/textures/leche/halo.png']);

    const scale = 6.0;
    const INNER_NOTE_RATIO = 0.4;
    const innerScale = scale * INNER_NOTE_RATIO * 1.6;

    const innerRef = useRef(null);

    useEffect(() => {
        //randomize initial z rotation
        ref.current.rotation.z = Math.random() * 2 * Math.PI;
    }, []);

    useFrame((state, delta) => {
        //make it rapidly spin
        ref.current.rotation.z += 0.4;
    });

    return (
        <>
            <mesh ref={ref} renderOrder={1000} scale={[1, 1, 1]}>
                <planeBufferGeometry args={[scale, scale]} />
                <meshBasicMaterial
                    map={innerTex}
                    transparent={true}
                    opacity={0.5}
                    depthWrite={false}
                    depthTest={false}
                    blending={THREE.AdditiveBlending}
                />
            </mesh>
        </>
    );
});

function Cow() {

    const CHALICE_SCALE = 5.0;
    const OFFSET_ABOVE_METABALLS = 11.0;

    const { scene } = useThree();

    const jugGLB = useGLTF(process.env.PUBLIC_URL + '/models/leche/' + 'jug.glb');

    const cowGLB = useGLTF(process.env.PUBLIC_URL + '/models/leche/' + 'leche_cow_v4.glb');

    const cowImages = [
        "10_f9b7c1.jpg", "12_fce2d5.jpg", "2_5bb5ea.jpg",
        "4_fefbd2.jpg", "6_f5c88d.jpg", "8_a5262d.jpg",
        "11_ecc55c.jpg", "1_5bb5ea.jpg", "3_bdabc5.jpg",
        "5_e7d7a4.jpg", "7_e7728d.jpg", "9_fcecca.jpg"
    ];

    const { texture: cowTex, color: milkCol }  = useMemo(() => {
        const textureLoader = new THREE.TextureLoader();
        const randomImage = cowImages[Math.floor(Math.random() * cowImages.length)];

        // Extract color code from the filename
        const colorCode = randomImage.split('_')[1].split('.')[0];
        const color = new THREE.Color(`#${colorCode}`);

        // Load the texture
        const texture = textureLoader.load(`${process.env.PUBLIC_URL}/textures/Leche/cows/${randomImage}`);

        return { texture, color };

    }, []);


    const cowMixerRef = useRef(null);

    // const milkCol = new THREE.Color(0.6, 0.6, 0.6);

    const MARCHING_CUBES_SCALE = 8.0;
    const NUM_BALLS = 300;
    const MILK_RESOLUTION = 50;
    const ballRefs = useRef(Array.from({ length: NUM_BALLS }, () => React.createRef()));
    const metaballRefs = useRef(Array.from({ length: NUM_BALLS }, () => React.createRef()));
    const metaballAgeRefs = useRef(Array.from({ length: NUM_BALLS }, () => React.createRef()));

    const COW_SCALE = 75.0;

    const UDDER_BONE_OFFSET_Y = -6.0;
    const UDDER_COLLIDER_SCALE = 7.3;
    const UDDER_JIGGLE_AMP = 0.4;

    const haloRef = useRef(null);

    //objects that are pinned to the cow skeleton
    const pinsRefs = useRef([]);

    const udderRef = useRef(null);
    const udderJiggleAmtRef = useRef(0);
    const udderJiggleAngRef = useRef(0);

    const curFrameRef = useRef(0);

    const nipTrackerRefs = useRef([]);

    const nipAngRefs = useRef([]);
    const nipAngTargetRefs = useRef([]);

    const marchingCubesRef = useRef(null);
    const curMetaballs = useRef([]);

    const pumpingIndexRef = useRef(-1);

    const nipIncDirRef = useRef(1);

    const metaballIndexRef = useRef(0);

    const milkChaliceRef = useRef(null);
    const wing1Ref = useRef(null);
    const wing2Ref = useRef(null);
    const wingFlapAngRef = useRef(0);

    const udderBone = useMemo(() => {
        let udderBone = null;
        cowGLB.scene.traverse((child) => {
            if (child.isBone) {
                //if bone name starts with pin
                if (child.name.startsWith('UDDER_TOP')) {
                    udderBone = child;
                }
            }
        });
        return udderBone;

    }, [cowGLB]);

    const nips = useMemo(() => {
        const nips = [];

        //remove all objects from sceen in nipTrackerRefs
        nipTrackerRefs.current.forEach((child) => {
            scene.remove(child);
        });
        nipTrackerRefs.current = [];

        cowGLB.scene.traverse((child) => {
            if (child.name.startsWith('NIP') && child.isBone) {
                //get number at the end of name
                const nipNum = child.name.slice(3);
                const nipNumInt = parseInt(nipNum) - 1;

                //add to nips array at index nipNumInt
                nips[nipNumInt] = child;
                nipAngRefs.current[nipNumInt] = 0;
                nipAngTargetRefs.current[nipNumInt] = 0;

                //add a sphere as child of nip
                const nipSphere = new THREE.Mesh(new THREE.SphereBufferGeometry(1.0, 32, 32), new THREE.MeshBasicMaterial({ color: 0xff0000 }));

                child.matrixAutoUpdate = true;

                scene.add(nipSphere);
                nipSphere.visible = false;
                nipSphere.matrixAutoUpdate = true;
                //set nipSphere parent to nip
                nipSphere.parent = child;
                nipSphere.position.set(0, 0.015, 0);
                nipSphere.scale.set(0.001, 0.001, 0.001);

                nipTrackerRefs.current[nipNumInt] = nipSphere;
            }
        });

        //sort nips 

        return nips;
    }, [cowGLB]);

    useEffect(() => {
        if (cowGLB) {
            //SETUP ANIMATION AND MATERIAL
            cowMixerRef.current = new THREE.AnimationMixer(cowGLB.scene);
            cowGLB.animations.forEach(clip => { cowMixerRef.current.clipAction(clip).play() });

            // cowGLB.scene.traverse((child) => {
            //     if (child.isMesh && child.material) {

            //         // console.log(child);
            //         // // child.material = new THREE.MeshBasicMaterial({map: cowTex, transparent: true});
            //         // child.material.map = cowTex;
            //         // child.material.needsUpdate = true;
            //     }
            // });


        }
    }, [cowGLB]);

    useEffect(() => {
        console.log('mount');
        if (cowTex) {
            cowTex.flipY = false;
            console.log('flipping cowTex')
        }
    }, [cowTex]);

    function worldPosToMetaballPos(worldPos, volumeScale) {
        //get position offset
        // let posOffset = marchingCubesRef.current.position.clone();
        //add pos offset to world pos
        // worldPos.add(posOffset);
        let metaballPos = worldPos.clone();
        metaballPos.add(new THREE.Vector3(volumeScale, volumeScale, volumeScale));
        metaballPos.divideScalar(volumeScale * 2.0);
        return metaballPos;
    }

    useFrame((state, delta) => {

        //randomly rotate milkChaliceRef
        // milkChaliceRef.current.rotation.z += 0.01;
        //set to random translation
        let curTranslation = milkChaliceRef.current.translation();
        let vec = new THREE.Vector3(curTranslation.x, curTranslation.y, curTranslation.z);
        // vec.x += Math.random() * 0.02 - 0.01;
        vec.y += Math.sin(wingFlapAngRef.current) * 0.02;
        // vec.z += Math.random() * 0.02 - 0.01;

        let flapSpeedBoost = 0.0;

        //get current world X position of current nip
        if (pumpingIndexRef.current >= 0) {
            let nipWorldPos = new THREE.Vector3();
            nips[pumpingIndexRef.current].getWorldPosition(nipWorldPos);
            //lerp x position of milkChaliceRef to that position
            vec.x = THREE.MathUtils.lerp(vec.x, nipWorldPos.x, 0.2);

            //set flapSpeedBoost based on x dist from nip
            let dist = Math.abs(vec.x - nipWorldPos.x);
            flapSpeedBoost = dist * 0.5;
        }


        milkChaliceRef.current.setNextKinematicTranslation(vec)

        let curRotation = milkChaliceRef.current.rotation();
        // console.log(curRotation);
        //slightly animate the x and z rotation 
        curRotation.x = Math.sin(wingFlapAngRef.current) * 0.02;
        curRotation.z = Math.sin(wingFlapAngRef.current) * 0.02;
        // curRotation.z += Math.random() * 0.005 - 0.0025;
        milkChaliceRef.current.setNextKinematicRotation(curRotation);


        wingFlapAngRef.current += 0.1;
        wing1Ref.current.rotation.y = Math.sin(wingFlapAngRef.current * (2.5)) * (0.4);
        wing2Ref.current.rotation.y = -Math.sin(wingFlapAngRef.current * (2.5)) * (0.4);

        curFrameRef.current++;

        if (udderBone && udderRef.current) {
            udderRef.current.position.copy(udderBone.position);

            const udderWorldPos = new THREE.Vector3();
            udderBone.getWorldPosition(udderWorldPos);

            udderWorldPos.y += UDDER_BONE_OFFSET_Y;

            udderRef.current.position.copy(udderWorldPos);

            //pump nips 
            if (pumpingIndexRef.current >= 0) {
                //lerp all nips to target angle, then set the y scale of each one based on the sin of the angle. if an angle reaches 180 degrees, set it back to 0 
                for (let i = 0; i < nips.length; i++) {
                    const nip = nips[i];

                    nipAngRefs.current[i] = THREE.MathUtils.lerp(nipAngRefs.current[i], nipAngTargetRefs.current[i], 0.06);
                    nip.scale.y = Math.sin(nipAngRefs.current[i]) * 0.3 + 1.0;

                    if (Math.abs(nipAngRefs.current[i] - Math.PI) < 0.1) {
                        // console.log('resetting nip ' + i);
                        nipAngRefs.current[i] = 0;
                        nipAngTargetRefs.current[i] = 0;
                    }
                }

                const MILK_EMIT_RATE = 1;

                //drip new milk
                if (nipAngRefs.current[pumpingIndexRef.current] > 0 && curFrameRef.current % MILK_EMIT_RATE == 0) {

                    let numMilk = 1;

                    if (udderJiggleAmtRef.current > 0.2) numMilk = 2;

                    for (let i = 0; i < numMilk; i++) {
                        const nip = nips[pumpingIndexRef.current];

                        const nipTracker = nipTrackerRefs.current[pumpingIndexRef.current];

                        // console.log(nipTracker)

                        //translate this to the position in metaball space 
                        const nipWorldPos = new THREE.Vector3();
                        nipTracker.getWorldPosition(nipWorldPos);

                        //add marchingCubes offset
                        nipWorldPos.sub(marchingCubesRef.current.position);

                        metaballIndexRef.current = (metaballIndexRef.current + 1) % ballRefs.current.length;

                        let ball = ballRefs.current[metaballIndexRef.current];

                        let curTranslation = ball.current.translation();

                        ball.current.setTranslation({ x: nipWorldPos.x, y: nipWorldPos.y, z: curTranslation.z });
                        //zero the current velocity
                        ball.current.setLinvel({ x: 0, y: -40, z: 0 });
                    }
                }

                //synchronize metaballs with balls
                for (let i = 0; i < ballRefs.current.length; i++) {
                    const ball = ballRefs.current[i];
                    const metaball = metaballRefs.current[i];
                    const scaleFactor = .015; //HACK

                    let curTranslation = ball.current.translation();

                    metaball.current.position.set(curTranslation.x * scaleFactor, curTranslation.y * scaleFactor, curTranslation.z);
                }
            }

            if (cowMixerRef.current) {
                cowMixerRef.current.update(delta * (1.0 + udderJiggleAmtRef.current * 20.0));
            }

            //jiggle udder
            udderJiggleAngRef.current += 8.0 * udderJiggleAmtRef.current;
            udderBone.rotation.z = Math.sin(udderJiggleAngRef.current) * udderJiggleAmtRef.current * UDDER_JIGGLE_AMP;
            udderJiggleAmtRef.current *= 0.97;

            //halo
            haloRef.current.material.opacity = udderJiggleAmtRef.current * 7.0;
            haloRef.current.scale.x = udderJiggleAmtRef.current * 20.0;
            haloRef.current.scale.y = udderJiggleAmtRef.current * 20.0;
        }
    });

    function pumpMilk(e) {
        // console.log(e)
        // console.log('PUMPING MILK');

        //move haloRef to e.pageX, e.pageY
        haloRef.current.position.x = e.intersections[0].point.x;
        haloRef.current.position.y = e.intersections[0].point.y;

        //randomize pumpingIndexRef
        // pumpingIndexRef.current = Math.floor(Math.random() * nips.length);
        //increment pumpingIndexRef
        pumpingIndexRef.current = (pumpingIndexRef.current + 1 * nipIncDirRef.current) % nips.length;
        //if pumpingIndexRef is nips.length - 1, set nipIncDirRef to -1, if 0, set to 1
        if (pumpingIndexRef.current == nips.length - 1) {
            nipIncDirRef.current = -1;
        }
        else if (pumpingIndexRef.current == 0) {
            nipIncDirRef.current = 1;
        }

        //set target angle to 180 degrees
        nipAngTargetRefs.current[pumpingIndexRef.current] = Math.PI;
        nipAngRefs.current[pumpingIndexRef.current] = 0;

        // console.log(marchingCubesRef.current);

        udderJiggleAmtRef.current += 0.03;
        if (udderJiggleAmtRef.current > 1.0) {
            udderJiggleAmtRef.current = 1.0;
        }
    }

    return (
        <Physics gravity={[0, -45, 0]}>
            <MarchingCubes
                resolution={MILK_RESOLUTION}
                maxPolyCount={20000}
                enableUvs={false}
                enableColors
                position={[0, 0, 0]}
                scale={MARCHING_CUBES_SCALE}
                renderOrder={5000}
                ref={marchingCubesRef}
            >
                <meshStandardMaterial
                    color={milkCol}
                    roughness={0.0}
                    metalness={0.2}
                    vertexColors
                />

                {metaballRefs.current.map((metaRef, i) => (
                    <MarchingCube
                        strength={0.03}
                        subtract={2}
                        // color={new THREE.Color(0.5*Math.random(), 0.5*Math.random(), 0.5*Math.random())}
                        color={milkCol}
                        key={i}
                        ref={metaRef}
                        position={[1000, 0, 0]}
                    />
                ))}

            </MarchingCubes>

            {ballRefs.current.map((ballRef, i) => (
                <RigidBody
                    ref={ballRef}
                    key={i}
                    colliders={false}
                    linearDamping={3}
                    angularDamping={0.95}
                    mass={10000.0}
                    enabledTranslations={[true, true, false]}
                    position={[1000, 0, 0]}
                >
                    <mesh>
                        <sphereBufferGeometry attach="geometry" args={[0.18]} />
                        <meshStandardMaterial
                            attach="material"
                            color="red"
                            transparent={true}
                            opacity={0.4}
                            depthTest={false}
                            depthWrite={false}
                            visible={false}
                        />
                    </mesh>
                    <BallCollider args={[0.18]} type="dynamic" />
                </RigidBody>
            ))}

            <group position={[0, OFFSET_ABOVE_METABALLS, 0]}>
                <RigidBody type="kinematicPosition" ref={milkChaliceRef} colliders={false}>
                    <MeshCollider type="trimesh">
                        <mesh geometry={jugGLB.nodes.pitcher.geometry} scale={CHALICE_SCALE * 0.77} position={[0, -12, 0]} >
                            <meshPhysicalMaterial
                                transmission={1.0}
                                roughness={0.0}
                                thickness={0.1}
                                clearcoat={1}
                                clearcoatRoughness={0.0}
                                envMapIntensity={1.0}
                                depthTest={false}
                                depthWrite={false}
                            />
                        </mesh>
                    </MeshCollider>
                    <mesh geometry={jugGLB.nodes.pitcher.geometry} scale={CHALICE_SCALE} position={[0, -12, 0]} >
                        <meshPhysicalMaterial
                            transmission={1.0}
                            roughness={0.0}
                            thickness={0.1}
                            clearcoat={1}
                            clearcoatRoughness={0.0}
                            envMapIntensity={1.0}
                            depthTest={false}
                            depthWrite={false}
                        />
                    </mesh>
                    <mesh ref={wing2Ref} geometry={jugGLB.nodes.wing1.geometry} scale={CHALICE_SCALE}
                        position={[jugGLB.nodes.wing1.position.x * CHALICE_SCALE, -12, jugGLB.nodes.wing1.position.z * CHALICE_SCALE]} 
                        renderOrder={100}>
                        <meshPhysicalMaterial
                            transmission={1.0}
                            roughness={0.5}
                            thickness={0.1}
                            clearcoat={1}
                            clearcoatRoughness={0.0}
                            envMapIntensity={1.0}
                            emissive={'white'}
                            emissiveIntensity={0.2}
                            depthTest={false}
                            depthWrite={false}
                        />
                    </mesh>
                    <mesh ref={wing1Ref} geometry={jugGLB.nodes.wing2.geometry} scale={CHALICE_SCALE}
                        position={[jugGLB.nodes.wing2.position.x * CHALICE_SCALE, -12, jugGLB.nodes.wing2.position.z * CHALICE_SCALE]} renderOrder={100}>
                        <meshPhysicalMaterial
                            transmission={1.0}
                            roughness={0.5}
                            thickness={0.1}
                            clearcoat={1}
                            clearcoatRoughness={0.0}
                            envMapIntensity={1.0}
                            emissive={'white'}
                            emissiveIntensity={0.2}
                            depthTest={false}
                            depthWrite={false}
                        />
                    </mesh>
                </RigidBody>

                <group position={[0, 0, -5]} scale={[1, 1, 1]}>
                    <primitive object={cowGLB.scene} scale={COW_SCALE} visible={false} />

                    <skinnedMesh
                        geometry={cowGLB.nodes.cow_mesh.geometry}
                        scale={COW_SCALE}
                        skeleton={cowGLB.nodes.cow_mesh.skeleton}
                    >
                        <CustomShaderMaterial
                            vertexShader={assetVert}
                            fragmentShader={assetFrag}
                            baseMaterial={THREE.MeshBasicMaterial}
                            uniforms={{
                                map: { value: cowTex },
                            }}
                            attach="material"
                            // transparent={true}
                            // depthWrite={false}
                            // depthTest={false}
                        />

                    </skinnedMesh>
                </group>
            </group>


            <mesh ref={udderRef} position={[0, 0, 0]}
                scale={UDDER_COLLIDER_SCALE}
                renderOrder={1000}
                onPointerDown={(e) => pumpMilk(e)}
                visible={false}
            >
                <sphereBufferGeometry attach="geometry" args={[1, 32, 32]} />
                <meshStandardMaterial
                    attach="material"
                    color="red"
                    transparent={true}
                    opacity={0.4}
                    depthTest={false}
                    depthWrite={false}
                />
            </mesh>

            <Halo ref={haloRef} />
        </Physics>
    );
}

export default Cow; 
import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF } from "@react-three/drei";
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { lerp } from 'three/src/math/MathUtils';
import { useControls, Leva, folder } from 'leva';
import CustomShaderMaterial from 'three-custom-shader-material'

export default function AircurrentMat(props) {

    const aircurrentMat = useRef();
    const randTimeStart = Math.random() * 1000.0;

    const { noiseScaleX, noiseScaleY, timeScale, currentPow, currentBoost, distortAmp, swayAmp, swayScale, swaySpeed } = useControls('aircurrent', {
        noiseScaleX: {
            value: 12,
            min: 0.0,
            max: 200.0,
            step: 0.01
        },
        noiseScaleY: {
            value: 2.0,
            min: 0.0,
            max: 200.0,
            step: 0.001
        },
        timeScale: {
            value: 1.0,
            min: 0.0,
            max: 50.0,
            step: 0.001
        },
        currentPow: {
            value: 7.7,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        currentBoost: {
            value: 1.1,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        distortAmp: {
            value: 0.3,
            min: 0.0,
            max: 5.0,
            step: 0.01
        },
        swayAmp: {
            value: 0.9,
            min: 0.0,
            max: 1.0,
            step: 0.001
        },
        swayScale: {
            value: 10.3,
            min: 0.0,
            max: 20.0,
            step: 0.1
        },
        swaySpeed: {
            value: 8.46,
            min: 0.0,
            max: 10.0,
            step: 0.001
        },
    });

    useFrame(({ clock }) => {
        //update time uniform
        if(aircurrentMat.current) {
            aircurrentMat.current.uniforms.time.value = clock.getElapsedTime() * timeScale + randTimeStart;
            aircurrentMat.current.uniforms.noiseScaleX.value = noiseScaleX;
            aircurrentMat.current.uniforms.noiseScaleY.value = noiseScaleY;
            aircurrentMat.current.uniforms.currentPow.value = currentPow;
            aircurrentMat.current.uniforms.currentBoost.value = currentBoost;
            aircurrentMat.current.uniforms.distortAmp.value = distortAmp;
        }
    });

    return(
        <CustomShaderMaterial
        baseMaterial={THREE.MeshBasicMaterial}
        ref={aircurrentMat}
        vertexShader={
        `
            varying vec2 myUv;
            uniform float time;
            uniform float distortAmp;
            uniform float noiseScaleX;
            uniform float noiseScaleY;

            float rand(float n){return fract(sin(n) * 43758.5453123);}

            float noise(float p){
                float fl = floor(p);
                float fc = fract(p);
                return mix(rand(fl), rand(fl + 1.0), fc);
            }
                
            float noise(vec2 n) {
                const vec2 d = vec2(0.0, 1.0);
                vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
                return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
            }

            void main() {
                myUv = uv;
                //distort mesh along the normals using noise and time to create the effect of an aircurrent moving up
                csm_Position = position + normal * noise(vec2(position.x * noiseScaleX * 0.25, position.y - time * noiseScaleY * 0.25)) * distortAmp;
                
            }
        `
        }
        fragmentShader={
        ` 
            uniform float swayAmp;
            uniform float swayScale;
            uniform float swaySpeed;
            uniform float noiseScaleX;
            uniform float noiseScaleY;
            uniform float time;
            uniform float currentPow;
            uniform float currentBoost;
            varying vec2 myUv;

            float rand(float n){return fract(sin(n) * 43758.5453123);}

            float noise(float p){
                float fl = floor(p);
                float fc = fract(p);
                return mix(rand(fl), rand(fl + 1.0), fc);
            }
                
            float noise(vec2 n) {
                const vec2 d = vec2(0.0, 1.0);
                vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
                return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
            }

            void main() {

                //use time and noise to create the effect of an aircurrent moving up 

                
                // float stripLen = 2.5;
                // float roundedY = floor(myUv.y / stripLen) * stripLen;
                
                float horizontalDistortion = sin(myUv.y * swayScale - time * swaySpeed) * noise(myUv) * swayAmp;
                float n  = noise(vec2(myUv.x * noiseScaleX + horizontalDistortion, myUv.y - time * noiseScaleY));
                
                // float n2 = noise(vec2((myUv.x + horizontalDistortion) * noiseScaleX, roundedY - time * noiseScaleY + 100.0));
                // float n3 = noise(vec2((myUv.x + horizontalDistortion) * noiseScaleX, roundedY - time * noiseScaleY + 200.0));
                // float n4 = noise(vec2((myUv.x + horizontalDistortion) * noiseScaleX, roundedY - time * noiseScaleY + 300.0));
                // float n5 = noise(vec2((myUv.x + horizontalDistortion) * noiseScaleX, roundedY - time * noiseScaleY + 400.0));
                // float n6 = noise(vec2((myUv.x + horizontalDistortion) * noiseScaleX, roundedY - time * noiseScaleY + 500.0));

                // float current = (n + n2 + n3 + n4 + n5 + n6) / 6.0;
                // float currentAlpha = pow(1.0 - current, currentPow) * currentBoost;

                float current = clamp((n - 0.5) * 2.0, 0.0, 1.0);
                float currentAlpha = pow(current, currentPow) * currentBoost;
                currentAlpha = clamp(currentAlpha, 0.0, 1.0);

                vec3 currentCol = vec3(1.0);

                csm_FragColor = vec4(currentCol, currentAlpha);
            }
        `
        }
        uniforms={{
            time: {
                value: 0
            },
            noiseScaleX: {
                value: noiseScaleX
            },
            noiseScaleY: {
                value: noiseScaleY
            },
            currentPow: {
                value: currentPow
            },
            currentBoost: {
                value: currentBoost
            },
            distortAmp: {
                value: distortAmp
            },
            swayAmp: {
                value: swayAmp
            },
            swayScale: {
                value: swayScale
            },
            swaySpeed: {
                value: swaySpeed
            }
        }}
        depthWrite={false}
        fog={false}
        side={THREE.DoubleSide}
        color={0xffffff}
        opacity={0.5}
        transparent={true}
        attach="material" 
        // ...
    />
    );
}
import React, { useState } from 'react';
import './Tantrum.css';

function ChatInput({ addMessage }) {
    const [value, setValue] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        if (value.trim() !== '') {
            addMessage({ avatar: '...', text: value });
            setValue('');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="chat-input">
            <input 
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder="Type your message, say 'Gigi' to talk to Gigi..."
            />
            <button type="submit">Send</button>
        </form>
    );
}

export default ChatInput;

import React from 'react';
import { useEffect } from 'react';

function YechePies(props) {

  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <iframe src="https://mileshiroo.github.io/yeche-pies/" title="Galerie Yeche Lange Pie Key Un" style={{position: "absolute", top: "4vh"}} onLoad={window.scroll(0,0)} width="100%" height="100%" frameBorder="0" scrolling="yes" allowFullScreen></iframe>
  );
}

export default YechePies;

import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { useGLTF } from '@react-three/drei';
import io from 'socket.io-client';
import { SceneContext } from './Lunar';
import { useStore } from './Store';
import { Avatar } from './Avatar';

// const socket = io('todo.herokuapp.com');
// var socket = io();
//connect to localhost port 3000

//CONNECT TO CORRECT PORT
const socket = io('https://yeche-multiplayer-server-987ec9d64ba5.herokuapp.com', { transports : ['websocket'] });

// Join the "lunar" room right after connecting to the server
socket.emit('joinRoom', 'lunar');


export default function Multiplayer({}) {

    const avatarGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/avatar.glb');

    const denimTexture = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/denim.jpg');

    const { camera, scene } = useThree();

    const otherPlayers = useRef([]);

    const { 
            introCamPctRef,
            curColliderNameRef, 
            numUserBidsRef, 
            userAddressRef, 
            userENSRef,
            avatarDataRef,
            messageRef,
        } = useContext(SceneContext);

    const avatars = useStore(state => state.avatars);
    const addAvatar = useStore(state => state.addAvatar);
    const removeAvatar = useStore(state => state.removeAvatar);

    const avatarRefs = useRef([]);

    const addedAvatarsRef = useRef({});

    const lastEmitTime = useRef(-1);


    useEffect(() => {
        if(denimTexture.image) {
            denimTexture.wrapS = denimTexture.wrapT = THREE.RepeatWrapping;
            denimTexture.repeat.set(2, 2);  
        }

    }, [denimTexture]);

    


    useEffect(() => {

        socket.on("playerdata" , (data) => {

            let avatarsInData = [];

            for (const [key, value] of Object.entries(data)) { 

                let isSelf = (key === socket.id);

                //check if avatar with socketID key exists in avatars, if not, add it
                // if(key === socket.id) {
                //     continue;
                // }

                avatarsInData.push(key);

                // console.log('avatars ' + avatars)

                //find avatar with socketID key
                for(let i = 0; i < avatars.length; i++) {

                    // console.log(avatars[i]);
                    // console.log('checking if ' + avatars[i].socketID + ' == ' + key)

                    if(avatars[i].socketID == key) {
                        // console.log("avatar exists for socket id " + key + " updating position")
                        //update position

                        avatarDataRef.current[avatars[i].socketID] = {};


                        if(value.object.message !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].message = value.object.message;
                        }

                        if(value.object.landed !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].landed = value.object.landed;
                        }

                        if(value.object.address !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].address = value.object.address;
                        }

                        if(value.object.ens !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].ens = value.object.ens;
                        }

                        if(value.object.numBids !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].numBids = value.object.numBids;
                        }

                        let avatar = avatarRefs.current[i];

                        if(avatar !== null && !isSelf && value.object.position && value.object.rotation && value.object.curCollider) {
                            let avatarCollider = scene.getObjectByName(value.object.curCollider);

                            if(avatarCollider === undefined) {
                                // console.log('avatar collider is undefined');
                                continue;
                            }

                            let avatarLocalPos = new THREE.Vector3(value.object.position.x, value.object.position.y, value.object.position.z);

                            let avatarGlobalPos = avatarCollider.localToWorld(avatarLocalPos);

                            let avatarOffsetY = 1.5;
                            avatarGlobalPos.y = avatarGlobalPos.y - avatarOffsetY;

                            // avatar.position.x = avatarGlobalPos.x;
                            // avatar.position.y = avatarGlobalPos.y - 1.5;
                            // avatar.position.z = avatarGlobalPos.z;

                            let avatarLerpSpeed = 0.05;

                            avatar.position.lerp(avatarGlobalPos, avatarLerpSpeed);

                            let newAvatarRot = new THREE.Euler(value.object.rotation.x, value.object.rotation.y, value.object.rotation.z, 'XYZ');

                            let ogRot = avatar.quaternion.clone();

                            avatar.setRotationFromEuler(newAvatarRot);

                            let newRot = avatar.quaternion.clone();

                            avatar.quaternion.set(ogRot.x, ogRot.y, ogRot.z, ogRot.w)

                            avatar.quaternion.slerp(newRot, avatarLerpSpeed);

                            break;
                        }

                        // else {
                        //     console.log('avatar is undefined or position, rotation, or curCollider is undefined');
                        // }
                    }
                }

                //if not, add it
                if(addedAvatarsRef.current[key] === undefined && value.object.timestamp !== undefined) {
                    //check that the avatar has been updated in the last 30 seconds
                    let now = new Date().getTime();
                    let diff = now - value.object.timestamp;

                    if(diff > 30000) {
                        continue;
                    }

                    console.log("adding avatar for socket id " + key);
                    console.log('isSelf is ' + isSelf);
                    addedAvatarsRef.current[key] = true;
                    addAvatar(0, key, avatarGLB, denimTexture, isSelf);
                }
            }

            //remove avatars that are no longer in data
            for(let i = 0; i < avatars.length; i++) {
                if(!avatarsInData.includes(avatars[i].socketID) && addedAvatarsRef.current[avatars[i].socketID] == true) {
                    console.log("removing avatar for socket id " + avatars[i].socketID);
                    console.log('my socket id is ' + socket.id);
                    addedAvatarsRef.current[avatars[i].socketID] = undefined;
                    removeAvatar(avatars[i].name);
                    //remove avatar
                }
            }        
        });


    }, [avatars]);



    function emitPlayerData() {
        let playerCollider = scene.getObjectByName(curColliderNameRef.current);
        let playerLocalPos = playerCollider.worldToLocal(camera.position.clone());
        let playerRotation = new THREE.Vector3(camera.rotation.x, camera.rotation.y, camera.rotation.z);
      
        let playerData = {
          curCollider: curColliderNameRef.current,
          position: playerLocalPos,
          rotation: playerRotation,
          address: userAddressRef.current,
          ens: userENSRef.current,
          numBids: numUserBidsRef.current,
          landed: introCamPctRef.current >= 1,
          message: messageRef.current,
          timestamp: Date.now(),
        }
      
        socket.emit("sendplayerposition", playerData);
    }

    //emit player data every 100ms
    useFrame(() => {
        if(lastEmitTime.current == -1 || Date.now() - lastEmitTime.current > 100) {
            emitPlayerData();
            lastEmitTime.current = Date.now();
        }
    });
            


 
    useEffect(() => {
    
        return () => {
            socket.off('disconnect');
        };
      }, []);
    


    return(
    <>
    {avatars.map((props, i) => 
        <Avatar {...props} ref={(element) => (avatarRefs.current[i] = element)} />
    )}
    </>) 
}
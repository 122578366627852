import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { useGLTF } from '@react-three/drei';
import io from 'socket.io-client';
import { SceneContext } from './Nome';
import { useStore } from './Store';
import { Avatar } from './Avatar';
import Gigi from './Gigi';

const socket = io('https://yeche-multiplayer-server-987ec9d64ba5.herokuapp.com', { transports: ['websocket'] });

socket.emit('joinRoom', 'nome');

export default function Multiplayer({ }) {

    const avatarGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/avatar.glb');

    const denimTexture = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/denim.jpg');
    const wlgTexture = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/wlg_hat.jpg');

    const { camera, scene } = useThree();

    const otherPlayers = useRef([]);

    const lastGigiTimestampRef = useRef(new Date(0));

    const {
        curColliderNameRef,
        numUserHatsRef,
        userAddressRef,
        userENSRef,
        avatarDataRef,
        messageRef,
        gigiMessageRef,
    } = useContext(SceneContext);

    const avatars = useStore(state => state.avatars);
    const addAvatar = useStore(state => state.addAvatar);
    const removeAvatar = useStore(state => state.removeAvatar);

    const avatarRefs = useRef([]);

    const addedAvatarsRef = useRef({});

    const lastEmitTime = useRef(-1);

    const lastMessageSentToGigiRef = useRef("");


    useEffect(() => {
        if (denimTexture.image) {
            denimTexture.wrapS = denimTexture.wrapT = THREE.RepeatWrapping;
            denimTexture.repeat.set(2, 2);
        }

        if (wlgTexture.image) {
            wlgTexture.flipY = false;
        }

    }, [denimTexture, wlgTexture]);


    useEffect(() => {

        socket.on("gigidata", (data) => {
            // console.log("gigidata: " + data);

            for (const [key, value] of Object.entries(data)) {
                // console.log(value);

                if (key == "message" && value !== undefined && gigiMessageRef.current != value) {
                    // console.log('setting gigi message: ' + value);
                    gigiMessageRef.current = value;
                }
            }
        });

        socket.on("playerdata", (data) => {

            let avatarsInData = [];

            for (const [key, value] of Object.entries(data)) {

                let isSelf = (key === socket.id);

                avatarsInData.push(key);

                // console.log('avatars ' + avatars)

                //find avatar with socketID key
                for (let i = 0; i < avatars.length; i++) {

                    // console.log(avatars[i]);
                    // console.log('checking if ' + avatars[i].socketID + ' == ' + key)

                    if (avatars[i].socketID == key) {
                        // console.log("avatar exists for socket id " + key + " updating position")
                        //update position

                        avatarDataRef.current[avatars[i].socketID] = {};

                        if (value.object.message !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].message = value.object.message;
                        }

                        if (value.object.lastGigiTimestamp !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].lastGigiTimestamp = value.object.lastGigiTimestamp;
                        }

                        if (value.object.landed !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].landed = value.object.landed;
                        }

                        if (value.object.address !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].address = value.object.address;
                        }

                        if (value.object.ens !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].ens = value.object.ens;
                        }

                        if (value.object.numHats !== undefined) {
                            avatarDataRef.current[avatars[i].socketID].numHats = value.object.numHats;
                        }

                        let avatar = avatarRefs.current[i];

                        if (avatar !== null && !isSelf && value.object.position && value.object.rotation && value.object.curCollider) {
                            let avatarCollider = scene.getObjectByName(value.object.curCollider);

                            if (avatarCollider === undefined) {
                                // console.log('avatar collider is undefined');
                                continue;
                            }

                            let avatarLocalPos = new THREE.Vector3(value.object.position.x, value.object.position.y, value.object.position.z);

                            let avatarGlobalPos = avatarCollider.localToWorld(avatarLocalPos);

                            let avatarOffsetY = 1.5;
                            avatarGlobalPos.y = avatarGlobalPos.y - avatarOffsetY;


                            let avatarLerpSpeed = 0.05;

                            avatar.position.lerp(avatarGlobalPos, avatarLerpSpeed);

                            let newAvatarRot = new THREE.Euler(value.object.rotation.x, value.object.rotation.y, value.object.rotation.z, 'XYZ');

                            let ogRot = avatar.quaternion.clone();

                            avatar.setRotationFromEuler(newAvatarRot);

                            let newRot = avatar.quaternion.clone();

                            avatar.quaternion.set(ogRot.x, ogRot.y, ogRot.z, ogRot.w)

                            avatar.quaternion.slerp(newRot, avatarLerpSpeed);

                            break;
                        }
                    }
                }

                //if not, add it
                if (addedAvatarsRef.current[key] === undefined && value.object.timestamp !== undefined) {
                    //check that the avatar has been updated in the last 30 seconds
                    let now = new Date().getTime();
                    let diff = now - value.object.timestamp;

                    if (diff > 30000) {
                        continue;
                    }

                    console.log("adding avatar for socket id " + key);
                    console.log('isSelf is ' + isSelf);
                    addedAvatarsRef.current[key] = true;
                    addAvatar(key, avatarGLB, denimTexture, wlgTexture, isSelf);
                }
            }

            //remove avatars that are no longer in data
            for (let i = 0; i < avatars.length; i++) {
                if (!avatarsInData.includes(avatars[i].socketID) && addedAvatarsRef.current[avatars[i].socketID] == true) {
                    console.log("removing avatar for socket id " + avatars[i].socketID);
                    console.log('my socket id is ' + socket.id);
                    addedAvatarsRef.current[avatars[i].socketID] = undefined;
                    removeAvatar(avatars[i].name);
                    //remove avatar
                }
            }
        });


    }, [avatars]);

    async function sendGigiPrompt(userPrompt) {
        try {
            console.log('sending message to Gigi');

            let gigiData = {
                message: "Gigi is thinking...",
            }

            socket.emit('sendgigidata', gigiData);

            const response = await fetch('https://yeche-api.herokuapp.com/openai/nomeprompt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: userPrompt,
                }),
            });

            const gigiResponse = await response.text();

            //quick fix to extract message
            let gigiMessage = gigiResponse;

            const contentMatch = gigiResponse.match(/"content":"(.*?)"/);
            
            if (contentMatch && contentMatch[1]) {
                gigiMessage = contentMatch[1];
            } else {
                console.log('Could not find content in gigi response.');
            }
            
            gigiData = {
                message: gigiMessage,
            }

            socket.emit('sendgigidata', gigiData);

        } catch (error) {
            console.error('Error:', error);
        }
    }


    function emitPlayerData() {
        let playerCollider = scene.getObjectByName(curColliderNameRef.current);
        let playerLocalPos = playerCollider.worldToLocal(camera.position.clone());
        let playerRotation = new THREE.Vector3(camera.rotation.x, camera.rotation.y, camera.rotation.z);


        // if(messageRef.current.toLowerCase().includes('gigi') && lastMessageSentToGigiRef.current !== messageRef.current) {
        if (lastMessageSentToGigiRef.current !== messageRef.current) {
            lastMessageSentToGigiRef.current = messageRef.current;
            sendGigiPrompt(messageRef.current);

            lastGigiTimestampRef.current = Date.now();
        }


        let playerData = {
            curCollider: curColliderNameRef.current,
            position: playerLocalPos,
            rotation: playerRotation,
            address: userAddressRef.current,
            ens: userENSRef.current,
            numHats: numUserHatsRef.current,
            landed: true,
            message: messageRef.current,
            timestamp: Date.now(),
            lastGigiTimestamp: lastGigiTimestampRef.current,
        }

        socket.emit("sendplayerposition", playerData);
        // console.log('emitting player data');
    }

    //emit player data every 100ms
    useFrame(() => {
        if (lastEmitTime.current == -1 || Date.now() - lastEmitTime.current > 100) {
            emitPlayerData();
            lastEmitTime.current = Date.now();
        }
    });

    useEffect(() => {

        return () => {
            socket.off('disconnect');
        };
    }, []);


    return (
        <>
            <Gigi />

            {avatars.map((props, i) =>
                <Avatar {...props} ref={(element) => (avatarRefs.current[i] = element)} />
            )}
        </>)
}
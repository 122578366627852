import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import './Tantrum.css';
import { DeviceUUID } from 'device-uuid';


function ChatWindow() {
    const [messages, setMessages] = useState([]);
    
    const SPONSORED_MESSAGE_INTERVAL = 60 * 1000; 
    const [sponsoredMessages, setSponsoredMessages] = useState([]);
    const [mergedMessages, setMergedMessages] = useState([]);

    const usernameUUID = new DeviceUUID().get();

    const connectionUrl = `https://yeche-api.herokuapp.com`;

    const socket = useRef(null);

    const getAllMessages = useCallback(async () => {
        const { data: receivedMessages } = await axios.get(connectionUrl + '/messages');
    
        let newMessages = [];
    
        for(let i = 0; i < receivedMessages.length; i++) {
            
            let messageText = receivedMessages[i].message;
            let isAssistant = receivedMessages[i].isAssistant;
            let username = receivedMessages[i].user.username;
            let createdAt = receivedMessages[i].createdAt;

            let newMessage = {
                avatar: '...',
                text: messageText,
                isAssistant: isAssistant,
                isSponsored: false,
                username: username,
                createdAt: createdAt
            }
    
            newMessages.push(newMessage);
        }
    
        // console.log('concatenating sponsored messages');
        // console.log(sponsoredMessages);

        newMessages = newMessages.concat(sponsoredMessages);
    
        newMessages.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
    
        setMessages(newMessages);
    }, [sponsoredMessages]);  // added sponsoredMessages as a dependency
    
    /* make a hook that's called every SPONSORED_MESSAGE_INTERVAL seconds that adds a new sponsored message to the sponsoredMessages array */
    useEffect(() => {
        const interval = setTimeout(() => {
            const freemintMessages = [
                'Hey there, you party animals! Are you ready to let loose and mint a free NFT by Jared Madere and Wretched Worm? Click below, my tipsy pals.',
                "Lost in the shadows, searching for my beauty mark, but fear not, my drunken darlings! Click below, and together we'll mint a free NFT by Jared Madere and Wretched Worm.",
                "In this swirling haze of music and merriment, we dance and sing, celebrating the unpredictable. So put down that drink for a moment and click below to mint your free NFT at Tantrum!",
                "Oh, where did I put my lipstick? No matter, my blurry-eyed friends! Click below and let's mint a free NFT by Jared Madere and Wretched Worm at Tantrum. We'll paint the town red, leaving a trail of drunken creativity that will be the talk of the digital world!",
                "Grab a fag and gather 'round, my intoxicated comrades! It's time to mint a free NFT at Tantrum, where the cabaret chaos reigns supreme.",
                "Hiccup, hiccup! Gather 'round, my boisterous bunch! It's time to get messy and mint a free NFT by Jared Madere and Wretched Worm.",
                "Click below, my merry mates, and let's stumble into the world of NFTs at Tantrum. Life's a cabarave, and we're the drunken dancers in this pixelated parade!",
                "Hey, have you seen my cocktail? Nevermind, let's focus on something even better—minting a free NFT! Click below, my intoxicated comrades",
                "Toast to the chaos, my slurred speech stars! Click below, my stumbling sweethearts, and let's mint a free NFT by Jared Madere and Wretched Worm at Tantrum.",
                "In this whimsical whirlwind of inebriation and imagination, click below, my playful partiers, and let's mint a free NFT at Tantrum.",
                "Ah, I see the skepticism in your eyes, questioning the allure of NFTs. But hear me out, my doubting comrade! Click below and let's explore the enchanting realm of free NFTs at Tantrum. Prepare to be pleasantly surprised!",
                "I understand your reservations, my friend, for the digital realm can be a perplexing maze. Yet, let me offer you a toast to curiosity! Click below and venture into the world of free NFTs by Jared Madere and Wretched Worm. Discover the beauty that lies within, beyond the doubts that cloud your mind!"
            ];
    
            let randMessage = freemintMessages[Math.floor(Math.random() * freemintMessages.length)];

            let newSponsoredMessage = {
                avatar: '...',
                text: randMessage,
                isAssistant: true,
                isSponsored: true,
                username: 'Gigi',
                createdAt: new Date().toISOString()
            }
            // console.log(newSponsoredMessage.createdAt);

            setSponsoredMessages(prevSponsoredMessages => [...prevSponsoredMessages, newSponsoredMessage]);

        }, SPONSORED_MESSAGE_INTERVAL);
        return () => clearTimeout(interval);
    }, []);


    useEffect(() => {
        // console.log(sponsoredMessages);
        // console.log('merging');

        let merged = [...messages, ...sponsoredMessages];
        merged.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);

        //if the last two messages are sponsored messages, remove the last one
        // if(merged.length > 1 && merged[merged.length - 1].isSponsored && merged[merged.length - 2].isSponsored) {
        //     console.log('last two messages are sponsored, removing last one');
        //     merged.pop();
        //     //also remove the last message from sponsoredMessages
        //     setSponsoredMessages(prevSponsoredMessages => prevSponsoredMessages.slice(0, -1));
        // }

        setMergedMessages(merged);
    }, [messages, sponsoredMessages]);

    useEffect(() => {
        socket.current = io(connectionUrl);

        socket.current.on('connect', () => {
            console.log(`Connected to socket`);
        });

        socket.current.on('messages', async () => {
            await getAllMessages();
        });

        socket.current.on('exception', (data) => {
            console.error(data);
        });

        socket.current.on('disconnect', () => {
            console.log('Disconnected');
        });

        // Fetch messages when component mounts
        getAllMessages();

        // Cleanup function to disconnect from socket when component unmounts
        return () => {
            socket.current.disconnect();
        };

    }, []);  // empty dependency array so this runs once on mount

    const addMessage = message => {
        // setMessages(prevMessages => [...prevMessages, message]);

        // Create payload and send new message to server
        const payload = { username: usernameUUID, message: message.text };
        if(socket.current) {
            console.log('Sending payload', payload);
            socket.current.emit('newMessage', payload);
        }
    };

    return (
        <>
            <MessageList messages={mergedMessages} />
            <ChatInput addMessage={addMessage} />
        </>
    );
}

export default ChatWindow;

import React, { useEffect, useState } from 'react';
import './Tantrum.css';
import { NavLink } from 'react-router-dom';

function Message({ text, isAssistant, username, isSponsored }) {
    const [avatarURL, setAvatarURL] = useState("");

    function simpleHash(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            let charCode = str.charCodeAt(i);
            hash += charCode;
        }
        return hash;
    }
    
    function uuidToInteger(uuid) {
        // remove the hyphens from the uuid
        let strippedUuid = uuid.replace(/-/g, '');
    
        // hash the uuid
        let hash = simpleHash(strippedUuid);
    
        // modulo 42 and add 1 to make the range from 1 to 42
        let integer = (hash % 42) + 1;
    
        return integer;
    }
        
    useEffect(() => {
        // console.log(isAssistant);
        let avatarURL;

        if(isAssistant) {
            avatarURL = process.env.PUBLIC_URL + "/textures/avatar_tokens/gigi.png";
            setAvatarURL(avatarURL);
            return;
        }
        else {
            if(username === undefined) {
                avatarURL = process.env.PUBLIC_URL + "/textures/avatar_tokens/0.png";
                setAvatarURL(avatarURL);
                return;    
            }
            else {
                avatarURL = process.env.PUBLIC_URL + "/textures/avatar_tokens/" + uuidToInteger(username).toString() + ".png";
                setAvatarURL(avatarURL);
                return;
            }

        }
    }, [isAssistant, username]);

    return (

        <>
        
        {isSponsored && (

        <div className={isAssistant ? "message-Gigi" : "message"}>
            <img src = {avatarURL} className="avatarToken" />
            <div className={isAssistant ? "messageGigiBubble" : "messageBubble"}>
                {text}<br/><br/>
                <div className="tantrumVideoContainer">
                    <NavLink to="/tantrummint">
                        <video src={process.env.PUBLIC_URL + "/textures/freemint.mov"} className="tantrumSponsoredVideo" autoPlay loop muted playsInline/>
                    </NavLink>
                    <NavLink to="/tantrummint" className="tantrumMintLink">
                        Click Here
                    </NavLink>
                </div>
            </div>
        </div>

        )}

        {!isSponsored && (

        <div className={isAssistant ? "message-Gigi" : "message"}>
        <img src = {avatarURL} className="avatarToken" />
        <div className={isAssistant ? "messageGigiBubble" : "messageBubble"}>
            {text}
        </div>
        </div>
        )}
        
        
        </>


    );
}

export default Message;

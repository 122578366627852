import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Contract, providers } from 'ethers';
import '../WanderlustOE.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import WANDERLUSTOE_ABI from '../wanderlustoe-abi.json';

import { isMobile } from 'react-device-detect';

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const WANDERLUSTOE_CONTRACT = '0xd86b1768Fe0fe28d533b85B002aAf24BBdCa3587';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;
const giveawayIndex = 1;

function CountdownTimer(props) {
    const { endTime } = props;
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Calculate the time left until the end time
        const timeLeft = Math.max(0, endTime - Date.now());
  
        // Update the state with the new time left value
        setTimeLeft(timeLeft);
      }, 1000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, [endTime]);
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the days, hours, minutes, and seconds left
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
    return (
      <div>
        {hours} hours {minutes} minutes {seconds} seconds left
      </div>
    );
}
  
/* carousel of 3 images, mint button, connect wallet button */
function TantrumMint(props) {

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");

    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [mintEnd, setMintEnd] = useState(null);

    const [minted, setMinted] = useState(false);

    const [numMinted, setNumMinted] = useState(0);

    async function loadNumMinted() {
        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

        const wanderlustOEContract = new Contract(WANDERLUSTOE_CONTRACT, WANDERLUSTOE_ABI, infuraProviderTemp);

        const curGiveaway = await wanderlustOEContract.giveaways(giveawayIndex);
        const numMintedHex = curGiveaway.currentSupply;

        //convert from hex to decimal
        const numMintedDec = parseInt(numMintedHex, 16);

        setNumMinted(numMintedDec);
    }

    useEffect(() => {
        document.title = props.title;
    

        //scroll to the top
        window.scrollTo(0, 0);

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);

        loadNumMinted();

        const end = new Date(0);
        end.setUTCSeconds(1685365200);

        setMintEnd(end);
    }, []);

    async function mintWanderlustOE() {
        const signer = ethersProvider.getSigner();

        const wanderlustOEContract = new Contract(WANDERLUSTOE_CONTRACT, WANDERLUSTOE_ABI, signer);

        const tx = await wanderlustOEContract.mintGiveaway(giveawayIndex);

        console.log('minting');
        setMintText("minting...");

        await tx.wait();

        setMintText("minted");
        setMinted(true);        
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        // const signer = ethersProvider.getSigner();

        // const wanderlustOEContract = new Contract(WANDERLUSTOE_CONTRACT, WANDERLUSTOE_ABI, signer);

        // const curGiveaway = await wanderlustOEContract.giveaways(0);
        // const numMintedHex = curGiveaway.currentSupply;

        // //convert from hex to decimal
        // const numMintedDec = parseInt(numMintedHex, 16);

        // setNumMinted(numMintedDec);

        // const claimedNFT = await wanderlustOEContract.claimed(userAddress);

        // if(claimedNFT) {
        //     //TODO disable mint button
        // }

        setMintText("MINT ");
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    return( 
        <>
            {connected && <div className="Address-pill-wanderlustOE">{humanAddress}</div>}
            {!connected && <button className="Connect-button-wanderlustOE" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="bg-wanderlustOE"></div>

            {isMobile &&
                <div className="TantrumOE-container-mobile">
                    <div className="WanderlustOE-preview-mobile">
                        <img src={require('../images/wlgla.jpg')} />
                    </div>

                    <div className="WanderlustOE-info-mobile">
                        <div className="WanderlustOE-header">
                            <div className="WanderlustOE-info-item">
                                <div className="WanderlustOE-title">
                                    Laje<br/>
                                    Wretched Worm and Jared Madere
                                </div>
                            </div>

                            <div className='WanderlustOE-info-item'>
                                <div className="Etherscan-link">
                                    <a href="https://etherscan.io/address/0xd86b1768fe0fe28d533b85b002aaf24bbdca3587" target="_blank">
                                        <img src={require('../images/etherscan_black.png')} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="WanderlustOE-info-item">
                            <div className="WanderlustOE-fullres-link">
                                <a href="https://arweave.net/eqRdjjuIUcHyXMZmlvDNgvscY4H3uznIH6BGcY-2hnE" target="_blank">
                                    view full resolution
                                </a>
                            </div>
                        </div>

                        <div className="WanderlustOE-info-item">
                            {connected && <button className="Mint-button-active-wanderlustOE" onClick={() => {if(!minted) mintWanderlustOE(); } }>{mintText}</button>}
                            {!connected && <button className="Mint-button-inactive-wanderlustOE" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                        </div>

                        <div className="WanderlustOE-info-item">
                            <div className="Countdown">
                                <CountdownTimer endTime={mintEnd} />
                            </div>
                        </div>

                        <div className="WanderlustOE-info-item">
                            <div className="WanderlustOEText">
                            Laje is a unique 1/1 referencing Wretched Worm and Jared Madere's Wanderlust Girls collection. This artwork features a new girl, accompanied by exclusive 25 slot Blood Camo passbook—and is further expanded with the addition of special 1/1 token sets.<br/><br/>
                            {numMinted > 0 && (
                            <div>
                                <br />
                                {numMinted} minted so far
                            </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            }


            {!isMobile &&
                <>
                <div className="WanderlustOE-info-desktop">
                    <div className="WanderlustOE-header">
                        <div className="WanderlustOE-info-item">
                            <div className="WanderlustOE-title">
                            Laje<br/>
                            Wretched Worm and Jared Madere
                            </div>
                        </div>

                        <div className='WanderlustOE-info-item'>
                            <div className="Etherscan-link">
                                <a href="https://etherscan.io/address/0xd86b1768fe0fe28d533b85b002aaf24bbdca3587" target="_blank">
                                    <img src={require('../images/etherscan_black.png')} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="WanderlustOE-info-item">
                        {connected && <button className="Mint-button-active-wanderlustOE" onClick={() => {if(!minted) mintWanderlustOE(); } }>{mintText}</button>}
                        {!connected && <button className="Mint-button-inactive-wanderlustOE" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                    </div>

                    <div className="WanderlustOE-info-item">
                        <div className="WanderlustOE-fullres-link">
                            <a href="https://arweave.net/eqRdjjuIUcHyXMZmlvDNgvscY4H3uznIH6BGcY-2hnE" target="_blank">
                                view full resolution
                            </a>
                        </div>
                    </div>

                    <div className="WanderlustOE-info-item">
                        <div className="Countdown">
                            <CountdownTimer endTime={mintEnd} />
                        </div>
                    </div>

                    <div className="WanderlustOE-info-item">
                        <div className="WanderlustOEText">
                        Laje is a unique 1/1 referencing Wretched Worm and Jared Madere's Wanderlust Girls collection. This artwork features a new girl, accompanied by exclusive 25 slot Blood Camo passbook—and is further expanded with the addition of special 1/1 token sets.<br/><br/>

                        {numMinted > 0 && (
                        <div>
                            <br />
                            {numMinted} minted so far
                        </div>
                        )}
                        </div>
                    </div>
                </div>

                <div className="WanderlustOE-preview-desktop">
                    <img src={require('../images/wlgla.jpg')} />
                </div>
                </>
            }
        </>
    );
}

export default TantrumMint;
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Select } from '@react-three/postprocessing';
import { SceneContext } from "./Lunar.js";

export default function SelectCrosshair({children, name}) {

    const selectEnabledRef = useRef(false);
    const [selectEnabled, setSelectEnabled] = useState(false);
    const { crosshairArtworkRef } = useContext(SceneContext);

    useFrame(() => {
        if(crosshairArtworkRef.current == name && !selectEnabledRef.current) {
            setSelectEnabled(true);
            selectEnabledRef.current = true;
        }
        else if(crosshairArtworkRef.current != name && selectEnabledRef.current){
            setSelectEnabled(false);
            selectEnabledRef.current = false;
        }
    });


    return(
        <Select enabled={selectEnabled}>
            {children}
        </Select>
    )
}

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Contract, providers, utils } from 'ethers';
import './Whistlegraph.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import SCHOOL_OF_TRUTH_ABI from './school-of-truth-abi.json';

import { isMobile } from 'react-device-detect';
import CryptoJS from "crypto-js";

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const SCHOOL_OF_TRUTH_ADDRESS = '0x34102CE63687fdE9B746E18A1469467C07F9F5ff';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

/* carousel of 3 images, mint button, connect wallet button */
function Whistlegraph(props) {

    const lightboxRef = useRef(null);
    const lightboxImgRef = useRef(null);

    const textBoxRef = useRef(null);

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("connect to verify ownership");

    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [mintEnd, setMintEnd] = useState(null);

    const [minted, setMinted] = useState(false);

    useEffect(() => {
        document.title = props.title;

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);
    }, []);

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        const normalizedUserAddress = utils.getAddress(userAddress);
        
        const signer = ethersProvider.getSigner();
        const schoolOfTruthContract = new Contract(SCHOOL_OF_TRUTH_ADDRESS, SCHOOL_OF_TRUTH_ABI, signer);
        
        const ownsToken12 = await schoolOfTruthContract.ownerOf(12);
        const ownsToken33 = await schoolOfTruthContract.ownerOf(33);
        
        const normalizedOwnsToken12 = utils.getAddress(ownsToken12);
        const normalizedOwnsToken33 = utils.getAddress(ownsToken33);
        
        if(normalizedOwnsToken12 === normalizedUserAddress) {
            //create a div with a link to performance40Link and the text "download assets for performance 40"
            const performance40Div = document.createElement("div");
            // performance40Div.className = "Whistlegraph-info-item";
            const performance40Link = document.createElement("a");
            performance40Link.href = CryptoJS.AES.decrypt('U2FsdGVkX187xblZ+rL9lmN1EjYGHFTAYaO1VX7kWGwbgb+wNgjp8pJI2g/nsAaPetla+lvrtJcJ6iMBumggUgLKKRmpJG7Dit8zbUpLwnk=', 'notsecurejustannoying').toString(CryptoJS.enc.Utf8);
            performance40Link.innerText = "Download assets for Underpainting 22.10.25.19.40 Performance";
            performance40Div.appendChild(performance40Link);
            textBoxRef.current.appendChild(performance40Div);
        }

        if(normalizedOwnsToken33 === normalizedUserAddress) {
            //create a div with a link to performance30Link and the text "download assets for performance 30"
            const performance30Div = document.createElement("div");
            // performance30Div.className = "Whistlegraph-info-item";
            const performance30Link = document.createElement("a");
            performance30Link.href = CryptoJS.AES.decrypt('U2FsdGVkX1+NPn45h2Gw5CLOf1GhgGgRLMl566xIz65mo6uIQ3BN6lIkDsw6EXcPQnlYxRigXHQUDPfmjZ8k41AkTkrE4NWHpdScSzdKc9I=', 'notsecurejustannoying').toString(CryptoJS.enc.Utf8);
            performance30Link.innerText = "Download assets for Underpainting 22.10.25.17.30 Performance";
            performance30Div.appendChild(performance30Link);
            textBoxRef.current.appendChild(performance30Div);
        }
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    return( 
        <>
            {connected && <div className="Address-pill-whistlegraph">{humanAddress}</div>}
            {!connected && <button className="Connect-button-whistlegraph" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="bg-whistlegraph"></div>

            <div className="Whistlegraph-container-mobile">
                <div className="Whistlegraph-info-item">
                    <div className="WhistlegraphText" ref={textBoxRef}>
                    This page is for collectors of <i>Underpainting 22.10.25.17.30 Performance</i> and <i>Underpainting 22.10.25.19.40 Performance</i> by Whistlegraph. Please connect your wallet to verify ownership and gain access to extra materials.
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Whistlegraph;
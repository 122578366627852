import React from 'react';
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';

export default function CountdownTimer({endTime}) {
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
        let intervalId;
    
        // Immediately update the time left if endTime has changed
        //get Date.now in seconds

        const timeNowSeconds = Math.floor(Date.now() / 1000);

        const timeLeft = Math.max(0, endTime - timeNowSeconds);
        setTimeLeft(timeLeft);
    
        // console.log('setting time left to ' + timeLeft);

        // Update the time left every second
        intervalId = setInterval(() => {
        //   const newTimeLeft = Math.max(0, endTime - Date.now());
        const newTimeLeft = Math.max(0, endTime - Math.floor(Date.now() / 1000));
          setTimeLeft(newTimeLeft);
        }, 1000);
    
        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, [endTime]);    
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the hours, minutes, and seconds left
    const days = Math.floor(timeLeft / 60 / 60 / 24);
    const hours = Math.floor(timeLeft / 60 / 60) % 24;
    const minutes = Math.floor(timeLeft / 60) % 60;
    const seconds = Math.floor(timeLeft) % 60;
  
    return (
        <div className="AuctionEndTimer">

        {timeLeft == 0 ? "" : `${days} ${days == 1 ? 'day' : 'days'} ${hours} ${hours == 1 ? 'hour' : 'hours'} ${minutes} ${minutes == 1 ? 'minute' : 'minutes'} ${seconds} ${seconds == 1 ? 'second' : 'seconds'} remaining`}
        </div>
    );
}  


import React from 'react';
import { useState, useEffect, useRef, forwardRef } from 'react';
import { Contract, providers } from 'ethers';
import './WanderlustGirls.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
// import WANDERLUSTOE_ABI from './wanderlustoe-abi.json';
import WANDERLUST_ABI from './WanderlustGirlsAbi.json';
import { NavLink } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { isMobile, isTablet } from 'react-device-detect';

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const WANDERLUST_CONTRACT = '0x05c96B7DB340027B07D4D234fb12F45a51f444CF';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

const carouselSettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
};

function CountdownTimer(props) {
    const { endTime } = props;
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Calculate the time left until the end time
        const timeLeft = Math.max(0, endTime - Date.now());
  
        // Update the state with the new time left value
        setTimeLeft(timeLeft);
      }, 1000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, [endTime]);
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the days, hours, minutes, and seconds left
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
    return (
      <div>
        {days} days {hours} hours {minutes} minutes {seconds} seconds left
      </div>
    );
}

// function IntegerInput() {
//     const [inputValue, setInputValue] = useState('1');
  
//     const handleInputChange = (e) => {
//       const value = e.target.value;
  
//       if (value === '' || Number.isInteger(parseFloat(value))) {
//         setInputValue(value);
//       }
//     };
  
//     return (
//       <div>
//         <input
//           className="WanderlustMintInput"
//           type="number"
//           step="1"
//           min="0"
//           value={inputValue}
//           onChange={handleInputChange}
//         />
//       </div>
//     );
// }
    
/* carousel of 3 images, mint button, connect wallet button */
function WanderlustGirls(props) {

    const lightboxRef = useRef(null);
    const lightboxImgRef = useRef(null);

    const [inputValue, setInputValue] = useState('1');

    const [mouseDownPosition, setMouseDownPosition] = useState(null);

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");

    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [mintEnd, setMintEnd] = useState(null);

    const [minted, setMinted] = useState(false);

    const [numMinted, setNumMinted] = useState(0);

    async function loadNumMinted() {
        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

        const wanderlustGirlsContract = new Contract(WANDERLUST_CONTRACT, WANDERLUST_ABI, infuraProviderTemp);

        const totalSupply = await wanderlustGirlsContract.totalSupply();
        const totalSupplyNum = totalSupply.toNumber();

        console.log("totalSupply: " + totalSupply);

        // const curGiveaway = await wanderlustGirlsContract.giveaways(0);
        // const numMintedHex = curGiveaway.currentSupply;

        // //convert from hex to decimal
        // const numMintedDec = parseInt(numMintedHex, 16);

        setNumMinted(totalSupplyNum);
    }

    useEffect(() => {
        document.title = props.title;
    

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);



        loadNumMinted();


        const end = new Date(0);
        end.setUTCSeconds(1681239600);

        setMintEnd(end);
    }, []);

    async function mintWanderlustGirls() {
        const signer = ethersProvider.getSigner();

        const wanderlustGirlsContract = new Contract(WANDERLUST_CONTRACT, WANDERLUST_ABI, signer);

        const quantity = parseInt(inputValue);

        const whitelistOnly = await wanderlustGirlsContract.whitelistOnly();

        if(whitelistOnly) {
            const whitelisted = await wanderlustGirlsContract.checkWhitelisted(address);

            if(!whitelisted) {
                alert("You must be whitelisted to mint now, please check back later for the public mint");
                return;
            }
        }

        const price = await wanderlustGirlsContract.checkPrice(quantity, address);

        console.log('price for ' + quantity + ' babies is ' + price);

        let tx = await wanderlustGirlsContract.mint(quantity, {
            value: price
        });

        console.log('minting');
        setMintText("minting...");

        await tx.wait();

        setMintText("minted");
        setMinted(true);        


        // const giveawayIndex = 0;

        // const tx = await wanderlustGirlsContract.mintGiveaway(giveawayIndex);

    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        // const signer = ethersProvider.getSigner();

        // const wanderlustGirlsContract = new Contract(WANDERLUST_CONTRACT, WANDERLUST_ABI, signer);

        // const price = await wanderlustGirlsContract.checkPrice(quantity, address);

        // console.log('price for ' + quantity + ' babies is ' + price);

        // tx = await wanderlustGirlsContract.mint(quantity, {
        //     value: price
        // });


        // const curGiveaway = await wanderlustGirlsContract.giveaways(0);
        // const numMintedHex = curGiveaway.currentSupply;

        // //convert from hex to decimal
        // const numMintedDec = parseInt(numMintedHex, 16);

        // setNumMinted(numMintedDec);

        // const claimedNFT = await wanderlustGirlsContract.claimed(userAddress);

        // if(claimedNFT) {
        //     //TODO disable mint button
        // }

        setMintText("MINT ");
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    function openLightbox(src) {
        lightboxRef.current.classList.add('visible');
        lightboxRef.current.style.pointerEvents = 'all';

        //remove current src
        lightboxImgRef.current.src = "";

        lightboxImgRef.current.src = src; // display actual image
    }

    function closeLightbox() {
        //check if current opacity is less than 1
        lightboxRef.current.classList.remove('visible');
        lightboxRef.current.style.pointerEvents = 'none';
    }

      
    function handleMouseDown(e) {
        setMouseDownPosition({ x: e.clientX, y: e.clientY });
    }
    
    function handleMouseUp (e, imgSrc) {
        const threshold = 5; // Adjust this value to control swipe sensitivity
        const mouseUpPosition = { x: e.clientX, y: e.clientY };
    
        if (
          Math.abs(mouseUpPosition.x - mouseDownPosition.x) <= threshold &&
          Math.abs(mouseUpPosition.y - mouseDownPosition.y) <= threshold
        ) {
          openLightbox(imgSrc);
        }
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
    
        if (value === '' || Number.isInteger(parseFloat(value))) {
          setInputValue(value);
        }
    };  
  

    return( 
        <>
            {connected && <div className="Address-pill-wanderlustGirls">{humanAddress}</div>}
            {!connected && <button className="Connect-button-wanderlustGirls" onClick={() => connectWallet()}>{connectText}</button>}

            <NavLink to="/wanderlustyechelles" className="Enter-gallery-wanderlust">
                <div className={!isMobile || isTablet ? "EnterGallery" : "EnterGalleryMobile"}>
                  <img src={require('./images/enter_gallery.png')} alt="enter gallery" className={!isMobile || isTablet ? "EnterGalleryImage" : "EnterGalleryImage-mobile"} />
                  <div className='EnterYechellesText'>
                    ENTER<br/> YECHELLES<br/> GALLERY
                  </div>
                </div>
            </NavLink>

            {/* <div className="Enter-gallery-wanderlust">
                <div className="EnterGallery">
                  <img src={require('./images/enter_gallery.png')} alt="enter gallery" className={!isMobile || isTablet ? "EnterGalleryImage" : "EnterGalleryImage-mobile"} />
                  <div className='EnterYechellesText'>
                    YECHELLES GALLERY<br/> OPENS 3PM EST
                  </div>
                </div>
            </div> */}

            <div className="WanderlustGirls-lightbox" ref={lightboxRef} onClick={(() => closeLightbox())}>
                <img ref={lightboxImgRef}/>
            </div>

            <div className="bg-wanderlustGirls"></div>

            <div className={`WanderlustGirls-container${isMobile && !isTablet ? "-mobile" : ""}`}>
                <div className={`WanderlustGirls-preview${isMobile && !isTablet ? "-mobile" : "-desktop"}`}>
                    <Slider {...carouselSettings}>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/1.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/1.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/2.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/2.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/3.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/3.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/4.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/4.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/5.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/5.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/6.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/6.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/7.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/7.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/8.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/8.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/9.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/9.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/10.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/10.jpg")}/>
                        <img src={process.env.PUBLIC_URL + "/textures/wanderlust_mint/11.jpg"} className="WanderlustCarousel-img" onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(e, process.env.PUBLIC_URL + "/textures/wanderlust_mint/11.jpg")}/>
                    </Slider>
                </div>

                <div className={`WanderlustGirls-info${isMobile && !isTablet ? "-mobile" : "-desktop"}`}>
                    <img src={process.env.PUBLIC_URL + '/textures/wanderlust_logo.png'} className='WanderlustGirlsLogo'/>
                    <div className='WanderlustGirls-ethscan-link'>
                        <div className="Etherscan-link-wanderlust">
                            <a href="https://etherscan.io/address/0x05c96b7db340027b07d4d234fb12f45a51f444cf" target="_blank">
                                <img src={require('./images/etherscan_black.png')} />
                            </a>
                        </div>
                        <div className="Etherscan-link-wanderlust">
                            <a href="https://opensea.io/collection/wanderlustgirls" target="_blank">
                                <img src={require('./images/opensea_black.png')} />
                            </a>
                        </div>
                    </div>

                    <div className="WanderlustGirls-info-item">
                        {/* <input type="number" step="1" min="0" /> */}
                        <div className="WanderlustGirlsMintZone">
                            {/* <IntegerInput/>*/}

                            <div>
                                <input
                                className="WanderlustMintInput"
                                type="number"
                                step="1"
                                min="0"
                                value={inputValue}
                                onChange={handleInputChange}
                                />
                            </div>


                            {connected && <button className="Mint-button-active-wanderlustGirls" onClick={() => {if(!minted) mintWanderlustGirls(); } }>{mintText}</button>}
                            {!connected && <button className="Mint-button-inactive-wanderlustGirls" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                        </div>
                    </div>

                    <div className="WanderlustGirls-info-item">
                        <div className="WanderlustGirlsPriceGrid">
                            <div>Mint 1 = 0.0333E</div>
                            <div>Mint 5 = 5% off</div>
                            <div>Mint 10 = 10% off</div>
                            <div>Mint 15 = 15% off</div>
                        </div>
                        <div className="WanderlustPieKeyNote">
                            Additional 20% discount for Pie Key holders.
                        </div>
                    </div>

                    {/* <div className="WanderlustGirls-info-item">
                        <div className="WanderlustGirls-fullres-link">
                            <a href="https://arweave.net/YJYwFL-6KGJHLEpy2wGhka9eduEeaXMLtRbMqWeoN3s" target="_blank">
                                view full resolution
                            </a>
                        </div>
                    </div> */}

                    {/* <div className="WanderlustGirls-info-item">
                        <div className="Countdown">
                            <CountdownTimer endTime={mintEnd} />
                        </div>
                    </div> */}

                    <div className="WanderlustGirls-info-item">
                        <div className="WanderlustGirlsText">
                        Greetings, weary traveler. This is what you’ve been looking for. You stand now on the precipice of a new world, one of exploration, beauty beyond description, and boundless freedom. We, the Wanderlust Girls, extend our hands to you, offering you a chance to ride alongside us on a journey like no other.<br/><br/>

                        Our sisterhood is woven from the threads of adventure, and we traverse the winding roads of life as a united force, leaving a trail of discovery and transformation in our wake. We are seekers of the unknown, purveyors of enlightenment, and emissaries of the open road. We know that of all the stories, in all the world, the best are found on a public ledger<br/><br/>

                        There are 333 of us, each holding a unique collection of up to 9 coveted memento tokens, in a series of passbooks blessed by the divine patina of travel. We had to put it all on chain forever sealed glistening in its own perfection—We had to collect ourselves, so you could collect us too.<br/><br/>

                        Whatever you mint becomes a part of you somehow. Crypto fills your pockets, but NFTs fill your soul.<br/><br/>

                        Wanderlust Girls is an NFT collection by <a href="https://twitter.com/wretched_worm" target='_blank'>Wretched Worm</a> and <a href="https://en.wikipedia.org/wiki/Jared_Madere" target='_blank'>Jared Madere</a><br/><br/>
                        {/* {numMinted > 0 && (
                        <div>
                            <br/>
                            {numMinted} minted so far
                        </div>
                        )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WanderlustGirls;
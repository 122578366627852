import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { SceneContext } from "./Lunar.js";
import { useGLTF } from '@react-three/drei';


function getWaveInfo(waves, offsetX, offsetZ, x, z, time) {
    const pos = new THREE.Vector3()
    const tangent = new THREE.Vector3(1, 0, 0)
    const binormal = new THREE.Vector3(0, 0, 1)
    waves.forEach((w) => {
        const k = (Math.PI * 2.0) / w.wavelength
        const c = Math.sqrt(9.8 / k)
        const d = new THREE.Vector2(
            Math.sin((w.direction * Math.PI) / 180),
            -Math.cos((w.direction * Math.PI) / 180)
        )
        const f = k * (d.dot(new THREE.Vector2(x, z)) - c * time)
        const a = w.steepness / k

        pos.x += d.x * (a * Math.cos(f))
        pos.y += a * Math.sin(f)
        pos.z += d.y * (a * Math.cos(f))

        tangent.x += -d.x * d.x * (w.steepness * Math.sin(f))
        tangent.y += d.x * (w.steepness * Math.cos(f))
        tangent.z += -d.x * d.y * (w.steepness * Math.sin(f))

        binormal.x += -d.x * d.y * (w.steepness * Math.sin(f))
        binormal.y += d.y * (w.steepness * Math.cos(f))
        binormal.z += -d.y * d.y * (w.steepness * Math.sin(f))
    })

    const normal = binormal.cross(tangent).normalize()
    return { position: pos, normal: normal }
}

export default function Book({ bookName, waves }) {

    const arakawa = useGLTF(process.env.PUBLIC_URL + '/models/lunar/arakawa.glb');
    const dinosaurs = useGLTF(process.env.PUBLIC_URL + '/models/lunar/dinosaurs.glb');
    const faerie = useGLTF(process.env.PUBLIC_URL + '/models/lunar/faerie.glb');
    const logic = useGLTF(process.env.PUBLIC_URL + '/models/lunar/logic.glb');

    const arakawaTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/arakawa.jpg');
    const dinosaursTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/dinosaurs.jpg');
    const faerieTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/faerie.jpg');
    const logicTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/logic.jpg');
    
    const { waterTimescaleRef } = useContext(SceneContext);

    const dummy = new THREE.Object3D();

    const [book, setBook] = useState(null);

    const bookRef = useRef(null);

    const floatPointRefs = useRef([]);
    
    const bookScale = 1.5;

    useFrame((state, delta) => {
        if(bookRef.current && floatPointRefs.current.length > 0 ) {

            let bookCurPos = bookRef.current.position;

            let t = state.clock.elapsedTime * waterTimescaleRef.current;

            const accumulatedPosition = new THREE.Vector3();
            const accumulatedNormal = new THREE.Vector3();

            for(let i = 0; i < floatPointRefs.current.length; i++) {
                let floatPointWorld = new THREE.Vector3();
                bookRef.current.localToWorld(floatPointWorld.copy(floatPointRefs.current[i]));

                const waveInfo = getWaveInfo(
                    waves,
                    bookCurPos.x,
                    bookCurPos.z,
                    floatPointWorld.x,
                    floatPointWorld.z,
                    t
                );

                accumulatedPosition.y += waveInfo.position.y;

                accumulatedPosition.x += bookCurPos.x;
                accumulatedPosition.z += bookCurPos.z;

                accumulatedNormal.add(waveInfo.normal);
            }

            accumulatedPosition.divideScalar(floatPointRefs.current.length);

            accumulatedNormal.divideScalar(floatPointRefs.current.length);

            accumulatedPosition.y += 2.0;

            let dummyQuat = new THREE.Quaternion();

            dummy.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), accumulatedNormal);

            bookRef.current.position.lerp(accumulatedPosition, 0.25);
            bookRef.current.quaternion.slerp(dummy.quaternion, 0.25);
        }

    });

    useEffect(() => {

        let bookGLB;

        switch(bookName) {
            case 'arakawa':
                setBook(arakawa);
                bookGLB = arakawa;

                //set book material map to arakawaTex
                arakawa.scene.traverse((child) => {
                    if(child.isMesh) {
                        arakawaTex.flipY = false;
                        arakawaTex.minFilter = THREE.NearestFilter;
                        arakawaTex.magFilter = THREE.NearestFilter;

                        child.material.map = arakawaTex;

                        child.position.multiplyScalar(bookScale);

                        bookRef.current = child;
                    }
                });
                break;
            case 'dinosaurs':
                setBook(dinosaurs);
                bookGLB = dinosaurs;

                //set book material map to dinosaursTex
                dinosaurs.scene.traverse((child) => {
                    if(child.isMesh) {
                        dinosaursTex.flipY = false;

                        child.material.map = dinosaursTex;

                        child.position.multiplyScalar(bookScale);

                        bookRef.current = child;

                    }
                });
                break;
            case 'faerie':
                setBook(faerie);
                bookGLB = faerie;

                //set book material map to faerieTex
                faerie.scene.traverse((child) => {
                    if(child.isMesh) {
                        faerieTex.flipY = false;

                        child.material.map = faerieTex;

                        child.position.multiplyScalar(bookScale);

                        bookRef.current = child;

                    }
                });
                break;
            case 'logic':
                setBook(logic);
                bookGLB = logic;

                //set book material map to logicTex
                logic.scene.traverse((child) => {
                    if(child.isMesh) {
                        logicTex.flipY = false;

                        child.material.map = logicTex;

                        child.position.multiplyScalar(bookScale);

                        bookRef.current = child;
                    }
                });
                break;
        }




        //traverse through 
        for(const [key, value] of Object.entries(bookGLB.nodes)) {
            if(key.startsWith("floatPoint")) {
                let localPos = new THREE.Vector3();

                bookRef.current.worldToLocal(localPos.copy(value.position));
                floatPointRefs.current.push(localPos);
            }
        }

    }, []);

    return(
        <>
       {book && <primitive object={book.scene} />}
        </>
    );


}
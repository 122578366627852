import React from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { SceneContext } from './Nome';
import { isMobile } from 'react-device-detect';
import Switch from "react-switch";
import { useIsWideView } from './detectWideView';
import './Nome.css';

import { Contract, providers, utils, BigNumber } from 'ethers';
import Counter from './Counter';
import CountdownTimer from './CountdownTimer';
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import AUCTION_HOUSE_ABI from './AuctionHouseABI.json';
import GACHAPON_ABI from './GachaponABI.json';
import OPEN_EDITION_ABI from './OpenEditionABI.json';

import HAT_DATA from './hat_data.json';


// Handles UI pane and all onchain reads/writes

// Needs to talk to 3 contracts:
// 1 - auction contract for bids, remaining time 
// 2 - open edition erc1155 contract 
// 3 - erc721a gachapon contract

export default function NomeOverlay() {

    const ONE_OF_ONE_ADDRESS = '0x7ADE16f069e71a4a159E04EaA1c21437417064eB';
    const AUCTION_HOUSE_ADDRESS = '0xdDd9abEc523A05aDC1E1B7a97f5015Ece0FB492a';
    const GACHAPON_ADDRESS = '0x66E465CE699967c96e2D77F7184B59449F230761';
    const OPEN_EDITION_ADDRESS = '0xAf89410E92B69B47A53980DB851d732fE4da5981';

    const targetChainName = 'mainnet';
    const targetChainID = 1;

    const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

    const MESSAGE_CHAR_LIMIT = 150;

    const auctionContractRef = useRef(null);
    const gachaponContractRef = useRef(null);
    const openEditionContractRef = useRef(null);

    const auctionDataRef = useRef(null);
    const oeDataRef = useRef(null);
    const gachaDataRef = useRef(null);

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");
    const [bidButtonText, setBidButtonText] = useState("Bid");

    const [oeMintButtonText, setOeMintButtonText] = useState("Mint");
    const [oeMintSetButtonText, setOeMintSetButtonText] = useState("MINT COMPLETE SUITE OF OPEN EDITIONS");
    const [gachaMintButtonText, setGachaMintButtonText] = useState("Mint");

    const [avatarVisible, setAvatarVisible] = useState(false);
    const [artworkTitle, setArtworkTitle] = useState("");
    const [artistName, setArtistName] = useState("");

    const [whitelistRootHash, setWhitelistRootHash] = useState("");
    const [whitelistMerkleTree, setWhitelistMerkleTree] = useState(null);
    const [whitelistWallets, setWhitelistWallets] = useState([]);

    const [bidHistoryInfo, setBidHistoryInfo] = useState(null);

    const [auctionEndTime, setAuctionEndTime] = useState(0);
    const [oeEndTime, setOeEndTime] = useState(0);

    const [mintCountNum, setMintCountNum] = useState(1);

    const [workType, setWorkType] = useState("");

    const [humanAddress, setHumanAddress] = useState("");

    const [highestBidText, setHighestBidText] = useState("");

    const wideView = useIsWideView();

    // const arweaveBaseURLImg = "https://arweave.net/_SFbZ-h9mnkEOeRphFB-uqio6JaCA9Yt0wCXSFdXt-0/";

    const arweaveBaseGacha = "https://hyunmvlmpnhwxblti7a6o3rhgkrsoik4hqfkxo5qicygbeg5ogjq.arweave.net/PijWVWx7T2uFc0fB524nMqMnIVw8Cqu7sECwYJDdcZM/";
    const arweaveBaseOE = "https://arweave.net/32z530JexYHB6Sme5-C2_tx14-RTawn9NNGSdQplYlw/";
    const arweaveBaseOneOfOne = "https://arweave.net/7jYOfISWn0glfaEaRFVRS9KlkPpTCYoyg_h-v5JZ8C4/";

    const showOverlayClass = !wideView ? "showOverlayMobile" : "showOverlayDesktop";
    const hideOverlayClass = !wideView ? "hideOverlayMobile" : "hideOverlayDesktop";

    const oceanWindAudioRef = useRef(null);

    const {
        crosshairRef,
        crosshairArtworkRef,
        fullDisplayRef,
        artObjectsRef,
        switchArtInstantRef,
        curPlaneIndexRef,
        artworkInfoRef,
        cameraPosRef,
        joystickRef,
        jumpRef,
        userAddressRef,
        userENSRef,
        numUserHatsRef,
        avatarVisibleRef,
        messageRef,
        artworkPath,
        modelPath,
        historicalHatDataRef
    } = useContext(SceneContext);


    const bidBoxRef = useRef(null);
    const bidInputRef = useRef(null);
    const bidButtonRef = useRef(null);

    const navigatorRef = useRef(null);
    const showOverlayRef = useRef(false);
    const overlayRef = useRef(null);
    const curArtworkRef = useRef("");
    const autoTourPlayingRef = useRef(false);
    const autoTourIntervalRef = useRef(null);
    const progressBarRef = useRef(null);
    const progressBarIntervalRef = useRef(null);
    const autoTourStartTimeRef = useRef(null);
    const autoTourButtonRef = useRef(null);
    const messageInputRef = useRef(null);
    const arweaveLinkRef = useRef(null);
    // const artDescriptionRef = useRef(null);
    const artThumbnailRef = useRef(null);
    const gachaResultsRef = useRef(null);

    const teleportingRef = useRef(false);
    const muteIconRef = useRef(null);
    const arrowIconRef = useRef(null);
    const artHeaderRef = useRef(null);
    const overlayPaneInfoRef = useRef(null);
    const paneIsDraggingRef = useRef(false);
    const lastFullDisplayRef = useRef(fullDisplayRef.current);

    // setup crosshair event listeners and constrain crosshair to window
    function setupCrosshair() {
        window.addEventListener("mousemove", (e) => {
            if (crosshairRef.current) {
                let newLeft = e.clientX - (crosshairRef.current.offsetWidth / 2);

                let constrainMarginWidth = crosshairRef.current.offsetWidth * 1.2;
                let constrainMarginHeight = crosshairRef.current.offsetHeight * 1.2;

                //constrain left to be within the window
                if (newLeft < constrainMarginWidth - (crosshairRef.current.offsetWidth)) {
                    newLeft = constrainMarginWidth - (crosshairRef.current.offsetWidth);
                }
                else if (newLeft > window.innerWidth - constrainMarginWidth) {
                    newLeft = window.innerWidth - constrainMarginWidth;
                }

                let newTop = e.clientY - (crosshairRef.current.offsetHeight / 2);

                //constrain top to be within the window
                if (newTop < constrainMarginHeight - (crosshairRef.current.offsetHeight)) {
                    newTop = constrainMarginHeight - (crosshairRef.current.offsetHeight);
                }
                else if (newTop > window.innerHeight - constrainMarginHeight) {
                    newTop = window.innerHeight - constrainMarginHeight;
                }


                crosshairRef.current.style.left = newLeft + "px";
                crosshairRef.current.style.top = newTop + "px";
            }

            if (wideView) return;

            if (paneIsDraggingRef.current) {
                handlePaneDrag(e);
            }
        });
    }

    async function refreshAuctionData() {
        //AUCTION DATA
        try {
            const response = await fetch(' https://yeche-onchain-c25dac264fe6.herokuapp.com/nomeAuctionData', { mode: 'cors' });
            const data = await response.json();

            let auctionDataTemp = [];

            //loop through every object in the data array and add it to the auctionDataRef
            for (let i = 0; i < data.length; i++) {
                // let tokenAuctionData = data[i];
                let tokenAuctionData = {};

                tokenAuctionData.tokenId = parseInt(data[i].tokenId);
                tokenAuctionData.reservePrice = BigNumber.from(data[i].reservePrice);
                tokenAuctionData.startTime = parseInt(data[i].startTime);
                tokenAuctionData.duration = parseInt(data[i].duration);
                tokenAuctionData.hasBids = data[i].auctionHasBids;

                if (tokenAuctionData.hasBids) {
                    let bidsTemp = [];

                    //for each bid, parse bid bidder and timestamp and add to bidsTemp
                    for (let j = 0; j < data[i].bids.length; j++) {
                        let bid = {};

                        bid.bidder = data[i].bids[j].bidder;
                        bid.timestamp = parseInt(data[i].bids[j].timestamp);
                        bid.amount = parseInt(data[i].bids[j].amount);

                        bidsTemp.push(bid);
                    }

                    tokenAuctionData.bids = bidsTemp;
                }

                else {
                    console.log('no bids for token ' + tokenAuctionData.tokenId);
                }

                auctionDataTemp.push(tokenAuctionData);
            }

            auctionDataRef.current = auctionDataTemp;

            // console.log(auctionDataRef.current);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function refreshOEData() {
        try {
            const response = await fetch(' https://yeche-onchain-c25dac264fe6.herokuapp.com/nomeOEData', { mode: 'cors' });
            const data = await response.json();

            const oeEndTime = parseInt(data.endTime);

            let supplies = [];
            for (let i = 0; i < data.editionSupplies.length; i++) {
                supplies.push(parseInt(data.editionSupplies[i]));
            }

            // console.log('oeEndTime: ' + oeEndTime);
            // console.log('supplies: ' + supplies);

            oeDataRef.current = {
                endTime: oeEndTime,
                supplies: supplies
            };

            setOeEndTime(oeEndTime);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function refreshGachaData() {
        const response = await fetch(' https://yeche-onchain-c25dac264fe6.herokuapp.com/nomeGachaponData', { mode: 'cors' });
        const data = await response.json();

        const totalSupply = parseInt(data.totalSupply);

        console.log('gacha total supply: ' + totalSupply);

        gachaDataRef.current = {
            totalSupply: totalSupply
        };
    }

    async function initWeb3() {

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
            }
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });

        setWeb3Modal(web3Modal);
    }

    function toChecksumAddress(address) {
        address = address.toLowerCase().replace('0x', '')
        var hash = keccak256(address).toString('hex')
        var ret = '0x'

        for (var i = 0; i < address.length; i++) {
            if (parseInt(hash[i], 16) >= 8) {
                ret += address[i].toUpperCase()
            } else {
                ret += address[i]
            }
        }

        return ret
    }

    function initMerkleTree(wallets) {
        const leafNodes = wallets.map(address => keccak256(address));

        const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

        const rootHash = tree.getRoot();
        const rootHashHex = MerkleTree.bufferToHex(rootHash);

        console.log("root hash is " + rootHashHex);

        setWhitelistRootHash(rootHashHex);
        setWhitelistMerkleTree(tree);
    }

    function loadWhitelist() {
        fetch('data/nomewhitelist.csv')
            .then(response => response.text())
            .then(data => {
                let wallets = data.split('\n').map(wallet => wallet.replace('\r', ''));
                //remove any non alphanumeric characters from wallet addresses
                wallets = wallets.map(wallet => wallet.replace(/[^a-zA-Z0-9]/g, ''));

                //get the checksummed version of each wallet address
                wallets = wallets.map(wallet => toChecksumAddress(wallet));

                //ensure there are no duplicates
                wallets = [...new Set(wallets)];

                // console.log(wallets);
                //make all wallet addresses lowercase
                // wallets = wallets.map(wallet => wallet.toLowerCase());
                // this.whitelistWallets = wallets;
                // this.initMerkleTree(wallets);

                initMerkleTree(wallets);

                setWhitelistWallets(wallets);
            })
            .catch(error => console.error('Error:', error));
    }

    function loadHatData(auctionData) {
        const addressTally = {};

        Object.keys(auctionData).forEach(address => {
            addressTally[address] = auctionData[address];
        });

        return addressTally;
    }

    //Setup component
    useEffect(() => {
        //todo init web3 
        initWeb3();

        historicalHatDataRef.current = loadHatData(HAT_DATA);

        refreshAuctionData();
        refreshOEData();
        refreshGachaData();

        loadWhitelist();

        //todo load old hat auction data

        if (!isMobile) {
            setupCrosshair();
        }

        else {
            window.addEventListener("touchmove", (e) => {
                if (wideView) return;

                if (paneIsDraggingRef.current) {
                    handlePaneDrag(e);
                }
            });
        }

        //refresh updateOverlay to run every 300ms -- this is not ideal
        const interval = setInterval(() => {
            // if(fellInWaterRef.current && !teleportingRef.current) {
            //     teleportToRandomArtwork();
            // }
            updateOverlay();
        }, 300);

        //refresh auction data every 30 seconds
        const auctionDataInterval = setInterval(() => {
            refreshAuctionData();
        }
            , 30000);
    }, []);

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();

        const ethersProvider = new providers.Web3Provider(provider);

        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);

        if (chainId !== targetChainID) {
            setConnected(false);
            setConnectText(wrongNetworkMessage);
            return;
        }

        const userAddress = await ethersProvider.getSigner().getAddress();

        const signer = ethersProvider.getSigner();

        userAddressRef.current = userAddress;

        //todo get num hats + old auction data + WLGs minted

        const name = await ethersProvider.lookupAddress(userAddress);

        setEthersProvider(ethersProvider);

        if (name) {
            setHumanAddress(name);
            userENSRef.current = name;
        }

        else {
            let truncatedAddress = truncateEthAddress(userAddress);
            setHumanAddress(truncatedAddress);
        }

        setConnected(true);
    }

    function checkWhitelisted(address) {
        let isWhitelisted = false;
        for (let i = 0; i < whitelistWallets.length; i++) {
            if (whitelistWallets[i].toLowerCase() == address.toLowerCase()) {
                isWhitelisted = true;
                break;
            }
        }

        return isWhitelisted;
    }

    async function mintOESet() {
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        const signer = ethersProvider.getSigner();
        const openEditionContract = new Contract(OPEN_EDITION_ADDRESS, OPEN_EDITION_ABI, signer);

        //make sure mint isn't paused 
        const isPaused = await openEditionContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);

        const numEditions = 10;

        //check if whitelist only, and that user is on whitelist if so 

        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await openEditionContract.checkPrice(true, numEditions, userAddress);

            console.log('price to mint ' + numEditions + ' is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintSetWL(hexProof, { value: price });

            setOeMintSetButtonText("Minting...");

            await tx.wait();

            setOeMintSetButtonText("done!");
        }

        else {
            //make sure mint is not WL only 
            let isWhitelistOnly = await openEditionContract.whitelistOnly();

            if (isWhitelistOnly) {
                alert("Minting is whitelist only, please come back later for public mint");
                return;
            }

            const price = await openEditionContract.checkPrice(false, numEditions, userAddress);

            console.log('price to mint ' + numEditions + ' is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionPublic({ value: price });

            setOeMintSetButtonText("Minting...");

            await tx.wait();

            setOeMintSetButtonText("done!");
        }
    }

    async function mintOE() {
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        const curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;

        //get index of curArtworkRef.current in artObjectsRef.current
        const curPlaneIndex = artObjectsRef.current.findIndex(artObject => artObject.artName == curArtName);
        const curArt = artObjectsRef.current[curPlaneIndex];
        const tokenID = curArt.tokenID;

        const signer = ethersProvider.getSigner();
        const openEditionContract = new Contract(OPEN_EDITION_ADDRESS, OPEN_EDITION_ABI, signer);

        //make sure mint isn't paused 
        const isPaused = await openEditionContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);
        const quantity = mintCountNum;

        //check if whitelist only, and that user is on whitelist if so 

        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await openEditionContract.checkPrice(true, quantity, userAddress);

            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionWL(tokenID, quantity, hexProof, { value: price });

            setOeMintButtonText("Minting...");

            await tx.wait();

            setOeMintButtonText("done!");
        }

        else {
            //make sure mint is not WL only 
            let isWhitelistOnly = await openEditionContract.whitelistOnly();

            if (isWhitelistOnly) {
                alert("Minting is whitelist only, please come back later for public mint");
                return;
            }

            const price = await openEditionContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionPublic(tokenID, quantity, { value: price });

            setOeMintButtonText("Minting...");

            await tx.wait();

            setOeMintButtonText("done!");
        }
    }

    async function mintGacha() {
        //make sure user is connected
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        //clear everything in gachaResultsRef
        if (gachaResultsRef.current) {
            while (gachaResultsRef.current.firstChild) {
                gachaResultsRef.current.removeChild(gachaResultsRef.current.firstChild);
            }
        }

        //make sure mint isn't paused
        const signer = ethersProvider.getSigner();
        const gachaponContract = new Contract(GACHAPON_ADDRESS, GACHAPON_ABI, signer);

        const isPaused = await gachaponContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);
        const quantity = mintCountNum;

        const firstTokenID = await gachaponContract.totalSupply();
        const lastTokenID = parseInt(firstTokenID) + parseInt(quantity) - 1;

        console.log('trying to mint ' + quantity + ' gacha');
        console.log('first token ID: ' + firstTokenID);
        console.log('last token ID: ' + lastTokenID);

        //check if whitelist, public, etc
        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(true, quantity, userAddress);

            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintWL(quantity, hexProof, { value: price });

            setGachaMintButtonText("Minting...");

            await tx.wait();

            setGachaMintButtonText("done!");
        }

        else {
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintPublic(quantity, { value: price });

            setGachaMintButtonText("Minting...");

            await tx.wait();

            setGachaMintButtonText("done!");
        }

        //Show minted images
        const imgBaseURL = 'https://bafybeicbxhfttv4oa3irc7rivyrs2bvw5x7tjhfq5redeaco2ev4ry7q6m.ipfs.nftstorage.link/';

        if (gachaResultsRef.current) {
            for (let i = firstTokenID; i <= lastTokenID; i++) {
                let gachaResult = {};

                gachaResult.tokenID = i;
                gachaResult.url = imgBaseURL + i + '.jpg';

                //add image tag to gachaResultsRef with src = url
                let img = document.createElement('img');
                img.src = gachaResult.url;
                img.className = "ArtThumbnailImg";
                gachaResultsRef.current.appendChild(img);

                //add link under image that says "View full resolution on Arweave"
                let arweaveLink = document.createElement('a');
                arweaveLink.href = arweaveBaseGacha + i + '.jpg';
                arweaveLink.innerHTML = 'View full resolution on Arweave';
                arweaveLink.target = "_blank";
                gachaResultsRef.current.appendChild(arweaveLink);

                //add line break
                let br = document.createElement('br');
                gachaResultsRef.current.appendChild(br);

                img.onload = () => {
                    console.log(`Image ${gachaResult.url} loaded successfully.`);
                };

                img.onerror = (error) => {
                    console.error(`Error loading image ${gachaResult.url}:`, error);
                };
            }
        }
    }


    async function bid() {
        //get token ID of current artwork 
        //get auction ID from contract
        //make sure bid price is above reserve price OR last bid
        //send transaction

        let bidAmountEth = bidInputRef.current.value;

        if (!connected) {
            alert("Please connect your wallet to bid");
            bidInputRef.current.value = "";
            return;
        }

        let curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;

        //get index of curArtworkRef.current in artObjectsRef.current
        let curPlaneIndex = artObjectsRef.current.findIndex(artObject => artObject.artName == curArtName);

        let curArt = artObjectsRef.current[curPlaneIndex];

        let tokenID = curArt.tokenID;

        let curPrice;

        if (auctionDataRef.current[tokenID].hasBids) {
            console.log(auctionDataRef.current[tokenID].bids);

            //get latest bid 
            let lastBidIndex = auctionDataRef.current[tokenID].bids.length - 1;
            let lastBid = auctionDataRef.current[tokenID].bids[lastBidIndex];
            let lastBidAmt = utils.formatEther(lastBid.amount.toString());

            console.log('lastBid was ' + lastBidAmt);

            curPrice = lastBidAmt;
        }

        else {
            let reservePrice = utils.formatEther(auctionDataRef.current[tokenID].reservePrice.toString());

            curPrice = reservePrice;
        }

        //min bid increment percentage is 5%, so ensure that bid is at least 5% higher than current price
        let minBid = curPrice * 1.05;
        console.log('minBid: ' + minBid);

        if (bidAmountEth < minBid) {
            alert("Bid must be at least 5% higher than last bid");
            bidInputRef.current.value = "";
            return;
        }

        const signer = ethersProvider.getSigner();
        const auctionContract = new Contract(AUCTION_HOUSE_ADDRESS, AUCTION_HOUSE_ABI, signer);

        //get auction ID from contract
        let auctionID = await auctionContract.getAuctionId(ONE_OF_ONE_ADDRESS, tokenID);


        //convert bidAmount to wei
        let bidAmountWei = utils.parseEther(bidAmountEth);
        const tx = await auctionContract.createBid(auctionID, bidAmountWei, { value: bidAmountWei });

        setBidButtonText("Bidding...");

        await tx.wait();

        setBidButtonText("done!");

        //clean the input field
        bidInputRef.current.value = "";

        //refresh work data
        await refreshAuctionData();
        updateAuctionInfo(tokenID);
    }

    // updates thumbnail, arweave link, and artwork title/artist name
    async function updatePaneInfo() {
        let curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;

        //get index of curArtworkRef.current in artObjectsRef.current
        let curPlaneIndex = artObjectsRef.current.findIndex(artObject => artObject.artName == curArtName);

        let curArt = artObjectsRef.current[curPlaneIndex];

        if (curArt == undefined) return;

        // let fileURL = process.env.PUBLIC_URL + artworkPath + curArt.artName + '.jpg';
        let fileURL = process.env.PUBLIC_URL + artworkPath + curArt.ogFilename;


        artThumbnailRef.current.src = fileURL;
        /* show art thumbnail */
        artThumbnailRef.current.style.display = "block";

        //show arweave link - TODO have different base URIs for different work types
        arweaveLinkRef.current.style.display = "block";

        let arweaveBase;

        if (curArt.workType == "1 of 1 auction") {
            arweaveLinkRef.current.style.display = "block";
            arweaveBase = arweaveBaseOneOfOne;
        }

        else if (curArt.workType == "open edition") {
            arweaveLinkRef.current.style.display = "block";
            arweaveBase = arweaveBaseOE;
        }

        else if (curArt.workType == "ERC721 collection") {
            // arweaveBase = arweaveBaseGacha;
            arweaveLinkRef.current.style.display = "none";
        }


        let arweaveLink = arweaveBase + curArt.ogFilename;
        arweaveLinkRef.current.href = arweaveLink;

        //set the innerHTML if it is blank
        if (arweaveLinkRef.current.innerHTML == "") {
            arweaveLinkRef.current.innerHTML = 'View full resolution on Arweave';
        }

    }

    function updateAuctionInfo(tokenID) {
        if (auctionDataRef.current[tokenID].hasBids) {
            //the last bid is the last element in the bids array
            let lastBidIndex = auctionDataRef.current[tokenID].bids.length - 1;
            let lastBid = auctionDataRef.current[tokenID].bids[lastBidIndex];

            let lastBidder = truncateEthAddress(lastBid.bidder, 6);
            let lastBidAmt = utils.formatEther(lastBid.amount.toString());
            setHighestBidText("Last bid: " + lastBidAmt + "ETH by " + lastBidder);

            let bids = auctionDataRef.current[tokenID].bids;

            let bidsJSX = bids.map(bid => {
                let bidder = truncateEthAddress(bid.bidder, 6);
                let bidAmount = utils.formatEther(bid.amount.toString());
                let formattedTime = new Date(bid.timestamp * 1000).toLocaleString();
                return (
                    <div key={bid.timestamp}>
                        {bidder} bid {bidAmount}ETH
                        <div className='BidDate'>{formattedTime}</div><br />
                    </div>
                );
            });

            setBidHistoryInfo(bidsJSX);
        }

        else {
            let reservePrice = utils.formatEther(auctionDataRef.current[tokenID].reservePrice.toString());

            setHighestBidText("Reserve price: " + reservePrice + "ETH");
        }

        //compute remaining time
        let startTime = auctionDataRef.current[tokenID].startTime;
        let duration = auctionDataRef.current[tokenID].duration;

        let endTimeTemp = startTime + duration;

        console.log('endTimeTemp: ' + endTimeTemp);

        setAuctionEndTime(endTimeTemp);
    }

    // switch artwork shown in overlay
    async function updateOverlay() {
        if (crosshairArtworkRef.current != curArtworkRef.current) {

            if (fullDisplayRef.current) {
                curArtworkRef.current = fullDisplayRef.current;
                crosshairArtworkRef.current = fullDisplayRef.current;
            }

            else {
                curArtworkRef.current = crosshairArtworkRef.current;
            }

            const artwork = artworkInfoRef.current.find(artwork => artwork.filename === curArtworkRef.current);

            if (artwork) {
                //FADE OUT OLD WORK
                artHeaderRef.current.classList.remove("fadeIn");
                artHeaderRef.current.classList.add("fadeOut");

                //FADE OUT OLD INFO PANE
                overlayPaneInfoRef.current.classList.remove("fadeIn");
                overlayPaneInfoRef.current.classList.add("fadeOut");

                //WAIT 
                await new Promise(r => setTimeout(r, 500));

                //UPDATE INFO PANE 
                updatePaneInfo();

                setArtworkTitle(artwork.title);
                setArtistName(artwork.name);
                setWorkType(artwork.workType);

                //UPDATE DATA RELEVANT TO WORK TYPE
                if (artwork.workType == "1 of 1 auction") {
                    updateAuctionInfo(artwork.tokenID);
                }

                //reset mint count
                else {
                    setMintCountNum(1);
                }

                //FADE IN NEW WORK
                artHeaderRef.current.classList.remove("fadeOut");
                artHeaderRef.current.classList.add("fadeIn");

                overlayPaneInfoRef.current.classList.remove("fadeOut");
                overlayPaneInfoRef.current.classList.add("fadeIn");
            }
        }

        if (fullDisplayRef.current != "" && lastFullDisplayRef.current == "" && !showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            updatePaneInfo();
        }

        else if (fullDisplayRef.current == "" && lastFullDisplayRef.current != "" && showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            if (autoTourPlayingRef.current) {
                console.log("autotour playing, stopping");
                toggleAutoTour();
            }
        }

        lastFullDisplayRef.current = fullDisplayRef.current;
    }

    function handlePaneDrag(e) {
        //get the height of the navigator
        let navigatorHeight = navigatorRef.current.getBoundingClientRect().height;

        //set top value so that the pointer is in the vertical center of the navigator

        let top;

        if (isMobile) {
            top = e.touches[0].clientY - navigatorHeight / 2;
        }

        else {
            top = e.clientY - navigatorHeight / 2;
        }

        //constrain top to be greater than 15vh
        let constrainTop = 0.15 * window.innerHeight;
        if (top < constrainTop) {
            top = constrainTop;
        }

        //constrain top to be less than 95vh
        let constrainBottom = 0.94 * window.innerHeight;
        if (top > constrainBottom) {
            top = constrainBottom;
        }

        if (!wideView) {
            overlayRef.current.classList.remove('showOverlayMobileHeight');
            overlayRef.current.classList.remove('hideOverlayMobileHeight');
        }

        overlayRef.current.classList.remove(showOverlayClass);
        navigatorRef.current.classList.remove(showOverlayClass);
        overlayRef.current.classList.remove(hideOverlayClass);
        navigatorRef.current.classList.remove(hideOverlayClass);

        //set transition values to instant
        overlayRef.current.style.transition = "none";
        navigatorRef.current.style.transition = "none";

        overlayRef.current.style.top = top + "px";
        navigatorRef.current.style.top = top + "px";

        //set height of overlay
        overlayRef.current.style.height = "calc(" + "100vh - " + top + "px)";

        e.stopPropagation();
        e.preventDefault();
    }

    function hideCrosshair() {
        console.log('hiding crosshair');

        crosshairRef.current.classList.remove("hueAndPulse")
        crosshairRef.current.classList.remove("hueAnim");

        crosshairRef.current.classList.add("fadeOut");
    }

    function toggleOverlay() {
        if (showOverlayRef.current) {
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(showOverlayClass);
            navigatorRef.current.classList.add(hideOverlayClass);

            overlayRef.current.classList.remove(showOverlayClass);
            overlayRef.current.classList.add(hideOverlayClass);

            if (wideView) {
                overlayRef.current.classList.remove('showOverlayDesktopHeight')
                overlayRef.current.classList.add('hideOverlayDesktopHeight');
            }

            else {
                overlayRef.current.classList.remove('showOverlayMobileHeight')
                overlayRef.current.classList.add('hideOverlayMobileHeight');
            }

            scrollOverlayToTop();

            showOverlayRef.current = false;
        } else {
            //set transition values to all 0.5s ease-in-out
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(hideOverlayClass);
            navigatorRef.current.classList.add(showOverlayClass);

            overlayRef.current.classList.remove(hideOverlayClass);
            overlayRef.current.classList.add(showOverlayClass);

            if (wideView) {
                overlayRef.current.classList.remove('hideOverlayDesktopHeight');
                overlayRef.current.classList.add('showOverlayDesktopHeight')
            }

            else {
                overlayRef.current.classList.remove('hideOverlayMobileHeight');
                overlayRef.current.classList.add('showOverlayMobileHeight')
            }

            scrollOverlayToTop();

            showOverlayRef.current = true;
        }
    }

    function scrollOverlayToTop() {
        overlayRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    async function incrementArtwork(forward) {
        if (fullDisplayRef.current == "") {
            selectClosestPlane();
            return;
        }

        await new Promise(r => setTimeout(r, 500));

        if (fullDisplayRef.current == "") return;

        let index = curPlaneIndexRef.current;

        let direction = forward ? 1 : -1;

        let nextIndex = index + direction;

        //wrap around 
        if (nextIndex < 0) {
            curPlaneIndexRef.current = artObjectsRef.current.length - 1;
            fullDisplayRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }

        else if (nextIndex >= artObjectsRef.current.length) {
            curPlaneIndexRef.current = 0;
            fullDisplayRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }

        else {
            curPlaneIndexRef.current = nextIndex;
            fullDisplayRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //?
            crosshairArtworkRef.current = artObjectsRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }

        scrollOverlayToTop();
    }

    function startPaneDrag(e) {
        if (wideView) return;

        console.log('starting pane drag');

        paneIsDraggingRef.current = true;

    }

    function endPaneDrag() {
        if (wideView) return;

        paneIsDraggingRef.current = false;
    }

    function selectClosestPlane() {
        console.log('finding closest plane');

        let closestPlaneIndex = 0;

        let closestPlaneDist = Infinity;

        let camPos = cameraPosRef.current.clone();

        for (let i = 0; i < artObjectsRef.current.length; i++) {
            let plane = artObjectsRef.current[i].plane;
            let planeWorld = new THREE.Vector3();
            plane.getWorldPosition(planeWorld);

            let dist = camPos.distanceTo(planeWorld);

            if (dist < closestPlaneDist) {
                closestPlaneDist = dist;
                closestPlaneIndex = i;
            }
        }

        curPlaneIndexRef.current = closestPlaneIndex;
        fullDisplayRef.current = artObjectsRef.current[closestPlaneIndex].artName;
        switchArtInstantRef.current = false;

        // if(!wideView) hideCrosshair();
        if (isMobile) hideCrosshair();
    }

    function toggleAutoTour() {
        if (!autoTourPlayingRef.current) {

            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_pause.png";

            //remove PlayTour class from autoTourButtonRef and add PauseTour class
            autoTourButtonRef.current.classList.remove("PlayTour");
            autoTourButtonRef.current.classList.add("PauseTour");


            autoTourPlayingRef.current = true;
            // autoTourButtonRef.current.innerText = "■";

            if (fullDisplayRef.current == "") {
                //START WITH CLOSEST TO THE USER 

                //find closest plane to camera.position
                selectClosestPlane();
            }

            let showWorkTime = 8000;
            let camTransitionTime = 2000;
            autoTourStartTimeRef.current = Date.now();

            //run incrementArtwork every 2 seconds
            autoTourIntervalRef.current = setInterval(() => {
                incrementArtwork(true);
            }, showWorkTime + camTransitionTime);

            //set up an interval to map the time the work has been shown from a percent, then set the width of progressBarRef based on that
            progressBarIntervalRef.current = setInterval(() => {
                let curTime = Date.now();
                let timeElapsed = (curTime - autoTourStartTimeRef.current) % (showWorkTime + camTransitionTime);

                if (timeElapsed < camTransitionTime) {
                    timeElapsed = 0;
                } else {
                    timeElapsed -= camTransitionTime;
                }

                let percent = timeElapsed / showWorkTime;

                if (percent >= 1) {
                    percent = 1;
                }

                progressBarRef.current.style.width = percent * 100 + "%";
            }, 25);

        } else {
            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_play.png";

            //remove PauseTour class from autoTourButtonRef and add PlayTour class
            autoTourButtonRef.current.classList.remove("PauseTour");
            autoTourButtonRef.current.classList.add("PlayTour");

            autoTourPlayingRef.current = false;
            // autoTourButtonRef.current.innerText = "▷";

            progressBarRef.current.style.width = 0 + "%";

            //clear the interval
            clearInterval(autoTourIntervalRef.current);
            clearInterval(progressBarIntervalRef.current);
        }
    }

    function toggleArrow(shouldToggleOverlay) {
        // if(tutorialVisibleRef.current) {
        //     hideTutorial();
        // }

        if (shouldToggleOverlay) toggleOverlay();

        if (showOverlayRef.current) {
            //add cross class to arrowRef
            arrowIconRef.current.classList.add("Cross");
            //remove arrow class from arrowRef
            arrowIconRef.current.classList.remove("Arrow");
        }

        else {
            //add arrow class to arrowRef
            arrowIconRef.current.classList.add("Arrow");
            //remove cross class from arrowRef
            arrowIconRef.current.classList.remove("Cross");
        }
    }

    function toggleMute() {
        console.log('trying to unmute');

        //TODO TOGGLE MUTE OF ALL ENV AUDIO 
        if (oceanWindAudioRef.current) {
            //play wind audio if not playing
            if (!oceanWindAudioRef.current.isPlaying) {
                oceanWindAudioRef.current.play();
            }

            oceanWindAudioRef.current.muted = !oceanWindAudioRef.current.muted;

        }

        // if(isMobile && oceanWindAudioRef.current) {
        //     oceanWindAudioRef.current.muted = videosMutedRef.current;

        //     //play wind audio if not playing
        //     if(!oceanWindAudioRef.current.isPlaying) {
        //         oceanWindAudioRef.current.play();
        //     }
        // }

        // else if(!isMobile && oceanAudioRef.current && windAudioRef.current) {
        //     oceanAudioRef.current.muted = videosMutedRef.current;
        //     windAudioRef.current.muted = videosMutedRef.current;

        //     //play wind audio if not playing
        //     if(!oceanAudioRef.current.isPlaying) {
        //         oceanAudioRef.current.play();
        //     }

        //     if(!windAudioRef.current.isPlaying) {
        //         windAudioRef.current.play();
        //     }
        // }
    }

    function toggleAvatarVisible() {
        avatarVisibleRef.current = !avatarVisibleRef.current;
        setAvatarVisible(avatarVisibleRef.current);
    }

    function sendChatMessage() {
        //clear input field
        messageRef.current = messageInputRef.current.value;
        messageInputRef.current.value = "";
    }

    function handleMessageKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Backspace") {
            //deleted all selected text
            if (messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }

            //deleted last character
            else {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.value.length - 1);
            }
        }

        //if key is left arrow, move cursor left
        else if (e.key === "ArrowLeft") {
            //make sure new selectionStart is not negative
            if (messageInputRef.current.selectionStart > 0) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart - 1;
            }
        }

        //if key is right arrow, move cursor right
        else if (e.key === "ArrowRight") {
            //make sure new selectionStart is not negative
            if (messageInputRef.current.selectionStart < messageInputRef.current.value.length) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart + 1;
            }
        }

        if (e.key === "Enter") {
            sendChatMessage();
        }

        else if (e.key.length === 1 && messageInputRef.current.value.length < MESSAGE_CHAR_LIMIT) {

            //delete any selected text
            if (messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }


            messageInputRef.current.value += e.key;
        }
    }

    function handleAvatarSwitchKeydown(e) {
        //check if the user pressed the space bar
        if (e.key === " ") {
            e.preventDefault();
        }
    }

    return (
        <>
            {<audio ref={oceanWindAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/oceanwind-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true} />}

            {connected && <div className="Address-pill">{humanAddress}</div>}
            {!connected && <button className={isMobile ? "Connect-button Connect-button-mobile" : "Connect-button Connect-button-desktop"} onClick={() => connectWallet()}>{connectText}</button>}


            {/* Avatar toggle, chat bar, work navigator */}
            <div className={!wideView ? "WorkNavigatorMobile" : "WorkNavigatorDesktop"} ref={navigatorRef}
                // onPointerMove={handlePaneDrag}
                onPointerDown={(e) => startPaneDrag(e)}
                onPointerUp={endPaneDrag}
            >

                <div className={"AvatarController"}>

                    <div className={"MessageBar"}>
                        <input ref={messageInputRef} className={"MessageBox"} type="text" name="messagebox" placeholder="Send a message to Gigi... 🌪️" onKeyDown={handleMessageKeyDown} />
                        <button className={"SendMessageButton"} onClick={sendChatMessage} />
                    </div>

                    <Switch
                        onColor={'#888'}
                        onChange={toggleAvatarVisible}
                        checked={avatarVisible}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        className={"AvatarSwitch"}
                        onKeyDown={handleAvatarSwitchKeydown}

                        uncheckedHandleIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 20,
                                    animation: "glow-switch 8s infinite",
                                    borderRadius: "50%"
                                }}
                            />
                        }
                        checkedHandleIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    color: "red",
                                    fontSize: 18,
                                    animation: "glow-switch 8s infinite",
                                    borderRadius: "50%"
                                }}
                            />
                        }
                    />
                </div>

                <div className={"NavigatorButtonRow"}>
                    <div className="WorkNavigatorProgressBar" ref={progressBarRef} />
                    <div className="WorkNavigatorButtonPrev" onClick={() => { incrementArtwork(false) }} />
                    <div className="WorkNavigatorButtonPlay PlayTour" onClick={toggleAutoTour} ref={autoTourButtonRef} />
                    <div className="WorkNavigatorButtonNext" onClick={() => { incrementArtwork(true) }} />
                    <div className="WorkNavigatorButtonMute SoundOff" onClick={toggleMute} ref={muteIconRef} />
                    <div className="WorkNavigatorButtonArrow Arrow" onClick={() => toggleArrow(true)} ref={arrowIconRef} />
                </div>
            </div>

            <div className={!wideView ? "OverlayMobile" : "OverlayDesktop"} ref={overlayRef}>
                <div className={!isMobile ? "OverlayInnerDesktop" : "OverlayInnerMobile"}>
                    <div className="OverlayPane">
                        <div className="OverlayPaneItem">
                            <div className="OverlayPaneArtHeader" ref={artHeaderRef}>
                                <div className='ArtHeaderTitleName'>
                                    {artistName}<br />
                                    {artworkTitle}<br />
                                    <div className='WorkType'>
                                        {workType}
                                    </div>
                                </div>

                                {workType == "ERC721 collection" &&
                                    (<div className="mintBox">
                                        <Counter count={mintCountNum} setCount={setMintCountNum} />
                                        <button className="mintButton" onClick={mintGacha}>{gachaMintButtonText}</button>
                                    </div>)}

                                {/* {workType == "open edition" &&
                                    (<div className="mintBox">
                                        <Counter count={mintCountNum} setCount={setMintCountNum} />
                                        <button className="mintButton" onClick={mintOE}>{oeMintButtonText}</button>
                                    </div>)} */}

                                {/* {workType == "1 of 1 auction" &&
                                    (<div className="OverlayCurrentBidInfo">
                                        <div className="BidBox">
                                            <div className="BidInput" ref={bidBoxRef}>
                                                <input ref={bidInputRef} className="BidInputBox" type="text" name="bid" placeholder="0.00" />
                                                <button className="BidButton" onClick={bid}>{bidButtonText}</button>
                                            </div>
                                            <div className='HighestBidInfo'>
                                                {highestBidText}
                                            </div>
                                        </div>
                                    </div>)} */}
                            </div>

                            {workType == "1 of 1 auction" &&
                                <>
                                    <CountdownTimer endTime={auctionEndTime} />

                                </>}

                            {workType == "open edition" &&
                                (<CountdownTimer endTime={oeEndTime} />)}
                        </div>

                        {workType == "1 of 1 auction" && (
                            <div className={!wideView ? "OverlayPaneInfoMobile" : "OverlayPaneInfoDesktop"}>

                                <div className="AuctionTimingNote">
                                    <b>All auctions for this exhibition are now complete.</b><br/><br/>
                                    1/1 auctions are available to receive bids from 9/12/23 11:59AM to 9/22/23 11:59AM. At the close of the auction the artworks will be automatically transferred to their owners.
                                </div>

                            </div>
                        )}

                        {/* <div className="OverlayPaneItem">
                            <div className='MintButtons'>
                                <button className="MintButton" onClick={() => {mint(1) }}>Mint 1</button>
                                <button className="MintButton" onClick={() => {mint(5) }}>Mint 5</button>
                                <button className="MintButton" onClick={() => {mint(10) }}>Mint 10</button>
                                <button className="MintButton" onClick={() => {mint(15) }}>Mint 15</button>
                            </div>
                        </div> */}

                        <div className={!wideView ? "OverlayPaneInfoMobile" : "OverlayPaneInfoDesktop"} ref={overlayPaneInfoRef}>

                            {/* <div className="SeparatorLine" /> */}



                            <div className="OverlayPaneItem">
                                <div className="ArtThumbnailContainer">
                                    {workType == "ERC721 collection" &&
                                        (<div className="ArtThumbnailContainer" ref={gachaResultsRef}>
                                        </div>)}

                                    <img className="ArtThumbnailImg" ref={artThumbnailRef} />
                                    {/* <video className="ArtVideoThumbnail" ref={videoThumbnailRef} muted playsInline loop controls preload='false' /> */}

                                    <a href="www.google.com" ref={arweaveLinkRef} target="_blank"></a>
                                </div>

                            </div>

                            {workType == "open edition" && (
                                <div className="OverlayPaneItem">
                                    <div className="mintSetContainer">
                                        <img className="ArtThumbnailImg" src={process.env.PUBLIC_URL + artworkPath + '/oegrid.png'} />

                                        {/* <button className="mintButton mintSetButton" onClick={mintOESet}>{oeMintSetButtonText}</button> */}
                                    </div>
                                </div>)}

                            <div className="OverlayPaneItem">
                                {/* AUCTION */}
                                {workType == "1 of 1 auction" && (<div className="ContractInfo">
                                    <div className="Etherscan-link">
                                        <a href="https://etherscan.io/address/0xddd9abec523a05adc1e1b7a97f5015ece0fb492a" target="_blank">
                                            <img src={require('../images/etherscan_black.png')} />
                                        </a>
                                    </div>
                                    <div className="Opensea-link">
                                        <a href="https://opensea.io/collection/nomemenesolo" target="_blank">
                                            <img src={require('../images/opensea_black.png')} />
                                        </a>
                                    </div>
                                </div>)}

                                {/* OE */}
                                {workType == "open edition" && (<div className="ContractInfo">
                                    <div className="Etherscan-link">
                                        <a href="https://etherscan.io/address/0xaf89410e92b69b47a53980db851d732fe4da5981" target="_blank">
                                            <img src={require('../images/etherscan_black.png')} />
                                        </a>
                                    </div>
                                    <div className="Opensea-link">
                                        <a href="https://opensea.io/collection/nomemene-open-editions" target="_blank">
                                            <img src={require('../images/opensea_black.png')} />
                                        </a>
                                    </div>
                                </div>)}

                                {/* GACHAPON */}
                                {workType == "ERC721 collection" && (<div className="ContractInfo">
                                    <div className="Etherscan-link">
                                        <a href="https://etherscan.io/address/0x66e465ce699967c96e2d77f7184b59449f230761" target="_blank">
                                            <img src={require('../images/etherscan_black.png')} />
                                        </a>
                                    </div>
                                    <div className="Opensea-link">
                                        <a href="https://opensea.io/collection/nomemenegachapon" target="_blank">
                                            <img src={require('../images/opensea_black.png')} />
                                        </a>
                                    </div>
                                </div>)}
                            </div>

                            {workType == "open edition" && (
                                <div className='OverlayPaneItem'>
                                    <div className="PriceGrid">
                                        <div><b>Minting of Open Editions is now closed.</b></div>
                                        <div>Open Editions are available to mint from 9/12/23 11:59AM to 9/22/23 11:59AM.</div>
                                        <div>Mint 1 = 0.0123ETH</div>
                                        <div>Pie key = 10% off</div>
                                        <div>Whitelist = 10% off</div>
                                        <div>Mint set of 10 editions = 30% off</div>
                                    </div>
                                </div>)}

                            {workType == "ERC721 collection" && (
                                <div className='OverlayPaneItem'>
                                    <div className="PriceGrid">
                                        <div>First 100 free to mint for whitelisted wallets, 1 per wallet.</div>
                                        <div>Public = 0.0111 ETH</div>
                                        <div>Whitelist = 0.01 ETH</div>
                                        <div>Pie key = 10% off</div>
                                        <div>Mint 10 = 20% off</div>
                                    </div>
                                </div>)}

                            {workType == "1 of 1 auction" && (<div className="OverlayPaneItem">
                                <div className="BidHistoryHeader">Bid History</div>
                                <div className="BidHistoryList">
                                    {bidHistoryInfo}
                                </div>
                            </div>)}

                            {workType == "ERC721 collection" && (
                                <div className='OverlayPaneItem'>
                                    A set of 1378 images generated by Nomemene's fictional toy shop owner, SyjoAi who makes homunculus elemental dolls.<br /><br />

                                    SyjoAi delivers the homunculus to Nomemene and they collaborate on Soul Synthesis. In the process, the alchemist Nomemene vaguely thinks about the soul. If you look at a doll, and you're a doll, and there's a higher-intelligent being, you're a doll without a soul, right? Conversely, Nomemene wonders if the doll, the earth, or even these things have souls.<br /><br />

                                    "If my heart, which I am trying to put a soul into by trial and error, is getting more and more soulful, it seems to me that the clay and soil are putting a soul into me."<br /><br />

                                    The large set of images are presented within a toy gachapon machine on the southwest corner of the exhibition architecture.

                                </div>)}

                            {workType == "1 of 1 auction" && (
                                <div className='OverlayPaneItem'>
                                    Five one-of-ones by Nomemene each auctioned individually. The auction lasts for Ten Days, with any bid in the last five minutes of the auction increasing the auction time by Five Minutes. The most painterly and detailed in this show, the images are displayed prominently on the fishing wall of the exhibition architecture.
                                </div>)}

                            {workType == "open edition" && (
                                <div className='OverlayPaneItem'>
                                    A series of ten images by Nomemene. Each are available individually or together as a complete set for a discounted price. Open Editions are available for Five Days. The series are presented in a circle of signs in the center of the exhibition architecture
                                </div>)}

                            <div className="OverlayPaneItem">
                                Nomemene is an artist based in Japan who works with painting, photography, A.I., sculpture, and writing to develop experimental and combined approaches towards new ideas of digital painting that eschew boundaries between media for the sake of presenting unified works under new rubrics of the screen. They are at the forefront of a new generation of digital painters that recognize anime as a default global language for exploring contemporary themes of pain, existence, death, transformation, and the soul.<br /><br />

                                𝘛𝘩𝘦 𝘢𝘭𝘤𝘩𝘦𝘮𝘪𝘴𝘵 𝘸𝘩𝘰 𝘵𝘳𝘪𝘦𝘥 𝘵𝘰 𝘱𝘶𝘵 𝘢 𝘴𝘰𝘶𝘭 𝘪𝘯 𝘵𝘩𝘦 𝘩𝘰𝘮𝘶𝘯𝘤𝘶𝘭𝘶𝘴 𝘥𝘪𝘥𝘯’𝘵 𝘩𝘢𝘷𝘦 𝘢 𝘴𝘰𝘶𝘭 𝘦𝘪𝘵𝘩𝘦𝘳 is their first solo exhibition, presented by Galerie Yeche Lange within a decayed fishing shack scanned by the artist. The exhibition environment creates a background for Nomemene's artwork that reflects a longstanding interest of the artist: how do gaps in consciousness allow for creative potential, whether this is on the side of the subject and its relationship to its own soul or the outer world and our perceptions of it, and how are these gaps mimicked by technical machines or consciousness like A.I.? What relationship do our memories of the past have in suggesting the shape that fills these gaps?<br /><br />

                                A recurring figure of the exhibition is an image of a doll or girl, the boundary between animate and inanimate already in question, with a sharp object lodged or stabbed into her eye. Within an internet vocabulary, this image appears related to teenage self-harm trends, often understood as tragic physical manifestations of the desire to regain bodily autonomy when it is so often demanded by modern institutions. Circulated within Nomemene's focus on the capacity for the brain to amend the gaps in what the eyes see however, this tragic figure is stripped from its contemporary context and instead aligned with the figure of the oracle. The doll self-wounds its own eye not only so that it feels a gratifyingly self-authorized pain, but also so that it might create more gaps wherein further creative vision could be possible. The act resembles a sacrificial pact, wherein sight is lost in order that vision might be gained.
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );

}
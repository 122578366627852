import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { SceneContext } from "./Lunar.js";
import { useControls } from 'leva';
import { clamp, lerp } from 'three/src/math/MathUtils.js';
import { mapLinear } from 'three/src/math/MathUtils.js';
import { useLoader } from '@react-three/fiber';
import CustomShaderMaterial from 'three-custom-shader-material';

export default function Clock({roomID, roomScale, roomPos}) {
    const clockNode = useGLTF(process.env.PUBLIC_URL + '/models/lunar/clock_room' + roomID + '.glb').scene.children[0];

    // const clockNode = clockGLB.scene.children[0];

    const clockBGTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/clock_bg.png");
    const hand1 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_1.png");
    const hand2 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_2.png");
    const hand3 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_3.png");
    const hand4 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_4.png");
    const hand5 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_5.png");
    const hand6 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_6.png");
    const hand7 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/darkyecheclock/hand_7.png");

    const hand1Ref = useRef();
    const hand2Ref = useRef();
    const hand3Ref = useRef();
    const hand4Ref = useRef();
    const hand5Ref = useRef();
    const hand6Ref = useRef();
    const hand7Ref = useRef();

    const clockScaleMap = {
        0: 10.0,
        1: 5.0,
        2: 8.0,
    }

    const clockScale = clockScaleMap[roomID];

    const matRef = useRef();

    const { distortScale, distortStrength } = useControls('clock shader', {
        distortScale: {
            value: 10,
            min: 0,
            max: 100.0,
            step: 0.01
        },
        distortStrength: {
            value: 0.05,
            min: 0.0,
            max: 1.0,
            step: 0.01
        }
    });

    useFrame(({clock}) => {
        //update time uniform
        if(matRef.current) {
            matRef.current.uniforms.time.value = clock.getElapsedTime() * 100.0;
        }
    });

    return(
        <>
        {clockNode && 
        <group
        position={[clockNode.position.x - roomPos.x/roomScale, 
                   clockNode.position.y - roomPos.y/roomScale, 
                   clockNode.position.z - roomPos.z/roomScale]}
        scale={[clockScale, clockScale, clockScale]}>
            <mesh 
            geometry={clockNode.geometry}
            rotation={clockNode.rotation}
            >
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}

                transparent={true}
                side={THREE.DoubleSide}

                ref={matRef}

                uniforms={{
                    distortStrength: {
                        value: distortStrength
                    },
                    distortScale: {
                        value: distortScale
                    },
                    time: {
                        value: 0
                    },
                    clockBG: {
                        value: clockBGTex
                    },
                    hand1: {
                        value: hand1
                    },
                    hand2: {
                        value: hand2
                    },
                    hand3: {
                        value: hand3
                    },
                    hand4: {
                        value: hand4
                    },
                    hand5: {
                        value: hand5
                    },
                    hand6: {
                        value: hand6
                    },
                    hand7: {
                        value: hand7
                    }
                }}


                vertexShader={
                    `
                    varying vec2 myUV;

                    void main() {
                        myUV = uv;
                    }
                    
                    `
                }

                fragmentShader={

                    `
                    varying vec2 myUV;
                    uniform sampler2D clockBG;
                    uniform sampler2D hand1;
                    uniform sampler2D hand2;
                    uniform sampler2D hand3;
                    uniform sampler2D hand4;
                    uniform sampler2D hand5;
                    uniform sampler2D hand6;
                    uniform sampler2D hand7;

                    uniform float distortScale;
                    uniform float distortStrength;
                    
                    uniform float time;

                    // Noise function
                    float rand(float n){return fract(sin(n) * 43758.5453123);}

                    float noise(float p){
                        float fl = floor(p);
                        float fc = fract(p);
                        return mix(rand(fl), rand(fl + 1.0), fc);
                    }
                        
                    float noise(vec2 n) {
                        const vec2 d = vec2(0.0, 1.0);
                        vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
                        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
                    }
                                
                    // Flame function
                    vec4 flame(vec2 uv, vec4 color) {
                        float alpha = color.a;
                        float flameValue = 0.0;
                        // Iterate over multiple noise scales to create a flickering effect
                        for (float i = 0.0; i < 4.0; i += 1.0) {
                            vec2 noiseUV = vec2(uv.x + i * time * 0.5, uv.y);
                            flameValue += noise(noiseUV) * pow(0.5, i);
                        }
                        // Scale the flame value by the alpha of the clock hand
                        flameValue *= alpha;
                        // Use a smoothstep function to fade out the flame towards the edges of the clock hand
                        float flameAlpha = flameValue;
                        // Blend the flame color with the clock hand color
                        vec4 flameColor = vec4(1.0, 0.5, 0.0, 1.0); // Set the flame color to orange
                        return mix(vec4(0.0), flameColor, flameAlpha);
                    }


                    void main() {
                        //rotate all UV maps at different speeds using time

                        //distort the UVs with noise and time
                        // float noiseScale = 30.0;
                        vec2 distortedUV = myUV + noise(myUV * distortScale) * distortStrength;

                        vec4 clockBGColor = texture2D(clockBG, distortedUV);


                        //bg 
                        vec4 bgColor = texture2D(clockBG, distortedUV);
                        // bgColor = flame(distortedUV, bgColor);

                        //hand1
                        float hand1Angle = -0.0085 * time;
                        float hand1X = distortedUV.x - 0.5;
                        float hand1Y = distortedUV.y - 0.5;
                        float hand1XRot = hand1X * cos(hand1Angle) - hand1Y * sin(hand1Angle);
                        float hand1YRot = hand1X * sin(hand1Angle) + hand1Y * cos(hand1Angle);

                        vec2 hand1UV = vec2(hand1XRot + 0.5, hand1YRot + 0.5);

                        vec4 hand1Color = texture2D(hand1, hand1UV);
                        // hand1Color = flame(hand1UV, hand1Color);

                        //hand2
                        float hand2Angle = 0.0063 * time;
                        float hand2X = distortedUV.x - 0.5;
                        float hand2Y = distortedUV.y - 0.5;
                        float hand2XRot = hand2X * cos(hand2Angle) - hand2Y * sin(hand2Angle);
                        float hand2YRot = hand2X * sin(hand2Angle) + hand2Y * cos(hand2Angle);

                        vec2 hand2UV = vec2(hand2XRot + 0.5, hand2YRot + 0.5);

                        vec4 hand2Color = texture2D(hand2, hand2UV);
                        // hand2Color = flame(hand2UV, hand2Color);

                        //hand3
                        float hand3Angle = -0.0034 * time;
                        float hand3X = distortedUV.x - 0.5;
                        float hand3Y = distortedUV.y - 0.5;
                        float hand3XRot = hand3X * cos(hand3Angle) - hand3Y * sin(hand3Angle);
                        float hand3YRot = hand3X * sin(hand3Angle) + hand3Y * cos(hand3Angle);

                        vec2 hand3UV = vec2(hand3XRot + 0.5, hand3YRot + 0.5);

                        vec4 hand3Color = texture2D(hand3, hand3UV);
                        // hand3Color = flame(hand3UV, hand3Color);
                        
                    
                        //hand4 
                        float hand4Angle = 0.01 * time;
                        float hand4X = distortedUV.x - 0.5;
                        float hand4Y = distortedUV.y - 0.5;
                        float hand4XRot = hand4X * cos(hand4Angle) - hand4Y * sin(hand4Angle);
                        float hand4YRot = hand4X * sin(hand4Angle) + hand4Y * cos(hand4Angle);

                        vec2 hand4UV = vec2(hand4XRot + 0.5, hand4YRot + 0.5);

                        vec4 hand4Color = texture2D(hand4, hand4UV);
                        // hand4Color = flame(hand4UV, hand4Color);

                        //hand5
                        float hand5Angle = -0.003 * time;
                        float hand5X = distortedUV.x - 0.5;
                        float hand5Y = distortedUV.y - 0.5;
                        float hand5XRot = hand5X * cos(hand5Angle) - hand5Y * sin(hand5Angle);
                        float hand5YRot = hand5X * sin(hand5Angle) + hand5Y * cos(hand5Angle);

                        vec2 hand5UV = vec2(hand5XRot + 0.5, hand5YRot + 0.5);
                            
                        vec4 hand5Color = texture2D(hand5, hand5UV);
                        // hand5Color = flame(hand5UV, hand5Color);

                        //hand6
                        float hand6Angle = 0.002 * time;
                        float hand6X = distortedUV.x - 0.5;
                        float hand6Y = distortedUV.y - 0.5;
                        float hand6XRot = hand6X * cos(hand6Angle) - hand6Y * sin(hand6Angle);
                        float hand6YRot = hand6X * sin(hand6Angle) + hand6Y * cos(hand6Angle);

                        vec2 hand6UV = vec2(hand6XRot + 0.5, hand6YRot + 0.5);


                        vec4 hand6Color = texture2D(hand6, hand6UV);
                        // hand6Color = flame(hand6UV, hand6Color);

                        //hand7
                        float hand7Angle = 0.0007 * time;
                        float hand7X = distortedUV.x - 0.5;
                        float hand7Y = distortedUV.y - 0.5;
                        float hand7XRot = hand7X * cos(hand7Angle) - hand7Y * sin(hand7Angle);
                        float hand7YRot = hand7X * sin(hand7Angle) + hand7Y * cos(hand7Angle);

                        vec2 hand7UV = vec2(hand7XRot + 0.5, hand7YRot + 0.5);

                        vec4 hand7Color = texture2D(hand7, hand7UV);
                        // hand7Color = flame(hand7UV, hand7Color);

                        //get the max alpha of all the hands
                        float maxAlpha = max(hand1Color.a, hand2Color.a);
                        maxAlpha = max(maxAlpha, hand3Color.a);
                        maxAlpha = max(maxAlpha, hand4Color.a);
                        maxAlpha = max(maxAlpha, hand5Color.a);
                        maxAlpha = max(maxAlpha, hand6Color.a);
                        maxAlpha = max(maxAlpha, hand7Color.a);
                        maxAlpha = max(maxAlpha, bgColor.a);

                        //average all the colors
                        // vec4 finalColor2 = (hand1Color + hand2Color + hand3Color + hand4Color + hand5Color + hand6Color + hand7Color + bgColor) / 8.0;

                        vec4 finalColor = vec4(vec3(0.0), maxAlpha);

                        csm_DiffuseColor = finalColor;
                    }

                    `
                }

                />



            </mesh>
        </group>
        }
        </>
    )

}
const sparkleVert = `
    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix *    vec4(position, 1.0);
    }
`;

const sparkleFrag = `
    varying vec2 vUv;
    uniform sampler2D colorMap;

    uniform sampler2D glitterMap;

    uniform float materializeAmt;

    uniform float time;

    uniform float planeIndex;

    float rand(float n){return fract(sin(n) * 43758.5453123);}

    float noise(float p){
        float fl = floor(p);
        float fc = fract(p);
        return mix(rand(fl), rand(fl + 1.0), fc);
    }

    float rand(vec2 n) {
        return fract(sin(dot(n, vec2(12.9898, 78.233))) * 43758.5453);
    }    
        
    float noise(vec2 n) {
        const vec2 d = vec2(0.0, 1.0);
        vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
    }
    
    vec3 calcGlitter(vec2 uv, float time) {
        //rotate uv by 20 * planeIndex degrees
        float angle = radians(20.0 * planeIndex);
        float s = sin(angle);
        float c = cos(angle);
    
        uv -= 0.5;
        vec2 rotatedUV = vec2(uv.x * c - uv.y * s, uv.x * s + uv.y * c);
        rotatedUV += 0.5; // Re-center the UV coordinates
    
        // Scale the UV coordinates
        float scale = 0.5;
        rotatedUV = mix(vec2(0.5), rotatedUV, scale);
    
        vec3 glitter1 = texture2D(glitterMap, rotatedUV).rgb;
    
        vec2 flippedUV = vec2(1.0 - rotatedUV.x, rotatedUV.y);
    
        vec3 glitter2 = texture2D(glitterMap, flippedUV).rgb;

        vec2 flippedUV2 = vec2(1.0 - rotatedUV.x, 1.0 - rotatedUV.y);

        vec3 glitter3 = texture2D(glitterMap, flippedUV2).rgb;
    
        float noise1 = noise(vec2(uv.x * 10.0 - time * 10.0, uv.y * 10.0 + time * 13.0));
    
        float noise2 = noise(vec2(uv.x * 10.0 + time * 8.0, uv.y * 10.0 - time * 15.0));
    
        float noise3 = noise(vec2(uv.x * 10.0 - time * 9.0, uv.y * 10.0 + time * 8.0));
    
        float noiseAvg = (noise1 + noise2) / 2.0;
    
        vec3 glitter = mix(glitter1, glitter2, noiseAvg);

        glitter = mix(glitter, glitter3, noise3);
    
        return glitter;
    }

    void main() {
        vec4 color = texture2D(colorMap, vUv);

        vec3 glitterCol = calcGlitter(vUv, time) * 1.0;

        float glitter = pow(length(glitterCol), 0.6);
        glitter = clamp(glitter, 0.0, 1.0);

        glitterCol *= 8.0;

        glitterCol.r = max(glitterCol.r, color.r);
        glitterCol.g = max(glitterCol.g, color.g);
        glitterCol.b = max(glitterCol.b, color.b);

        glitter -= materializeAmt;
        glitter = clamp(glitter, 0.0, 1.0);

        vec3 outCol = mix(color.rgb, glitterCol, glitter);

        gl_FragColor = vec4(outCol, max(glitter, materializeAmt));
    }
`;

export { sparkleVert, sparkleFrag }
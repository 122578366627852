import React, { useEffect } from 'react';

function Counter(props) {
    const { count, setCount } = props;

    if (typeof count !== 'number') {
        console.error('Invalid count:', count);
        return null; // Return null or some default JSX to avoid crashing
    }

    const decrementCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    return (
            <div className="counterCenterContainer">
                <div className="counterButtonCol">
                    <button onClick={decrementCount}>-</button>
                </div>
                <div className="counterNumberCol">
                    <h1>{count}</h1>
                </div>
                <div className="counterButtonCol">
                    <button onClick={() => setCount(count + 1)}>+</button>
                </div>
            </div>
    );
}

export default Counter;


import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { useControls, Leva, folder } from 'leva';
import { useGLTF } from '@react-three/drei';
import { SceneContext } from './Lunar';
import CustomShaderMaterial from 'three-custom-shader-material'
// import { MudMat } from './MudMat';
import { lerp } from 'three/src/math/MathUtils';
import { MaterialContext } from './LunarScene';

export default function Firmament() {

    const { 
        scale, 
        renderOrder, 
        fresnelPow, 
        fresnelBoost, 
        maxAlpha, 
        noiseScale, 
        swirlStrength, 
        timeScale, 
        vertexNoiseScale,
        alphaBoost 
    } = useControls('firmament', {
        scale: {
            value: 4.45,
            min: 0.0,
            max: 1000.0,
            step: 0.1
        },
        renderOrder: {
            value: -1,
            min: -10,
            max: 10,
            step: 1
        },
        fresnelPow: {
            value: 2.9,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        fresnelBoost: {
            value: 0.8,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        maxAlpha: {
            value: 1.0,
            min: 0.0,
            max: 1.0,
            step: 0.01
        },
        noiseScale: {
            value: 10.0,
            min: 0.0,
            max: 50.0,
            step: 0.1
        },
        swirlStrength: {
            value: 0.2,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        timeScale: {
            value: 0.1,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        vertexNoiseScale: {
            value: 0.03,
            min: 0.0,
            max: 5.0,
            step: 0.1
        },
        alphaBoost: {
            value: 1.0,
            min: 0.0,
            max: 10.0,
            step: 0.01
        },
    });
    const removedRef = useRef(false);

    const { introCamPctRef } = useContext(SceneContext);

    const soapBubbleMap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/soapbubble.jpg');
    const firmamentGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/firmament.glb');
    const matRef = useRef();
    const meshRef = useRef();
    const mudUnderRef = useRef();

    const { mudMat } = useContext(MaterialContext);

    const vert = `
    uniform float time;
    uniform float vertexNoiseScale;
    float offset = 0.001;
    
    varying vec2 myUv;
    varying vec3 vNorm;
    varying vec3 vEye;

    float rand(float n){return fract(sin(n) * 43758.5453123);}

    float noise(float p){
        float fl = floor(p);
        float fc = fract(p);
        return mix(rand(fl), rand(fl + 1.0), fc);
    }
        
    float noise(vec2 n) {
        const vec2 d = vec2(0.0, 1.0);
        vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
    }

    vec3 orthogonal(vec3 v) {
        return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
        : vec3(0.0, -v.z, v.y));
    }

    vec3 displace(vec3 point, float strength) {
        float n = noise(vec2(point.x * vertexNoiseScale + time, point.y * vertexNoiseScale + time * 2.345));
        return point + normal * n * strength;
    }

    void main() {
        /* feed uvs into noise and use result to animate vertices, offset by time */

        vNorm = normalize(normalMatrix * normal);
        vEye = normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);
        myUv = uv;

        // float n = noise(vec2(position.x * vertexNoiseScale + time, position.y * vertexNoiseScale + time * 2.345));
        // vec3 newPosition = position + normal * n * 5.0;

        vec3 newPosition = displace(position, 5.0);
        csm_Position = newPosition;

        //RECALCULATE NORMAL
        vec3 tangent = orthogonal(normal);
        vec3 bitangent = normalize(cross(normal, tangent));
        vec3 neighbour1 = position + tangent * offset;
        vec3 neighbour2 = position + bitangent * offset;
        vec3 displacedNeighbour1 = neighbour1 + normal * displace(neighbour1, 5.0);
        vec3 displacedNeighbour2 = neighbour2 + normal * displace(neighbour2, 5.0);
  
        // https://i.ya-webdesign.com/images/vector-normals-tangent-16.png
        vec3 displacedTangent = displacedNeighbour1 - newPosition;
        vec3 displacedBitangent = displacedNeighbour2 - newPosition;
  
        // https://upload.wikimedia.org/wikipedia/commons/d/d2/Right_hand_rule_cross_product.svg
        vec3 displacedNormal = normalize(cross(displacedTangent, displacedBitangent));
  
        csm_Normal = displacedNormal;
    }    
    `;

    const frag = `

    varying vec2 myUv;
    varying vec3 vNorm;
    varying vec3 vEye;
    uniform float fresnelPow;
    uniform float fresnelBoost;
    uniform float maxAlpha;
    uniform float time;
    uniform float noiseScale;
    uniform float rainbowBoost;
    uniform float swirlStrength;
    uniform float alphaBoost;

    uniform float timeScale;

    uniform sampler2D colorMap;

    float rand(float n){return fract(sin(n) * 43758.5453123);}

    float noise(float p){
        float fl = floor(p);
        float fc = fract(p);
        return mix(rand(fl), rand(fl + 1.0), fc);
    }
        
    float noise(vec2 n) {
        const vec2 d = vec2(0.0, 1.0);
        vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
    }
    
    vec3 generateRainbowColor(float value) {
        // map value to a hue value between 0.0 and 1.0
        float hue = value;
        // create a full saturation and brightness color
        vec3 color = vec3(1.0, 1.0, 1.0);
        // calculate the rgb values for the hue, saturation and brightness
        color = mix(vec3(1.0, 0.0, 0.0), vec3(1.0, 1.0, 0.0), clamp(hue - 0.0, 0.0, 1.0));
        color = mix(color, vec3(0.0, 1.0, 0.0), clamp(hue - 0.1667, 0.0, 1.0));
        color = mix(color, vec3(0.0, 1.0, 1.0), clamp(hue - 0.3333, 0.0, 1.0));
        color = mix(color, vec3(0.0, 0.0, 1.0), clamp(hue - 0.5, 0.0, 1.0));
        color = mix(color, vec3(1.0, 0.0, 1.0), clamp(hue - 0.6667, 0.0, 1.0));
        color = mix(color, vec3(1.0, 0.0, 0.0), clamp(hue - 0.8333, 0.0, 1.0));
        return color;
    }    

    void main() {
        //calculate fresnel value from eye vector and normal
        float fresnel = dot(vEye, vNorm);
        fresnel = pow(fresnel, fresnelPow) * fresnelBoost;
        fresnel = clamp(fresnel, 0.0, 1.0);

        //generate rainbow color from fresnel
        vec3 color = generateRainbowColor((1.0 - fresnel) * rainbowBoost);
        color = mix(color, vec3(1.0), 0.5);

        //use noise and time to distort UVs
        vec2 noiseCoords = vec2(myUv.x * noiseScale + time * timeScale * 1.2345, myUv.y * noiseScale + time * timeScale * 0.2345);
        float noiseValue = noise(noiseCoords);
        vec2 distortedUv = vec2(myUv.x + noiseValue * swirlStrength, myUv.y + noiseValue * swirlStrength);

        //sample color map
        vec3 colorMapColor = texture2D(colorMap, distortedUv).rgb;


        //remove seam by blending with color from other side of texture
        if (distortedUv.x > 0.95) {
            vec2 otherSideUv = vec2(distortedUv.x - 1.0, distortedUv.y);
            vec3 otherSideColor = texture2D(colorMap, otherSideUv).rgb;

            //smoothly blend colors without abrupt transition
            float blend = smoothstep(0.95, 1.0, distortedUv.x);
            colorMapColor = mix(colorMapColor, otherSideColor, blend);
        }

        //mix multply color with color map color
        color = color * colorMapColor;

        // map the lightness of the color to alpha
        float alpha = length(color) * maxAlpha * alphaBoost;
        //clamp alpha
        alpha = clamp(alpha, 0.0, 1.0);
    
        // set final color and alpha
        csm_FragColor = vec4(color, alpha);

    }
    `;

    useFrame(({ scene, clock }) => {

        if(removedRef.current) return;

        // if(meshRef.current.visible === false) return;

        if (matRef.current) {
            matRef.current.uniforms.time.value = clock.getElapsedTime();
        }

        //gradually increase matRef.current.uniforms.rainbowBoost.value to 3.3, stop at 3.3
        if (introCamPctRef.current < 1.0) {
            matRef.current.uniforms.rainbowBoost.value = lerp(0.5, 3.3, introCamPctRef.current);
            matRef.current.uniforms.timeScale.value = lerp(0.35, 0.1, introCamPctRef.current);
        }

        else {
            if (matRef.current.uniforms.maxAlpha.value > 0.0) {

                let curAlpha = matRef.current.uniforms.maxAlpha.value;

                matRef.current.uniforms.maxAlpha.value = lerp(curAlpha, 0.0, 0.1);

                if (matRef.current.uniforms.maxAlpha.value < 0.01) {
                    console.log('removing firmament');
                    // meshRef.current.visible = false;
                    // mudUnderRef.current.visible = false;
                    scene.remove(meshRef.current);

                    if(mudUnderRef.current) {
                        scene.remove(mudUnderRef.current);
                    }
                    removedRef.current = true;
                }
            }

        }
    });

    return(
        <>        
        {firmamentGLB &&
        (<>
        
        
        <mesh
         ref={meshRef}
         geometry={firmamentGLB.nodes.Sphere.geometry} 
         scale={[scale,scale,scale]}
         renderOrder={renderOrder}
         >
            <CustomShaderMaterial
                ref={matRef}
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader = {vert}
                fragmentShader = {frag}
                uniforms={{
                    time: { value: 0.0 },
                    fresnelPow: { value: fresnelPow },
                    fresnelBoost: { value: fresnelBoost },
                    maxAlpha: { value: maxAlpha },
                    colorMap: { value: soapBubbleMap },
                    noiseScale: { value: noiseScale },
                    rainbowBoost: { value: 1.1 },
                    swirlStrength: { value: swirlStrength },
                    timeScale: { value: timeScale },
                    vertexNoiseScale: { value: vertexNoiseScale },
                    alphaBoost: { value: alphaBoost },
                }}
                // blending={THREE.SubtractiveBlending}
                side={THREE.DoubleSide}
                // roughness={roughness}
                // metalness={metalness}
                transparent={true}
                // clearcoat={1.0}
                depthWrite={false}
                // depthTest={false}
                // envMapIntensity={envMapIntensity}    
            />
        </mesh>

        {mudMat && <mesh 
        geometry={firmamentGLB.nodes.mudunder.geometry}
        ref={mudUnderRef}
        scale={[scale-0.1,scale,scale-0.1]}
        material={mudMat}
        />}
        </>)}
        </>
    );
}
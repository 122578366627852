import React, { useEffect, useState, useRef } from 'react';
import './Nome.css';
import ARTWORK_INFO from './artworkinfo.json';
import { Contract, providers, utils, BigNumber } from 'ethers';
import { NavLink } from 'react-router-dom';
import Counter from './Counter';
import CountdownTimer from './CountdownTimer';
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { isMobile, isTablet } from 'react-device-detect';

import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import AUCTION_HOUSE_ABI from './AuctionHouseABI.json';
import GACHAPON_ABI from './GachaponABI.json';
import OPEN_EDITION_ABI from './OpenEditionABI.json';

function TabFolder(props) {
    const [activeTab, setActiveTab] = useState(props.children[0].props.name);

    return (
        <div>
            <div className={isMobile ? "tabsMobile" : "tabs"}>
                {props.children.map((child, index) => (
                    <button
                        key={index}
                        className={`tab ${activeTab === child.props.name ? 'active' : ''}`}
                        onClick={() => setActiveTab(child.props.name)}
                    >
                        {child.props.name}
                    </button>
                ))}
            </div>
            {props.children.map((child, index) => {
                if (child.props.name === activeTab) {
                    return <div key={index} className={isMobile ? "tab-content-mobile" : "tab-content"}>{child}</div>;
                }
                return null;
            })}
        </div>
    );
}

function TabSection(props) {
    return <div className="tab-section">{props.children}</div>;
}

function OEElements({ mintOE, mintOESet, oeMintButtonsText, setOEMintButtonsText, oeSuiteButtonText }) {

    // const [oeMintButtonsText, setOEMintButtonsText] = useState({});
    const [oeMintCounts, setOEMintCounts] = useState({});

    const [gotInitialData, setGotInitialData] = useState(false);

    const oeEndTime = useRef(0);

    const oeDataRef = useRef(null);

    const artworkPath = "/textures/nome_artwork/";

    async function refreshOEData() {
        try {
            const response = await fetch(' https://yeche-onchain-c25dac264fe6.herokuapp.com/nomeOEData', { mode: 'cors' });
            const data = await response.json();

            let supplies = [];
            for (let i = 0; i < data.editionSupplies.length; i++) {
                supplies.push(parseInt(data.editionSupplies[i]));
            }

            // console.log('oeEndTime: ' + oeEndTime);
            // console.log('supplies: ' + supplies);

            oeDataRef.current = {
                endTime: oeEndTime,
                supplies: supplies
            };

            // setOeEndTime(oeEndTime);
            oeEndTime.current = parseInt(data.endTime);

            // generateOEContent();
            // computeOEState();

            setGotInitialData(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        refreshOEData();
        // Create initial states for mint buttons and mint counts
        const initialOEMintButtonsText = {};
        const initialOEMintCounts = {};

        ARTWORK_INFO.artworks.filter(artwork => artwork.workType === "open edition").forEach(artwork => {
            const tokenID = artwork.tokenID;
            initialOEMintButtonsText[tokenID] = "Mint";
            initialOEMintCounts[tokenID] = 1;
        });

        // Update the state with these initial values
        setOEMintButtonsText(initialOEMintButtonsText);
        setOEMintCounts(initialOEMintCounts);

    }, []);

    return (gotInitialData &&
        <>
            {/* <button className="mintSuite" onClick={() => mintOESet()}>{oeSuiteButtonText}</button> */}

            {ARTWORK_INFO.artworks.filter(artwork => artwork.workType === "open edition").map((artwork) => {
                const imageUrl = process.env.PUBLIC_URL + artworkPath + artwork.ogFilename;
                const tokenID = artwork.tokenID;

                const index = tokenID;

                const endTime = oeDataRef.current.endTime;

                return (
                    <div className='DegenItem' key={tokenID}>
                        <div className='ArtThumbnailContainer'>
                            <img className="ArtThumbnailImgDegen" src={imageUrl} alt={artwork.title} />
                        </div>
                        <div className="OverlayPaneArtHeader">
                            <div className='ArtHeaderTitleName'>
                                {artwork.name}<br />
                                {artwork.title}<br />
                                <div className='WorkType'>
                                    {artwork.workType}
                                </div>
                            </div>

                            {/* <div className="OverlayCurrentBidInfo">
                                <div className="mintBox">
                                    <Counter
                                        count={oeMintCounts[tokenID]}
                                        setCount={(newCount) => {
                                            setOEMintCounts(prevCounts => ({
                                                ...prevCounts,
                                                [tokenID]: newCount
                                            }));
                                        }}
                                    />
                                    <button className="mintButton" onClick={() => mintOE(tokenID, oeMintCounts[tokenID])}>{oeMintButtonsText[tokenID]}</button>
                                </div>
                                <CountdownTimer endTime={oeEndTime.current} />
                            </div> */}
                        </div>

                        {tokenID == 0 && (
                            <>
                                <div className="ContractInfo">
                                    <div className="Etherscan-link">
                                        <a href="https://etherscan.io/address/0xaf89410e92b69b47a53980db851d732fe4da5981" target="_blank">
                                            <img src={require('../images/etherscan_black.png')} />
                                        </a>
                                    </div>
                                    <div className="Opensea-link">
                                        <a href="https://opensea.io/collection/nomemene-open-editions" target="_blank">
                                            <img src={require('../images/opensea_black.png')} />
                                        </a>
                                    </div>
                                </div>

                                <div className='OverlayPaneItem'>
                                    <div className="PriceGridDegen">
                                        <div><b>Minting of Open Editions is now closed.</b></div>
                                        <div>Open Editions are available to mint from 9/12/23 11:59AM to 9/22/23 11:59AM.</div>
                                        <div>Mint 1 = 0.0123ETH</div>
                                        <div>Pie key = 10% off</div>
                                        <div>Whitelist = 10% off</div>
                                        <div>Mint set of 10 editions = 30% off</div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                )
            })}
        </>
    );
}

export default function Nome2D(props) {

    const ONE_OF_ONE_ADDRESS = '0x7ADE16f069e71a4a159E04EaA1c21437417064eB';
    const AUCTION_HOUSE_ADDRESS = '0xdDd9abEc523A05aDC1E1B7a97f5015Ece0FB492a';
    const GACHAPON_ADDRESS = '0x66E465CE699967c96e2D77F7184B59449F230761';
    const OPEN_EDITION_ADDRESS = '0xAf89410E92B69B47A53980DB851d732fE4da5981';

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");
    const [bidButtonText, setBidButtonText] = useState("Bid");
    const [humanAddress, setHumanAddress] = useState("");
    const [web3Modal, setWeb3Modal] = useState(null);
    const [ethersProvider, setEthersProvider] = useState(null);

    const [oeSuiteButtonText, setOESuiteButtonText] = useState("MINT COMPLETE SUITE OF OPEN EDITIONS");

    const [gachaSuiteButtonText, setGachaSuiteButtonText] = useState("MINT 10");

    const [auctionContent, setAuctionContent] = useState(null);

    const bidInputRefs = useRef([]);
    const [bidButtonStates, setBidButtonStates] = useState({});
    const [highestBidStates, setHighestBidStates] = useState({});

    const [whitelistRootHash, setWhitelistRootHash] = useState("");
    const [whitelistMerkleTree, setWhitelistMerkleTree] = useState(null);
    const [whitelistWallets, setWhitelistWallets] = useState([]);

    const [oeMintButtonsText, setOEMintButtonsText] = useState({});

    const [gachaMintCount, setGachaMintCount] = useState(1);
    const [gachaMintButtonText, setGachaMintButtonText] = useState("Mint");

    const gachaResultsRef = useRef(null);

    const arweaveBaseGacha = "https://hyunmvlmpnhwxblti7a6o3rhgkrsoik4hqfkxo5qicygbeg5ogjq.arweave.net/PijWVWx7T2uFc0fB524nMqMnIVw8Cqu7sECwYJDdcZM/";
    const gachaImgBaseURL = 'https://bafybeicbxhfttv4oa3irc7rivyrs2bvw5x7tjhfq5redeaco2ev4ry7q6m.ipfs.nftstorage.link/';

    const userAddressRef = useRef("");
    const userENSRef = useRef("");

    const targetChainName = 'mainnet';
    const targetChainID = 1;

    const auctionDataRef = useRef(null);
    const oeDataRef = useRef(null);
    const gachaDataRef = useRef(null);

    const artworkPath = "/textures/nome_artwork/";

    const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

    function initWeb3() {
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
            }
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });

        setWeb3Modal(web3Modal);
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();

        const ethersProvider = new providers.Web3Provider(provider);

        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);

        if (chainId !== targetChainID) {
            setConnected(false);
            setConnectText(wrongNetworkMessage);
            return;
        }

        const userAddress = await ethersProvider.getSigner().getAddress();

        const signer = ethersProvider.getSigner();

        userAddressRef.current = userAddress;

        //todo get num hats + old auction data + WLGs minted

        const name = await ethersProvider.lookupAddress(userAddress);

        setEthersProvider(ethersProvider);

        if (name) {
            setHumanAddress(name);
            userENSRef.current = name;
        }

        else {
            let truncatedAddress = truncateEthAddress(userAddress);
            setHumanAddress(truncatedAddress);
        }

        setConnected(true);
    }

    function toChecksumAddress(address) {
        address = address.toLowerCase().replace('0x', '')
        var hash = keccak256(address).toString('hex')
        var ret = '0x'

        for (var i = 0; i < address.length; i++) {
            if (parseInt(hash[i], 16) >= 8) {
                ret += address[i].toUpperCase()
            } else {
                ret += address[i]
            }
        }

        return ret
    }

    function initMerkleTree(wallets) {
        const leafNodes = wallets.map(address => keccak256(address));

        const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

        const rootHash = tree.getRoot();
        const rootHashHex = MerkleTree.bufferToHex(rootHash);

        console.log("root hash is " + rootHashHex);

        setWhitelistRootHash(rootHashHex);
        setWhitelistMerkleTree(tree);
    }

    function loadWhitelist() {
        fetch('data/nomewhitelist.csv')
            .then(response => response.text())
            .then(data => {
                let wallets = data.split('\n').map(wallet => wallet.replace('\r', ''));
                //remove any non alphanumeric characters from wallet addresses
                wallets = wallets.map(wallet => wallet.replace(/[^a-zA-Z0-9]/g, ''));

                //get the checksummed version of each wallet address
                wallets = wallets.map(wallet => toChecksumAddress(wallet));

                //ensure there are no duplicates
                wallets = [...new Set(wallets)];

                initMerkleTree(wallets);

                setWhitelistWallets(wallets);
            })
            .catch(error => console.error('Error:', error));
    }

    function checkWhitelisted(address) {
        let isWhitelisted = false;
        for (let i = 0; i < whitelistWallets.length; i++) {
            if (whitelistWallets[i].toLowerCase() == address.toLowerCase()) {
                isWhitelisted = true;
                break;
            }
        }

        return isWhitelisted;
    }

    async function mintOE(tokenID, quantity) {
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        const signer = ethersProvider.getSigner();
        const openEditionContract = new Contract(OPEN_EDITION_ADDRESS, OPEN_EDITION_ABI, signer);

        //make sure mint isn't paused 
        const isPaused = await openEditionContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);

        //check if whitelist only, and that user is on whitelist if so 

        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await openEditionContract.checkPrice(true, quantity, userAddress);

            console.log('minting token id ' + tokenID + ' quantity ' + quantity + ' whitelisted');
            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionWL(tokenID, quantity, hexProof, { value: price });

            // setOeMintButtonText("Minting...");
            setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "Minting..." }));

            await tx.wait();

            // setOeMintButtonText("done!");
            setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "done!" }));

            console.log('minted');
        }

        else {
            //make sure mint is not WL only 
            let isWhitelistOnly = await openEditionContract.whitelistOnly();

            if (isWhitelistOnly) {
                alert("Minting is whitelist only, please come back later for public mint");
                return;
            }

            const price = await openEditionContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionPublic(tokenID, quantity, { value: price });

            setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "Minting..." }));

            await tx.wait();

            setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "done!" }));
        }

    }

    async function mintOESet() {
        //todo
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        const signer = ethersProvider.getSigner();
        const openEditionContract = new Contract(OPEN_EDITION_ADDRESS, OPEN_EDITION_ABI, signer);

        //make sure mint isn't paused 
        const isPaused = await openEditionContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);

        const quantity = 10;

        //check if whitelist only, and that user is on whitelist if so 

        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await openEditionContract.checkPrice(true, quantity, userAddress);

            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintSetWL(hexProof, { value: price });

            setOESuiteButtonText("Minting...");
            // setOeMintButtonText("Minting...");
            // setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "Minting..." }));

            await tx.wait();

            setOESuiteButtonText("done!");
            // setOeMintButtonText("done!");
            // setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "done!" }));

            console.log('minted');
        }

        else {
            //make sure mint is not WL only 
            let isWhitelistOnly = await openEditionContract.whitelistOnly();

            if (isWhitelistOnly) {
                alert("Minting is whitelist only, please come back later for public mint");
                return;
            }

            const price = await openEditionContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await openEditionContract.mintEditionPublic({ value: price });

            // setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "Minting..." }));
            setOESuiteButtonText("Minting...");

            await tx.wait();

            // setOEMintButtonsText(prevStates => ({ ...prevStates, [tokenID]: "done!" }));
            setOESuiteButtonText("done!");
        }

    }

    async function mintGachaSuite() {
        const quantity = 10;

        //make sure user is connected
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        //clear everything in gachaResultsRef
        if (gachaResultsRef.current) {
            while (gachaResultsRef.current.firstChild) {
                gachaResultsRef.current.removeChild(gachaResultsRef.current.firstChild);
            }
        }

        //make sure mint isn't paused
        const signer = ethersProvider.getSigner();
        const gachaponContract = new Contract(GACHAPON_ADDRESS, GACHAPON_ABI, signer);

        const isPaused = await gachaponContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);

        const firstTokenID = await gachaponContract.totalSupply();
        const lastTokenID = parseInt(firstTokenID) + parseInt(quantity) - 1;

        //check if whitelist, public, etc
        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(true, quantity, userAddress);

            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintWL(quantity, hexProof, { value: price });

            setGachaSuiteButtonText("Minting...");

            await tx.wait();

            setGachaSuiteButtonText("done!");
        }

        else {
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintPublic(quantity, { value: price });

            setGachaSuiteButtonText("Minting...");

            await tx.wait();

            setGachaSuiteButtonText("done!");
        }

        if (gachaResultsRef.current) {
            for (let i = firstTokenID; i <= lastTokenID; i++) {
                let gachaResult = {};

                gachaResult.tokenID = i;
                gachaResult.url = gachaImgBaseURL + i + '.jpg';

                //add image tag to gachaResultsRef with src = url
                let img = document.createElement('img');
                img.src = gachaResult.url;
                img.className = "ArtThumbnailImg";
                gachaResultsRef.current.appendChild(img);

                //add link under image that says "View full resolution on Arweave"
                let arweaveLink = document.createElement('a');
                arweaveLink.href = arweaveBaseGacha + i + '.jpg';
                arweaveLink.innerHTML = 'View full resolution on Arweave';
                arweaveLink.target = "_blank";
                gachaResultsRef.current.appendChild(arweaveLink);

                //add line break
                let br = document.createElement('br');
                gachaResultsRef.current.appendChild(br);

                img.onload = () => {
                    console.log(`Image ${gachaResult.url} loaded successfully.`);
                };

                img.onerror = (error) => {
                    console.error(`Error loading image ${gachaResult.url}:`, error);
                };
            }
        }
    }

    async function mintGacha(quantity) {
        //make sure user is connected
        if (!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        //make sure mint isn't paused
        const signer = ethersProvider.getSigner();
        const gachaponContract = new Contract(GACHAPON_ADDRESS, GACHAPON_ABI, signer);

        const isPaused = await gachaponContract.mintPaused();

        if (isPaused) {
            alert("Minting is paused, please try again later");
            return;
        }

        //check if user is on the whitelist
        const userAddress = userAddressRef.current;
        const isWhitelisted = checkWhitelisted(userAddress);

        const firstTokenID = await gachaponContract.totalSupply();
        const lastTokenID = parseInt(firstTokenID) + parseInt(quantity) - 1;

        //check if whitelist, public, etc
        if (isWhitelisted) {
            //mint WL
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(true, quantity, userAddress);

            console.log('price to mint ' + quantity + ' whitelisted is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintWL(quantity, hexProof, { value: price });

            setGachaMintButtonText("Minting...");

            await tx.wait();

            setGachaMintButtonText("done!");
        }

        else {
            const hexProof = whitelistMerkleTree.getHexProof(keccak256(userAddress));

            const price = await gachaponContract.checkPrice(false, quantity, userAddress);

            console.log('price to mint ' + quantity + ' public is ' + utils.formatEther(price.toString()));

            const tx = await gachaponContract.mintPublic(quantity, { value: price });

            setGachaMintButtonText("Minting...");

            await tx.wait();

            setGachaMintButtonText("done!");
        }

        if (gachaResultsRef.current) {
            for (let i = firstTokenID; i <= lastTokenID; i++) {
                let gachaResult = {};

                gachaResult.tokenID = i;
                gachaResult.url = gachaImgBaseURL + i + '.jpg';

                //add image tag to gachaResultsRef with src = url
                let img = document.createElement('img');
                img.src = gachaResult.url;
                img.className = "ArtThumbnailImg";
                gachaResultsRef.current.appendChild(img);

                //add link under image that says "View full resolution on Arweave"
                let arweaveLink = document.createElement('a');
                arweaveLink.href = arweaveBaseGacha + i + '.jpg';
                arweaveLink.innerHTML = 'View full resolution on Arweave';
                arweaveLink.target = "_blank";
                gachaResultsRef.current.appendChild(arweaveLink);

                //add line break
                let br = document.createElement('br');
                gachaResultsRef.current.appendChild(br);

                img.onload = () => {
                    console.log(`Image ${gachaResult.url} loaded successfully.`);
                };

                img.onerror = (error) => {
                    console.error(`Error loading image ${gachaResult.url}:`, error);
                };
            }
        }
    }

    async function bid(tokenID) {
        // let bidAmountEth = bidInputRef.current.value;

        //get the bid amount in Eth from the input field
        let bidAmountEth = bidInputRefs.current[tokenID].current.value;

        console.log('value of connected is ' + connected);

        if (!connected) {
            alert("Please connect your wallet to bid");
            bidInputRefs.current[tokenID].current.value = "";
            return;
        }

        let curPrice;

        if (auctionDataRef.current[tokenID].hasBids) {
            console.log(auctionDataRef.current[tokenID].bids);

            //get latest bid 
            let lastBidIndex = auctionDataRef.current[tokenID].bids.length - 1;
            let lastBid = auctionDataRef.current[tokenID].bids[lastBidIndex];
            let lastBidAmt = utils.formatEther(lastBid.amount.toString());

            console.log('lastBid was ' + lastBidAmt);

            curPrice = lastBidAmt;
        }

        else {
            let reservePrice = utils.formatEther(auctionDataRef.current[tokenID].reservePrice.toString());

            curPrice = reservePrice;
        }

        //min bid increment percentage is 5%, so ensure that bid is at least 5% higher than current price
        let minBid = curPrice * 1.05;
        console.log('minBid: ' + minBid);

        if (bidAmountEth < minBid) {
            alert("Bid must be at least 5% higher than last bid");
            bidInputRefs.current[tokenID].current.value = "";
            return;
        }

        const signer = ethersProvider.getSigner();
        const auctionContract = new Contract(AUCTION_HOUSE_ADDRESS, AUCTION_HOUSE_ABI, signer);

        //get auction ID from contract
        let auctionID = await auctionContract.getAuctionId(ONE_OF_ONE_ADDRESS, tokenID);


        //convert bidAmount to wei
        let bidAmountWei = utils.parseEther(bidAmountEth);
        const tx = await auctionContract.createBid(auctionID, bidAmountWei, { value: bidAmountWei });

        // setBidButtonText("Bidding...");

        // update the button state to "bidding..."
        let updatedBidButtonStates = { ...bidButtonStates }; // Create a copy of the current object

        updatedBidButtonStates[tokenID] = "Bidding...";

        setBidButtonStates(updatedBidButtonStates); // Set the updated values

        await tx.wait();

        // setBidButtonText("done!");

        // update the button state to "done!"
        updatedBidButtonStates = { ...bidButtonStates }; // Create a copy of the current object

        updatedBidButtonStates[tokenID] = "Bidding...";

        setBidButtonStates(updatedBidButtonStates); // Set the updated values

        //clean the input field
        bidInputRefs.current[tokenID].current.value = "";

        //update the highest bid state using this address and the bid amount
        let truncatedAddress = truncateEthAddress(userAddressRef.current, 6);

        let updatedHighestBidStates = { ...highestBidStates }; // Create a copy of the current state

        updatedHighestBidStates[tokenID] = "Last bid: " + bidAmountEth + "ETH by " + truncatedAddress;

        setHighestBidStates(updatedHighestBidStates); // Set the updated values

        await refreshAuctionData();
    }

    function generateAuctionContent() {
        const auctionArtworks = ARTWORK_INFO.artworks.filter(artwork => artwork.workType === "1 of 1 auction");

        const thisAuctionContent = auctionArtworks.map((artwork) => {
            const imageUrl = process.env.PUBLIC_URL + artworkPath + artwork.ogFilename;
            const tokenID = artwork.tokenID;
            const index = tokenID;

            bidInputRefs.current[index] = React.createRef();

            const auctionEndTime = auctionDataRef.current[index].startTime + auctionDataRef.current[index].duration;

            return (
                <div className='DegenItem' key={tokenID}>
                    <div className='ArtThumbnailContainer'>
                        <img className="ArtThumbnailImgDegen" src={imageUrl} alt={artwork.title} />
                    </div>
                    <div className="OverlayPaneArtHeader">
                        <div className='ArtHeaderTitleName'>
                            {artwork.name}<br />
                            {artwork.title}<br />
                            <div className='WorkType'>
                                {artwork.workType}
                            </div>
                        </div>
                        {/* <div className="OverlayCurrentBidInfo">
                            <div className="BidBox">
                                <div className="BidInput">
                                    <input ref={bidInputRefs.current[index]} className="BidInputBox" type="text" name="bid" placeholder="0.00" />
                                    <button className="BidButton" onClick={() => bid(tokenID)}>
                                        {bidButtonStates[tokenID] || "Bid"}
                                    </button>
                                </div>
                                <div className='HighestBidInfo'>
                                    {highestBidStates[tokenID] || "No Bid Yet"}
                                </div>
                            </div>
                            <CountdownTimer endTime={auctionEndTime} />
                        </div> */}
                    </div>
                    
                    {tokenID == 0 && (
                        <div className='OverlayPaneItem'>
                            <div className="PriceGridDegen">
                                <div><b>All auctions for this exhibition are now complete.</b></div>
                                <div>1/1 auctions are available to receive bids from 9/12/23 11:59AM to 9/22/23 11:59AM. At the close of the auction the artworks will be automatically transferred to their owners.</div>
                            </div>
                        </div>
                    )}
                </div>
            );
        });

        setAuctionContent(thisAuctionContent);
    }

    function computeAuctionContentState() {
        const auctionArtworks = ARTWORK_INFO.artworks.filter(artwork => artwork.workType === "1 of 1 auction");

        let computedBidButtonStates = {};
        let computedHighestBidStates = {};

        auctionArtworks.forEach((artwork) => {
            const tokenID = artwork.tokenID;
            const index = tokenID;

            if (auctionDataRef.current[index].hasBids) {
                let lastBidIndex = auctionDataRef.current[tokenID].bids.length - 1;
                let lastBid = auctionDataRef.current[tokenID].bids[lastBidIndex];

                let lastBidder = truncateEthAddress(lastBid.bidder, 6);
                let lastBidAmt = utils.formatEther(lastBid.amount.toString());

                computedHighestBidStates[tokenID] = "Last bid: " + lastBidAmt + "ETH by " + lastBidder;
                computedBidButtonStates[tokenID] = "Bid";
            } else {
                let reservePrice = utils.formatEther(auctionDataRef.current[tokenID].reservePrice.toString());
                computedHighestBidStates[tokenID] = "Reserve price: " + reservePrice + "ETH";
                computedBidButtonStates[tokenID] = "Bid";
            }
        });

        setBidButtonStates(prevStates => ({ ...prevStates, ...computedBidButtonStates }));
        setHighestBidStates(prevStates => ({ ...prevStates, ...computedHighestBidStates }));
    }

    useEffect(() => {
        if (auctionDataRef.current == null) return;
        generateAuctionContent();
    }, [highestBidStates, bidButtonStates]);

    useEffect(() => {
        if (auctionDataRef.current == null) return;
        generateAuctionContent();
    }, [connected]);

    async function refreshAuctionData() {
        //AUCTION DATA
        try {
            const response = await fetch(' https://yeche-onchain-c25dac264fe6.herokuapp.com/nomeAuctionData', { mode: 'cors' });
            const data = await response.json();

            let auctionDataTemp = [];

            //loop through every object in the data array and add it to the auctionDataRef
            for (let i = 0; i < data.length; i++) {
                // let tokenAuctionData = data[i];
                let tokenAuctionData = {};

                tokenAuctionData.tokenId = parseInt(data[i].tokenId);
                tokenAuctionData.reservePrice = BigNumber.from(data[i].reservePrice);
                tokenAuctionData.startTime = parseInt(data[i].startTime);
                tokenAuctionData.duration = parseInt(data[i].duration);
                tokenAuctionData.hasBids = data[i].auctionHasBids;

                if (tokenAuctionData.hasBids) {
                    let bidsTemp = [];

                    //for each bid, parse bid bidder and timestamp and add to bidsTemp
                    for (let j = 0; j < data[i].bids.length; j++) {
                        let bid = {};

                        bid.bidder = data[i].bids[j].bidder;
                        bid.timestamp = parseInt(data[i].bids[j].timestamp);
                        bid.amount = parseInt(data[i].bids[j].amount);

                        bidsTemp.push(bid);
                    }

                    tokenAuctionData.bids = bidsTemp;
                }

                auctionDataTemp.push(tokenAuctionData);
            }

            auctionDataRef.current = auctionDataTemp;

            // initAuctionContent();

            computeAuctionContentState();

            // console.log(auctionDataRef.current);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if (auctionDataRef.current == null) return;
        // initAuctionContent();
    }, [connected]);

    useEffect(() => {
        document.title = props.title;

        initWeb3();

        loadWhitelist();

        refreshAuctionData();

        // refreshOEData();
        // refreshGachaData();
    }, []);

    return (
        <div className="Nome">
            {connected && <div className="Address-pill">{humanAddress}</div>}
            {!connected && <button className={isMobile ? "Connect-button Connect-button-mobile" : "Connect-button Connect-button-desktop"} onClick={() => connectWallet()}>{connectText}</button>}

            <div className="degen-bg-splash"></div>

            <NavLink to="/nomemene" className={!isMobile || isTablet ? "EnterGallery" : "EnterGalleryMobile"}>
                <div>
                    <img src={process.env.PUBLIC_URL + "/textures/entergallerynome.png"} alt="enter gallery" className={!isMobile || isTablet ? "EnterGalleryImage" : "EnterGalleryImageMobile"} />
                </div>
            </NavLink>


            <TabFolder>
                <TabSection name={"Gachapon Collection"}>

                    <div className="GachaGridContainer">
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha1.jpg"} alt="Description 1" />
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha2.jpg"} alt="Description 2" />
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha3.jpg"} alt="Description 3" />
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha4.jpg"} alt="Description 4" />
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha5.jpg"} alt="Description 5" />
                        <img src={process.env.PUBLIC_URL + artworkPath + "nomegacha6.jpg"} alt="Description 6" />
                    </div>

                    <div className="OverlayCurrentBidInfo">
                        <div className="mintBox">
                            <Counter
                                count={gachaMintCount}
                                setCount={setGachaMintCount}
                            />
                            <button className="mintButton" onClick={() => mintGacha(gachaMintCount)}>{gachaMintButtonText}</button>
                        </div>
                        <button className="mintButton" onClick={() => mintGachaSuite()}>{gachaSuiteButtonText}</button>
                    </div>

                    <div className="ArtThumbnailContainer" ref={gachaResultsRef}></div>

                    <div className="ContractInfo">
                        <div className="Etherscan-link">
                            <a href="https://etherscan.io/address/0x66e465ce699967c96e2d77f7184b59449f230761" target="_blank">
                                <img src={require('../images/etherscan_black.png')} />
                            </a>
                        </div>
                        <div className="Opensea-link">
                            <a href="https://opensea.io/collection/nomemenegachapon" target="_blank">
                                <img src={require('../images/opensea_black.png')} />
                            </a>
                        </div>
                    </div>

                    <div className='OverlayPaneItem'>
                        <div className="PriceGridDegen">
                            <div>First 100 free to mint for whitelisted wallets, 1 per wallet.</div>
                            <div>Public = 0.0111 ETH</div>
                            <div>Whitelist = 0.01 ETH</div>
                            <div>Pie key = 10% off</div>
                            <div>Mint 10 = 20% off</div>
                        </div>
                    </div>

                    <div className="OverlayPaneItemDegen">
                        A set of 1378 images generated by Nomemene's fictional toy shop owner, SyjoAi who makes homunculus elemental dolls.<br /><br />

                        SyjoAi delivers the homunculus to Nomemene and they collaborate on Soul Synthesis. In the process, the alchemist Nomemene vaguely thinks about the soul. If you look at a doll, and you're a doll, and there's a higher-intelligent being, you're a doll without a soul, right? Conversely, Nomemene wonders if the doll, the earth, or even these things have souls.<br /><br />

                        "If my heart, which I am trying to put a soul into by trial and error, is getting more and more soulful, it seems to me that the clay and soil are putting a soul into me."<br /><br />

                        The large set of images are presented within a toy gachapon machine on the southwest corner of the exhibition architecture.<br /><br />

                        Nomemene is an artist based in Japan who works with painting, photography, A.I., sculpture, and writing to develop experimental and combined approaches towards new ideas of digital painting that eschew boundaries between media for the sake of presenting unified works under new rubrics of the screen. They are at the forefront of a new generation of digital painters that recognize anime as a default global language for exploring contemporary themes of pain, existence, death, transformation, and the soul.<br /><br />

                        𝘛𝘩𝘦 𝘢𝘭𝘤𝘩𝘦𝘮𝘪𝘴𝘵 𝘸𝘩𝘰 𝘵𝘳𝘪𝘦𝘥 𝘵𝘰 𝘱𝘶𝘵 𝘢 𝘴𝘰𝘶𝘭 𝘪𝘯 𝘵𝘩𝘦 𝘩𝘰𝘮𝘶𝘯𝘤𝘶𝘭𝘶𝘴 𝘥𝘪𝘥𝘯’𝘵 𝘩𝘢𝘷𝘦 𝘢 𝘴𝘰𝘶𝘭 𝘦𝘪𝘵𝘩𝘦𝘳 is their first solo exhibition, presented by Galerie Yeche Lange within a decayed fishing shack scanned by the artist. The exhibition environment creates a background for Nomemene's artwork that reflects a longstanding interest of the artist: how do gaps in consciousness allow for creative potential, whether this is on the side of the subject and its relationship to its own soul or the outer world and our perceptions of it, and how are these gaps mimicked by technical machines or consciousness like A.I.? What relationship do our memories of the past have in suggesting the shape that fills these gaps?<br /><br />

                        A recurring figure of the exhibition is an image of a doll or girl, the boundary between animate and inanimate already in question, with a sharp object lodged or stabbed into her eye. Within an internet vocabulary, this image appears related to teenage self-harm trends, often understood as tragic physical manifestations of the desire to regain bodily autonomy when it is so often demanded by modern institutions. Circulated within Nomemene's focus on the capacity for the brain to amend the gaps in what the eyes see however, this tragic figure is stripped from its contemporary context and instead aligned with the figure of the oracle. The doll self-wounds its own eye not only so that it feels a gratifyingly self-authorized pain, but also so that it might create more gaps wherein further creative vision could be possible. The act resembles a sacrificial pact, wherein sight is lost in order that vision might be gained.
                    </div>
                </TabSection>

                <TabSection name={"Open Editions"}>

                    {/* {oeContent} */}
                    <OEElements
                        mintOE={mintOE}
                        mintOESet={mintOESet}
                        oeMintButtonsText={oeMintButtonsText}
                        setOEMintButtonsText={setOEMintButtonsText}
                        oeSuiteButtonText={oeSuiteButtonText}
                    />

                    <div className="OverlayPaneItemDegen">
                        A series of ten images by Nomemene. Each are available individually or together as a complete set for a discounted price. Open Editions are available for Five Days. The series are presented in a circle of signs in the center of the exhibition architecture<br /><br />

                        Nomemene is an artist based in Japan who works with painting, photography, A.I., sculpture, and writing to develop experimental and combined approaches towards new ideas of digital painting that eschew boundaries between media for the sake of presenting unified works under new rubrics of the screen. They are at the forefront of a new generation of digital painters that recognize anime as a default global language for exploring contemporary themes of pain, existence, death, transformation, and the soul.<br /><br />

                        𝘛𝘩𝘦 𝘢𝘭𝘤𝘩𝘦𝘮𝘪𝘴𝘵 𝘸𝘩𝘰 𝘵𝘳𝘪𝘦𝘥 𝘵𝘰 𝘱𝘶𝘵 𝘢 𝘴𝘰𝘶𝘭 𝘪𝘯 𝘵𝘩𝘦 𝘩𝘰𝘮𝘶𝘯𝘤𝘶𝘭𝘶𝘴 𝘥𝘪𝘥𝘯’𝘵 𝘩𝘢𝘷𝘦 𝘢 𝘴𝘰𝘶𝘭 𝘦𝘪𝘵𝘩𝘦𝘳 is their first solo exhibition, presented by Galerie Yeche Lange within a decayed fishing shack scanned by the artist. The exhibition environment creates a background for Nomemene's artwork that reflects a longstanding interest of the artist: how do gaps in consciousness allow for creative potential, whether this is on the side of the subject and its relationship to its own soul or the outer world and our perceptions of it, and how are these gaps mimicked by technical machines or consciousness like A.I.? What relationship do our memories of the past have in suggesting the shape that fills these gaps?<br /><br />

                        A recurring figure of the exhibition is an image of a doll or girl, the boundary between animate and inanimate already in question, with a sharp object lodged or stabbed into her eye. Within an internet vocabulary, this image appears related to teenage self-harm trends, often understood as tragic physical manifestations of the desire to regain bodily autonomy when it is so often demanded by modern institutions. Circulated within Nomemene's focus on the capacity for the brain to amend the gaps in what the eyes see however, this tragic figure is stripped from its contemporary context and instead aligned with the figure of the oracle. The doll self-wounds its own eye not only so that it feels a gratifyingly self-authorized pain, but also so that it might create more gaps wherein further creative vision could be possible. The act resembles a sacrificial pact, wherein sight is lost in order that vision might be gained.
                    </div>

                </TabSection>

                <TabSection name={"1/1 Auctions"}>

                    {auctionContent}

                    <div className="ContractInfo">
                        <div className="Etherscan-link">
                            <a href="https://etherscan.io/address/0xddd9abec523a05adc1e1b7a97f5015ece0fb492a" target="_blank">
                                <img src={require('../images/etherscan_black.png')} />
                            </a>
                        </div>
                        <div className="Opensea-link">
                            <a href="https://opensea.io/collection/nomemenesolo" target="_blank">
                                <img src={require('../images/opensea_black.png')} />
                            </a>
                        </div>
                    </div>

                    <div className="OverlayPaneItemDegen">
                        Five one-of-ones by Nomemene each auctioned individually. The auction lasts for Ten Days, with any bid in the last five minutes of the auction increasing the auction time by Five Minutes. The most painterly and detailed in this show, the images are displayed prominently on the fishing wall of the exhibition architecture.<br /><br />

                        Nomemene is an artist based in Japan who works with painting, photography, A.I., sculpture, and writing to develop experimental and combined approaches towards new ideas of digital painting that eschew boundaries between media for the sake of presenting unified works under new rubrics of the screen. They are at the forefront of a new generation of digital painters that recognize anime as a default global language for exploring contemporary themes of pain, existence, death, transformation, and the soul.<br /><br />

                        𝘛𝘩𝘦 𝘢𝘭𝘤𝘩𝘦𝘮𝘪𝘴𝘵 𝘸𝘩𝘰 𝘵𝘳𝘪𝘦𝘥 𝘵𝘰 𝘱𝘶𝘵 𝘢 𝘴𝘰𝘶𝘭 𝘪𝘯 𝘵𝘩𝘦 𝘩𝘰𝘮𝘶𝘯𝘤𝘶𝘭𝘶𝘴 𝘥𝘪𝘥𝘯’𝘵 𝘩𝘢𝘷𝘦 𝘢 𝘴𝘰𝘶𝘭 𝘦𝘪𝘵𝘩𝘦𝘳 is their first solo exhibition, presented by Galerie Yeche Lange within a decayed fishing shack scanned by the artist. The exhibition environment creates a background for Nomemene's artwork that reflects a longstanding interest of the artist: how do gaps in consciousness allow for creative potential, whether this is on the side of the subject and its relationship to its own soul or the outer world and our perceptions of it, and how are these gaps mimicked by technical machines or consciousness like A.I.? What relationship do our memories of the past have in suggesting the shape that fills these gaps?<br /><br />

                        A recurring figure of the exhibition is an image of a doll or girl, the boundary between animate and inanimate already in question, with a sharp object lodged or stabbed into her eye. Within an internet vocabulary, this image appears related to teenage self-harm trends, often understood as tragic physical manifestations of the desire to regain bodily autonomy when it is so often demanded by modern institutions. Circulated within Nomemene's focus on the capacity for the brain to amend the gaps in what the eyes see however, this tragic figure is stripped from its contemporary context and instead aligned with the figure of the oracle. The doll self-wounds its own eye not only so that it feels a gratifyingly self-authorized pain, but also so that it might create more gaps wherein further creative vision could be possible. The act resembles a sacrificial pact, wherein sight is lost in order that vision might be gained.

                    </div>
                </TabSection>

            </TabFolder>
        </div>
    );
}
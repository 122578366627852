import React from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { SceneContext } from './Wanderlust';
import { isMobile } from 'react-device-detect';
import Switch from "react-switch";
import { useIsWideView } from './detectWideView';
import './Wanderlust.css';
import { Contract, providers, utils } from 'ethers';
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import CONTRACT_ABI from './abi.json';
import AUCTION_DATA from './auction_data.json';


const CONTRACT_ADDRESS = '0x05c96B7DB340027B07D4D234fb12F45a51f444CF';
const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

const arweaveBaseURLImg = "https://arweave.net/_SFbZ-h9mnkEOeRphFB-uqio6JaCA9Yt0wCXSFdXt-0/";

export default function WanderlustOverlay() {

    const DARK_MODE_TIME = 1678058899;

    const MESSAGE_CHAR_LIMIT = 150;

    const [connectText, setConnectText] = useState("wallet connect");
    const [connected, setConnected] = useState(false);

    const [humanAddress, setHumanAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const infuraProviderRef = useRef(null);

    const [contract, setContract] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    // const [auctionEnd, setAuctionEnd] = useState(null);

    const wideView = useIsWideView();

    const loadedWorkDataRef = useRef(false);

    const showOverlayRef = useRef(false);
    
    const autoTourPlayingRef = useRef(false);
    const autoTourIntervalRef = useRef(null);

    const progressBarRef = useRef(null);
    const progressBarIntervalRef = useRef(null);

    const autoTourStartTimeRef = useRef(null);
    const autoTourButtonRef = useRef(null);

    const artworkBidInfoRef = useRef({});
    const artistListTimerRef = useRef({});

    const [showBidsOverlay, setShowBidsOverlay] = useState(false);
    const [avatarVisible, setAvatarVisible] = useState(false);


    const [bidButtonText, setBidButtonText] = useState("Bid");

    const showOverlayClass = !wideView ? "showOverlayMobile" : "showOverlayDesktop";
    const hideOverlayClass = !wideView ? "hideOverlayMobile" : "hideOverlayDesktop";

    const curArtworkRef = useRef("");

    const showedSkipIntro = useRef(false);

    const navigatorRef = useRef(null);
    const overlayRef = useRef(null);

    // const bidBoxRef = useRef(null);
    // const bidInputRef = useRef(null);
    const messageInputRef = useRef(null);

    const highestBidInfoRef = useRef(null);

    const arweaveLinkRef = useRef(null);

    const artDescriptionRef = useRef(null);
    const bidHistoryRef = useRef(null);
    const artThumbnailRef = useRef(null);
    // const videoThumbnailRef = useRef(null);

    const crossfadeOverlayRef = useRef(null);

    const showingArtistListRef = useRef(false);

    const teleportingRef = useRef(false);

    // const videosMutedRef = useRef(true);
    const muteIconRef = useRef(null);
    const arrowIconRef = useRef(null);

    const showBidsOverlayRef = useRef(null);

    const artistListToggleRef = useRef(null);

    const artistListRef = useRef(null);
    const artistListInnerRef = useRef(null);

    const artHeaderRef = useRef(null);
    const curBidInfoRef = useRef(null);
    const overlayPaneInfoRef = useRef(null);

    // const timerRef = useRef(null);

    const paneIsDraggingRef = useRef(false);

    // const skipIntroRef = useRef(null);
    // const skippedIntro = useRef(false);

    const [artworkTitle, setArtworkTitle] = useState("");
    const [artistName, setArtistName] = useState("");

    const { 
        workDataRef,
        crosshairArtworkRef, 
        fullDisplayRef,
        artPlanesRef,
        switchArtInstantRef,
        curPlaneIndexRef,
        artworkInfoRef,
        cameraPosRef,
        crosshairRef,
        joystickRef,
        jumpRef,
        videoElemsRef,
        videosMutedRef,
        oceanAudioRef,
        windAudioRef,
        oceanWindAudioRef,
        // ridingWormRef,
        shouldTeleportWormRef,
        fellInWaterRef,
        introCamPctRef,
        tutorialVisibleRef,
        tutorialRef,
        userAddressRef, 
        userENSRef,
        numUserHatsRef,
        avatarVisibleRef,
        messageRef,
        darkYecheEnabled,
        darkYecheVideoRef,
        artworkPath, 
        modelPath,
        historicalAuctionDataRef
    } = useContext(SceneContext);

    const lastFullDisplayRef = useRef(fullDisplayRef.current);

    async function loadWorkData() {
        //get total supply of collection
        const contractTemp = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, infuraProviderRef.current);

        //check if current token exists by seeing if the tokenID is less than the totalSupply
        const totalSupply = await contractTemp.totalSupply();

        console.log('refreshing onchain data..');
        for(let i = 0; i < artPlanesRef.current.length; i++) {
            let artworkName = artPlanesRef.current[i].artName;
            let tokenID = artPlanesRef.current[i].tokenID;

            attemptRefreshWorks(tokenID, totalSupply);
        }
    }

    async function updateOverlay() {

        if(!loadedWorkDataRef.current && artPlanesRef.current.length > 0) {
            console.log("loading work data");
            console.log(artPlanesRef.current.length);
            loadWorkData();
            loadedWorkDataRef.current = true;
        }

        if (crosshairArtworkRef.current != curArtworkRef.current) {

            if(fullDisplayRef.current) {
                curArtworkRef.current = fullDisplayRef.current;
            }

            else {
                curArtworkRef.current = crosshairArtworkRef.current;
            }

            const artwork = artworkInfoRef.current.find(artwork => artwork.filename === curArtworkRef.current);

            if(artwork) {
                //fade out artwork
                artHeaderRef.current.classList.remove("fadeIn");
                artHeaderRef.current.classList.add("fadeOut");

                //fade out info pane
                // if(showOverlayRef.current) {
                overlayPaneInfoRef.current.classList.remove("fadeIn");
                overlayPaneInfoRef.current.classList.add("fadeOut");
                // }

                //fade out timer
                // timerRef.current.classList.remove("fadeIn");
                // timerRef.current.classList.add("fadeOut");

                //fade out bid box
                // bidBoxRef.current.classList.remove("fadeIn");
                // bidBoxRef.current.classList.add("fadeOut");
                
                //update artworkTitle and artistName after 2s
                await new Promise(r => setTimeout(r, 500));
                
                //TODO change info pane 
                updatePaneInfo();

                setArtworkTitle(artwork.title);
                setArtistName(artwork.name);

                //fade in timer
                // timerRef.current.classList.remove("fadeOut");
                // timerRef.current.classList.add("fadeIn");

                //fade in artwork
                artHeaderRef.current.classList.remove("fadeOut");
                artHeaderRef.current.classList.add("fadeIn");

                //fade in info pane
                // if(showOverlayRef.current) {
                overlayPaneInfoRef.current.classList.remove("fadeOut");
                overlayPaneInfoRef.current.classList.add("fadeIn");
                // }
            }
        }

        if(fullDisplayRef.current != "" && lastFullDisplayRef.current == "" && !showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            updatePaneInfo();
        }

        else if(fullDisplayRef.current == "" && lastFullDisplayRef.current != "" && showOverlayRef.current) {
            toggleOverlay();
            toggleArrow(false);
            if(autoTourPlayingRef.current) {
                console.log("autotour playing, stopping");
                toggleAutoTour();
            }
        }

        lastFullDisplayRef.current = fullDisplayRef.current;
    }

    function handlePaneDrag(e) {
        //get the height of the navigator
        let navigatorHeight = navigatorRef.current.getBoundingClientRect().height;

        //set top value so that the pointer is in the vertical center of the navigator

        let top;

        if(isMobile) {
            top = e.touches[0].clientY - navigatorHeight/2;
        }

        else {
            top = e.clientY - navigatorHeight/2;
        }

        //constrain top to be greater than 15vh
        let constrainTop = 0.15 * window.innerHeight;
        if(top < constrainTop) {
            top = constrainTop;
        }

        //constrain top to be less than 95vh
        let constrainBottom = 0.94 * window.innerHeight;
        if(top > constrainBottom) {
            top = constrainBottom;
        }

        // overlayRef.current.style.animation = "none";
        // navigatorRef.current.style.animation = "none";

        if(!wideView) {
            overlayRef.current.classList.remove('showOverlayMobileHeight');
            overlayRef.current.classList.remove('hideOverlayMobileHeight');
        }

        overlayRef.current.classList.remove(showOverlayClass);
        navigatorRef.current.classList.remove(showOverlayClass);
        overlayRef.current.classList.remove(hideOverlayClass);
        navigatorRef.current.classList.remove(hideOverlayClass);

        //set transition values to instant
        overlayRef.current.style.transition = "none";
        navigatorRef.current.style.transition = "none";

        overlayRef.current.style.top = top + "px";
        navigatorRef.current.style.top = top + "px";

        //set height of overlay
        overlayRef.current.style.height = "calc(" + "100vh - " + top + "px)";

        e.stopPropagation();
        e.preventDefault();

    }

    function teleportToRandomArtwork() {
        teleportingRef.current = true;
        crossfadeOverlayRef.current.classList.remove("fadeOut");
        crossfadeOverlayRef.current.classList.add("fadeIn");

        let planeIndex = Math.floor(Math.random() * artPlanesRef.current.length);
        let artworkFilename = artPlanesRef.current[planeIndex].artName;

        console.log('teleporting to ' + artworkFilename + ' at index ' + planeIndex );

        //wait 0.5 seconds, then switch to the artwork
        setTimeout(() => {
            fullDisplayRef.current = artworkFilename;
            crosshairArtworkRef.current = artworkFilename;

            switchArtInstantRef.current = true;

            curPlaneIndexRef.current = planeIndex;

            // ridingWormRef.current = false;
            // setRidingWorm(false);

            if(artPlanesRef.current[planeIndex].inTower) {
                shouldTeleportWormRef.current = true;
                // setRidingWorm(true);
            }

            else {
                shouldTeleportWormRef.current = false;
            }

            //fade out crossfade overlay
            crossfadeOverlayRef.current.classList.remove("fadeIn");
            crossfadeOverlayRef.current.classList.add("fadeOut");

            fellInWaterRef.current = false;
            teleportingRef.current = false;
        }, 500);
    }

    async function initWeb3() {

        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);
        infuraProviderRef.current = infuraProviderTemp;


        let contractTemp = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, infuraProviderTemp);

        setContract(contractTemp);


        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);
    }

    function loadAuctionData(auctionData) {
        const addressTally = {};
      
        Object.keys(auctionData).forEach(address => {
          addressTally[address] = auctionData[address];
        });
      
        return addressTally;
    }
      
    
    useEffect(() => {
        initWeb3();

        loadWorkData();

        //repeatedly load work data
        // let loadWorkDataInterval = setInterval(loadWorkData, 60000);

        //load auction data for hats 
        historicalAuctionDataRef.current = loadAuctionData(AUCTION_DATA);

        //this won't work if the user resizes the window
        if(!isMobile) {
        //set up onMouseMove event listener
            window.addEventListener("mousemove", (e) => {
                if(crosshairRef.current) {
                    // console.log("mousemove")
                    // crosshairRef.current.style.left = e.clientX + "px";
                    // crosshairRef.current.style.top = e.clientY + "px";

                    // //update the crosshair so the mouse is always in the center

                    let newLeft = e.clientX - (crosshairRef.current.offsetWidth / 2);

                    let constrainMarginWidth = crosshairRef.current.offsetWidth * 1.2;
                    let constrainMarginHeight = crosshairRef.current.offsetHeight * 1.2;

                    //constrain left to be within the window
                    if(newLeft < constrainMarginWidth  - (crosshairRef.current.offsetWidth)) {
                        newLeft = constrainMarginWidth - (crosshairRef.current.offsetWidth);
                    }
                    else if(newLeft > window.innerWidth - constrainMarginWidth) {
                        newLeft = window.innerWidth - constrainMarginWidth;
                    }

                    let newTop = e.clientY - (crosshairRef.current.offsetHeight / 2);

                    //constrain top to be within the window
                    if(newTop < constrainMarginHeight - (crosshairRef.current.offsetHeight)) {
                        newTop = constrainMarginHeight - (crosshairRef.current.offsetHeight);
                    }
                    else if(newTop > window.innerHeight - constrainMarginHeight) {
                        newTop = window.innerHeight - constrainMarginHeight;
                    }


                    crosshairRef.current.style.left = newLeft + "px";
                    crosshairRef.current.style.top = newTop + "px";
                }

                if(wideView) return;

                if(paneIsDraggingRef.current) {
                    handlePaneDrag(e);
                }
            });
        }

        else {
            window.addEventListener("touchmove", (e) => {
                if(wideView) return;

                if(paneIsDraggingRef.current) {
                    handlePaneDrag(e);
                }
            });
        }

        //set updateOverlay to run every 300ms -- this is not ideal
        const interval = setInterval(() => {

            if(fellInWaterRef.current && !teleportingRef.current) {
                teleportToRandomArtwork();
            }


            updateOverlay();
        }, 300);
    }, []);

    function scrollOverlayToTop() {
        overlayRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    function toggleOverlay() {
        if (showOverlayRef.current) {
            //set transition values to all 0.5s ease-in-out
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(showOverlayClass);
            navigatorRef.current.classList.add(hideOverlayClass);

            overlayRef.current.classList.remove(showOverlayClass);
            overlayRef.current.classList.add(hideOverlayClass);

            if(wideView) {
                // overlayPaneInfoRef.current.classList.remove("fadeIn");
                // overlayPaneInfoRef.current.classList.add("fadeOut");

                overlayRef.current.classList.remove('showOverlayDesktopHeight')
                overlayRef.current.classList.add('hideOverlayDesktopHeight');
            }

            else {
                overlayRef.current.classList.remove('showOverlayMobileHeight')
                overlayRef.current.classList.add('hideOverlayMobileHeight');
            }

            scrollOverlayToTop();

            showOverlayRef.current = false;
        } else {
            //set transition values to all 0.5s ease-in-out
            navigatorRef.current.style.transition = "all 0.5s ease-in-out";
            overlayRef.current.style.transition = "all 0.5s ease-in-out";

            navigatorRef.current.classList.remove(hideOverlayClass);
            navigatorRef.current.classList.add(showOverlayClass);

            overlayRef.current.classList.remove(hideOverlayClass);
            overlayRef.current.classList.add(showOverlayClass);

            if(wideView) {
                // overlayPaneInfoRef.current.classList.remove("fadeOut");
                // overlayPaneInfoRef.current.classList.add("fadeIn");

                overlayRef.current.classList.remove('hideOverlayDesktopHeight');
                overlayRef.current.classList.add('showOverlayDesktopHeight')
            }

            else {
                overlayRef.current.classList.remove('hideOverlayMobileHeight');
                overlayRef.current.classList.add('showOverlayMobileHeight')
            }

            scrollOverlayToTop();

            showOverlayRef.current = true;
        }
    }

    function hideCrosshair() {
        console.log('hiding crosshair');

        crosshairRef.current.classList.remove("hueAndPulse")
        crosshairRef.current.classList.remove("hueAnim");

        crosshairRef.current.classList.add("fadeOut");
    }

    async function incrementArtwork(forward) {        
        if(fullDisplayRef.current == "") {
            selectClosestPlane();
            return;
        }

        await new Promise(r => setTimeout(r, 500));

        if(fullDisplayRef.current == "") return;

        let index = curPlaneIndexRef.current;
        // console.log('old index: ' + index );

        let direction = forward ? 1 : -1;

        let nextIndex = index + direction;

        //wrap around 
        if(nextIndex < 0) {
            curPlaneIndexRef.current = artPlanesRef.current.length - 1;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        } 
        
        else if(nextIndex >= artPlanesRef.current.length) {
            curPlaneIndexRef.current = 0;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //? 
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }
        
        else {
            curPlaneIndexRef.current = nextIndex;
            fullDisplayRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            crosshairArtworkRef.current = artPlanesRef.current[curPlaneIndexRef.current].artName; //?
            switchArtInstantRef.current = false;
        }

        scrollOverlayToTop();

        // ridingWormRef.current = false;
        // setRidingWorm(false);

        if(artPlanesRef.current[curPlaneIndexRef.current].inTower) {
            shouldTeleportWormRef.current = true;
        }

        else {
            shouldTeleportWormRef.current = false;
        }

        // console.log('new index: ' + curPlaneIndexRef.current );
    }

    function startPaneDrag(e) {
        if(wideView) return;

        console.log('starting pane drag');

        paneIsDraggingRef.current = true;

    }

    function endPaneDrag() {
        if(wideView) return;

        paneIsDraggingRef.current = false;
    }

    function selectClosestPlane() {
        console.log('finding closest plane');

        let closestPlaneIndex = 0;

        let closestPlaneDist = Infinity;

        let camPos = cameraPosRef.current.clone();

        for(let i = 0; i < artPlanesRef.current.length; i++) {
            let plane = artPlanesRef.current[i].plane;
            let planeWorld = new THREE.Vector3();
            plane.getWorldPosition(planeWorld); 
            
            let dist = camPos.distanceTo(planeWorld);

            if(dist < closestPlaneDist) {
                closestPlaneDist = dist;
                closestPlaneIndex = i;
            }
        }

        curPlaneIndexRef.current = closestPlaneIndex;
        fullDisplayRef.current = artPlanesRef.current[closestPlaneIndex].artName;
        switchArtInstantRef.current = false;

        // ridingWormRef.current = false;
        // setRidingWorm(false);

        if(artPlanesRef.current[closestPlaneIndex].inTower) {
            shouldTeleportWormRef.current = true;
        }

        else {
            shouldTeleportWormRef.current = false;
        }

        // if(!wideView) hideCrosshair();
        if(isMobile) hideCrosshair();
    }

    function toggleAutoTour() {
        if(!autoTourPlayingRef.current) {

            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_pause.png";

            //remove PlayTour class from autoTourButtonRef and add PauseTour class
            autoTourButtonRef.current.classList.remove("PlayTour");
            autoTourButtonRef.current.classList.add("PauseTour");


            autoTourPlayingRef.current = true;
            // autoTourButtonRef.current.innerText = "■";

            if(fullDisplayRef.current == "") {
                //START WITH CLOSEST TO THE USER 

                //find closest plane to camera.position
                selectClosestPlane();
            }

            let showWorkTime = 8000;
            let camTransitionTime = 2000;
            autoTourStartTimeRef.current = Date.now();

            //run incrementArtwork every 2 seconds
            autoTourIntervalRef.current = setInterval(() => {
                incrementArtwork(true);
            }, showWorkTime + camTransitionTime);

            //set up an interval to map the time the work has been shown from a percent, then set the width of progressBarRef based on that
            progressBarIntervalRef.current = setInterval(() => { 
                let curTime = Date.now();
                let timeElapsed = (curTime - autoTourStartTimeRef.current) % (showWorkTime + camTransitionTime);

                if(timeElapsed < camTransitionTime) {
                    timeElapsed = 0;
                } else {
                    timeElapsed -= camTransitionTime;
                }

                let percent = timeElapsed / showWorkTime;

                if(percent >= 1) {
                    percent = 1;
                }

                progressBarRef.current.style.width = percent * 100 + "%";
            }, 25);

        } else {
            // autoTourButtonRef.current.src = process.env.PUBLIC_URL + "/textures/tour_play.png";

            //remove PauseTour class from autoTourButtonRef and add PlayTour class
            autoTourButtonRef.current.classList.remove("PauseTour");
            autoTourButtonRef.current.classList.add("PlayTour");

            autoTourPlayingRef.current = false;
            // autoTourButtonRef.current.innerText = "▷";

            progressBarRef.current.style.width = 0 + "%";

            //clear the interval
            clearInterval(autoTourIntervalRef.current);
            clearInterval(progressBarIntervalRef.current);
        }
    }

    function hideTutorial() {
        tutorialVisibleRef.current = false;
        tutorialRef.current.classList.add("fadeOut");

        setTimeout(() => {
            // tutorialRef.current.remove();
            //set display to none
            tutorialRef.current.style.display = "none";
            if(darkYecheEnabled) {
                //remove the video from the dom
                darkYecheVideoRef.current.remove();
            }
        }, 500);
    }

    function toggleArtistList() {
        if(tutorialVisibleRef.current) {
            hideTutorial();
        }

        //hide artist list
        if(showingArtistListRef.current) {
            if(isMobile) crosshairRef.current.style.display = "block";

            artistListRef.current.style.display = "none";
            showingArtistListRef.current = false;

            //change inner html of artist list toggle button to "Artists<br/>Index"
            artistListToggleRef.current.innerHTML = "Artist<br/>Index";

            //show overlayRef and navigatorRef
            navigatorRef.current.style.display = "flex";
            overlayRef.current.style.display = "flex";

            //show joystick and jump button
            if(isMobile) {
                joystickRef.current.style.display = "block";
                jumpRef.current.style.display = "block";
            }

            //fade out showBidsOverlayRef
            showBidsOverlayRef.current.classList.remove("fadeIn");
            showBidsOverlayRef.current.classList.add("fadeOut");

            //hide in 500ms
            setTimeout(() => {
                showBidsOverlayRef.current.style.display = "none";
            } , 500);
        }

        //show artist list
        else {
            if(isMobile) crosshairRef.current.style.display = "none";

            artistListRef.current.style.display = "flex";
            showingArtistListRef.current = true;

            //change inner text of artist list toggle button
            artistListToggleRef.current.innerHTML = "Back";

            //hide overlayRef and navigatorRef
            navigatorRef.current.style.display = "none";
            overlayRef.current.style.display = "none";

            //hide joystick and jump button
            if(isMobile) {
                joystickRef.current.style.display = "none";
                jumpRef.current.style.display = "none";
            }

            //fade in showBidsOverlayRef
            showBidsOverlayRef.current.style.display = "flex";
            showBidsOverlayRef.current.classList.remove("fadeOut");
            showBidsOverlayRef.current.classList.add("fadeIn");
        }
    }

    async function attemptRefreshWorks(tokenID, totalSupply) {
        let shouldRequest = false;

        //check if historicalAuctionDataRef.current[tokenID] is defined
        if(workDataRef.current[tokenID] != undefined) {
            //check if curTimestamp - historicalAuctionDataRef.current[tokenID].fetchedDataAt > 60
            let curTimestamp = Math.floor(Date.now() / 1000);
            let timeSinceLastFetch = curTimestamp - workDataRef.current[tokenID].fetchedDataAt;

            let tooRecent = timeSinceLastFetch < 30;

            if(!tooRecent) {
                shouldRequest = true;
            }
        }

        else {
            //fetch data for the first time
            shouldRequest = true;
        }

        if(shouldRequest) {
            if(infuraProviderRef.current == null) {
                console.log('infura is null')
                return;
            }

            const contractTemp = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, infuraProviderRef.current);

            // //check if current token exists by seeing if the tokenID is less than the totalSupply
            if(tokenID >= totalSupply) {
                // console.log('tokenID is greater than totalSupply');
                return;
            }

            var currentOwner = await contractTemp.ownerOf(tokenID);
            var timeMintedAt = await contractTemp.mintedAt(tokenID);

            //get current timestamp in seconds
            const curTimestamp = Math.floor(Date.now() / 1000);

            // let ownerName = await infuraProviderRef.current.lookupAddress(currentOwner);

            // if(ownerName != null) {`
            //     currentOwner = ownerName;
            // }

            workDataRef.current[tokenID] = {
                currentOwner: currentOwner,
                fetchedDataAt: curTimestamp,
                mintedAt: timeMintedAt,
            };
        }

        else {
            console.log('requested too recently, not making infura request..');
        }
    }

    //TODO call this whenever art changes to change thumbnail
    async function updatePaneInfo() {

        let curArtName = showOverlayRef.current ? fullDisplayRef.current : curArtworkRef.current;

        //get index of curArtworkRef.current in artPlanesRef.current
        let curPlaneIndex = artPlanesRef.current.findIndex(artPlane => artPlane.artName == curArtName);

        let curArt = artPlanesRef.current[curPlaneIndex];

        if(curArt == undefined) return;


        if(workDataRef.current[curArt.tokenID] == undefined) {
            let fileURL = process.env.PUBLIC_URL + '/textures/questionmark.png';
            artThumbnailRef.current.src = fileURL;

            arweaveLinkRef.current.style.display = "none";
        }

        else {
            let fileURL = process.env.PUBLIC_URL + artworkPath + curArt.artName + '.jpg';

            // console.log('loading image ' + fileURL);
    
            artThumbnailRef.current.src = fileURL;
            /* show art thumbnail */
            artThumbnailRef.current.style.display = "block";
    
            /* hide video thumbnail */
            // videoThumbnailRef.current.style.display = "none";
    
            //show arweave link
            arweaveLinkRef.current.style.display = "block";
            let arweaveLink = arweaveBaseURLImg + curArt.ogFilename;
            arweaveLinkRef.current.href = arweaveLink;    
        }

        // arweaveLinkRef.current.innerHTML = "View full resolution on Arweave";
        // arweaveLinkRef.current.onclick = () => true;

    }

    function toggleArrow(shouldToggleOverlay) {
        if(tutorialVisibleRef.current) {
            hideTutorial();
        }

        if(shouldToggleOverlay) toggleOverlay();

        if(showOverlayRef.current) {
            //add cross class to arrowRef
            arrowIconRef.current.classList.add("Cross");
            //remove arrow class from arrowRef
            arrowIconRef.current.classList.remove("Arrow");
        }
        
        else {
            //add arrow class to arrowRef
            arrowIconRef.current.classList.add("Arrow");
            //remove cross class from arrowRef
            arrowIconRef.current.classList.remove("Cross");
        }
    }

    //todo - make this work for mobile
    function toggleMute() {
        console.log('trying to unmute');
        videoElemsRef.current.forEach(video => {
            console.log('unmuting');
            video.muted = !video.muted;
            console.log('setting video ' + video.src + ' to ' + video.muted);
        });

        videosMutedRef.current = !videosMutedRef.current;

        //set inner text of mute button
        // muteButtonRef.current.innerText = videosMutedRef.current ? "🔇" : "🔈";
        //if muted, change image of mute button to process.env.PUBLIC_URL + "/textures/soundoff.png" otherwise process.env.PUBLIC_URL + "/textures/soundon.png"

        // videosMutedRef.current ? muteIconRef.current.src = process.env.PUBLIC_URL + "/textures/soundoff.png" : muteIconRef.current.src = process.env.PUBLIC_URL + "/textures/soundon.png";

        if(videosMutedRef.current) {
            //add SoundOff class to muteIconRef and remove SoundOn class
            muteIconRef.current.classList.add("SoundOff");
            muteIconRef.current.classList.remove("SoundOn");
        }

        else {
            muteIconRef.current.classList.remove("SoundOff");
            muteIconRef.current.classList.add("SoundOn");
        }

        //TODO TOGGLE MUTE OF ALL ENV AUDIO 
        if(isMobile && oceanWindAudioRef.current) {
            oceanWindAudioRef.current.muted = videosMutedRef.current;

            //play wind audio if not playing
            if(!oceanWindAudioRef.current.isPlaying) {
                oceanWindAudioRef.current.play();
            }
        }

        else if(!isMobile && oceanAudioRef.current && windAudioRef.current) {
            oceanAudioRef.current.muted = videosMutedRef.current;
            windAudioRef.current.muted = videosMutedRef.current;

            //play wind audio if not playing
            if(!oceanAudioRef.current.isPlaying) {
                oceanAudioRef.current.play();
            }

            if(!windAudioRef.current.isPlaying) {
                windAudioRef.current.play();
            }
        }
    }

    async function mint(quantity) {

        if(!connected) {
            alert("Please connect your wallet to mint");
            return;
        }

        const signer = ethersProvider.getSigner();
        const wanderlustGirlsContract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

        const whitelistOnly = await wanderlustGirlsContract.whitelistOnly();

        const userAddress = await ethersProvider.getSigner().getAddress();


        if(whitelistOnly) {
            const whitelisted = await wanderlustGirlsContract.checkWhitelisted(userAddress);

            if(!whitelisted) {
                alert("You must be whitelisted to mint now, please check back later for the public mint");
                return;
            }
        }

        const price = await wanderlustGirlsContract.checkPrice(quantity, userAddress);

        console.log('price for ' + quantity + ' babies is ' + price);

        let tx = await wanderlustGirlsContract.mint(quantity, {
            value: price
        });

        await tx.wait();

        numUserHatsRef.current += quantity;
    }

    //todo 
    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();

        userAddressRef.current = userAddress;

        //GET INITIAL NUMBER OF HATS
        let contractTemp = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, ethersProvider);
        let numMinted = await contractTemp.balanceOf(userAddress);
        console.log('num minted: ' + numMinted);
        numUserHatsRef.current = numMinted.toNumber();

        numUserHatsRef.current += historicalAuctionDataRef.current[userAddress];

        const name = await ethersProvider.lookupAddress(userAddress);
            
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        if(name) {
          setHumanAddress(name);
          userENSRef.current = name;
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }


    function toggleAvatarVisible() {
        avatarVisibleRef.current = !avatarVisibleRef.current;
        setAvatarVisible(avatarVisibleRef.current);
    }

    //todo 
    function sendChatMessage() {
        //clear input field
        messageRef.current = messageInputRef.current.value;
        messageInputRef.current.value = "";
    }

    function handleMessageKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        
        if(e.key === "Backspace") {
            //deleted all selected text
            if(messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }

            //deleted last character
            else {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.value.length - 1);
            }
        }

        //if key is left arrow, move cursor left
        else if(e.key === "ArrowLeft") {
            //make sure new selectionStart is not negative
            if(messageInputRef.current.selectionStart > 0) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart - 1;
            }
        }

        //if key is right arrow, move cursor right
        else if(e.key === "ArrowRight") {
            //make sure new selectionStart is not negative
            if(messageInputRef.current.selectionStart < messageInputRef.current.value.length) {
                messageInputRef.current.selectionStart = messageInputRef.current.selectionStart + 1;
            }
        }
  
        if(e.key === "Enter") {
            sendChatMessage();
        }

        else if(e.key.length === 1 && messageInputRef.current.value.length < MESSAGE_CHAR_LIMIT) {

            //delete any selected text
            if(messageInputRef.current.selectionStart !== messageInputRef.current.selectionEnd) {
                messageInputRef.current.value = messageInputRef.current.value.substring(0, messageInputRef.current.selectionStart) + messageInputRef.current.value.substring(messageInputRef.current.selectionEnd);
            }


            messageInputRef.current.value += e.key;
        }
    }

    function handleAvatarSwitchKeydown(e) {
        //check if the user pressed the space bar
        if(e.key === " ") {
            e.preventDefault();
        }
    }

    return(
        <>

        {/* <button className={isMobile ? "Skip-intro Skip-intro-mobile" : "Skip-intro Skip-intro-desktop"} ref={skipIntroRef} onClick={skipIntro}>Skip Intro</button> */}

        {!isMobile && <audio ref={windAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/wind1-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}
        {!isMobile && <audio ref={oceanAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/ocean-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}

        {isMobile && <audio ref={oceanWindAudioRef} src={process.env.PUBLIC_URL + '/audio/lunar/oceanwind-converted.mp3'} loop={true} autoPlay={false} volume={0.1} muted={true}/>}

        {/* <button className={isMobile ? "Artist-list-toggle Artist-list-toggle-mobile" :  "Artist-list-toggle Artist-list-toggle-desktop"} ref={artistListToggleRef} onClick={toggleArtistList}>Artist<br/>Index</button> */}

        {/* <div className={isMobile ? "switchContainer switchContainer-mobile" : "switchContainer switchContainer-desktop"} ref={showBidsOverlayRef}>
            <Switch 
            className="showBidsSwitch"
            onChange={toggleShowWorkGrid} 
            checked={showBidsOverlay} 
            checkedIcon={false}
            uncheckedIcon={false}
            />
            <div className="switchLabel">Show Bids</div>
        </div> */}

        {/* <div className="Artist-list" ref={artistListRef}>
            {wideView && <div className="Artist-list-inner-desktop" ref={artistListInnerRef} />}

            {!wideView && <div className="Artist-list-inner-mobile" ref={artistListInnerRef} />}
        </div> */}

        {wideView && 
        <div className="Artist-list-desktop-container" ref={artistListRef}>
            <div className="Desktop-blur-overlay"/>
            <div className="Artist-list-desktop">
                <div className="Artist-list-inner-desktop" ref={artistListInnerRef} />
            </div>
        </div>}

        {!wideView &&
        <div className="Artist-list-mobile-container" ref={artistListRef}>
            <div className="Mobile-blur-overlay"/>
            <div className="Artist-list-mobile">
                <div className="Artist-list-inner-mobile" ref={artistListInnerRef} />
            </div>
        </div>}

        {connected && <div className="Address-pill-wanderlust">{humanAddress}</div>}
        {!connected && <button className={isMobile ? "Connect-button-wanderlust Connect-button-wanderlust-mobile" : "Connect-button-wanderlust Connect-button-wanderlust-desktop"} onClick={() => connectWallet()}>{connectText}</button>}

        <div className="Crossfade-overlay" ref={crossfadeOverlayRef}/>


        <div className={!wideView ? "WorkNavigatorMobile" : "WorkNavigatorDesktop"} ref={navigatorRef}
            // onPointerMove={handlePaneDrag}
            onPointerDown={(e) => startPaneDrag(e)}
            onPointerUp={endPaneDrag}
        >

            <div className={"AvatarController"}>

                <div className={"MessageBar"}>
                    <input ref={messageInputRef} className={"MessageBox"} type="text" name="messagebox" placeholder="Send a message to Gigi... 🌪️" onKeyDown={handleMessageKeyDown}/>
                    <button className={"SendMessageButton"} onClick={sendChatMessage}/>
                </div>

                <Switch
                    onColor={'#888'}
                    onChange={toggleAvatarVisible} 
                    checked={avatarVisible}        
                    checkedIcon={false}
                    uncheckedIcon={false}             
                    className={"AvatarSwitch"}
                    onKeyDown={handleAvatarSwitchKeydown}

                    uncheckedHandleIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 20,
                            animation: "glow-switch 8s infinite",
                            borderRadius: "50%"
                          }}
                        />
                    }
                      checkedHandleIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            color: "red",
                            fontSize: 18,
                            animation: "glow-switch 8s infinite",
                            borderRadius: "50%"
                          }}
                        />
                    }
                />
            </div>

            <div className={"NavigatorButtonRow"}>
                <div className="WorkNavigatorProgressBar" ref={progressBarRef}/>


                <div className="WorkNavigatorButtonPrev" onClick={() => { incrementArtwork(false) }} />

                <div className="WorkNavigatorButtonPlay PlayTour" onClick={toggleAutoTour} ref={autoTourButtonRef} />

                <div className="WorkNavigatorButtonNext" onClick={() => { incrementArtwork(true) }} />

                <div className="WorkNavigatorButtonMute SoundOff" onClick={toggleMute} ref={muteIconRef} />

                <div className="WorkNavigatorButtonArrow Arrow" onClick={() => toggleArrow(true)} ref={arrowIconRef} />
            </div>

        </div>
        <div className={!wideView ? "OverlayMobile" : "OverlayDesktop"} ref={overlayRef}>
            <div className={!isMobile ? "OverlayInnerDesktop" : "OverlayInnerMobile"}>
                <div className="OverlayPaneWanderlust">

                    <div className="OverlayPaneItem-wanderlust">
                        <div className="OverlayPaneArtHeaderWanderlust" ref={artHeaderRef}>
                            <div className='ArtHeaderTitleNameWanderlust'>
                                Wanderlust Girls<br/>
                                Wretched Worm and Jared Madere
                            </div>
                        </div>
                    </div>

                    <div className="OverlayPaneItem-wanderlust">
                        <div className='WanderlustMintButtons'>
                            <button className="WanderlustMintButton" onClick={() => {mint(1) }}>Mint 1</button>
                            <button className="WanderlustMintButton" onClick={() => {mint(5) }}>Mint 5</button>
                            <button className="WanderlustMintButton" onClick={() => {mint(10) }}>Mint 10</button>
                            <button className="WanderlustMintButton" onClick={() => {mint(15) }}>Mint 15</button>
                        </div>
                    </div>


                    <div className={!wideView ? "OverlayPaneInfoMobileWanderlust" : "OverlayPaneInfoDesktopWanderlust"} ref={overlayPaneInfoRef}>

                        {/* <div className="SeparatorLine" /> */}

                        <div className="OverlayPaneItem-wanderlust">
                            <div className="ArtThumbnailContainer">
                                <img className="ArtThumbnailImg" ref={artThumbnailRef} src={process.env.PUBLIC_URL + "/textures/wanderlust_artwork/wanderlust1.jpg"}/>
                                {/* <video className="ArtVideoThumbnail" ref={videoThumbnailRef} muted playsInline loop controls preload='false' /> */}

                                <a href="www.google.com" ref={arweaveLinkRef} target="_blank">View full resolution on Arweave</a>
                            </div>
                        </div>

                        <div className="OverlayPaneItem-wanderlust">
                            <div className="WanderlustGirlsPriceGridYechelles">
                                <div>Mint 1 = 0.0333E</div>
                                <div>Mint 5 = 5% off</div>
                                <div>Mint 10 = 10% off</div>
                                <div>Mint 15 = 15% off</div>
                            </div>
                        </div>

                        <div className="OverlayPaneItem-wanderlust">
                            <div className="ContractInfo">
                                <div className="Etherscan-link">
                                    <a href="https://etherscan.io/address/0x05c96b7db340027b07d4d234fb12f45a51f444cf" target="_blank">
                                        <img src={require('../images/etherscan_black.png')} />
                                    </a>
                                </div>
                                <div className="Opensea-link">
                                    <a href="https://opensea.io/collection/wanderlustgirls" target="_blank">
                                        <img src={require('../images/opensea_black.png')} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="OverlayPaneItem-wanderlust" ref={artDescriptionRef}>

                        Greetings, weary traveler. This is what you’ve been looking for. You stand now on the precipice of a new world, one of exploration, beauty beyond description, and boundless freedom. We, the Wanderlust Girls, extend our hands to you, offering you a chance to ride alongside us on a journey like no other.<br/><br/>

                        Our sisterhood is woven from the threads of adventure, and we traverse the winding roads of life as a united force, leaving a trail of discovery and transformation in our wake. We are seekers of the unknown, purveyors of enlightenment, and emissaries of the open road. We know that of all the stories, in all the world, the best are found on a public ledger<br/><br/>

                        There are 333 of us, each holding a unique collection of up to 9 coveted memento tokens, in a series of passbooks blessed by the divine patina of travel. We had to put it all on chain forever sealed glistening in its own perfection—We had to collect ourselves, so you could collect us too.<br/><br/>

                        Whatever you mint becomes a part of you somehow. Crypto fills your pockets, but NFTs fill your soul.<br/><br/>

                        Wanderlust Girls is an NFT collection by <a href="https://twitter.com/wretched_worm" target='_blank'>Wretched Worm</a> and <a href="https://en.wikipedia.org/wiki/Jared_Madere" target='_blank'>Jared Madere</a><br/><br/>
                        </div> 

                        <div className="OverlayPaneItem-wanderlust">
                            {/* <div className="BidHistoryHeader">Bid History</div>
                            <div className="BidHistoryList" ref={bidHistoryRef}/> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

import React from 'react';
import { useEffect, useState } from 'react';
import './Splash.css';
import './About.css';
import { NavLink } from 'react-router-dom';

function About(props) {

    useEffect(() => {
        document.title = props.title;
    }, []);    
        
    return(
        <>
            <div className="bg-splash"></div>

            <div className="about-container">
                <div className="about-statement">
                Yeche Lange is a New Gallery dedicated to putting Art on the internet for the first time. This means presenting both virtual and physical exhibitions, supporting a programme for critical discourse, developing infrastructure to foreground and preserve the most radical culture from the worlds of onchain & contemporary art. Where most onchain projects focus on one off collections, Yeche Lange remains committed to presenting the full breadth of our artists' work across a lifetime and beyond.<br /><br />

                Yeche Lange continues to shape the conventions set onchain, with innovations in mint experience, work installation and digital display cabinets for our collectors, free mint subscriptions by gallery artists, physical art tie-ins, responsive virtual exhibition environments and more.<br /><br />
                
                The gallery team is curators <a href="https://flash---art.com/2020/03/jared-madere-in-the-back-of-the-restaurant-i-made-him-kiss-the-ring-haunted-house-in-the-key-of-new-years-paths-to-g-ddess-tiny-dick-timmy-ricochet-live-from-the-geomancers-clit-ring-you/">Milo Conroy</a>, <a href="https://en.m.wikipedia.org/wiki/Jared_Madere">Jared Madere</a>, <a href="https://twitter.com/wretched_worm">Wretched Wurm</a>, editor <a href="https://theaphora.biz/">Anastasios Karnazes</a>, and developer <a href="https://milespeyton.earth">Miles Peyton</a>. In 2013 artist Jared Madere opened <a href="https://www.forbes.com/sites/adamlehrer/2015/10/15/artist-and-bed-stuy-love-affair-founder-jared-madere-opens-whitney-museum-installation/amp/">Bed Stuy Love Affair</a> in New York where they met Milo Conroy and began to hatch their second gallery, <a href="https://flash---art.com/2018/01/jake-cruzen-jared-madere/">Mother Culture</a> which staged shows in Los Angeles, Berlin, Istanbul, and Himachal Pradesh. In 2021 Conroy and Madere began building Galerie Yeche Lange—they were soon joined by veteran poaster Wretched Wurm, artist & dev Miles Peyton, and writer & pundit Anastasios Karnazes.<br /><br />

                We are located in New York, Los Angeles, Berlin, the internet, and heaven on earth.<br/><br/>

                Galerie Yeche Lange will open its NYC gallery at 11 Broadway May 2024.
                </div>
            </div>
        </>
    );
}

export default About;
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { SceneContext } from "./Lunar.js";
import { useControls } from 'leva';
import { clamp, lerp } from 'three/src/math/MathUtils.js';
import { mapLinear } from 'three/src/math/MathUtils.js';

export default function Worm({roomScale, roomPos}) {

    const wormGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/worm.glb');

    const [waypoints, setWaypoints] = useState([]);

    const [wormNode, setWormNode] = useState(null);
    const [wormMeshNode, setWormMeshNode] = useState(null);
    const [wormHoodieNode, setWormHoodieNode] = useState(null);

    const [animMixer, setAnimMixer] = useState(null);

    const { camera } = useThree();

    const wormStartPos = new THREE.Vector3(0.0, 0.0, 0.0);
    const wormStartRot = new THREE.Quaternion();

    const wormSceneRef = useRef(null);

    // const waypointPctRef = useRef(0.0);

    const targetQuat = new THREE.Quaternion();
    const dummy = new THREE.Object3D();

    const closestWaypointIndexRef = useRef(0);
    const frameCount = useRef(0);

    const wormIsEvil = useRef(false);

    //do not change for now 
    const wormScale = 0.5; //WAS 0.5

    const { 
        wormRef, 
        wormDirRef, 
        wormSpeedRef,
        ridingWormRef, 
        fullDisplayRef,
        waypointPctRef,
        shouldTeleportWormRef,
        darkYecheEnabled
    } = useContext(SceneContext);

    const { yRotOffset } = useControls('worm', {
        yRotOffset: {
            value: 0.0,
            min: -180.0,
            max: 180.0,
            step: 0.1
        }
    });

    useEffect(() => {
        wormIsEvil.current = darkYecheEnabled;

        if(wormGLB.nodes != null) {
            let mixer = new THREE.AnimationMixer(wormGLB.scene);
            wormGLB.animations.forEach(clip => {
                if(clip.name.includes("worm")) {
                    const action = mixer.clipAction(clip)
                    action.play();
                }
            });
            setAnimMixer(mixer);

            let waypoints = [];
            let roomPosUnscaled = new THREE.Vector3(roomPos.x / roomScale, roomPos.y / roomScale, roomPos.z / roomScale);

            for (const [key, value] of Object.entries(wormGLB.nodes)) {
                if(key.startsWith('waypoint')) {
                    let curPos = value.position;    
                    value.position.set(curPos.x - roomPosUnscaled.x, curPos.y - roomPosUnscaled.y, curPos.z - roomPosUnscaled.z)
                    waypoints.push(value);
                    value.visible = false;
                }

                else if(key.startsWith('worm_mesh')) {
                    value.frustumCulled = false;
                    
                    value.material = new THREE.MeshPhysicalMaterial({
                        map: value.material.map,
                        roughness: 0.0,
                        clearcoat: 1.0,
                        envMapIntensity: 0.5,
                        metalness: 0.0,
                        clearcoatRoughness: 0.0,
                    });

                    //if dark yeche is enabled, set worm color to purple
                    if(wormIsEvil.current) {
                        value.material.color = new THREE.Color(0x6f00ff);
                    }

                    setWormMeshNode(value);
                    // console.log(value);
                }
                else if(key.startsWith('hoodie')) {
                    value.frustumCulled = false;

                    setWormHoodieNode(value);

                    if(!wormIsEvil.current) value.visible = false;
                }
                
                else if(key.startsWith('worm_skinned')) {
                    let curPos = value.position;    
                    value.position.set(curPos.x - roomPosUnscaled.x, curPos.y - roomPosUnscaled.y, curPos.z - roomPosUnscaled.z)
                    
                    value.scale.set(wormScale, wormScale, wormScale);
                    setWormNode(value);
                    wormRef.current = value;

                    wormStartPos.copy(value.position);
                    wormStartRot.copy(value.quaternion);
                }

            }

            //sort by last 3 digits of waypoint[i].name, parse as int
            waypoints.sort((a, b) => {
                return parseInt(a.name.slice(-3)) - parseInt(b.name.slice(-3));
            });

            setWaypoints(waypoints);
        }

    }, [wormGLB]);

    useEffect(() => {
        //TODO SHOW HOOD + TURN PURPEL 
        if(!wormIsEvil.current && darkYecheEnabled) {
            //turn worm mesh purple
            wormMeshNode.material.color = new THREE.Color(0x6f00ff);

            //show worm hoodie
            wormHoodieNode.visible = true;

            wormIsEvil.current = true;

            console.log("WORM IS EVIL NOW")
        }
    }, [darkYecheEnabled]);

    function updateWormMaterial() {
        if(fullDisplayRef.current != "" && wormMeshNode.material.opacity > 0.1) {
            wormMeshNode.material.transparent = true;
            wormMeshNode.material.opacity = 0.2;
            // wormMeshNode.depthWrite = false;
            // wormMeshNode.renderOrder = 100;

            if(wormHoodieNode.visible) {
                wormHoodieNode.material.transparent = true;
                wormHoodieNode.material.opacity = 0.2;
            }
        }

        else if(fullDisplayRef.current == "" && wormMeshNode.material.opacity < 1.0) {
            wormMeshNode.material.transparent = false;
            wormMeshNode.material.opacity = 1.0;
            // wormMeshNode.depthWrite = true;
            // wormMeshNode.renderOrder = 0;

            if(wormHoodieNode.visible) {
                wormHoodieNode.material.transparent = false;
                wormHoodieNode.material.opacity = 1.0;
            }
        }
    }

    useFrame((state, delta) => {
        frameCount.current++;

        if(animMixer != null) {
           
            let animSpeed = ridingWormRef.current ? 5.0 : 1.0;

            animMixer.update(delta * animSpeed);
            
            if(ridingWormRef.current) {
                updateWormMaterial();

                let direction = wormDirRef.current;

                //THIS BREAKS UTURN
                // if(wormSpeedRef.current == 0.0) return;

                //get current waypoint index from waypointPctRef
                let curWaypointIndex = Math.floor(waypoints.length * waypointPctRef.current);
                curWaypointIndex = clamp(curWaypointIndex, 0, waypoints.length - 1);

                //get next waypoint index
                let nextWaypointIndex = curWaypointIndex + direction;

                let prevWaypointIndex = curWaypointIndex - direction;

                if(nextWaypointIndex < 0 || nextWaypointIndex >= waypoints.length) {
                    nextWaypointIndex = curWaypointIndex;
                }

                if(prevWaypointIndex < 0 || prevWaypointIndex >= waypoints.length) {
                    prevWaypointIndex = curWaypointIndex;
                }

                //get current waypoint
                let curWaypoint = waypoints[curWaypointIndex].position;

                //get next waypoint
                let nextWaypoint = waypoints[nextWaypointIndex].position;

                // let lerpAmt = 0.0;

                // if(Math.abs(wormSpeedRef.current) > 0.0) {

                let lerpAmt = 0.0;
                    
                if(direction == 1) {
                    lerpAmt = waypointPctRef.current * waypoints.length - curWaypointIndex;
                }

                else {
                    lerpAmt = 1 - (waypointPctRef.current * waypoints.length - curWaypointIndex);
                }
                
                //interpolate between them based on waypointPctRef
                let targetPos = curWaypoint.clone().lerp(nextWaypoint, lerpAmt );
                // let targetPos = curWormPos.clone().lerp(nextWaypoint, lerpAmt );

                wormNode.position.set(targetPos.x, targetPos.y, targetPos.z);

                // let velocity = 0.1;
                // wormNode.position.add(targetPos.clone().sub(wormNode.position).multiplyScalar(velocity));    

                let prevWaypointWorld =  new THREE.Vector3();
                waypoints[prevWaypointIndex].getWorldPosition(prevWaypointWorld);

                //look at next waypoint
                if(direction != 0) {
                    let wormWorld = new THREE.Vector3();
                    wormNode.getWorldPosition(wormWorld);

                    dummy.position.copy(wormWorld);
                    dummy.lookAt(prevWaypointWorld);
                    targetQuat.copy(dummy.quaternion);
                }

                //lerp rotation to match target rotation
                if(targetQuat != null) {
                    wormNode.quaternion.slerp(targetQuat, 0.05);
                }

                //increment waypointPctRef
                waypointPctRef.current += 0.001 * direction * wormSpeedRef.current;
                //clamp waypointPctRef
                waypointPctRef.current = clamp(waypointPctRef.current, 0.0, 1.0);
            }

            //if not riding worm, teleport worm to closest waypoint to player
            else if(shouldTeleportWormRef.current) {
                wormMeshNode.material.transparent = true;
                wormMeshNode.material.opacity = 0.2;

                if(wormHoodieNode.visible) {
                    wormHoodieNode.material.transparent = true;
                    wormHoodieNode.material.opacity = 0.2;
                }    
    
                //find the index of the closest waypoint to the player, this is expensive don't do it every frame ***
                let wormGlobalPos = new THREE.Vector3();
                wormNode.getWorldPosition(wormGlobalPos);
                
                //todo don't check this every frame
                let distToWorm = camera.position.distanceTo(wormGlobalPos);

                // console.log('dist to worm: ' + distToWorm);
                
                if(distToWorm > 200.0) {
                    return;
                }

                let closestDist = 1000000.0;

                for(let i = 0; i < waypoints.length; i++) {
                    let waypointGlobalPos = new THREE.Vector3();
                    waypoints[i].getWorldPosition(waypointGlobalPos);
                    let dist = camera.position.distanceTo(waypointGlobalPos);

                    if(dist < closestDist) {
                        closestDist = dist;
                        closestWaypointIndexRef.current = i;
                    }
                }

                //set worm position to closest waypoint
                let closestWaypoint = waypoints[closestWaypointIndexRef.current];

                wormNode.position.lerp(closestWaypoint.position, 0.25);

                //get pct of closest waypoint out of all waypoints
                let targetPct = closestWaypointIndexRef.current / waypoints.length;
                waypointPctRef.current = targetPct;
            }
        }
    });



    return(
    <>
    {wormNode && waypoints.length > 0 &&
    // (<mesh geometry={wormNode.geometry} material={wormNode.material} position={wormNode.position} scale={[wormScale, wormScale, wormScale]}/>)}
    <primitive ref={wormSceneRef} object={wormGLB.scene} />
    }
    </>
    );
}
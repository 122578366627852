const assetVert = `
varying vec2 vUv;
void main() {
    vUv = uv;
    csm_Position = position;
}                        
`;

const assetFrag = `

uniform sampler2D map;
varying vec2 vUv;

void main() {
    //get distance of color to (0,0,1) green, and reduce alpha the closer it is to green (greenscreen)
    // vec2 vUvInverted = vec2(vUv.x, 1.0 - vUv.y);

    vec3 color = texture2D(map, vUv).rgb;
    float dist = distance(color, vec3(0.,1.,0.));
    float alpha = smoothstep(0.0, 1.0, dist);
    alpha = mix(alpha, alpha / 5.0, step(alpha, 0.8));
    csm_DiffuseColor = vec4(color * 1.2, alpha);
    if(alpha < 0.8) discard;
}
`;

export { assetVert, assetFrag };
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Select } from '@react-three/postprocessing';
import { SceneContext } from "./Wanderlust.js";

export default function SelectCrosshair({children, name}) {

    const selectEnabledRef = useRef(false);
    const [selectEnabled, setSelectEnabled] = useState(false);
    const { crosshairArtworkRef, workDataRef, artPlanesRef } = useContext(SceneContext);

    const tokenIDRef = useRef(-1);

    useFrame(() => {

        if(artPlanesRef.current.length > 0 && tokenIDRef.current == -1) {

            let planeIndex = artPlanesRef.current.findIndex((plane) => plane && plane.artName == name);

            let artworkPlane = artPlanesRef.current[planeIndex];

            if(artworkPlane) {
                tokenIDRef.current = artworkPlane.tokenID;
            }
        }

        else if(tokenIDRef.current != -1 && workDataRef.current[tokenIDRef.current] == undefined) {
            return;
        }

        if(crosshairArtworkRef.current == name && !selectEnabledRef.current) {
            setSelectEnabled(true);
            selectEnabledRef.current = true;
        }
        else if(crosshairArtworkRef.current != name && selectEnabledRef.current){
            setSelectEnabled(false);
            selectEnabledRef.current = false;
        }
    });


    return(
        <Select enabled={selectEnabled}>
            {children}
        </Select>
    )
}

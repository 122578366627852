

import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { MeshStandardMaterial } from 'three';
import { useControls } from 'leva';
import CustomShaderMaterial from 'three-custom-shader-material'
import { vert, frag } from './NoiseFog.js'

export const LilyMat = forwardRef((props, ref) => {

    const lilyTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/lilypadmap.jpg");
    const lilyNormal = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/lilypadnormal.jpg");
    const [texturesLoaded, setTexturesLoaded] = useState(false);

    // const matRef = useRef();

    const { roughness, metalness, noiseScale, fogColBase, noiseTimeScale } = useControls('lily mat', {
        roughness: {
            value: 0.4,
            min: 0.0,
            max: 1.0,
        },
        metalness: {
            value: 0.1,
            min: 0.0,
            max: 1.0,
        },
        noiseScale: { value: 2739.0, min: 0.0, max: 5000.0, step: 0.1 },
        noiseTimeScale: { value: 80.0, min: 0.0, max: 500.0, step: 0.1 },
        fogColBase: '#76c9e2',
    });

    // useFrame(({ clock }) => {
    //     if(matRef.current) {
    //         matRef.current.uniforms.time.value = clock.getElapsedTime() * noiseTimeScale;
    //         matRef.current.uniforms.noiseScaleBase.value = noiseScale;
    //         matRef.current.uniforms.fogColBase.value = new THREE.Color(fogColBase);
    //     }
    // });

    useEffect(() => {
        if(lilyTex && lilyNormal) {
            setTexturesLoaded(true);
        }
    }, [lilyTex, lilyNormal]);

    return(
        <>
        {texturesLoaded &&
        (

        <CustomShaderMaterial
        baseMaterial={THREE.MeshStandardMaterial}
        vertexShader={vert}
        fragmentShader={frag}
        // ref={matRef}
        ref={ref}

        // uniforms={{
        //     time: { value: 0 },
        //     noiseScaleBase: { value: noiseScale },
        //     fogColBase: { value: new THREE.Color(fogColBase) },
        //     useNoise: { value: true }
        // }}
        fog={false}
        map={lilyTex}
        normalMap={lilyNormal}
        normalMap-flipY={false}
        normalMap-encoding={THREE.LinearEncoding}    
        roughness={roughness}
        metalness={metalness}
        side={THREE.DoubleSide}
        />
        )}
        </>
    )
});
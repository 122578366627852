import React, { useEffect, useRef } from 'react';
import Message from './Message';
import './Tantrum.css';

function MessageList({ messages }) {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100); // Adjust delay time if necessary
        }
    };

    useEffect(scrollToBottom, [messages]);

    return (
        <div className="message-list">
            {messages.map((message, i) => (
                <Message key={i} {...message} />
            ))}
            <div ref={messagesEndRef} />
        </div>
    );
}

export default MessageList;

//todo make procedural sky + moon component
import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { Sky, Stars } from "@react-three/drei";
import NomeSkyDome from './NomeSkyDome.js';
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { lerp } from 'three/src/math/MathUtils';
import { useControls, Leva, folder } from 'leva';
import { MeshBasicMaterial } from 'three';

export default function NomeSky(props) {

    const starRef = useRef();

    const { starRadius, starDepth, starCount, starFactor, starSaturation, starFade } = useControls('sky', {
        starRadius: {
            value: 800,
            min: 100.0,
            max: 1000.0,
            step: 0.1
        },
        starDepth: {
            value: 72.4,
            min: 0.0,
            max: 300.0,
            step: 0.1
        },
        starCount: {
            value: 5000,
            min: 1000.0,
            max: 10000.0,
            step: 0.1
        },
        starFactor: {
            value: 60.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        },
        starSaturation: {
            value: 6.8,
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
        starFade: {
            value: 3.8, //was 6.9
            min: 0.0,
            max: 10.0,
            step: 0.1
        },
    });

    // const galaxyMap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/stars.jpg');

    useEffect(() => {
        if(starRef.current) {
            starRef.current.frustumCulled = false;
        }
    }, [starRef]);

    return(
        <>
         <NomeSkyDome/>

         {/* <Stars 
         ref={starRef}
         radius={starRadius} 
         depth={starDepth} 
         count={starCount} 
         factor={starFactor} 
         saturation={starSaturation} 
         fade
         speed={starFade} /> */}
        </>
    )
};


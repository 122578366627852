import React from 'react';
import { useEffect, useState, useRef } from 'react';
import './Splash.css';
import useCarousel from './useCarousel';
import { NavLink } from 'react-router-dom';

function Splash(props) {

    // const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, slidesToScroll: 1 }, [Autoplay({ delay: 2000, stopOnInteraction: false })]);

    const TickerItem = () => <div className="ticker__item">ART ON THE INTERNET FOR THE FIRST TIME</div>;

    const carouselInnerRef = useRef(null);
    const loadingBarRef = useRef(null);

    useCarousel(carouselInnerRef, loadingBarRef, 4000);

    const [index, setIndex] = useState(0);

    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <div className="homeContainer">
            <div className="bg-splash"></div>

            <div className="carouselContainer">
                <div className="carouselInner" ref={carouselInnerRef}>
                    <div className="carouselItem">
                        {/* <NavLink to="/swollen-hearts">
                        <img src={require('./images/nomemene_thumb.jpg')} alt="Tournament for Swollen Hearts" className={"carouselItemImg"}/><br />
                    </NavLink> */}
                        <NavLink to="/nomedegen">
                            <img src={require('./images/nomemene_thumb.jpg')} alt="Nomemene" className={"carouselItemImg"} /><br />
                            <div className="carouselItemText">Nomemene Solo Exhibition September 2023</div>
                        </NavLink>
                    </div>

                    <div className="carouselItem">
                        {/* <img src={require('./images/yechelles_banner.jpg')} alt="Yechelles" className={"carouselItemImg"}/><br/> */}

                        <NavLink to="/schooloftruth">
                            <video className="carouselItemImg" autoPlay playsInline loop muted>
                                <source src={require('./images/yechelles.mp4')} type="video/mp4" />
                            </video>
                            <div className="carouselItemText">School of Truth @ Galerie Yeche Lange</div>
                        </NavLink>
                    </div>

                    {/* <div className="carouselItem">
                    <a href="https://www.premint.xyz/soph-wl-raffle/" target="_blank">
                        <img src={require('./images/sophbanner.jpg')} alt="Soph" className={"carouselItemImg"}/><br />
                        <div className="carouselItemText">School of Truth @ Galerie Yeche Lange</div>
                    </a>
                </div> */}
                </div>
                <div className="loadingBarBackground">
                    <div className="loadingBar" ref={loadingBarRef}></div>
                </div>
            </div>

            <div className="ticker-wrap">
                <div className="ticker">
                    {/* {Array(24).fill().map((_, i) => <TickerItem key={i} />)} */}
                    <div className="ticker__item">网络上的艺术首次出现</div>
                    <div className="ticker__item">ART ON THE INTERNET FOR THE FIRST TIME</div>
                    <div className="ticker__item">ARTE EN INTERNET POR PRIMERA VEZ</div>
                    <div className="ticker__item">इंटरनेट पर कला पहली बार</div>
                    <div className="ticker__item">الفن على الإنترنت لأول مرة</div>
                    <div className="ticker__item">ইন্টারনেটে আর্ট প্রথম বার</div>
                    <div className="ticker__item">ARTE NA INTERNET PELA PRIMEIRA VEZ</div>
                    <div className="ticker__item">ИСКУССТВО В ИНТЕРНЕТЕ ВПЕРВЫЕ</div>
                    <div className="ticker__item">インターネットでのアート初めて</div>
                    <div className="ticker__item">ਇੰਟਰਨੈੱਟ ਤੇ ਆਰਟ ਪਹਿਲੀ ਵਾਰ</div>
                    <div className="ticker__item">网络上的艺术首次出现</div>
                    <div className="ticker__item">ART ON THE INTERNET FOR THE FIRST TIME</div>
                    <div className="ticker__item">ARTE EN INTERNET POR PRIMERA VEZ</div>
                    <div className="ticker__item">इंटरनेट पर कला पहली बार</div>
                    <div className="ticker__item">الفن على الإنترنت لأول مرة</div>
                    <div className="ticker__item">ইন্টারনেটে আর্ট প্রথম বার</div>
                    <div className="ticker__item">ARTE NA INTERNET PELA PRIMEIRA VEZ</div>
                    <div className="ticker__item">ИСКУССТВО В ИНТЕРНЕТЕ ВПЕРВЫЕ</div>
                    <div className="ticker__item">インターネットでのアート初めて</div>
                    <div className="ticker__item">ਇੰਟਰਨੈੱਟ ਤੇ ਆਰਟ ਪਹਿਲੀ ਵਾਰ</div>
                    <div className="ticker__item">网络上的艺术首次出现</div>
                    <div className="ticker__item">ART ON THE INTERNET FOR THE FIRST TIME</div>
                    <div className="ticker__item">ARTE EN INTERNET POR PRIMERA VEZ</div>
                    <div className="ticker__item">इंटरनेट पर कला पहली बार</div>
                    <div className="ticker__item">الفن على الإنترنت لأول مرة</div>
                    <div className="ticker__item">ইন্টারনেটে আর্ট প্রথম বার</div>
                    <div className="ticker__item">ARTE NA INTERNET PELA PRIMEIRA VEZ</div>
                    <div className="ticker__item">ИСКУССТВО В ИНТЕРНЕТЕ ВПЕРВЫЕ</div>
                    <div className="ticker__item">インターネットでのアート初めて</div>
                    <div className="ticker__item">ਇੰਟਰਨੈੱਟ ਤੇ ਆਰਟ ਪਹਿਲੀ ਵਾਰ</div>
                </div>
            </div>

            <div className="splashContent">
                <NavLink to="/nomemene" className="enterGallery">
                    <img src={process.env.PUBLIC_URL + "/textures/ENTER_GALLERY_2.png"} alt="enter gallery" className={"enterGallery"} />

                    {/* <div className="delayMessage">
                NOMEMENE MINT DELAYED UNTIL 11:59 am EST
               </div>  */}
                </NavLink>

                <div className="currentAndRecent">
                    <div className="sectionHeaders">
                        <div className="splashSectionHeader">
                            Now Showing
                        </div>
                        <div className="splashSectionHeader">
                            Recent
                        </div>
                    </div>

                    <div className="currentRecent">

                        {/* <div className="currentRecentSection">
                        <div className="currentRecentSectionInner">
                            <a href="https://obaa.life" target="_blank" className="nowShowingImg"></a>
                        </div>
                    </div>
                    */}

                        <div className="currentRecentSection">
                            <div className="currentRecentSectionInner">
                                {/* <a href="https://obaa.life" target="_blank" className="nowShowingImg"></a> */}
                                <NavLink to="/nomedegen">
                                    <img src={require('./images/grensplash.jpg')} alt="nome" className='nowShowingImg' />
                                </NavLink>
                            </div>
                        </div>


                        <div className="currentRecentSection">
                            <div className="currentRecentSectionInner">
                                <div className="recentMintItem">
                                    <a href="https://obaa.life" target="_blank">
                                        <img src={require('./images/obaa_banner_square.jpg')} alt="OBAA" className='recentMintImg' />
                                    </a>
                                </div>

                                <div className="recentMintItem">
                                    <NavLink to="/wanderlust">
                                        <img src={require('./images/wlg_splash.jpg')} alt="Wanderlust Girls" className='recentMintImg' />
                                    </NavLink>
                                </div>
                                {/* <div className="recentMintItem">
                                    <NavLink to="/schooloftruth">
                                        <img src={require('./images/splashscreen.jpg')} alt="School of Truth" className='recentMintImg' />
                                    </NavLink>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="archive">
                    <div className="sectionHeaders">
                        <div className="splashSectionHeader">
                            Archive
                        </div>
                    </div>

                    <div className="archiveGrid">
                        <div className="archiveItem">
                            <NavLink to="/schooloftruth">
                                <img src={require('./images/intro_logo.png')} alt="School of Truth" className='archiveImg' />
                            </NavLink>
                        </div>

                        <div className="archiveItem">
                            <NavLink to="/creation-babies">
                                <img src={require('./images/creation_splash.png')} alt="Creation Babies" className='archiveImg' />
                            </NavLink>
                        </div>

                        <div className="archiveItem">
                            <NavLink to="/yeche-cakes">
                                <img src={require('./images/cakes_splash.png')} alt="Yeche Cakes" className='archiveImg' />
                            </NavLink>
                        </div>

                        <div className="archiveItem">
                            <NavLink to="/yeche-pies">
                                <img src={require('./images/piekey.png')} alt="Pie Key" className='archiveImg' />
                            </NavLink>
                        </div>

                        <div className="archiveItem">
                            <NavLink to="/swollen-hearts">
                                <img src={require('./images/swollen_hearts.png')} alt="Swollen Hearts" className='archiveImg' />
                            </NavLink>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default Splash;
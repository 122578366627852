import React, { Suspense } from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { Water } from 'three/examples/jsm/objects/Water'
import { useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useControls, Leva, folder } from 'leva';
import FirstPersonControls from './FirstPersonControls';
import { SceneContext } from './Wanderlust';
import { usePerformanceMonitor } from '@react-three/drei';

import Ocean from './Ocean.js';
import Room from './Room';
import WanderlustSky from './WanderlustSky';
import { clamp } from 'three/src/math/MathUtils';
// import { isMobile } from 'react-device-detect';
import { useIsWideView } from './detectWideView';
import { isMobile } from 'react-device-detect';
import { MudMat } from "./MudMat.js";
import { SteelMat } from "./SteelMat.js";
import { LilyMat } from "./LilyMat.js";
// import Book from './Book.js';
import { sparkleFrag, sparkleVert } from './SparkleMaterialize.js';

export const MaterialContext = createContext();

export default function WanderlustScene() {

    const wideView = useIsWideView();

    const { waveSteepness, wavelengthA, wavelengthB, wavelengthC } = useControls('waves', {
        waveSteepness: {
            value: 0.1,
            min: 0.0,
            max: 50000.0,
            step: 0.005
        },
        wavelengthA: {
            value: 50.0,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
        wavelengthB: {
            value: 90.0,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
        wavelengthC: {
            value: 70,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
    });

    const { audioFadeFactor, windMaxY } = useControls('audio', {
        audioFadeFactor: {
            value: 30.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        },
        windMaxY: {
            value: 100.0,
            min: 0.0,
            max: 500.0,
            step: 0.1
        },
    });
 
    const waves = [
        { direction: 0, steepness: waveSteepness, wavelength:  wavelengthA },
        { direction: 30, steepness: waveSteepness, wavelength: wavelengthB },
        { direction: 60, steepness: waveSteepness, wavelength: wavelengthC },
    ];

    const collisionObjectsRef = useRef(null);

    
    // const towerGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/tower.glb');
    // const cubeRoomGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/cube_room.glb');
    
    const roomRefs = useRef([]);
    
    const { camera,gl } = useThree();
    
    const { 
        workDataRef,
        videoLoadedRef,
        videoElemsRef, 
        videoElemsPosRef, 
        sceneLoadedRef,
        cameraPosRef,
        containerRef,
        splashscreenRef,
        fullDisplayRef,
        artPlanesRef,
        videosMutedRef,
        oceanAudioRef,
        windAudioRef,
        fellInWaterRef,
        artworkPath,
        modelPath
    } = useContext(SceneContext);
    
    const hallGLB = useGLTF(process.env.PUBLIC_URL + modelPath + 'hall_room.glb');

    const glitterMap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/glitter6.jpg');
    
    const maxVol = 0.5;

    const frameRef = useRef(0);

    const [mudMat, setMudMat] = useState();
    const [lilyMat, setLilyMat] = useState();
    const [steelMat, setSteelMat] = useState();

    // function checkArtProximity() {
    //     //loop through all artPlanes and check if they are in proximity to player, if not and they are still visible, hide them
    //     artPlanesRef.current.forEach((artPlane, index) => {
    //         const artPlanePos = new THREE.Vector3();
    //         artPlane.plane.getWorldPosition(artPlanePos);

    //         const distance = camera.position.distanceTo(artPlanePos);

    //         //setting high because we want to load the textures at the beginning
    //         const visibleDist = 1000.0;

    //         if(distance > visibleDist && artPlane.plane.visible) {
    //             artPlane.plane.visible = false;
    //             // console.log('hiding art plane: ' + artPlane.artName);
    //         }

    //         else if(distance < visibleDist && !artPlane.plane.visible) {
    //             artPlane.plane.visible = true;


    //             if(!artPlane.loadedTex) {
    //                 let loader = new THREE.TextureLoader();

    //                 //try to load the image
    //                 loader.load(process.env.PUBLIC_URL + artworkPath + artPlane.artName + ".jpg", function (texture) {
    //                     texture.minFilter = THREE.NearestFilter;
    //                     texture.magFilter = THREE.NearestFilter;
    //                     artPlane.plane.material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });

    //                     // console.log('setting material on ' + plane.name + ' to ' + texture.image.src);
    //                     // console.log('loaded texture for ' + artPlane.artName);
    //                     //scale the plane to the texture
    //                     texture.flipY = true;
            
    //                     let aspect = texture.image.width / texture.image.height;
    //                     let baseScale = 1.0;
    //                     let planeScale = [baseScale * aspect, baseScale, baseScale];
                        
    //                     artPlane.plane.scale.set(planeScale[0], planeScale[1], planeScale[2]);
    //                 });    
    //             }

    //             // console.log('showing art plane: ' + artPlane.artName);
    //         }
    //     });
    // }

    function materializePlanes() {


        artPlanesRef.current.forEach((artPlane, index) => {
            let tokenID = artPlane.tokenID;

            if(workDataRef.current[tokenID] != undefined) {
                let plane = artPlane.plane;

                // console.log(plane.material.uniforms);

                // if(!plane.material.uniforms.materializeAmt) {
                //     console.log('materializeAmt uniform not found ' + plane.name);
                // }

                if(!artPlane.loadedTex) {

                    let loader = new THREE.TextureLoader();

                    //try to load the image
                    loader.load(process.env.PUBLIC_URL + artworkPath + artPlane.artName + ".jpg", function (texture) {
                        texture.minFilter = THREE.NearestFilter;
                        texture.magFilter = THREE.NearestFilter;

                        // artPlane.plane.material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });

                        plane.material = new THREE.ShaderMaterial({
                            vertexShader: sparkleVert,
                            fragmentShader: sparkleFrag,
                            transparent: true,
                            depthWrite: false,
                            depthTest: false,
                            uniforms: {
                                colorMap: { value: texture },
                                glitterMap: { value: glitterMap },
                                materializeAmt: { value: 0.0 },
                                planeIndex: { value: index },
                                time: { value: index * 0.1 },
                            }
                        });

                        plane.renderOrder = 300;

                        // console.log('setting material on ' + plane.name + ' to ' + texture.image.src);
                        // console.log('loaded texture for ' + artPlane.artName);
                        //scale the plane to the texture
                        texture.flipY = true;
            
                        let aspect = texture.image.width / texture.image.height;
                        let baseScale = 1.0;
                        let planeScale = [baseScale * aspect, baseScale, baseScale];
                        
                        artPlane.plane.scale.set(planeScale[0], planeScale[1], planeScale[2]);

                        plane.visible = true;
                    });    


                    artPlane.loadedTex = true;
                }


                else if(plane.material.uniforms && plane.material.uniforms.materializeAmt.value <= 1.0) {

                    let mintedAtUTC = workDataRef.current[tokenID].mintedAt * 1000; // Convert to milliseconds
                    let secondsSinceMinted = (Date.now() - mintedAtUTC) / 1000.0;
                    
                    // console.log('seconds since minted: ' + secondsSinceMinted);

                    //fully materialize the plane after 10 minutes
                    let materializeAmt = Math.min(1.0, secondsSinceMinted / 600.0);

                    plane.material.uniforms.materializeAmt.value = materializeAmt;
                    // plane.material.uniforms.materializeAmt.value = Math.min(1.0, plane.material.uniforms.materializeAmt.value + 0.0005);
                    plane.material.uniforms.time.value += 0.01; 
                }
            }

        });
    }

    function updateVideos(scene) {
        //update the volume of each video based on the distance from the camera, this doesn't work on mobile
        //also load the video if the user is in proximity
        videoElemsRef.current.forEach((videoElem, index) => {
            //get 'src' 
            const src = videoElem.src;
            //extract text after last slash before .
            const videoID = src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('.'));
            const sceneObjName = videoID;
            //find object in scene with name sceneObjName
            const vidSceneElem = scene.getObjectByName(sceneObjName);
            
            const vidWorldPos = new THREE.Vector3();
            vidSceneElem.getWorldPosition(vidWorldPos);

            const distance = camera.position.distanceTo(vidWorldPos);

            const loadDist = 500.0;
            const pauseDist = 800.0;

            // console.log('readystate ' + videoElem.readyState);

            let shouldPause = fullDisplayRef.current != "" && fullDisplayRef.current != sceneObjName;
            var isPlaying = videoElem.currentTime > 0 && !videoElem.paused && !videoElem.ended 
            && videoElem.readyState > videoElem.HAVE_CURRENT_DATA;

            //check if video has been loaded
            if(videoElem.readyState < 2 && !shouldPause) {
                if(distance < loadDist && videoLoadedRef.current[index] == false) {

                    // console.log('loading video: ' + sceneObjName);
                    videoElem.load();
                    //play video
                    videoElem.play();
                    videoLoadedRef.current[index] = true;
                }

                else {
                    return;
                }

            }

            //BAD CAUSES CRASH
            // else if(shouldPause) {
            //     if(videoLoadedRef.current[index] == true && isPlaying) {
            //         // console.log('pausing video: ' + sceneObjName);
            //         videoElem.pause();
            //     }
            // }

            // //if video has been loaded, check if it should be paused
            // else if(videoElem.readyState >= 2) {            

            //     if(distance < loadDist && videoLoadedRef.current[index] == true && !isPlaying) {
            //         // console.log('playing video: ' + sceneObjName);
            //         videoElem.play();
            //     }

            //     else if(distance > pauseDist && videoLoadedRef.current[index] == true && isPlaying) {
            //         // console.log('pausing video: ' + sceneObjName);
            //         videoElem.pause();
            //     }
            // }

            if(isMobile) {
                //unmute video if fullDisplayRef.current == sceneObjName and video is muted
                if(!videosMutedRef.current && fullDisplayRef.current == sceneObjName && videoElem.muted) {
                    videoElem.muted = false;
                }

                //mute video if fullDisplayRef.current != sceneObjName and video is not muted
                else if(fullDisplayRef.current != sceneObjName && !videoElem.muted) {
                    videoElem.muted = true;
                }
            }

            else {                
                if(videoElem.muted) {
                    return;
                }

                //fade audio by distance exponentially
                const distFactor = clamp(Math.pow(distance / audioFadeFactor, 2.0), 0.0, 1.0);

                const volume = (1.0 - distFactor) * maxVol;

                videoElem.volume = volume;
            }
        });
    }

    function updateEnvironmentAudio() {
        if(!isMobile && !videosMutedRef.current && oceanAudioRef.current && windAudioRef.current) {

            //interpolate volume smoothly based on camera z position 
            //when camera z == 0, oceanAudioRef.current.volume == 0.1 and windAudioRef.current.volume == 0.0
            //when camera z == 50, oceanAudioRef.current.volume == 0.0 and windAudioRef.current.volume == 0.1

            const y = camera.position.y;
            // console.log('cur cam y ' + y);

            const yMin = 0.0;
            const yMax = windMaxY;

            const yRange = yMax - yMin;

            const yNorm = (y - yMin) / yRange;

            const oceanVol = 0.1;
            const windVol = 0.1;

            const oceanVolNorm = 1.0 - yNorm;
            const windVolNorm = yNorm;

            let oceanVolFinal = oceanVolNorm * oceanVol;
            let windVolFinal = windVolNorm * windVol;

            //clamp volumes
            oceanVolFinal = clamp(oceanVolFinal, 0.0, 1.0);
            windVolFinal = clamp(windVolFinal, 0.0, 1.0);

            // console.log('ocean ' + oceanVolFinal + ' wind ' + windVolFinal);

            oceanAudioRef.current.volume = oceanVolFinal;
            windAudioRef.current.volume = windVolFinal;
        }

    }

    //TODO bring leva back in for initial values 
    function updateMaterials(clock) {
        if(mudMat) {

            if(Object.keys(mudMat.uniforms).length === 0) {
                mudMat.uniforms = {
                    time: { value: 0 },
                    noiseScaleBase: { value: 2739 },
                    fogColBase: { value: new THREE.Color('#e0e2c1') },
                    useNoise: { value: true }
                }
            }

            else {
                mudMat.uniforms.time.value = clock.getElapsedTime() * 80.0;
            }
        }

        if(steelMat) {
            if(Object.keys(steelMat.uniforms).length === 0) {
                // console.log('setting steelmat uniforms')
                steelMat.uniforms = {
                    time: { value: 0 },
                    noiseScaleBase: { value: 2739 },
                    fogColBase: { value: new THREE.Color('#ffabd7') },
                    useNoise: { value: true }
                }
            }

            else {
                steelMat.uniforms.time.value = clock.getElapsedTime() * 80.0;
            }

        }

        if(lilyMat) {

            if(Object.keys(lilyMat.uniforms).length === 0) {
                lilyMat.uniforms = {
                    time: { value: 0 },
                    noiseScaleBase: { value: 2739 },
                    fogColBase: { value: new THREE.Color('#76c9e2') },
                    useNoise: { value: true }
                }
            }

            else {
                lilyMat.uniforms.time.value = clock.getElapsedTime() * 80.0;
            }
        }



    }

    useFrame(({camera, scene, clock}) => {

        updateMaterials(clock);

        //print renderer.info
        if(frameRef.current == 50) {
            console.log(gl.info);
        }


        frameRef.current += 1;

        if(frameRef.current == 40) {
            console.log('loaded');
            containerRef.current.classList.add('fadeInSplash');
            splashscreenRef.current.classList.add('fadeOutSplash');
            sceneLoadedRef.current = true;
        }

        cameraPosRef.current = camera.position;

        if(frameRef.current % 5 == 0) {
            updateVideos(scene);
        }

        if(frameRef.current % 2 == 0) {
            // checkArtProximity();
            materializePlanes();
            updateEnvironmentAudio();
        }


        let distFromOrigin = camera.position.distanceTo(new THREE.Vector3(0, 0, 0));

        if(camera.position.y < 2.5 && distFromOrigin > 30.0 && !fellInWaterRef.current) {
            console.log('fell in water');
            fellInWaterRef.current = true;
        }


    });

    useEffect(() => {
        //setup sound todo

    }, []);

    return(
        <>
            <SteelMat tiling={10.0} ref={setSteelMat}/>
            <MudMat useNoise={true} ref={setMudMat}/>
            <LilyMat ref={setLilyMat}/>

            <MaterialContext.Provider value={
            {
            mudMat: mudMat,
            lilyMat: lilyMat,
            steelMat: steelMat
            }
            }>

                <FirstPersonControls ref={collisionObjectsRef}/>
                <group ref={collisionObjectsRef}>
                    <Room roomGLB={hallGLB} waves={waves} roomID={0} ref={roomRefs.current[0]}/>
                    {/* <Room roomGLB={cubeRoomGLB} waves={waves} roomID={1} ref={roomRefs.current[1]}/>
                    <Room roomGLB={towerGLB} waves={waves} roomID={2} ref={roomRefs.current[2]}/> */}
                </group>

                {/* <Book bookName={'arakawa'} waves={waves}/>
                <Book bookName={'dinosaurs'} waves={waves}/>
                <Book bookName={'logic'} waves={waves}/>
                <Book bookName={'faerie'} waves={waves}/> */}

                <Ocean waves={waves}/>  
                <WanderlustSky/>
            </MaterialContext.Provider>
        </>
    );
}
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { Box, Plane } from "@react-three/drei";
import { useGLTF, Stats, shaderMaterial, Environment, Text, Html, Billboard, AdaptiveDpr, OrbitControls, useVideoTexture, useTexture } from "@react-three/drei"
import { useControls, Leva, folder } from 'leva';
import {isMobile} from 'react-device-detect';
import { Joystick } from 'react-joystick-component';
import CameraControls from 'camera-controls';
import './Lunar.css';
import Multiplayer from './Multiplayer';
import LunarScene from './LunarScene';
import LunarPostProcessing from './LunarPostProcessing';
import LunarOverlay from './Overlay.js';
import { Selection } from '@react-three/postprocessing';
import ARTWORK_INFO from './artworkinfo.json';
import { Loader } from '@react-three/drei';
import { NoToneMapping } from 'three';
import { PerformanceMonitor } from '@react-three/drei';

export const SceneContext = createContext();
  
export default function Lunar(props) {

    const DARK_MODE_TIME = 1678058899;

    const [darkYecheEnabled, setDarkYecheEnabled] = useState(Date.now() > DARK_MODE_TIME * 1000);

    const darkYecheVideoRef = useRef(null);

    //MULTIPLAYER ON OR OFF
    const [multiplayerEnabled, setMultiplayerEnabled] = useState(true);
    const secretCount = useRef(0);


    const messageRef = useRef("");

    const cameraPosRef = useRef(new THREE.Vector3(0, 0, 0));

    const curColliderNameRef = useRef("");

    const wormRef = useRef(null);
    const wormDirRef = useRef(0);
    const wormSpeedRef = useRef(0.0);
    const ridingWormRef = useRef(false);

    const artworkInfoRef = useRef(ARTWORK_INFO.artworks);

    const waterTimescaleRef = useRef(0.5);

    const waypointPctRef = useRef(0.0);
    const shouldTeleportWormRef = useRef(false);

    const introCamPctRef = useRef(0.0);

    const crosshairRef = useRef(null);

    const crosshairArtworkRef = useRef("");
    const fullDisplayRef = useRef("");

    const fellInWaterRef = useRef(false);

    const videosMutedRef = useRef(true);

    const oceanAudioRef = useRef(null);
    const windAudioRef = useRef(null);
    const oceanWindAudioRef = useRef(null);

    const sceneLoadedRef = useRef(false);

    const tutorialVisibleRef = useRef(false);
    const tutorialRef = useRef(null);

    const controlsEnabledRef = useRef(false);

    const containerRef = useRef(null);
    const splashscreenRef = useRef(null);

    const joystickRef = useRef(null);
    const jumpRef = useRef(null);

    const listenerRef = useRef(null);
    const videoElemsRef = useRef([]);
    const videoLoadedRef = useRef([]);
    const videoElemsPosRef = useRef([]);

    const artPlanesRef = useRef([]);
    const curPlaneIndexRef = useRef(0);
    const visitedInTourRef = useRef([]);

    const switchArtInstantRef = useRef(false);

    const joystickXRef = useRef(0);
    const joystickYRef = useRef(0);

    const touchJumpRef = useRef(false);

    const lightRef = useRef(null);
    const ambientLightRef = useRef(null);

    const fogRef = useRef(null);

    const userAddressRef = useRef("");
    const userENSRef = useRef("");
    const numUserBidsRef = useRef(0);
    const avatarDataRef = useRef({});

    const avatarVisibleRef = useRef(false);

    // const windAudioRef = useRef(null);

    const sizes = 
    {
        rootWidth: "500px",
        controlWidth: "160px",
        scrubberWidth: "8px",
        scrubberHeight: "16px",
        rowHeight: "24px",
        folderHeight: "20px",
        checkboxSize: "16px",
        joystickWidth: "100px",
        joystickHeight: "100px",
        colorPickerWidth: "160px",
        colorPickerHeight: "100px",
        monitorHeight: "60px",
    };

    const levaTheme = {
        sizes
    }

    const {
        lightPosition, 
        lightIntensity, 
        lightColor,
        ambientLightIntensity,
        fogColor,
        fogNear,
        fogFar 
    } = useControls('lighting', {
        ambientLightIntensity: {
            value: 0.3,
            min: 0.0,
            max: 2.0,
            step: 0.1,
            onChange: (value) => {
                if(ambientLightRef.current) {
                    ambientLightRef.current.intensity = value;
                }
            }
        },
        lightPosition: {
            x: 10,
            y: 0,
            z: 20,
            onChange: (value) => {
                if(lightRef.current) {
                    lightRef.current.position.copy(value);
                }
            }
        },
        lightIntensity: {
            value: 2.0,
            min: 0.0,
            max: 2.0,
            step: 0.1,
            onChange: (value) => {
                if(lightRef.current) {
                    lightRef.current.intensity = value;
                }
            }
        },
        lightColor: {
            value: '#ffffff',
            onChange: (value) => {
                if(lightRef.current) {
                    lightRef.current.color.set(value);
                }
            }
        },
        fogColor: {
            value: '#76c9e2',
            onChange: (value) => {
                if(fogRef.current) {
                    fogRef.current.color.set(value);
                }
            }
        },
        fogNear: {
            value: 16,
            min: 0,
            max: 100,
            step: 1,
            onChange: (value) => {
                if(fogRef.current) {
                    fogRef.current.near = value;
                }
            }
        },
        fogFar: {
            value: 230,
            min: 0,
            max: 1000,
            step: 1,
            onChange: (value) => {
                if(fogRef.current) {
                    fogRef.current.far = value;
                }
            }
        }
    });

    function handleJoystickMove(event) {
        joystickXRef.current = event.x;
        joystickYRef.current = event.y;
    }  

    function handleJoystickStop(event) {
        joystickXRef.current = 0;
        joystickYRef.current = 0;
    }

    function handleJump(jump) {
        touchJumpRef.current = jump;            
    }

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if(event.key === '`') {
                secretCount.current++;

                if(secretCount.current >= 5) {
                    // setMultiplayerEnabled(!multiplayerEnabled);
                    setDarkYecheEnabled(!darkYecheEnabled);
                    secretCount.current = 0;
                }
            }
        });

        //check if darkYecheEnabled changed to true
        if(darkYecheEnabled && introCamPctRef.current >= 1.0) {
            console.log('dark yeche mode enabled');

            tutorialRef.current.classList.remove("fadeOut");
            // tutorialRef.current.classList.add("fadeIn");

            //set video autoplay to true
            darkYecheVideoRef.current.autoplay = true;

            //set tutorialRef to visible
            tutorialRef.current.style.display = "block";
            //set tutorialRef opacity to 1
            tutorialRef.current.style.opacity = 1.0;

            if(introCamPctRef.current >= 1.0) {
                //hide splash screen
                splashscreenRef.current.style.display = "none";
            }

            tutorialVisibleRef.current = true;
        }

    }, [darkYecheEnabled]);

    // useEffect(() => {
    //     document.addEventListener('keydown', (event) => {
    //         if(event.key === '`') {
    //             secretCount.current++;

    //             if(secretCount.current >= 5) {
    //                 // setMultiplayerEnabled(!multiplayerEnabled);
    //                 setUIEnabled(!uiEnabled);
    //                 secretCount.current = 0;
    //             }
    //         }
    //     });
    // }, [uiEnabled]);

    useEffect(() => {
        document.title = props.title;

        if(isMobile)  {
            document.addEventListener('touchstart', (event) => {
                if(darkYecheEnabled && tutorialVisibleRef.current) {
                    console.log('touchstart event detected');
                    hideTutorial();
                    
                    //remove this event listener
                    document.removeEventListener('touchstart', (event) => {});
                }
                else {
                    console.log('touchstart event detected but tutorial not visible');
                }
            });
        }
        else {
            //add an event listener for document click 
            document.addEventListener('click', (event) => {
                if(darkYecheEnabled && tutorialVisibleRef.current) {
                    hideTutorial();
                    //remove this event listener
                    document.removeEventListener('click', (event) => {});
                }
            });
        }

        //add an event listener for document touchstart

        //get current utc seconds
        const nowUTCSeconds = Math.floor(Date.now() / 1000);

        if(nowUTCSeconds < DARK_MODE_TIME) {
            //set darkYecheEnabled to true at DARK_MODE_TIME
            const timeToDarkMode = (DARK_MODE_TIME - nowUTCSeconds) * 1000;
            console.log('enabling yeche dark mode in ' + (timeToDarkMode / 1000) + ' seconds');

            setTimeout(() => {
                setDarkYecheEnabled(true);
            }, timeToDarkMode);
        }
    }, [])


    function hideTutorial() {
        tutorialVisibleRef.current = false;
        tutorialRef.current.classList.add("fadeOut");
        
        setTimeout(() => {
            tutorialRef.current.style.display = "none";

            if(darkYecheEnabled) {
                //remove the video from the dom
                darkYecheVideoRef.current.remove();
            }
        }, 500);

    }

    function attemptHideTutorial(e) {
        if(tutorialVisibleRef.current) {

            //check if darkYecheVideRef is playing
            if(darkYecheEnabled && darkYecheVideoRef.current) {
                //check if the video is paused
                // if(darkYecheVideoRef.current.paused) {
                //     return;
                // }
                //block other clicks
                e.stopPropagation();

                //ignore clicks for dark yeche mode
                return;
            }

            hideTutorial();
        }
    }

    return(
        <>
            <SceneContext.Provider value={ 
            {
                crosshairArtworkRef: crosshairArtworkRef,
                fullDisplayRef: fullDisplayRef,
                artPlanesRef: artPlanesRef,
                switchArtInstantRef: switchArtInstantRef,
                curPlaneIndexRef: curPlaneIndexRef,
                visitedInTourRef: visitedInTourRef,
                artworkInfoRef: artworkInfoRef,
                cameraPosRef: cameraPosRef,
                crosshairRef: crosshairRef,
                joystickRef: joystickRef,
                jumpRef: jumpRef,
                videoElemsRef: videoElemsRef,
                videosMutedRef: videosMutedRef,
                oceanAudioRef: oceanAudioRef,
                windAudioRef: windAudioRef,
                oceanWindAudioRef: oceanWindAudioRef,
                ridingWormRef: ridingWormRef,
                shouldTeleportWormRef: shouldTeleportWormRef,
                fellInWaterRef: fellInWaterRef,
                introCamPctRef: introCamPctRef,
                tutorialVisibleRef: tutorialVisibleRef,
                tutorialRef: tutorialRef,
                userAddressRef: userAddressRef,
                userENSRef: userENSRef,
                numUserBidsRef: numUserBidsRef,
                avatarVisibleRef: avatarVisibleRef,
                messageRef: messageRef,
                darkYecheEnabled: darkYecheEnabled,
                darkYecheVideoRef: darkYecheVideoRef,
            }
            }>

            <LunarOverlay/>
            </SceneContext.Provider>

            <Leva
                collapsed
                theme={levaTheme}
                hidden
            /> 
            
            <div className={darkYecheEnabled ? "Tutorial-dark" : (isMobile ? "Tutorial-mobile" : "Tutorial-desktop")} ref={tutorialRef} onClick={(e) => attemptHideTutorial(e)}>
                {darkYecheEnabled && <video ref={darkYecheVideoRef} src={ process.env.PUBLIC_URL + '/textures/dark_yeche.mp4'} controls/>}
            </div>

            <div ref={crosshairRef} className={isMobile ? "Crosshair Crosshair-mobile" : "Crosshair Crosshair-desktop"} />

            <div className={darkYecheEnabled ? "SplashScreen SplashDark" : "SplashScreen SplashNormal"} ref={splashscreenRef}>
                <div className={isMobile ? "lds-ripple lds-ripple-mobile" : "lds-ripple lds-ripple-desktop"}><div></div><div></div></div>            
            </div>

            <div className="Lunar-container" ref={containerRef}>

                {isMobile &&
                <div className="Lunar-joystick" ref={joystickRef}>
                    <Joystick 
                    size={100} 
                    sticky={false} 
                    move={handleJoystickMove} 
                    stop={handleJoystickStop}
                    stickImage={process.env.PUBLIC_URL + '/textures/joystick/stick.png'}
                    baseImage={process.env.PUBLIC_URL + '/textures/joystick/base.png'}
                    />
                </div>}
                
                {isMobile &&
                <button className="Lunar-jump-button Jump" ref={jumpRef} onTouchStart={() => {handleJump(true)}} onTouchEnd={() => {handleJump(false)}} />}

                <Canvas 
                dpr={[0.5, 0.8]}
                shadows
                linear={true}
                camera={{
                    far: 5000,
                    fov: 130
                }}
                >
                        <color attach="background" args={["black"]} />

                        <SceneContext.Provider value={ 
                                {
                                    joystickXRef: joystickXRef,
                                    joystickYRef: joystickYRef,
                                    touchJumpRef: touchJumpRef,
                                    colliderLayer: 5,
                                    artLayer: 6,
                                    aircurrentLayer: 7,
                                    listenerRef: listenerRef,
                                    videoElemsRef: videoElemsRef,
                                    videoElemsPosRef: videoElemsPosRef,
                                    crosshairArtworkRef: crosshairArtworkRef,
                                    fullDisplayRef: fullDisplayRef,
                                    artPlanesRef: artPlanesRef,
                                    switchArtInstantRef: switchArtInstantRef,
                                    containerRef: containerRef,
                                    sceneLoadedRef: sceneLoadedRef,
                                    curPlaneIndexRef: curPlaneIndexRef,
                                    artworkInfoRef: artworkInfoRef,
                                    waterTimescaleRef: waterTimescaleRef,
                                    wormRef: wormRef,
                                    wormDirRef: wormDirRef,
                                    wormSpeedRef: wormSpeedRef,
                                    ridingWormRef: ridingWormRef,
                                    cameraPosRef: cameraPosRef,
                                    crosshairRef: crosshairRef,
                                    controlsEnabledRef: controlsEnabledRef,
                                    splashscreenRef: splashscreenRef,
                                    videoLoadedRef: videoLoadedRef,
                                    introCamPctRef: introCamPctRef,
                                    videosMutedRef: videosMutedRef,
                                    oceanAudioRef: oceanAudioRef,
                                    windAudioRef: windAudioRef,
                                    oceanWindAudioRef: oceanWindAudioRef,   
                                    jumpRef: jumpRef,      
                                    waypointPctRef: waypointPctRef,      
                                    shouldTeleportWormRef: shouldTeleportWormRef,     
                                    fellInWaterRef: fellInWaterRef,
                                    tutorialVisibleRef: tutorialVisibleRef,
                                    tutorialRef: tutorialRef,          
                                    curColliderNameRef: curColliderNameRef,          
                                    userAddressRef: userAddressRef,
                                    userENSRef: userENSRef,
                                    numUserBidsRef: numUserBidsRef,
                                    avatarDataRef: avatarDataRef,         
                                    avatarVisibleRef: avatarVisibleRef,
                                    messageRef: messageRef,
                                    darkYecheEnabled: darkYecheEnabled,
                                    darkYecheVideoRef: darkYecheVideoRef,
                                } 
                        }>
                            <Selection>
                                <PerformanceMonitor>
                                    {multiplayerEnabled && <Multiplayer/>}
                                    <LunarScene/>
                                    <ambientLight intensity={0.6} ref={ambientLightRef} castShadow />
                                        <directionalLight 
                                        ref={lightRef} 
                                        intensity={2.0} 
                                        position={[50,50,10]} 
                                        castShadow 
                                        shadow-mapSize-width={4096}
                                        shadow-mapSize-height={4096}
                                        shadow-camera-far={1000.0}
                                        shadow-camera-near={0.1}
                                        shadow-camera-left={-100}
                                        shadow-camera-right={100}
                                        shadow-camera-top={100}
                                        shadow-camera-bottom={-100}            
                                        />
                                    <LunarPostProcessing />
                                </PerformanceMonitor>
                            </Selection>
                            {/* <Stats /> */}


                        <fog attach="fog" args={['#76c9e2', 16, 2000]} ref={fogRef} />

                        </SceneContext.Provider>
                                        
                        <Environment
                            files={darkYecheEnabled ? ['px_dark.png', 'nx_dark.png', 'py_dark.png', 'ny_dark.png', 'pz_dark.png', 'nz_dark.png'] : ['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg']}
                            path={process.env.PUBLIC_URL + '/textures/env/lunar/'}
                        />
                </Canvas>           
            </div>
        </>
    );
}


import React from 'react';
import { useEffect, useState } from 'react';
import './MediaTexts.css';
import './Splash.css';
import { NavLink } from 'react-router-dom';

function MediaTexts(props) {

    useEffect(() => {
        document.title = props.title;
    }, []);    
        
    return(
        <>
        <div className="bg-splash"></div>
        
        <div className="mediatexts-container">
            <div className="mediatexts">

                <NavLink to="/thesis">Collecting Thesis</NavLink>
                <a href="https://that1yempejji.ooo/">Yempejji Culture Channels</a>

                <div className='yempejji'>
                In addition to the gallery, Yeche Lange hosts writing and criticism through Yempejji Culture Channels.
                </div>

                <div className='mediaCategoryHeader'>
                Galerie Yeche Lange Presents
                </div>

                <div className='videoContainer'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/S2ow6LBEGoM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/CjrbRo2Wq3A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ft0PEC2K118" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eD-6j1cNgMo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ib7bQ_n71zk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='mediaCategoryHeader'>
                Lunch Break Cultural Caucus Podcast + Twitter Space
                </div>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-20-Reality-Gamer-x-Harmless-A-I-e2237hs" height="102px" width="90%" frameborder="0" scrolling="no"></iframe>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-19-Heartlocket-x-Harmless-A-I-e2237hf" height="102px" width="90%" frameborder="0" scrolling="no"></iframe>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-18-School-of-Truth-e2150o0" height="102px" width="90%" frameBorder="0" scrolling="no"></iframe>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-17-Jeffrey-Alan-Scudder-e2150fc" height="102px" width="90%" frameBorder="0" scrolling="no"></iframe>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-16-Ugly-Bitches-x-Maya-Man--Ann-Hirsch-e2150lh" height="102px" width="90%" frameBorder="0" scrolling="no"></iframe>

                <iframe src="https://podcasters.spotify.com/pod/show/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-15-Ilja-Karilampi-e21507p" height="102px" width="90%" frameBorder="0" scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-14-Nate-Boyce-e1sr3vb/a-a93ubj1" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-13-Miles-Peyton-Mt--Get-Back-in-the-Game-e1sr3ss/a-a93uarh" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-12-Jared-Madere-Creation-Babies-e1rsknt/a-a90hksd" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-11-Sam-Spike-e1rskk3/a-a90hfpg" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-10-Soph-e1ragal/a-a8ub3dl" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-9-Ezra-Miller-e1r2jo1" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-8-Death-Clock-x-DIS-e1qih78/a-a8rgi8i" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-7-Adam-Martin-e1q5kol/a-a8pvtvu" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-6-Whistlegraph-e1pn3ip/a-a8ol4or" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-5-AdWorld-e1peull/a-a8n9412" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-4-notsofast-e1p2j0q/a-a8lr0vi" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-3-Petra-Cortright-e1otgrv/a-a8l85il"height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-2-Tojiba-CPU-Corp-e1om0bp/a-a8kc0hu"height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

                <iframe src="https://anchor.fm/galerie-yeche-lange/embed/episodes/Lunch-Break-Caucus-1-e1om080/a-a8kc02e" height="102px" width="90%" frameBorder={0} scrolling="no"></iframe>

            </div>
        </div>
        </>
    );
}

export default MediaTexts;
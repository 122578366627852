import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { SceneContext } from "./Wanderlust.js";
import { useGLTF, Html } from '@react-three/drei';
// import { MudMat } from "./MudMat.js";
// import LilyMat from "./LilyMat.js";
// import SteelMat from "./SteelMat.js";
import AircurrentMat from './AircurrentMat.js';
import { MaterialContext } from "./WanderlustScene.js";
import { isValidInputTimeValue } from '@testing-library/user-event/dist/utils';
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper.js';
import { useControls } from 'leva';
import { isMobile } from 'react-device-detect';
// import { Select } from '@react-three/postprocessing';
import SelectCrosshair from './SelectCrosshair.js';
import Worm from './Worm.js';
import Clock from './Clock.js';
// import Cloth from './Cloth.js';
import { RigidBody } from '@react-three/rapier';
import BookStack from './BookStack.js';
import Jumbotron from './Jumbotron.js';
// import { sparkleFrag, sparkleVert } from './SparkleMaterialize.js';

function getWaveInfo(waves, offsetX, offsetZ, x, z, time) {
    const pos = new THREE.Vector3()
    const tangent = new THREE.Vector3(1, 0, 0)
    const binormal = new THREE.Vector3(0, 0, 1)
    waves.forEach((w) => {
        const k = (Math.PI * 2.0) / w.wavelength
        const c = Math.sqrt(9.8 / k)
        const d = new THREE.Vector2(
            Math.sin((w.direction * Math.PI) / 180),
            -Math.cos((w.direction * Math.PI) / 180)
        )
        const f = k * (d.dot(new THREE.Vector2(x, z)) - c * time)
        const a = w.steepness / k

        pos.x += d.x * (a * Math.cos(f))
        pos.y += a * Math.sin(f)
        pos.z += d.y * (a * Math.cos(f))

        tangent.x += -d.x * d.x * (w.steepness * Math.sin(f))
        tangent.y += d.x * (w.steepness * Math.cos(f))
        tangent.z += -d.x * d.y * (w.steepness * Math.sin(f))

        binormal.x += -d.x * d.y * (w.steepness * Math.sin(f))
        binormal.y += d.y * (w.steepness * Math.cos(f))
        binormal.z += -d.y * d.y * (w.steepness * Math.sin(f))
    })

    const normal = binormal.cross(tangent).normalize()
    return { position: pos, normal: normal }
}

export default function Room({roomGLB, waves, roomID}) {

    // const steelMatTiling = 30;
    // const { steelMatTiling } = useControls('steel material', {
    //     steelMatTiling: { value: 10, min: 1, max: 100, step: 1 }
    // });    
    // const [mudMat, setMudMat] = useState();
    // const steelMatTiling = 10.0;
    // const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/models/lunar/cuberoom.glb')

    const lilyGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/lily_pad.glb');

    const questionMarkTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/questionmark.png');

    const [lilypadPositions, setLilypadPositions] = useState([]);
    // const [floatPoints, setFloatPoints] = useState([]);

    const roomScale = 3.6;
    const lilyScale = 1.2;

    const MAX_CLICK_DIST = 150.0;

    const lastArtTouchedRef = useRef("");

    const [roomHasGigi, setRoomHasGigi] = useState(false);
    const [gigiPosition, setGigiPosition] = useState(new THREE.Vector3(0,0,0));

    const [roomPosition, setRoomPosition] = useState(new THREE.Vector3(0,0,0));   

    const [spawnPoint, setSpawnPoint] = useState(null);
    const [introCamPoints, setIntroCamPoints] = useState([]);

    const [bookStackPos, setBookStackPos] = useState(null);

    const [jumbotronPos, setJumbotronPos] = useState(null);
    const [jumbotronRot, setJumbotronRot] = useState(null);

    const [buoyNode, setBuoyNode] = useState(null);

    const [metalObjects, setMetalObjects] = useState([]);

    const [mudObjects, setMudObjects] = useState([]);

    const [lilyObjects, setLilyObjects] = useState([]);

    const [buoyLilyObjects, setBuoyLilyObjects] = useState([]);

    const [colliders, setColliders] = useState([]);

    const [artObjects, setArtObjects] = useState([]);

    const [aircurrents, setAircurrents] = useState([]);

    const rigidbodyRefs = useRef([]);

    // const rigidbodyPosLocalRef = useRef([]);
    // const rigidbodyRotLocalRef = useRef([]);

    // const artworkPlaneRefs = useRef([]);

    const buoyLilyRefs = useRef([]);
    const lilyRefs = useRef([]);

    const lilyFloatPoints = useRef(null);

    // const introCamPct = useRef(0.0);

    const lilyRotSpeed = useRef([]);
    const buoyLilyRotSpeed = useRef([]);
    const buoyLilyRot = useRef([]);

    const floatPoints = useRef([]);
    const buoyRef = useRef();

    const dummy = new THREE.Object3D();

    const dummyRot = new THREE.Quaternion();

    const 
        { 
            colliderLayer, 
            aircurrentLayer,
            artLayer,
            listenerRef, 
            videoLoadedRef,
            videoElemsRef,
            videoElemsPosRef,  
            artPlanesRef,
            fullDisplayRef,
            switchArtInstantRef,
            containerRef,
            curPlaneIndexRef,
            artworkInfoRef,
            waterTimescaleRef,
            crosshairRef,
            crosshairArtworkRef,
            controlsEnabledRef,
            introCamPctRef,
            ridingWormRef,
            shouldTeleportWormRef,
            jumpRef,
            tutorialVisibleRef,
            tutorialRef,
            darkYecheEnabled,
            artworkPath,
            modelPath,
            workDataRef
        } = useContext(SceneContext);

    const {
        mudMat,
        lilyMat,
        steelMat
    } = useContext(MaterialContext);

    const { camera } = useThree();

    function resetPlayer(x, y, z) {
        console.log('resetting player');
        camera.position.set(x, y, z);
    }

    //get lily float points
    useEffect(() => {
        if(lilyGLB.nodes != null && lilyFloatPoints.current == null) {
            let lilyFloatPointsTemp = [];

            let buoy = lilyGLB.nodes.buoy;

            for (const [key, value] of Object.entries(lilyGLB.nodes)) {
                if(key.startsWith("floatPoint")) {
                    let localPos = new THREE.Vector3();
                    buoy.worldToLocal(localPos.copy(value.position));
    
                    lilyFloatPointsTemp.push(localPos);
                    // lilyFloatPointsTemp.push(value.position);
                }
            }

            lilyFloatPoints.current = lilyFloatPointsTemp;
        }

    }, [lilyGLB]);

    // useEffect(() => {
    //     if(spawnPoint != null && buoyNode != null) {
    //         resetPlayer(spawnPoint.x, spawnPoint.y, spawnPoint.z);
    //     }

    // }, [spawnPoint, buoyNode]);

    useEffect(() => {
        let lilypadPositionsTemp = [];
        let floatPointsTemp = [];

        let roomPosScaled;

        let buoy;
        let mudObjectsTemp = [];
        let metalObjectsTemp = [];
        let artObjectsTemp = [];
        let lilyObjectsTemp = [];
        let lilyRotSpeedTemp = [];

        let buoyLilyObjectsTemp = [];
        let buoyLilyRotSpeedTemp = [];
        let buoyLilyRotTemp = [];

        let collidersTemp = [];
        let aircurrentsTemp = [];

        let introCamPointsTemp = [];

        let spawnPointTemp = null;

        //offset room origin based on scale 
        for (const [key, value] of Object.entries(roomGLB.nodes)) {
            if(key.startsWith("buoy")) {
                roomPosScaled = new THREE.Vector3(value.position.x * roomScale, value.position.y * roomScale, value.position.z * roomScale);

                setRoomPosition(roomPosScaled);
            }
        }

        floatPoints.current = [];

        const nodes = roomGLB.nodes;

        //PARSE MAIN SCENE OBJECTS
        for (const [key, value] of Object.entries(nodes)) {
            if(key.startsWith("buoy")) {
                buoy = value;
            }

            else if(key.startsWith("mud")) {
                mudObjectsTemp.push(value);
            }

            else if(key.startsWith("metal")) {
                metalObjectsTemp.push(value);
            }

            else if(key.startsWith("collider")) {
                if(value.geometry != undefined) {
                    collidersTemp.push(value);
                }

                // console.log(value.name);
            }

            else if(key.startsWith("aircurrent")) {
                aircurrentsTemp.push(value);
            }
        }

        //need to offset other objects by the difference between the new room position and the old room position
        let translateBy = new THREE.Vector3(roomPosition.x - buoy.position.x, 
            roomPosition.y - buoy.position.y, 
            roomPosition.z - buoy.position.z);


        //PARSE OTHER SCENE OBJECTS
        for (const [key, value] of Object.entries(nodes)) {
            if(key.startsWith("floatPoint")) {
                let localPos = new THREE.Vector3();
                buoy.worldToLocal(localPos.copy(value.position));

                floatPoints.current.push(localPos);
            }

            else if(key.startsWith("gigi_start_pos")) {
                let localPos = new THREE.Vector3();
                buoy.worldToLocal(localPos.copy(value.position));
                //need to off
                localPos.add(translateBy);

                setGigiPosition(localPos);
                setRoomHasGigi(true);
            }

            else if(key.startsWith("spawn")) {
                //calculate world position of spawn point
                spawnPointTemp = value.position.clone();
                // roomHasSpawnPoint = true;
                spawnPointTemp.multiplyScalar(roomScale);

                setSpawnPoint(spawnPointTemp);
            }

            else if(key.startsWith("bookStack")) {
                let bookStackPosTemp = value.position.clone();
                bookStackPosTemp.add(translateBy);
                console.log('setting book stack pos');
                setBookStackPos(bookStackPosTemp);
            }

            else if(key.startsWith("jumbotron")) {
                let jumbotronPosTemp = value.position.clone();
                jumbotronPosTemp.add(translateBy);
                setJumbotronPos(jumbotronPosTemp);
                setJumbotronRot(value.rotation);
            }

            else if(key.startsWith("intro_cam")) {
                let introCamPos = value.position.clone();
                introCamPos.multiplyScalar(roomScale);

                introCamPointsTemp.push(introCamPos);
            }

            else if(key.startsWith("lilypad_bridge")) {
                let startPos = value.position.clone();
                startPos.multiplyScalar(roomScale);

                value.position.set(startPos.x, startPos.y, startPos.z);

                value.scale.set(roomScale * lilyScale, roomScale * lilyScale, roomScale * lilyScale);

                buoyLilyObjectsTemp.push(value);

                let sign = Math.random() < 0.5 ? -1 : 1;
                let rotSpeed = Math.random() * 0.0025 + 0.001;

                buoyLilyRotSpeedTemp.push(rotSpeed * sign);
                buoyLilyRotTemp.push(0);
            }

            else if(key.startsWith("lilypad")) {
                value.position.add(translateBy);

                if(key.startsWith("lilypad_tower")) {
                    value.scale.set(roomScale * lilyScale, roomScale * lilyScale, roomScale * lilyScale);
                    // value.scale.set(roomScale * lilyScale * 12.0, roomScale * lilyScale * 12.0, roomScale * lilyScale * 12.0);
                }

                else {
                    value.scale.set(roomScale * lilyScale, roomScale * lilyScale, roomScale * lilyScale);
                }

                lilyObjectsTemp.push(value);

                let sign = Math.random() < 0.5 ? -1 : 1;
                let rotSpeed = Math.random() * 0.0025 + 0.001;

                lilyRotSpeedTemp.push(rotSpeed * sign);
            }

            else if(key.startsWith("art")) {
                value.position.add(translateBy);

                //setup artwork on walls
                // setupArtwork(key, value, true, true);

                artObjectsTemp.push(value);
            }
        }


        if(introCamPointsTemp.length > 0 && spawnPointTemp != null) {
            introCamPointsTemp.sort((a, b) => {
                let distA = a.distanceTo(spawnPointTemp);
                let distB = b.distanceTo(spawnPointTemp);

                return distB - distA;
            });

            console.log('sorted points');

            //add spawn point to end of array
            // introCamPointsTemp.push(spawnPointTemp);

            //reverse array
            // introCamPointsTemp.reverse();
        }

        setBuoyNode(buoy);
        setMudObjects(mudObjectsTemp);
        setMetalObjects(metalObjectsTemp);
        setLilyObjects(lilyObjectsTemp);
        setAircurrents(aircurrentsTemp);
        setColliders(collidersTemp);
        setArtObjects(artObjectsTemp);

        setBuoyLilyObjects(buoyLilyObjectsTemp);

        setIntroCamPoints(introCamPointsTemp);

        lilyRotSpeed.current = lilyRotSpeedTemp;
        buoyLilyRotSpeed.current = buoyLilyRotSpeedTemp;
        buoyLilyRot.current = buoyLilyRotTemp;

    }, [roomGLB]);


    function setupArtwork(name, plane, extractName, doubleSidedMat) {
        if(plane == null) {
            console.log('plane object is null');
            return;
        }

        let isVideo = name.includes('vid');
        let matSide = doubleSidedMat ? THREE.DoubleSide : THREE.FrontSide;

        let artName = name;

        if(extractName) {
            // console.log('artName was ' + artName)
            artName = isVideo ? name.substring(name.indexOf("vid_") + 4, name.length) : name.substring(name.indexOf("img_") + 4, name.length);
            // console.log('artName is now ' + artName)
        }

        let planePos = plane.position.clone();

        // console.log('looking for ' + artName + ' in artworkInfoRef.current');

        let artwork = artworkInfoRef.current.find(artwork => artwork.filename === artName);

        // console.log(artworkInfoRef.current);

        // console.log(artwork);
        // console.log(artName);
        // console.log(artworkInfoRef.current);
        if(artwork == null) {
            console.log('artwork is null: ' + artName);
        }

        else {
            let description = artwork.description;
            let tourOrder = artwork.tourOrder;
            let inTower = artwork.inTower;
            let tokenID = artwork.tokenID;
            let ogFilename = artwork.ogFilename;

            artPlanesRef.current[tourOrder] = {
                artName: artName, 
                roomID: roomID, 
                ogFilename: ogFilename,
                pos: planePos, 
                plane: plane,
                inTower: inTower,
                isVideo: isVideo,
                tokenID: tokenID,
                description: description,
                loadedTex: false,
            };
        }

        plane.name = artName;
        plane.layers.enable(artLayer);

        if(isVideo) {
            let video;

            for(let i = 0; i < videoElemsRef.current.length; i++) {

                if(videoElemsRef.current[i].src.includes(artName + ".mp4")) {
                    // console.log('found a match');
                    video = videoElemsRef.current[i];
                    break;
                }
            }

            if(video == null) {
                video = document.createElement('video');
                video.src = process.env.PUBLIC_URL + artworkPath + artName + ".mp4";
                //set video type to mp4
                video.type = 'video/mp4';
                video.loop = true;
                video.muted = true;
                video.playsInline = true;
                video.setAttribute('preload', 'none'); // Prevents the video from loading automatically
                
                //todo figure this out
                // video.play();

                videoLoadedRef.current.push(false);
                videoElemsRef.current.push(video);
            }

            //get the world position of the plane, scale by roomScale and add roomPosition
            // let planePos = plane.position.clone().multiplyScalar(roomScale).add(roomPosition);
            // videoElemsPosRef.current.push(planePos);

            let texture = new THREE.VideoTexture(video);
            texture.flipY = true;
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = THREE.RGBFormat;

            plane.material = new THREE.MeshBasicMaterial({ map: texture, side: matSide, toneMapped: false });


            //POSITIONAL AUDIO

            //wait for video to load
            video.onloadeddata = function() {
                //scale the plane to the texture
                let aspect = video.videoWidth / video.videoHeight;
                let baseScale = 1.0;
                let planeScale = [baseScale * aspect, baseScale, baseScale];

                plane.scale.set(planeScale[0], planeScale[1], planeScale[2]);    
                // console.log('setting video plane scale to ' + planeScale[0] + ', ' + planeScale[1] + ', ' + planeScale[2]);            
            };
        } 

        else {
            plane.material = new THREE.MeshBasicMaterial({ map: questionMarkTex, transparent: true, side: matSide });
            //UNCOMMENT TO HIDE PLANE INITIALLY
            // plane.visible = false;
        }

        // else {

        //     let loader = new THREE.TextureLoader();

        //     //try to load the image
        //     loader.load(process.env.PUBLIC_URL + artworkPath + artName + ".jpg", function (texture) {
        //         texture.minFilter = THREE.NearestFilter;
        //         texture.magFilter = THREE.NearestFilter;
        //         // artPlane.plane.material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });

        //         plane.material = new THREE.ShaderMaterial({
        //             vertexShader: sparkleVert,
        //             fragmentShader: sparkleFrag,
        //             transparent: true,
        //             depthWrite: false,
        //             depthTest: false,
        //             uniforms: {
        //                 colorMap: { value: texture },
        //                 materializeAmt: { value: 0.0 },
        //                 time: { value: 0.0 },
        //             }
        //         });

        //         plane.renderOrder = 300;

        //         // console.log('setting material on ' + plane.name + ' to ' + texture.image.src);
        //         // console.log('loaded texture for ' + artPlane.artName);
        //         //scale the plane to the texture
        //         texture.flipY = true;
    
        //         let aspect = texture.image.width / texture.image.height;
        //         let baseScale = 1.0;
        //         let planeScale = [baseScale * aspect, baseScale, baseScale];
                
        //         plane.scale.set(planeScale[0], planeScale[1], planeScale[2]);
        //     });    
        // }
    }

    function updateIntroCamAnim(delta) {

        // let animDelta = Math.min(delta, 0.1);

        //TODO MULT THIS BY DELTA 

        //lerp from 0.001 to 0.002 based on introCamPct.current

        let camLerpSpeed = THREE.MathUtils.lerp(0.008, 0.02, introCamPctRef.current);

        if(introCamPctRef.current == 0.0) {
            //set camera position to first point and look at next point
            camera.position.copy(introCamPoints[0]);
            camera.lookAt(spawnPoint);
        }

        else {

            let thisPtIndex = Math.floor(introCamPctRef.current * introCamPoints.length);
            let nextPtIndex = thisPtIndex + 1;

            // let lookAtPoint = introCamPoints[nextPtIndex];

            //if nextPtIndex is out of bounds, set to last point
            if(nextPtIndex >= introCamPoints.length) {
                nextPtIndex = introCamPoints.length - 1;
                // lookAtPoint = spawnPoint;
            }

            //get the current point and the next point
            let currentPoint = introCamPoints[thisPtIndex];
            let nextPoint = introCamPoints[nextPtIndex];

            let camLerpAmt = introCamPctRef.current * introCamPoints.length - thisPtIndex;

            //lerp between the two points
            let curPos = new THREE.Vector3();
            curPos.lerpVectors(currentPoint, nextPoint, camLerpAmt);
            camera.position.copy(curPos);


            if(nextPtIndex == introCamPoints.length - 1) {
                let zeroRot = new THREE.Quaternion();

                camera.quaternion.slerp(zeroRot, 0.05);

                //if dist to spawn point < 0.1, intro animation is over, set controlsEnabledRef.current to true
                //NOW IN SCENE
                if(camera.position.distanceTo(nextPoint) < 5.0) {
                    controlsEnabledRef.current = true;

                    console.log('fading in crosshair');
                    crosshairRef.current.classList.add('hueAnim');

                    introCamPctRef.current = 1.0;

                    tutorialRef.current.style.display = 'block';
                    tutorialRef.current.classList.add('fadeIn');
                    tutorialVisibleRef.current = true;
                }
            }

            else {
                camera.lookAt(spawnPoint);
            }
        }

        //increment introCamPct 
        introCamPctRef.current += camLerpSpeed;
    }


    useFrame(({ clock }) => {

        //on the first frame sync is correct, get the local pos and rot relative to buoyRef, use that after to update
        if(rigidbodyRefs.current != null && buoyRef.current != null) {

            rigidbodyRefs.current.forEach((rigidbody, i) => {

                if(rigidbody == null) return;

                //set the rigidbody position and rotation to the world pos and rot
                // rigidbody.setNextKinematicTranslation(rbPosWorld);
                rigidbody.setNextKinematicTranslation(buoyRef.current.position);
                rigidbody.setNextKinematicRotation(buoyRef.current.quaternion);    
            });
        }

        // if(mudMat) {

        //     mudMat.uniforms = {
        //         time: { value: 0 },
        //         noiseScaleBase: { value: 2739 },
        //         fogColBase: { value: new THREE.Color('#e0e2c1') },
        //         useNoise: { value: true }
        //     }

        //     // uniforms={{
        //     //     time: { value: 0 },
        //     //     noiseScaleBase: { value: 2739 },
        //     //     fogColBase: { value: new THREE.Color('#e0e2c1') },
        //     //     useNoise: { value: true }
        //     // }}

        //     // console.log(mudMat);
        //     // mudMat.uniforms.time.value = clock.getElapsedTime() * 80.0;
        //     // mudMat.uniforms.useNoise.value = true;
        // }


        if(!controlsEnabledRef.current && introCamPoints.length > 0) {
            updateIntroCamAnim(clock.getDelta());
        }


        const t = clock.getElapsedTime() * waterTimescaleRef.current;

        if(floatPoints.current.length === 0) return;

        if(buoyRef.current == null) return;

        let buoyPos = buoyRef.current.position;

        const accumulatedPosition = new THREE.Vector3();

        const accumulatedNormal = new THREE.Vector3(0, 0, 0);

        //loop through all the float points, and convert them from local to world space
        //then apply the wave displacement to them
        for(let i = 0; i < floatPoints.current.length; i++) {
            let floatPointWorld = new THREE.Vector3();
            buoyRef.current.localToWorld(floatPointWorld.copy(floatPoints.current[i]));

            const waveInfo = getWaveInfo(waves, buoyPos.x, buoyPos.z, floatPointWorld.x, floatPointWorld.z, t);

            accumulatedPosition.y += waveInfo.position.y
            // accumulatedPosition.x += buoyPos.x + waveInfo.normal.x
            // accumulatedPosition.z += buoyPos.z + waveInfo.normal.z

            //test: try keeping buoy fixed on x and z
            accumulatedPosition.x += buoyPos.x;
            accumulatedPosition.z += buoyPos.z;

            accumulatedNormal.add(waveInfo.normal)
        }

        accumulatedNormal.divideScalar(floatPoints.current.length);

        //average all floatPoints directions
        accumulatedPosition.divideScalar(floatPoints.current.length);

        buoyRef.current.position.lerp(accumulatedPosition, 0.25);

        dummyRot.setFromUnitVectors(new THREE.Vector3(0, 1, 0), accumulatedNormal)

        //gradually rotate towards the average normal
        buoyRef.current.quaternion.slerp(dummyRot, 0.25);    

        //loop through lilyRefs and rotate them
        for(let i = 0; i < lilyRefs.current.length; i++) {
            lilyRefs.current[i].rotateY(lilyRotSpeed.current[i]);
        }

        //update free floating lilies 
        for(let i = 0; i < buoyLilyRefs.current.length; i++) {

            let lilyCurPos = buoyLilyRefs.current[i].position;

            const accumulatedPosition = new THREE.Vector3();
            const accumulatedNormal = new THREE.Vector3(0, 0, 0);

            for(let j = 0; j < lilyFloatPoints.current.length; j++) {
                let floatPointWorld = new THREE.Vector3();
                buoyLilyRefs.current[i].localToWorld(floatPointWorld.copy(lilyFloatPoints.current[j]));

                const waveInfo = getWaveInfo(waves, lilyCurPos.x, lilyCurPos.z, floatPointWorld.x, floatPointWorld.z, t);

                accumulatedPosition.y += waveInfo.position.y
                // accumulatedPosition.x += lilyCurPos.x + waveInfo.normal.x
                // accumulatedPosition.z += lilyCurPos.z + waveInfo.normal.z

                //test: try keeping buoy fixed on x and z
                accumulatedPosition.x += lilyCurPos.x;
                accumulatedPosition.z += lilyCurPos.z;

                accumulatedNormal.add(waveInfo.normal)
                // buoyLilyRefs.current[i].position.y += waveInfo.position.y;

            }

            accumulatedNormal.divideScalar(lilyFloatPoints.current.length);
            
            //average all floatPoints directions
            accumulatedPosition.divideScalar(lilyFloatPoints.current.length);
            //push them up a bit
            accumulatedPosition.y += 1.0;

            buoyLilyRefs.current[i].position.lerp(accumulatedPosition, 0.25);


            //get the current buoyLilyRef y rotation
            let curRot = buoyLilyRot.current[i];
            //add rotation speed
            curRot += buoyLilyRotSpeed.current[i];

            dummy.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), accumulatedNormal)

            //rotate dummy rot around local y axis by curRot
            dummy.rotateY(curRot);

            //rotate buoyLily around its y axis
            // buoyLilyRefs.current[i].rotateY(buoyLilyRotSpeed.current[i]);

            //gradually rotate towards the average normal
            buoyLilyRefs.current[i].quaternion.slerp(dummy.quaternion, 0.25);


            //update the current rotation
            buoyLilyRot.current[i] = curRot;
        }
    });

    function hideCrosshair() {
        console.log('hiding crosshair');

        crosshairRef.current.classList.remove("hueAndPulse")
        crosshairRef.current.classList.remove("hueAnim");

        crosshairRef.current.classList.add("fadeOut");
    }
    
    function showArtFromClick(artName) {

        //check that artwork is less than 50 away from plane
        // console.log('art planes ref');
        // console.log(artPlanesRef.current);

        // console.log('searching for: ' + artName);
        let planeIndex = artPlanesRef.current.findIndex((plane) => plane && plane.artName == artName);

        let curPlanePos = new THREE.Vector3();

        if(!artPlanesRef.current[planeIndex]) {
            console.log('ERROR: could not find plane for ' + artName + ' in artPlanesRef');
            console.log('planeIndex: ' + planeIndex);
            console.log(artPlanesRef.current);
            return;
        }

        let tokenID = artPlanesRef.current[planeIndex].tokenID;

        //don't show work if it hasn't been minted yet
        if(workDataRef.current[tokenID] == undefined) {
            return;
        } 
        
        artPlanesRef.current[planeIndex].plane.getWorldPosition(curPlanePos);

        let planeDist = curPlanePos.distanceTo(camera.position);

        if(planeDist < MAX_CLICK_DIST) {
            curPlaneIndexRef.current = planeIndex;
            
            fullDisplayRef.current = artName;
            switchArtInstantRef.current = false;

            crosshairArtworkRef.current = artName;

            // ridingWormRef.current = false;
            setRidingWorm(false);

            if(artPlanesRef.current[planeIndex].inTower) {
                shouldTeleportWormRef.current = true;
            }

            if(isMobile) hideCrosshair();
        }
    }

    function setRidingWorm(riding) {
        ridingWormRef.current = riding;

        if(jumpRef.current) {
            if(riding) {
                jumpRef.current.classList.add('Uturn');
                jumpRef.current.classList.remove('Jump');
            }

            else {
                jumpRef.current.classList.add('Jump');
                jumpRef.current.classList.remove('Uturn');    
            }
        }
    }

    function showGrapple(artName) {
        if(crosshairRef.current && !isMobile) {
            //add pulseAnim class to crosshairRef
            
            // console.log(artPlanesRef.current);
            // console.log('trying to find ' + artName + ' in artPlanesRef');
            
            let planeIndex = artPlanesRef.current.findIndex((plane) => plane && plane.artName == artName);
            
            
            let tokenID = artPlanesRef.current[planeIndex].tokenID;

            //don't show grapple if it hasn't been minted yet
            if(workDataRef.current[tokenID] == undefined) {
                return;
            }     
            
            let curPlanePos = new THREE.Vector3();
            
            crosshairRef.current.classList.add('hueAndPulse');
            crosshairRef.current.classList.remove('hueAnim');

            if(!artPlanesRef.current[planeIndex]) {
                console.log('ERROR: could not find plane for ' + artName + ' in artPlanesRef');
                console.log('planeIndex: ' + planeIndex);    
                console.log(artPlanesRef.current);
                return;
            }

        
            artPlanesRef.current[planeIndex].plane.getWorldPosition(curPlanePos);
          
            let planeDist = curPlanePos.distanceTo(camera.position);

            if(!isMobile && fullDisplayRef.current == "" && planeDist < MAX_CLICK_DIST) {
                crosshairArtworkRef.current = artName;
            }
        }
    }
    
    function hideGrapple() {
        if(crosshairRef.current && !isMobile) {

            //remove pulseAnim class from crosshairRef
            crosshairRef.current.classList.remove('hueAndPulse');
            crosshairRef.current.classList.add('hueAnim');

            //remove Grapple-cursor class
            // containerRef.current.classList.remove('Grapple-cursor');
            // containerRef.current.classList.add('Default-cursor');
        }
    }

    return(
        <>
        {/* <MudMat useNoise={true} ref={setMudMat}/> */}

        {(buoyNode && lilyGLB && (buoyLilyObjects.length > 0) &&
        <>
        {lilyMat && steelMat && buoyLilyObjects.map((obj, i) => {
            return (
                (<mesh 
                    key={i} 
                    castShadow 
                    receiveShadow 
                    material={lilyMat}
                    ref={(element) => (buoyLilyRefs.current[i] = element)}
                    geometry={lilyGLB.nodes.buoy.geometry} 
                    scale={obj.scale}
                    position={obj.position}>
                        {/* <LilyMat/> */}

                        <mesh geometry={lilyGLB.nodes.collider.geometry} layers={colliderLayer} visible={false} />
                        {/* <mesh geometry={lilyGLB.nodes.collider.geometry} visible={true} /> */}
                        
                        {
                        (() => {
    
                            let lilyFloatPointsTemp = [];
                            
                            let vidNames = obj.name.split('_vid_').slice(1);
                            vidNames = vidNames.map((vidName) => vidName.split('_')[0]);
                            vidNames = vidNames.map((vidName) => 'vid_' + vidName);
                            
                            let imgNames = obj.name.split('_img_').slice(1);
                            imgNames = imgNames.map((imgName) => imgName.split('_')[0]);
                            imgNames = imgNames.map((imgName) => 'img_' + imgName);
                            
                            let allWorks = vidNames.concat(imgNames);

                            //check if there are multiple instances of '_img_' in the name
                            let numWorks = allWorks.length;
                            let multipleWorks = numWorks > 1;
                            let rotAng = (Math.PI * 2.0) / numWorks;
            
                            return allWorks.map((artName, k) =>
                            {
                                
                                //rotate each artwork by 90 degrees
                                let offsetRad = multipleWorks ? 1.2 : 0.0;
                                let originOffset = new THREE.Vector3(offsetRad * Math.cos(rotAng * k), 0, offsetRad * Math.sin(rotAng * k));
    
                                let artworkRot = lilyGLB.nodes.artwork.rotation.clone();
                                
                                let isVideo = artName.includes('vid');
                                let pureName = isVideo ? artName.substring(artName.indexOf("vid_") + 4, artName.length) : artName.substring(artName.indexOf("img_") + 4, artName.length);    

                                return(
                                    <group key={'artwork-' + i + k} rotation={[0,  -rotAng * k - Math.PI/2, 0]} position={originOffset}>
                                        <SelectCrosshair name={pureName}>
                                            <mesh key={'plane-' + i + '-' + k} geometry={lilyGLB.nodes.artwork.geometry}
                                            onPointerDown={(e) => {
                                                if(!isVideo) {
                                                    lastArtTouchedRef.current = pureName;
                                                }
                                            }}
                                            onPointerUp={(e) => {
                                                if(!isVideo) {
                                                    console.log('pointer up');
                                                    if(lastArtTouchedRef.current == pureName) {
                                                        showArtFromClick(pureName);
                                                        e.stopPropagation();
                                                    }
                                                }
                                            }}
                                            onPointerLeave={(e) => {
                                                if(!isVideo) {
                                                    lastArtTouchedRef.current = "";
                                                }
                                            }}
                                            onPointerEnter={() => {if(!isVideo) showGrapple(pureName)}}
                                            onPointerOut={() => {if(!isVideo) hideGrapple()}}
                                            position={lilyGLB.nodes.artwork.position}
                                            rotation={artworkRot}
                                            ref={(element) => {
                                                if(element == null) return;
                                                setupArtwork(artName, element, true, true);
                                            }} />
                                        </SelectCrosshair>
    
                                        <mesh 
                                        key={'grate-' + i + '-' + k} 
                                        castShadow 
                                        receiveShadow 
                                        material={steelMat}
                                        geometry={lilyGLB.nodes.hash_grate.geometry}
                                        rotation={lilyGLB.nodes.hash_grate.rotation}/>
                                    </group>   
                                )
                            })
                        })()
                        }
                </mesh>)
            )
        })
        }
        </>
        )}


        {
        (buoyNode && lilyGLB && (mudObjects.length > 0) && (metalObjects.length > 0)) &&
        /* room group, everything in here is a child of the room architecture */
        (<group ref={buoyRef} scale={[roomScale,roomScale,roomScale]} position={roomPosition}>

            <group position={bookStackPos} scale={[0.3,0.3,0.3]}>
                <BookStack/>
            </group>

            {/* <group position={jumbotronPos} scale={[1.5, 1.5, 1.5]} rotation={jumbotronRot}>
                <Jumbotron url={"https://www.youtube.com/embed/live_stream?channel=UCCuWBd9-tHoXZWOlP9IBCUg&autoplay=1"}/>
            </group> */}

            {roomHasGigi && 
            (<mesh
            position={gigiPosition}
            name={'gigi_start_pos'}
            visible={false}
            />)}

            {
            artObjects.map((obj, i) => {

                let isVideo = obj.name.includes('vid');
                let pureName = isVideo ? obj.name.substring(obj.name.indexOf("vid_") + 4, obj.name.length) : obj.name.substring(obj.name.indexOf("img_") + 4, obj.name.length);

                return (
                <SelectCrosshair key={i} name={pureName}>
                    <mesh
                    onPointerDown={(e) => {
                        if(!isVideo) {
                            lastArtTouchedRef.current = pureName;
                        }
                    }}
                    onPointerUp={(e) => {
                        // console.log('pointer up');
                        if(!isVideo && lastArtTouchedRef.current == pureName) {
                            showArtFromClick(pureName);
                            e.stopPropagation();
                        }
                    }}
                    onPointerLeave={(e) => {
                        if(!isVideo) {
                            lastArtTouchedRef.current = "";
                        }
                    }}
                    onPointerEnter={() => {
                        if(!isVideo) {
                            showGrapple(pureName);
                        }
                    }}
                    onPointerOut={
                        () => {
                            if(!isVideo) {
                                hideGrapple();
                            }
                        }}
                    key={i}
                    geometry={obj.geometry}
                    rotation={obj.rotation}
                    position={obj.position}
                    scale={obj.scale}
                    ref={(element) => {
                        if (element == null) return;
                        setupArtwork(obj.name, element, true, true);
                    }}
                    >
                        {/* {isVideo && 
                        <Html transform rotation={[90, 0, 0]}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/85T4TF9INc4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </Html>} */}

                    </mesh>
                </SelectCrosshair>
                );
            })
            }


            {colliders.length > 0 && colliders.map((obj, i) =>
                <React.Fragment key={'frag' + i}>
                    <mesh
                        geometry={obj.geometry}
                        layers={obj.name.includes("aircurrent") ? aircurrentLayer : colliderLayer}
                        visible={true}
                        key={i}
                        name={obj.name}
                        >
                            <meshBasicMaterial color="red" />
                    </mesh>

                    (<RigidBody 
                    key={'rb'+i} 
                    ref={(element) => (rigidbodyRefs.current[i] = element)}
                    colliders={"trimesh"} 
                    type={"kinematicPosition"}
                    >
                        <mesh
                            geometry={obj.geometry}
                            visible={true}
                            name={obj.name}
                            >
                                <meshBasicMaterial color="red" transparent={true} opacity={0.0} depthWrite={false} depthTest={false}/>
                        </mesh>

                    </RigidBody>
                </React.Fragment>)}
            {/*             
            {colliders.map((obj, i) =>
                (<mesh
                    key={i}
                    geometry={obj.geometry}
                    visible={true}
                    name={obj.name}
                    >
                        <meshBasicMaterial color={obj.name.includes('aircurrent') ? "blue" : "red"} />
                    </mesh>)
            )} */}

            {mudMat && mudObjects.map((obj, i) => 
                <mesh castShadow receiveShadow key={i} geometry={obj.geometry} material={mudMat} />
            )}

            {steelMat && metalObjects.map((obj, i) => 
                (<mesh castShadow receiveShadow key={i} geometry={obj.geometry} material={steelMat}/>)
            )}

            {aircurrents.map((obj, i) =>
                (<mesh key={i} geometry={obj.geometry}>
                    <AircurrentMat/>
                </mesh>)
            )}

            {lilyMat && steelMat && lilyObjects.map((obj, i) => 
                (<mesh 
                key={i} 
                castShadow 
                receiveShadow 
                scale={[lilyScale, lilyScale, lilyScale]}
                ref={(element) => (lilyRefs.current[i] = element)}
                material={lilyMat}
                geometry={lilyGLB.nodes.buoy.geometry} 
                position={obj.position}>
                    {/* <LilyMat/> */}
                    
                    <mesh geometry={lilyGLB.nodes.collider.geometry} layers={colliderLayer} visible={false} />
                    
                    {
                    (() => {

                        let vidNames = obj.name.split('_vid_').slice(1);
                        vidNames = vidNames.map((vidName) => vidName.split('_')[0]);
                        vidNames = vidNames.map((vidName) => 'vid_' + vidName);

                        let imgNames = obj.name.split('_img_').slice(1);
                        imgNames = imgNames.map((imgName) => imgName.split('_')[0]);
                        imgNames = imgNames.map((imgName) => 'img_' + imgName);

                        let allWorks = vidNames.concat(imgNames);

                        let numWorks = allWorks.length;

                        //check if there are multiple instances of '_img_' in the name
                        // let numWorks = obj.name.split('_img_').length - 1;
                        let multipleWorks = numWorks > 1;
                        let rotAng = (Math.PI * 2.0) / numWorks;
                        
                        return allWorks.map((artName, k) =>
                        {
                            //rotate each artwork by 90 degrees
                            let offsetRad = multipleWorks ? 1.2 : 0.0;
                            let originOffset = new THREE.Vector3(offsetRad * Math.cos(rotAng * k), 0, offsetRad * Math.sin(rotAng * k));

                            let artworkRot = lilyGLB.nodes.artwork.rotation.clone();

                            let isVideo = artName.includes('vid');
                            let pureName = isVideo ? artName.substring(artName.indexOf("vid_") + 4, artName.length) : artName.substring(artName.indexOf("img_") + 4, artName.length);

                            return(
                                <group key={'artwork-' + i + k} rotation={[0,  -rotAng * k - Math.PI/2, 0]} position={originOffset}>
                                    <SelectCrosshair name={pureName}>
                                        <mesh key={'plane-' + i + '-' + k} geometry={lilyGLB.nodes.artwork.geometry}
                                        onPointerDown={(e) => {
                                            lastArtTouchedRef.current = pureName;
                                        }}
                                        onPointerUp={(e) => {
                                            console.log('pointer up');
                                            if(lastArtTouchedRef.current == pureName) {
                                                showArtFromClick(pureName);
                                                e.stopPropagation();
                                            }
                                        }}
                                        onPointerLeave={(e) => {
                                            lastArtTouchedRef.current = "";
                                        }}
                                        onPointerEnter={() => {showGrapple(pureName)}}
                                        onPointerOut={hideGrapple}
                                        position={lilyGLB.nodes.artwork.position}
                                        rotation={artworkRot}
                                        ref={(element) => {
                                            if(element == null) return;
                                            // console.log('setting up ' + artName);
                                            setupArtwork(artName, element, true, true);
                                        }}/>
                                    </SelectCrosshair>

                                    <mesh 
                                    key={'grate-' + i + '-' + k} 
                                    castShadow 
                                    receiveShadow 
                                    geometry={lilyGLB.nodes.hash_grate.geometry}
                                    rotation={lilyGLB.nodes.hash_grate.rotation}
                                    material={steelMat}
                                    />
                                </group>   
                            )
                        })
                    })()
                    }
                </mesh>)
            )}
        </group>)
        }
        </>
    )
}
import React from 'react';
import Ball from './Ball';
import Cakes from './Cakes';
import Splash from './Splash';
import About from './About';
import Thesis from './Thesis';
import MediaTexts from './MediaTexts';
import Collections from './Collections';
import SwollenHearts from './SwollenHearts';
import YechePies from './YechePies';
import CocoaStrawberry from './CocoaStrawberry';
import OneOfOneOneOne from './1-111';
import HolidayGiveaway from './HolidayGiveaway';
import Nav from './Nav';
import Panel from './Panel';
import Nome from './Nome/Nome';
import Leche from './Leche/Leche';
import Nome2D from './Nome/Nome2D';
import Lunar from './Lunar/Lunar';
// import DarkYeche from './Lunar/DarkYeche'
import AuctionPaddle from './AuctionPaddle';
import WanderlustOpenEdition from './WanderlustOpenEdition';
import YecheCalendar from './CalendarClaim';
import Whistlegraph from './Whistlegraph';

import Wanderlust from './Wanderlust/Wanderlust';
import WanderlustGirls from './WanderlustGirls';

import BallMint from './BallMint';

import ObaaSplash from './ObaaSplash';

import Tantrum from './Tantrum/Tantrum';
import TantrumMint from './Tantrum/TantrumMint';

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";

function App() {
  const location = useLocation();
  const hidePanelPages = ['/creation-babies', 
                          '/collections', 
                          '/', 
                          '/nome',
                          '/lecheyang',
                          '/lunar', 
                          '/darkyeche', 
                          '/auctionpaddle', 
                          '/wanderlustyechelles', 
                          '/wanderlust-pre-mint', 
                          '/wanderlustgirls', 
                          '/ball',
                          '/ball/bloop',
                          '/ball/trout',
                          '/ball/drei',
                          '/ball/fantasist',
                          '/ball/rect1',
                          '/ball/rect2',
                          '/ball/rect3',
                          '/ball/rect4',
                          '/ball/rect5',
                          '/ball/aesthetic',
                          '/tantrum',
                          '/tantrummint'
                        ];

  const hideNavPages = ['/tantrum'];

  return (
    <div className="App">
      {!hideNavPages.includes(location.pathname) && <Nav />}
      {/* {!hidePanelPages.includes(location.pathname) && <Panel />} */}

      <Routes>
        {/* <Route index element={<WanderlustGirls title="Wanderlust Girls"/>} /> */}

        <Route index element={<Splash title="Galerie Yeche Lange"/>} />

        {/* <Route index element={<ObaaSplash title="Galerie Yeche Lange"/>} /> */}

        <Route path="/nomemene" element={<Nome title="Nome"/>} />
        <Route path="/nomedegen" element={<Nome2D title="Nome"/>} />

        <Route path="/wanderlust" element={<WanderlustGirls title="Wanderlust Girls"/>}/>

        <Route path="/schooloftruth" element={<Lunar title="School of Truth"/>} />

        <Route path="/lecheyang" element={<Leche title="Leche Yang"/>} />

        {/* <Route path="/splash" element={<Splash title="Splash"/>} /> */}

        <Route path="/about" element={<About title="About"/>} />
        <Route path="/ball" element={<Ball title="Yeche Lange Shillball: Game #1"/>} />
        <Route path="/newyear" element={<HolidayGiveaway title="Yeche Lange New Years Eve 2023 Golden Opportunity 48 Hour Open Mint"/>} />
        <Route path="/auctionpaddle" element={<AuctionPaddle title="Yeche Paddle"/>} />
        <Route path="/wanderlust-pre-mint" element={<WanderlustOpenEdition title="Wanderlust Girls Open Edition"/>} />
        <Route path="/calendar" element={<YecheCalendar title="Yeche Calendar"/>} />
        <Route path="/thesis" element={<Thesis title="Collecting Thesis"/>} />
        <Route path="/media-texts" element={<MediaTexts title="Media Texts"/>} />
        <Route path="/collections" element={<Collections title="Collections"/>} />
        <Route path="/yeche-cakes" element={<Cakes title="Yeche Lange Cakes"/>} />
        <Route path="/swollen-hearts" element={<SwollenHearts title="Tournament for Swollen Hearts"/>} />
        <Route path="/yeche-pies" element={<YechePies title="Galerie Yeche Lange Pie Key Un"/>} />
        <Route path="/cocoa-freemint" element={<CocoaStrawberry title="Cocoa Strawberry Club Free Mint"/>} />
        <Route path="/creation-babies" element={<OneOfOneOneOne title="Creation Babies"/>} />
        <Route path="/whistlegraph" element={<Whistlegraph title="Whistlegraph Unlock Assets"/>} />

        <Route path="/wanderlustyechelles" element={<Wanderlust title="Wanderlust Yechelles"/>} />

        <Route path="/ball/bloop" element={<BallMint tokenID={0}/>} />
        <Route path="/ball/trout" element={<BallMint tokenID={1}/>} />
        <Route path="/ball/drei" element={<BallMint tokenID={2}/>} />
        <Route path="/ball/fantasist" element={<BallMint tokenID={3}/>} />
        <Route path="/ball/rect1" element={<BallMint tokenID={4}/>} />
        <Route path="/ball/rect2" element={<BallMint tokenID={5}/>} />
        <Route path="/ball/rect3" element={<BallMint tokenID={6}/>} />
        <Route path="/ball/rect4" element={<BallMint tokenID={7}/>} />
        <Route path="/ball/rect5" element={<BallMint tokenID={8}/>} />
        <Route path="/ball/aesthetic" element={<BallMint tokenID={9}/>} />

        <Route path="/ball/dirt" element={<BallMint tokenID={10}/>} />
        <Route path="/ball/xmas" element={<BallMint tokenID={11}/>} />
        <Route path="/ball/lettucetini" element={<BallMint tokenID={12}/>} />
        <Route path="/ball/whistle" element={<BallMint tokenID={13}/>} />

        <Route path="/tantrum" element={<Tantrum title="Tantrum"/>} />
        <Route path="/tantrummint" element={<TantrumMint title="Tantrum"/>} />

      </Routes>
    </div>
  );
}

export default App;

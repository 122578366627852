import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { lerp, smoothstep } from 'three/src/math/MathUtils';
import { useControls, Leva, folder } from 'leva';
import { isMobile } from 'react-device-detect';
import { SceneContext } from './Lunar.js';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const deg2rad = degrees => degrees * (Math.PI / 180);

//ref is for all the collision objects in the scene including children
const FirstPersonControls = forwardRef(({}, ref) => {

    const {
        ledgeHeight
    } = useControls('ledge', {
        ledgeHeight: {
            value: 35.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        }
    });

    const {
        tourPositionLerpFactor,
        tourRotationLerpFactor
    } = useControls('tour', {
        tourPositionLerpFactor: {
            value: 0.15,
            min: 0.0,
            max: 1.0,
            step: 0.01
        },
        tourRotationLerpFactor: {
            value: 0.05,
            min: 0.0,
            max: 1.0,
            step: 0.01
        }
    });

    const {
        currentDampenStart,
        currentDampenEnd,
    } = useControls('aircurrent', {
        currentDampenStart: {
            value: 12.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        },
        currentDampenEnd: {
            value: 45.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        }
    });


    const { 
        camFOV,
        playerHeight, 
        playerSpeed, 
        maxJumpHeight,
        jumpStartAccel, 
        jumpStartVel, 
        rotSpeed, 
        snapSpeed, 
        rayYOffset,
        minYVel, 
        wormMountDist,
        wormOffsetX,
        wormOffsetY,
        wormOffsetZ,
    } = useControls('player', {
        camFOV: {
            value: 120,
            min: 0.0,
            max: 180.0,
            step: 0.1
        },
        playerHeight: {
            value: 1.2,
            min: 0.0,
            max: 10000,
            step: 0.1
        },
        playerSpeed: {
            value: 20.0,
            min: 0.0,
            max: 100,
            step: 0.1
        },
        maxJumpHeight: {
            value: 15.0,
            min: 0.0,
            max: 40.0,
            step: 0.1
        },
        jumpStartAccel: {
            value: 0.03,
            min: 0.0,
            max: 2.0,
            step: 0.01
        },
        jumpStartVel: {
            value: 0.01,
            min: 0.0,
            max: 10.0,
            step: 0.01
        },
        rotSpeed: {
            value: 0.3, 
            min: 0.0,
            max: 2.0,
            step: 0.01
        },
        snapSpeed: {
            value: 0.2, 
            min: 0.0,
            max: 2.0,
            step: 0.01
        },
        rayYOffset: {
            value: 5.0,
            min: 0.0,
            max: 10.0,
            step: 0.01
        },
        minYVel: {
            value: -0.25,
            min: -10.0,
            max: 0.0,
            step: 0.01
        },
        wormMountDist: {
            value: 15.0,
            min: 0.0,
            max: 50.0,
            step: 0.01
        },
        wormOffsetX: {
            value: -0.0,
            min: -10.0,
            max: 10.0,
            step: 0.01
        },
        wormOffsetY: {
            value: 5.0,
            min: 0.0,
            max: 20.0,
            step: 0.01
        },
        wormOffsetZ: {
            value: -1.0,
            min: -10.0,
            max: 10.0,
            step: 0.01
        },
    });

    const { 
        colliderLayer, 
        aircurrentLayer,
        artLayer, 
        crosshairArtworkRef,
        fullDisplayRef,
        switchArtInstantRef,
        artPlanesRef,
        joystickXRef,
        joystickYRef,
        touchJumpRef, 
        sceneLoadedRef,
        curPlaneIndexRef,
        wormRef,
        wormDirRef,
        wormSpeedRef,
        ridingWormRef,
        shouldTeleportWormRef,
        controlsEnabledRef,
        crosshairRef,
        introCamPctRef,
        jumpRef,
        waypointPctRef,
        tutorialRef,
        tutorialVisibleRef,
        curColliderNameRef,
        darkYecheEnabled,
        darkYecheVideoRef
    } = useContext(SceneContext);

    const { camera, gl } = useThree();

    const FALLING_TOLERANCE = 1.0;

    const prevGroundY = useRef(-1.0);

    const allowMountWorm = useRef(false);

    const sprintingRef = useRef(false); 

    const xInput = useRef(0.0);
    const yInput = useRef(0.0);
    const aboveCollider = useRef(false);
    const spaceInput = useRef(false);

    const hitLedgeRef = useRef(false);
    const hitLedgeAtRef = useRef(0.0);
    const ledgeSafePosRef = useRef(null);

    const hitBackWall = useRef(false);
    const hitFrontWall = useRef(false);
    const hitLeftWall = useRef(false);
    const hitRightWall = useRef(false);
    
    const yAccel = useRef(0.0);
    const yVel = useRef(0.0);

    const frameRef = useRef(0);

    const leftFullDisplayViewAt = useRef(0.0);

    const raycaster = new THREE.Raycaster();

    const flipWormDir = useRef(false);

    const xInputSmooth = useRef(0.0);
    const yInputSmooth = useRef(0.0);

    const mouseLookActive = useRef(false);

    const touchIndexRef = useRef(0);

    const curMousePosition = useRef({x: 0, y: 0});
    const lastMousePosition = useRef({x: 0, y: 0});

    const camWorldDir = new THREE.Vector3();
    const raycastDir = new THREE.Vector3();
    const posInFront = new THREE.Vector3();

    const pCamPos = new THREE.Vector3(0,0,0);
    const ppCamPos = new THREE.Vector3(0,0,0);

    const tetherObjectRef = useRef(null);
    const tetheredRef = useRef(false);
    const tetheredLocalPos = useRef(new THREE.Vector3());

    const hitWallThreshold = 2.0;

    // useEffect(() => {
    //     camera.rotation.set(0, 0, 0);
    //     console.log('resetting camera rotation');
    // }, [camera]);
    
    useEffect(() => {
        const handleKeyDown = (event) => {
            if(!sceneLoadedRef.current) return;

            switch(event.key) {
                case 'w':
                case 'ArrowUp':
                    yInput.current = 1.0;
                    break;
                case 'a':
                case 'ArrowLeft':
                    xInput.current = -1.0;
                    break;
                case 's':
                case 'ArrowDown':
                    yInput.current = -1.0;
                    break;
                case 'd':
                case 'ArrowRight':
                    xInput.current = 1.0;
                    break;
                case 'Shift':
                    sprintingRef.current = true;
                    break;
                case ' ':
                    spaceInput.current = true;
                    break;
            }
        };

        const handleKeyUp = (event) => {
            if(!sceneLoadedRef.current) return;

            switch(event.key) {
                case 'w':
                case 's':
                case 'ArrowUp':
                case 'ArrowDown':
                    yInput.current = 0.0;
                    break;
                case 'a': 
                case 'd': 
                case 'ArrowLeft': 
                case 'ArrowRight':
                    xInput.current = 0.0;
                    break;
                case 'Shift':
                    sprintingRef.current = false;
                    break;
                case ' ':

                    if(spaceInput.current && ridingWormRef.current) {
                        console.log('flipping worm direction')
                        flipWormDir.current = !flipWormDir.current;
                    }


                    spaceInput.current = false;
                    break;
            }
        };

        const handleInputUp = (event) => {
            if(!sceneLoadedRef.current) return;

            mouseLookActive.current = false;
        };

        const handleInputDown = (event) => {
            if(!sceneLoadedRef.current) return;

            if(event.target.tagName == "CANVAS") {
                if(isMobile) {

                    //get index of touch
                    for(let i = 0; i < event.touches.length; i++) {
                        if(event.touches[i].identifier == event.changedTouches[0].identifier) {
                            touchIndexRef.current = i;
                            break;
                        }
                    }

                    mouseLookActive.current = true;
                    lastMousePosition.current = {x: event.touches[touchIndexRef.current].clientX, y: event.touches[touchIndexRef.current].clientY};
                    curMousePosition.current = {x: event.touches[touchIndexRef.current].clientX, y: event.touches[touchIndexRef.current].clientY};
                    event.preventDefault();
                    //disable scroll
                }
                else {
                    mouseLookActive.current = true;
                    lastMousePosition.current = {x: event.clientX, y: event.clientY};
                    curMousePosition.current = {x: event.clientX, y: event.clientY};
                }
            }

        }; 
        
        const handleInputMove = (event) => {
            if(!sceneLoadedRef.current) return;

            if(mouseLookActive.current) {
                let clientX, clientY;

                if(isMobile) {
                    clientX = event.touches[touchIndexRef.current].clientX;
                    clientY = event.touches[touchIndexRef.current].clientY;
                    //disable scroll
                    event.preventDefault();
                }

                else {
                    clientX = event.clientX;
                    clientY = event.clientY;
                }

                curMousePosition.current = {x: clientX, y: clientY};
            }
        };

        
        //add events for touch controls
        if(isMobile) {
            document.addEventListener('touchstart', handleInputDown, {passive: false});   
            document.addEventListener('touchmove', handleInputMove, {passive: false});
            document.addEventListener('touchend', handleInputUp, {passive: false});
        }
        
        else {
            document.addEventListener('mousedown', handleInputDown);
            document.addEventListener('mousemove', handleInputMove);
            document.addEventListener('mouseup', handleInputUp);    
        }

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            if(isMobile) {
                document.removeEventListener('touchstart', handleInputDown);
                document.removeEventListener('touchmove', handleInputMove);
                document.removeEventListener('touchend', handleInputUp);
            }
            
            else {
                document.removeEventListener('mousedown', handleInputDown);
                document.removeEventListener('mousemove', handleInputMove);
                document.removeEventListener('mouseup', handleInputUp);    
            }

            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        }    
    }, []);

    function handleMouseLook() {
        if(isMobile) {
            xInput.current = joystickXRef.current;
            yInput.current = joystickYRef.current;

            if(touchJumpRef.current && !spaceInput.current && ridingWormRef.current) {
                console.log('flipping worm direction')
                flipWormDir.current = !flipWormDir.current;
            }

            spaceInput.current = touchJumpRef.current;
        }

        if(mouseLookActive.current) {
            let xDiff = curMousePosition.current.x - lastMousePosition.current.x;
            let yDiff = curMousePosition.current.y - lastMousePosition.current.y;

            //rotate the camera around the global Y axis
            camera.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), deg2rad(-xDiff * rotSpeed));

            //rotate the camera around object right 
            camera.rotateOnAxis(new THREE.Vector3(1, 0, 0), deg2rad(-yDiff * rotSpeed));

            //update camera matrix
            // camera.updateMatrix();

            lastMousePosition.current = {x: curMousePosition.current.x, y: curMousePosition.current.y};
        }

        else {
            camera.getWorldDirection(camWorldDir);
            camWorldDir.y = 0;

            posInFront.copy(camera.position);
            posInFront.add(camWorldDir.multiplyScalar(10));

            let ogRot = camera.quaternion.clone();

            camera.lookAt(posInFront);
            //save the camera rotation
            let newCamRot = camera.quaternion.clone();

            //reset the camera rotation
            camera.quaternion.copy(ogRot);
            camera.quaternion.slerp(newCamRot, snapSpeed);            
        }
    }

    function smoothLerp(pointA, pointB, amt) {
        amt = amt < 0 ? 0 : amt;
        amt = amt > 1 ? 1 : amt;
        amt = (Math.sin(amt * Math.PI - Math.PI / 2) + 1) / 2;
      
        const result = new THREE.Vector3();
        result.x = THREE.MathUtils.lerp(pointA.x, pointB.x, amt);
        result.y = THREE.MathUtils.lerp(pointA.y, pointB.y, amt);
        result.z = THREE.MathUtils.lerp(pointA.z, pointB.z, amt);
      
        return result;
    }      

    function updateFullDisplayMode(camera) {

        //get index of plane whose plane.name == fullDisplayRef.current, if each element of artPlanesRef is {artName: artName, roomID: roomID, plane: plane}

        //don't do this every frame
        let fullDisplayPlane = artPlanesRef.current[curPlaneIndexRef.current].plane;

        //calculate point in front of plane using its normal

        let planeWorldPos = new THREE.Vector3();
        fullDisplayPlane.getWorldPosition(planeWorldPos);

        let pointInFront = planeWorldPos.clone();

        // let frontDir = new THREE.Vector3(0, 0, 0);
        // fullDisplayPlane.getWorldDirection(frontDir);

        //get global quaternion of plane
    
        let planeWorldRot = new THREE.Quaternion();
        fullDisplayPlane.getWorldQuaternion(planeWorldRot);


        //calculate lockon distance based on size of plane, using fullDisplayPlane.boundingBox, find the larger of the two dimensions and multiply by 1.5
        let planeW = fullDisplayPlane.geometry.boundingBox.max.x - fullDisplayPlane.geometry.boundingBox.min.x;
        let planeH = fullDisplayPlane.geometry.boundingBox.max.y - fullDisplayPlane.geometry.boundingBox.min.y;
        let planeSize = Math.max(planeW, planeH);
        let lockOnDistance = planeSize * 3;

        let frontDir = new THREE.Vector3(0, -1, 0);
        frontDir.applyQuaternion(planeWorldRot);
        frontDir.multiplyScalar(lockOnDistance);

        pointInFront.add(frontDir);

        let camPosLerpSpeed = switchArtInstantRef.current ? 1 : tourPositionLerpFactor;
        let camRotLerpSpeed = switchArtInstantRef.current ? 1 : tourRotationLerpFactor;

        //lerp camera to point in front of plane
        // camera.position.lerp(pointInFront, camPosLerpSpeed);

        let lerpedPos = smoothLerp(camera.position, pointInFront, camPosLerpSpeed);

        camera.position.set(lerpedPos.x, lerpedPos.y, lerpedPos.z);


        //use lookAt and gradually interpolate from current rotation to look at a work
        if(!mouseLookActive.current) {
            let ogRot = camera.quaternion.clone();
            
            camera.lookAt(planeWorldPos);
            //save the camera rotation
            let newCamRot = camera.quaternion.clone();
            
            //reset the camera rotation
            camera.quaternion.copy(ogRot);
            camera.quaternion.slerp(newCamRot, camRotLerpSpeed);
        }
    }

    function raycastAndTether(camera, ray, camForwardDir, shouldTether, deltaMove) {
        let collisionRoot = ref.current.children;

        //raycast down and forward for movement
        if(!hitFrontWall.current) {
            raycastDir.set(camForwardDir.x, -1, camForwardDir.z);
        }
        else {
            raycastDir.set(0, -1, 0);
        }
        raycastDir.normalize();

        ray.origin.set(camera.position.x, camera.position.y + rayYOffset, camera.position.z);
        ray.direction.set(raycastDir.x, raycastDir.y, raycastDir.z);
        raycaster.layers.set(colliderLayer);

        // raycaster.layers.set(colliderLayer);

        // let collisionRoot = ref.current.children;
        //not sure it's necessary to raycast against only collider objects if you're using layers
        const intersections = raycaster.intersectObjects(collisionRoot, true);
    
        let distance = 0;

        aboveCollider.current = false;

        //what is ground level
        let groundY = 0;

        let highestColliderIndex = 0;

        if (intersections.length > 0) {
            // console.log('num intersections: ' + intersections.length)
            aboveCollider.current = true;
            
            //set groundY to intersection with highest y value
            for(let i = 0; i < intersections.length; i++) {

                // let isBelowPlayer = intersections[i].point.y < camera.position.y;

                if(intersections[i].point.y > groundY) {
                    groundY = intersections[i].point.y;
                    highestColliderIndex = i;
                    //print name of object that is the highest collider
                    // console.log(intersections[i].object.name);
                }
            }

            curColliderNameRef.current = intersections[highestColliderIndex].object.name;

            let isFalling = camera.position.y - groundY > playerHeight + FALLING_TOLERANCE;
            let receivingInput = Math.abs(xInput.current) > 0.00 || Math.abs(yInput.current) > 0.00;

            if (!receivingInput && !isFalling && shouldTether) {
                if(!tetheredRef.current) {
                    //get position of the camera in the collided object's local object space
                    let localPos = new THREE.Vector3();
                    localPos.copy(camera.position);
                    intersections[highestColliderIndex].object.worldToLocal(localPos);

                    tetheredLocalPos.current = localPos;

                    tetheredRef.current = true;

                    tetherObjectRef.current = intersections[highestColliderIndex].object;
                }

                else {
                    //get the world position of tetheredLocalPos
                    let worldPos = new THREE.Vector3();
                    worldPos.copy(tetheredLocalPos.current);
                    tetherObjectRef.current.localToWorld(worldPos);

                    //lerp camera to worldPos to smooth out movement
                    camera.position.lerp(worldPos, 1.0 * deltaMove);
                    // camera.position.set(worldPos.x, worldPos.y, worldPos.z);
                }
            }

            else {
                tetheredRef.current = false;
            }
        }        

        return groundY;
    }

    function setRidingWorm(riding) {
        ridingWormRef.current = riding;

        if(jumpRef.current) {
            if(riding) {
                jumpRef.current.classList.add('Uturn');
                jumpRef.current.classList.remove('Jump');
            }

            else {
                jumpRef.current.classList.add('Jump');
                jumpRef.current.classList.remove('Uturn');    
            }
        }
    }

    function updateWormMount(deltaMove) {

        let wormWorldPos = new THREE.Vector3();
        wormRef.current.getWorldPosition(wormWorldPos, xInputSmooth, yInputSmooth);

        let inMountRange = camera.position.distanceTo(wormWorldPos) < wormMountDist;

        //check if camera is facing worm or away using dot product
        let wormDir = new THREE.Vector3(0, 0, 1);

        let movingForward = isMobile ? joystickYRef.current > 0 : yInputSmooth.current > 0 ;

        let canMount = inMountRange && (movingForward || fullDisplayRef.current != "");

        //MOUNT IF NOT CURRENTLY MOUNTED + CLOSE + MOVING FORWARD
        if(!ridingWormRef.current && canMount) {
            console.log('mounting worm');
            setRidingWorm(true);
        }

        if(ridingWormRef.current) {
            if(allowMountWorm.current) {
                allowMountWorm.current = false;
                console.log('allow mount worm false');
            }

            let wormOffset = new THREE.Vector3(wormOffsetX, wormOffsetY, wormOffsetZ);

            let wormOffsetWorld = new THREE.Vector3();
            wormOffsetWorld.copy(wormOffset);
            wormRef.current.localToWorld(wormOffsetWorld);

            wormWorldPos.add(wormOffset);
            camera.position.lerp(wormWorldPos, 10.0 * deltaMove);

            // if(spaceInput.current) {
            //     flipWormDir.current = !flipWormDir.current;
            //     console.log('flipped worm');
            // }

            wormDirRef.current = Math.round(flipWormDir.current ? -1 : 1);
            wormSpeedRef.current = yInputSmooth.current; //yInputSmooth.current > 0 ? yInputSmooth.current : 0;

            //face worm direciton if the worm is moving
            if(!mouseLookActive.current || yInputSmooth.current > 0) {
                let wormQuat = wormRef.current.quaternion.clone();
                camera.quaternion.slerp(wormQuat, 1.0 * deltaMove);
            }

            //TODO dismount if near entrance of tower and moving away 

            let movingAwayFromTower = !(wormDirRef.current == 1 && wormSpeedRef.current > 0.0);
            let nearEntrance = waypointPctRef.current < 0.005;

            if(movingAwayFromTower && nearEntrance) {
                console.log('dismounting worm');
                setRidingWorm(false);
            }

        }

        else if(!allowMountWorm.current && camera.position.distanceTo(wormWorldPos) > wormMountDist / 2.0) {
            allowMountWorm.current = true;
            console.log('allowing worm mount');
        }
    }

    function updateFreeNavigationMode(camera, state, ray, delta) {

        //if camera is within 20 units of wormRef.current.position, lerp camera to wormRef.current.position and set camera to look at wormRef.current.position
        
        //limit maximum value of delta to avoid sudden jumps in position when there's lag
        let deltaMove = Math.min(delta, 0.1);

        xInputSmooth.current = xInput.current;
        yInputSmooth.current = yInput.current;


        if(tutorialVisibleRef.current) {
        //get if the player is moving 
            let isMoving = Math.abs(xInputSmooth.current) > 0.00 || Math.abs(yInputSmooth.current) > 0.00;
            if(isMoving) {
                tutorialRef.current.classList.add('fadeOut');
                tutorialVisibleRef.current = false;

                // if(darkYecheEnabled && darkYecheVideoRef.current) {
                //     //pause the video
                //     darkYecheVideoRef.current.pause();
                // }    

                //in 500s remove tutorialRef from dom
                setTimeout(() => {
                    // tutorialRef.current.remove();
                    //set display to none
                    tutorialRef.current.style.display = 'none';

                    if(darkYecheEnabled) {
                        //remove the video from the dom
                        darkYecheVideoRef.current.remove();
                    }    
                }, 500);
            }
        }



        if(wormRef.current) {
            updateWormMount(deltaMove);
            if(ridingWormRef.current) {
                return;
            }
        }

        let collisionRoot = ref.current.children;

        let camWorldDir = new THREE.Vector3();
        //get camera world direction
        camera.getWorldDirection(camWorldDir);
        //set y to 0
        camWorldDir.y = 0;

        //move forward and backward in the direction of camWorldDir
        let camForwardDir = new THREE.Vector3();
        camForwardDir.copy(camWorldDir);

        let camRightDir = new THREE.Vector3();
        camRightDir.copy(camWorldDir);
        // camRightDir.applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);
        camRightDir.crossVectors(camWorldDir, new THREE.Vector3(0, 1, 0));
        
        //raycast straight down for aircurrent
        ray.direction.set(0, -1, 0);
        ray.origin.copy(camera.position);

        raycaster.layers.set(aircurrentLayer);

        let aboveAircurrent = false;
        let airCurrentY = 0;
        
        let aircurrentIntersections = raycaster.intersectObjects(collisionRoot, true);

        if(aircurrentIntersections.length > 0) {
            aboveAircurrent = true;
            airCurrentY = aircurrentIntersections[0].point.y;
        }

        let groundY = raycastAndTether(camera, ray, camForwardDir, !ridingWormRef.current, deltaMove);
        // xInputSmooth.current = lerp(xInputSmooth.current, xInput.current, 10.0 * delta);
        // yInputSmooth.current = lerp(yInputSmooth.current, yInput.current, 10.0 * delta);

        //raycast against walls
        switch(frameRef.current % 4) {
            case 0: 
                ray.direction.set(0, 0, 1).transformDirection(camera.matrixWorld);
                
                const intersectionsBackward = raycaster.intersectObjects(collisionRoot, true);
                hitBackWall.current = intersectionsBackward.length > 0 && intersectionsBackward[0].distance < hitWallThreshold;
            
                break;

            case 1:
                ray.direction.set(0, 0, -1).transformDirection(camera.matrixWorld);
                const intersectionsForward = raycaster.intersectObjects(collisionRoot, true);

                hitFrontWall.current = intersectionsForward.length > 0 && intersectionsForward[0].distance < hitWallThreshold;
                break;
            case 2:
                ray.direction.set(1, 0, 0).transformDirection(camera.matrixWorld);
                const intersectionsRight = raycaster.intersectObjects(collisionRoot, true);

                hitRightWall.current = intersectionsRight.length > 0 && intersectionsRight[0].distance < hitWallThreshold;

                break;

            case 3:
                ray.direction.set(-1, 0, 0).transformDirection(camera.matrixWorld);
                const intersectionsLeft = raycaster.intersectObjects(collisionRoot, true);

                hitLeftWall.current = intersectionsLeft.length > 0 && intersectionsLeft[0].distance < hitWallThreshold;
        }

        //if the player is close to a wall, don't let them move in that direction
        if(hitBackWall.current) {
            yInputSmooth.current = Math.max(yInputSmooth.current, 0.0);
        }

        if(hitFrontWall.current) {
            yInputSmooth.current = Math.min(yInputSmooth.current, 0.0);
        }

        if(hitRightWall.current) {
            xInputSmooth.current = Math.min(xInputSmooth.current, 0.0);
        }

        if(hitLeftWall.current) {
            xInputSmooth.current = Math.max(xInputSmooth.current, 0.0);
        }

        let moveSpeed = sprintingRef.current ? playerSpeed * 3.0 : playerSpeed;

        // console.log(deltaMove);

        //move cam forward along camForwardDir by yInputSmooth
        camera.position.add(camForwardDir.multiplyScalar(yInputSmooth.current * moveSpeed * deltaMove));

        //move cam right along camRightDir by xInputSmooth
        camera.position.add(camRightDir.multiplyScalar(xInputSmooth.current * moveSpeed * deltaMove));

        if(spaceInput.current) {
            if(yVel.current == 0.0) {
                yAccel.current = jumpStartAccel;
                yVel.current = jumpStartVel;
                spaceInput.current = false;
            }
        }

        let isJumping = yVel.current > 0.0 || (camera.position.y - groundY) > playerHeight + 1;
        
        //aircurrent 
        if(aboveAircurrent) {
            let verticalDistance = Math.max(camera.position.y - airCurrentY, 0.0);
            
            let maxHeightDampen = 1.0 - clamp(smoothstep(verticalDistance, currentDampenStart, currentDampenEnd), 0.0, 1.0);
            
            yVel.current = lerp(yVel.current, 0.5 * maxHeightDampen, 0.3);
            camera.position.y += yVel.current * deltaMove * 100.0;
        }

        //player is jumping / falling
        else if(isJumping) {
            //this block should update yVel
            yVel.current += yAccel.current * deltaMove * 100.0;

            let heightAboveGround = camera.position.y - (groundY + playerHeight);

            if(yAccel.current < 0.01 || heightAboveGround > maxJumpHeight) {
                if(yAccel.current > 0.01) {
                    yAccel.current = 0.0;
                }

                yAccel.current -= 0.001;
            }
            else {
                yAccel.current *= 0.85;
                yVel.current *= 0.95;
            }
            
            yVel.current = Math.max(yVel.current, minYVel);
            camera.position.y += yVel.current * deltaMove * 100.0;            

            //prevent camera from going underground
            if(groundY > camera.position.y - playerHeight) {
                camera.position.y = Math.max(camera.position.y, groundY + playerHeight);
            }
        }

        //player is grounded
        else if(aboveCollider.current) {
            yVel.current = 0.0;
            yAccel.current = 0.0;

            //lerp camera to groundY + playerHeight
            camera.position.y = lerp(camera.position.y, groundY + playerHeight, 0.1);
        }    


        // let ledgeAnimLen = 15;
        // let ledgeLerp = 1.0 / ledgeAnimLen;
        // let movingBackFromLedge = hitLedgeRef.current && frameRef.current - hitLedgeAtRef.current < ledgeAnimLen;

        // //LEDGE DETECTION 
        // if((prevGroundY.current != -1 && prevGroundY.current - groundY > ledgeHeight) || movingBackFromLedge) {
        //     // ledgeDetect = true;
        //     //check that pCamPos isn't 0,0,0
        //     if(ledgeSafePosRef.current == null && (ppCamPos.x != 0 || ppCamPos.y != 0 || ppCamPos.z != 0)) {

        //         console.log('NEAR LEDGE');


        //         let curCamPos = camera.position.clone();

        //         //get vector from camera to ppCamPos
        //         let camToPPCamPos = new THREE.Vector3();
        //         camToPPCamPos.copy(ppCamPos);
        //         camToPPCamPos.sub(curCamPos);
        //         camToPPCamPos.normalize();

        //         //calculate a safe position to move the player away from the ledge, behind ppCamPos
        //         let safePos = new THREE.Vector3();
        //         safePos.copy(curCamPos);
        //         safePos.add(camToPPCamPos.multiplyScalar(5.0));
        //         ledgeSafePosRef.current = safePos;

        //         hitLedgeRef.current = true;
        //         hitLedgeAtRef.current = frameRef.current;
        //     }

        //     if(ledgeSafePosRef.current != null) {
        //         camera.position.lerp(ledgeSafePosRef.current, ledgeLerp);

        //         //recalculate the new groundY and 
        //         groundY = raycastAndTether(camera, ray, camForwardDir, false);

        //         xInputSmooth.current = 0;
        //         yInputSmooth.current = 0;

        //         xInput.current = 0;
        //         yInput.current = 0;
        //     }

        //     else {
        //         console.log('ledgeSafePosRef is null');
        //     }
        // }

        // if(hitLedgeRef.current && frameRef.current - hitLedgeAtRef.current > ledgeAnimLen) {
        //     hitLedgeRef.current = false;
        //     ledgeSafePosRef.current = null;
        // }

        // prevGroundY.current = groundY;

        // ppCamPos.set(pCamPos.x, pCamPos.y, pCamPos.z);
        // pCamPos.set(camera.position.x, camera.position.y, camera.position.z);   
    }

    function showCrosshair() {
        // crosshairRef.current.classList.add("fadeIn");
        crosshairRef.current.classList.remove("fadeOut");
        crosshairRef.current.classList.add("hueAnim");
    }

    useFrame((state, delta) => {     
        if(!controlsEnabledRef.current) return;
        
        handleMouseLook();
        
        frameRef.current++;
        
        let camera = state.camera;
        let ray = raycaster.ray;

        // ray.origin.setFromMatrixPosition(camera.matrixWorld.clone());

        let scene = state.scene;

        //if camFOV differs from the current fov, update it
        if(Math.abs(camera.fov - camFOV) > 0.01 && !isNaN(camFOV)) {
            //lerp the fov
            camera.fov = lerp(camera.fov, camFOV, 0.05);

            // camera.fov = camFOV;
            camera.updateProjectionMatrix();
            // console.log("updated fov");
        }

        //full display mode
        if(fullDisplayRef.current && fullDisplayRef.current != "") {
            updateFullDisplayMode(camera);

            if(yInput.current != 0.0 || xInput.current != 0.0) {
                //switch back to free navigation mode
                fullDisplayRef.current = "";
                leftFullDisplayViewAt.current = frameRef.current;


                if(shouldTeleportWormRef.current) {
                    setRidingWorm(true);
                    shouldTeleportWormRef.current = false;
                }


                //fade in crosshair on mobile
                if(isMobile) showCrosshair();
            }
        }

        //normal mode
        else {
            updateFreeNavigationMode(camera, state, ray, delta);
        }

        //raycast against art from center of screen on mobile
        if(frameRef.current % 10 == 0 && isMobile) {

            // console.log('raycasting against art..');

            ray.origin.set(camera.position.x, camera.position.y, camera.position.z);

            //raycast against artLayer
            ray.direction.set(0, 0, -1).transformDirection(camera.matrixWorld);
            raycaster.layers.set(artLayer);
            const intersectionsArt = raycaster.intersectObjects(state.scene.children, true);

            if(introCamPctRef.current >= 1.0) {
                //mobile not in full display mode
                if(intersectionsArt.length > 0) {

                    // console.log('got hit ' + intersectionsArt[0].object.name);

                    if(fullDisplayRef.current == "") {
                        crosshairRef.current.classList.add('hueAndPulse');
                        crosshairRef.current.classList.remove('hueAnim');    
                    }

                    //highlight work
                    if(crosshairArtworkRef.current != intersectionsArt[0].object.name) {
                        crosshairArtworkRef.current = intersectionsArt[0].object.name;
                    }
                }

                else {
                    crosshairRef.current.classList.add('hueAnim');    
                    crosshairRef.current.classList.remove('hueAndPulse');
                }

                // else if(!isMobile || fullDisplayRef.current != "") {
                //     crosshairRef.current.classList.add('hueAnim');    
                //     crosshairRef.current.classList.remove('hueAndPulse');
                // }
            }
        }
    });

    return(
    <>
    </>
    );
});

export default FirstPersonControls;

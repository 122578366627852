import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { Engine } from 'react-matter-js';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { useGLTF, Html } from '@react-three/drei';
import { SceneContext } from "./Nome.js";
import SelectCrosshair from './SelectCrosshair.js';
import { isMobile, isTablet } from 'react-device-detect';
import { RigidBody } from '@react-three/rapier';

//Used to position floating objects on the water
function getWaveInfo(waves, offsetX, offsetZ, x, z, time) {
    const pos = new THREE.Vector3()
    const tangent = new THREE.Vector3(1, 0, 0)
    const binormal = new THREE.Vector3(0, 0, 1)
    waves.forEach((w) => {
        const k = (Math.PI * 2.0) / w.wavelength
        const c = Math.sqrt(9.8 / k)
        const d = new THREE.Vector2(
            Math.sin((w.direction * Math.PI) / 180),
            -Math.cos((w.direction * Math.PI) / 180)
        )
        const f = k * (d.dot(new THREE.Vector2(x, z)) - c * time)
        const a = w.steepness / k

        pos.x += d.x * (a * Math.cos(f))
        pos.y += a * Math.sin(f)
        pos.z += d.y * (a * Math.cos(f))

        tangent.x += -d.x * d.x * (w.steepness * Math.sin(f))
        tangent.y += d.x * (w.steepness * Math.cos(f))
        tangent.z += -d.x * d.y * (w.steepness * Math.sin(f))

        binormal.x += -d.x * d.y * (w.steepness * Math.sin(f))
        binormal.y += d.y * (w.steepness * Math.cos(f))
        binormal.z += -d.y * d.y * (w.steepness * Math.sin(f))
    })

    const normal = binormal.cross(tangent).normalize()
    return { position: pos, normal: normal }
}


export default function Gallery({ waves }) {
    const galleryGLB = useGLTF(process.env.PUBLIC_URL + '/models/nome/' + 'beach_shack.glb');
    const signpostGLB = useGLTF(process.env.PUBLIC_URL + '/models/nome/' + 'signpost.glb');
    const gachaMachineGLB = useGLTF(process.env.PUBLIC_URL + '/models/nome/' + 'gacha_machines.glb');

    const galleryTex1 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/shed_1_bright.jpg');
    const galleryTex2 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/shed_2_bright.jpg');
    const galleryTex3 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/shed_3_bright.jpg');
    const galleryTex4 = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/shed_4_bright.jpg');

    const animTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/nomeanim.jpg');

    const [galleryObject, setGalleryObject] = useState(null);

    const [artObjects, setArtObjects] = useState([]);

    const [signpostObjects, setSignpostObjects] = useState([]);

    const [colliders, setColliders] = useState([]);

    const [gachaMachines, setGachaMachines] = useState(null);

    const [gigiPosition, setGigiPosition] = useState(new THREE.Vector3(0, 0, 0));

    const [galleryPosition, setGalleryPosition] = useState(new THREE.Vector3(0, 0, 0));
    const galleryScale = 1.0;

    const MAX_CLICK_DIST = 150.0;

    const lastArtTouchedRef = useRef("");

    const [spawnPoint, setSpawnPoint] = useState(null);

    const floatPoints = useRef([]);

    const glassMatsRef = useRef([]);

    const rigidbodyRefs = useRef([]);

    const rootRef = useRef();

    //dummy quaternion for slerping
    const dummyRot = new THREE.Quaternion();

    const spriteanimVert = `
    precision mediump float;

    varying vec2 vUv;

    void main() {
        vUv = uv;

        //flip uv y
        vUv.y = 1.0 - vUv.y;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

const spriteanimFrag = `
    precision mediump float;

    uniform float time;
    uniform sampler2D map;
    uniform float offset;

    varying vec2 vUv;

    const float FRAME_COUNT = 25.0;
    const float SPRITES_PER_ROW = 5.0;
    const float FRAME_DURATION = 0.2; // Adjust this to change how long each frame displays.

    void main() {
        // Calculate the size of each sprite frame.
        vec2 frameSize = vec2(1.0 / SPRITES_PER_ROW, 1.0 / SPRITES_PER_ROW);

        // Get the current frame based on time and FRAME_DURATION.
        float currentFrame = mod(floor((time + offset) / FRAME_DURATION), FRAME_COUNT);
        
        // Get the current row and column based on the frame.
        float row = floor(currentFrame / SPRITES_PER_ROW);
        float column = mod(currentFrame, SPRITES_PER_ROW);
        
        // Calculate the offset for the current frame.
        vec2 frameOffset = vec2(column * frameSize.x, row * frameSize.y);
        
        // Sample the texture using the offset and the fragment's UV.
        vec2 sampledUv = vUv * frameSize + frameOffset;
        vec4 color = texture2D(map, sampledUv);

        //compute absolute distance of vUv.x from 0.5
        float dist = abs(vUv.x - 0.5);

        if(dist > 0.35) {
            discard;
        }

        gl_FragColor = color;
    }
`;

    const {
        waterTimescaleRef,
        colliderLayer,
        artLayer,
        artObjectsRef,
        fullDisplayRef,
        switchArtInstantRef,
        curPlaneIndexRef,
        artworkInfoRef,
        crosshairRef,
        crosshairArtworkRef,
        artworkPath,
    } = useContext(SceneContext);

    const { camera } = useThree();

    //Set up gallery objects
    useEffect(() => {
        floatPoints.current = [];

        const nodes = galleryGLB.nodes;

        let spawnPointTemp = null;

        let collidersTemp = [];
        let artObjectsTemp = [];

        let signpostObjectsTemp = [];

        let galleryTemp;

        for (const [key, value] of Object.entries(nodes)) {
            if (key.startsWith("gallery")) {
                galleryTemp = value;

                // console.log(galleryTemp);

                //loop through all children of galleryTemp and set the material to THREE.MeshBasicMaterial and preserve the material
                for (let i = 0; i < galleryTemp.children.length; i++) {
                    let child = galleryTemp.children[i];

                    // child.receiveShadow = true;

                    let map;

                    if (child.material.name == 'shed_1') {
                        map = galleryTex1;
                    }

                    else if (child.material.name == 'shed_2') {
                        map = galleryTex2;
                    }

                    else if (child.material.name == 'shed_3') {
                        map = galleryTex3;
                    }

                    else if (child.material.name == 'shed_4') {
                        map = galleryTex4;
                    }

                    map.flipY = false;

                    map.minFilter = THREE.NearestFilter;
                    map.magFilter = THREE.NearestFilter;

                    map.encoding = THREE.sRGBEncoding;

                    child.material = new THREE.MeshBasicMaterial({
                        map: map,
                        side: THREE.DoubleSide,
                        // transparent: true,
                        // opacity: 0.7
                    });
                }

                // let map = galleryTemp.material.map;

                // galleryTemp.material = new THREE.MeshBasicMaterial({map: map});

                setGalleryObject(value);
            }
        }

        for (const [key, value] of Object.entries(nodes)) {
            if (key.startsWith("floatPoint")) {
                let localPos = new THREE.Vector3();
                galleryTemp.worldToLocal(localPos.copy(value.position));

                floatPoints.current.push(localPos);
            }

            else if (key.startsWith("spawn")) {
                //calculate world position of spawn point
                spawnPointTemp = value.position.clone();
                spawnPointTemp.multiplyScalar(galleryScale);

                setSpawnPoint(spawnPointTemp);
            }

            else if (key.startsWith("gigi_start_pos")) {
                let gigiPos = value.position.clone();
                gigiPos.multiplyScalar(galleryScale);

                setGigiPosition(gigiPos);
            }

            else if (key.startsWith("art")) {
                artObjectsTemp.push(value);
            }

            else if (key.startsWith("signpost")) {
                signpostObjectsTemp.push(value);
            }

            else if (key.startsWith("collider")) {
                if (value.geometry != undefined) {
                    collidersTemp.push(value);
                }
            }

            else if (key.startsWith("gacha")) {
                setGachaMachines(value);
            }
        }

        setColliders(collidersTemp);
        setArtObjects(artObjectsTemp);
        setSignpostObjects(signpostObjectsTemp);

        resetPlayer(spawnPointTemp.x, spawnPointTemp.y, spawnPointTemp.z);

    }, [galleryGLB, galleryTex1, galleryTex2, galleryTex3, galleryTex4]);


    useEffect(() => {
        //traverse all the objects in the gachaMachineGLB
        if (!gachaMachineGLB) return;
        if (!animTex) return;


        let spriteAnimMat = new THREE.ShaderMaterial({
            uniforms: {
                time: { value: 0.0 },
                map: { value: animTex },
                offset: { value: 0.0 },
            },
            vertexShader: spriteanimVert,
            fragmentShader: spriteanimFrag,
            transparent: true,
        }); 

        let offsetCounter = 0.0;

        gachaMachineGLB.scene.traverse((child) => {
            // console.log(child.name);

            if(child.name.includes('glass')) {
                //replace material with spriteAnimMat
                child.material = spriteAnimMat.clone();

                glassMatsRef.current.push(child.material);
            
                child.material.uniforms.time.value = 0.0;
                child.material.uniforms.offset.value = offsetCounter;

                offsetCounter++;
            }
        });

    }, [gachaMachineGLB, animTex]);



    function resetPlayer(x, y, z) {
        console.log('resetting player');
        camera.position.set(x, y, z);
    }

    function updateGalleryBuoy(clock) {
        //loop through all the float points, and convert them from local to world space
        //then apply the wave displacement to them
        if (!rootRef.current) return;

        let rootPos = rootRef.current.position.clone();

        //todo add actual water timescale
        const t = clock.getElapsedTime() * waterTimescaleRef.current;
        const accumulatedPosition = new THREE.Vector3();
        const accumulatedNormal = new THREE.Vector3(0, 0, 0);

        for (let i = 0; i < floatPoints.current.length; i++) {
            let floatPointWorld = new THREE.Vector3();
            rootRef.current.localToWorld(floatPointWorld.copy(floatPoints.current[i]));

            const waveInfo = getWaveInfo(waves, rootPos.x, rootPos.z, floatPointWorld.x, floatPointWorld.z, t);

            accumulatedPosition.y += waveInfo.position.y
            accumulatedPosition.x += rootPos.x;
            accumulatedPosition.z += rootPos.z;

            accumulatedNormal.add(waveInfo.normal)
        }

        accumulatedNormal.divideScalar(floatPoints.current.length);

        //average all floatPoints directions
        accumulatedPosition.divideScalar(floatPoints.current.length);

        rootRef.current.position.lerp(accumulatedPosition, 0.25);

        dummyRot.setFromUnitVectors(new THREE.Vector3(0, 1, 0), accumulatedNormal)

        //gradually rotate towards the average normal
        rootRef.current.quaternion.slerp(dummyRot, 0.25);



        //on the first frame sync is correct, get the local pos and rot relative to buoyRef, use that after to update
        if (rigidbodyRefs.current != null) {

            rigidbodyRefs.current.forEach((rigidbody, i) => {

                if (rigidbody == null) return;
                rigidbody.setNextKinematicTranslation(rootRef.current.position);
                rigidbody.setNextKinematicRotation(rootRef.current.quaternion);
            });
        }

    }

    function showGrapple(artName) {
        if (crosshairRef.current && !isMobile) {


            let artObjectIndex = artObjectsRef.current.findIndex((artObject) => artObject && artObject.artName == artName);

            let tokenID = artObjectsRef.current[artObjectIndex].tokenID;

            //don't show grapple if it hasn't been minted yet
            // if(workDataRef.current[tokenID] == undefined) {
            //     return;
            // }     

            let curPlanePos = new THREE.Vector3();

            crosshairRef.current.classList.add('hueAndPulse');
            crosshairRef.current.classList.remove('hueAnim');

            if (!artObjectsRef.current[artObjectIndex]) {
                console.log('ERROR: could not find art object for ' + artName + ' in artObjectsRef');
                console.log('artObjectIndex: ' + artObjectIndex);
                console.log(artObjectsRef.current);
                return;
            }

            artObjectsRef.current[artObjectIndex].artObject.getWorldPosition(curPlanePos);

            let artObjectDist = curPlanePos.distanceTo(camera.position);

            // if (crosshairArtworkRef.current != "") {

            //     let oldArtIndex = artObjectsRef.current.findIndex((artObject) => artObject && artObject.artName == crosshairArtworkRef.current);
            //     let oldArtDist = artObjectsRef.current[oldArtIndex].artObject.getWorldPosition(new THREE.Vector3()).distanceTo(camera.position);

            //     //don't switch crosshair if the current one is further away
            //     if (oldArtDist < artObjectDist) {
            //         return;
            //     }

            // }

            if (!isMobile && fullDisplayRef.current == "" && artObjectDist < MAX_CLICK_DIST) {
                crosshairArtworkRef.current = artName;
                // console.log('switched crosshairArtworkRef to ' + artName);
            }
        }
    }

    function hideGrapple(artName) {
        if (crosshairRef.current && !isMobile && crosshairArtworkRef.current == artName) {
            //remove pulseAnim class from crosshairRef
            crosshairRef.current.classList.remove('hueAndPulse');
            crosshairRef.current.classList.add('hueAnim');

            if (fullDisplayRef.current == "") {
                crosshairArtworkRef.current = "";
            }
        }
    }

    function hideCrosshair() {
        console.log('hiding crosshair');

        crosshairRef.current.classList.remove("hueAndPulse")
        crosshairRef.current.classList.remove("hueAnim");

        crosshairRef.current.classList.add("fadeOut");
    }

    function showArtFromClick(artName) {
        if (crosshairArtworkRef.current != artName && !isMobile && !isTablet) return;

        let artObjectIndex = artObjectsRef.current.findIndex((artObject) => artObject && artObject.artName == artName);

        let curPlanePos = new THREE.Vector3();

        if (!artObjectsRef.current[artObjectIndex]) {
            console.log('ERROR: could not find art object for ' + artName + ' in artObjectsRef');
            console.log('artObjectIndex: ' + artObjectIndex);
            console.log(artObjectsRef.current);
            return;
        }

        let tokenID = artObjectsRef.current[artObjectIndex].tokenID;

        //don't show work if it hasn't been minted yet
        // if(workDataRef.current[tokenID] == undefined) {
        //     return;
        // } 

        artObjectsRef.current[artObjectIndex].artObject.getWorldPosition(curPlanePos);

        let artObjectDist = curPlanePos.distanceTo(camera.position);

        if (artObjectDist < MAX_CLICK_DIST) {
            curPlaneIndexRef.current = artObjectIndex;

            fullDisplayRef.current = artName;
            switchArtInstantRef.current = false;

            crosshairArtworkRef.current = artName;
            // console.log('setting crosshairArtworkRef to ' + artName);

            if (isMobile) hideCrosshair();
        }
    }

    function setupArtwork(name, artObject, doubleSidedMat, setTextureFromName) {
        if (artObject == null) {
            console.log('art object is null');
            return;
        }

        let matSide = doubleSidedMat ? THREE.DoubleSide : THREE.FrontSide;

        let artName = name;

        // if(extractName) {
        //     artName = name.substring(name.indexOf("img_") + 4, name.length);
        // }

        let artObjectPos = artObject.position.clone();

        let artwork = artworkInfoRef.current.find(artwork => artwork.filename === artName);

        if (artwork == null) {
            console.log('artwork is null: ' + artName);
        }

        else {
            let description = artwork.description;
            let tourOrder = artwork.tourOrder;
            let tokenID = artwork.tokenID;
            let ogFilename = artwork.ogFilename;
            let workType = artwork.workType;

            artObjectsRef.current[tourOrder] = {
                artName: artName,
                ogFilename: ogFilename,
                pos: artObjectPos,
                artObject: artObject,
                tokenID: tokenID,
                description: description,
                workType: workType,
            };
        }

        artObject.name = artName;
        artObject.layers.enable(artLayer);

        if (!setTextureFromName) return;

        let loader = new THREE.TextureLoader();

        loader.load(process.env.PUBLIC_URL + artworkPath + artName + ".jpg", function (texture) {
            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;

            artObject.material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });

            artObject.renderOrder = 300;

            texture.flipY = true;

            let aspect = texture.image.width / texture.image.height;
            let baseScale = 1.0;
            let artObjectScale = [baseScale * aspect, baseScale, baseScale];

            artObject.scale.set(artObjectScale[0], artObjectScale[1], artObjectScale[2]);

            artObject.visible = true;
        });
    }

    useFrame(({ clock }) => {
        updateGalleryBuoy(clock);

        //update time in all the glass materials
        if (glassMatsRef.current != null) {
            glassMatsRef.current.forEach((mat) => {
                mat.uniforms.time.value = clock.getElapsedTime();
            });
        }

    });

    return (
        <>

            {galleryObject && (

                <group ref={rootRef} scale={[galleryScale, galleryScale, galleryScale]}>


                    <primitive
                        object={galleryObject}
                        receiveShadow
                    />

                    <mesh
                        position={gigiPosition}
                        name={'gigi_start_pos'}
                        visible={false}
                    />

                    {colliders.length > 0 && colliders.map((obj, i) =>
                    (<mesh
                        key={i}
                        geometry={obj.geometry}
                        name={obj.name}
                        layers={colliderLayer}
                    >
                        <meshBasicMaterial color={"red"} />
                    </mesh>)
                    )}

                    {colliders.length > 0 && colliders.map((obj, i) =>
                        <React.Fragment key={'frag' + i}>
                            (<RigidBody
                                key={'rb' + i}
                                ref={(element) => (rigidbodyRefs.current[i] = element)}
                                colliders={"trimesh"}
                                type={"kinematicPosition"}
                            >
                                <mesh
                                    geometry={obj.geometry}
                                    visible={true}
                                    name={obj.name}
                                >
                                    <meshBasicMaterial color="red" transparent={true} opacity={0.0} depthWrite={false} depthTest={false} />
                                </mesh>

                            </RigidBody>
                        </React.Fragment>)}


                    {artObjects.map((obj, i) => {

                        let prefix = "img_";
                        let prefixLen = prefix.length;
                        let pureName = obj.name.substring(obj.name.indexOf(prefix) + prefixLen, obj.name.length);

                        return (
                            <SelectCrosshair key={i} name={pureName}>
                                <mesh
                                    onPointerDown={(e) => {
                                        console.log('pointer down on ' + pureName + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                        if (crosshairArtworkRef.current == pureName || isMobile) {
                                            lastArtTouchedRef.current = pureName;
                                        }
                                    }}
                                    onPointerUp={(e) => {
                                        console.log('pointer up on ' + pureName + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                        if (lastArtTouchedRef.current == pureName || isMobile) {
                                            showArtFromClick(pureName);
                                            e.stopPropagation();
                                        }
                                    }}
                                    onPointerLeave={(e) => {
                                        if (lastArtTouchedRef.current == pureName) {
                                            lastArtTouchedRef.current = "";
                                        }
                                    }}
                                    onPointerEnter={() => {
                                        showGrapple(pureName);
                                    }}
                                    onPointerOut={
                                        () => {
                                            hideGrapple(pureName);
                                        }}
                                    key={i}
                                    geometry={obj.geometry}
                                    rotation={obj.rotation}
                                    position={obj.position}
                                    scale={obj.scale}
                                    ref={(element) => {
                                        if (element == null) return;
                                        setupArtwork(pureName, element, true, true);
                                    }}
                                />
                            </SelectCrosshair>
                        )
                    })}

                    {gachaMachineGLB && gachaMachines && (

                        <SelectCrosshair name={gachaMachines.name}>
                            <primitive
                                object={gachaMachineGLB.scene}
                                position={gachaMachines.position}
                                rotation={gachaMachines.rotation}
                                onPointerDown={(e) => {
                                    console.log('pointer down on ' + gachaMachines.name + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                    if (crosshairArtworkRef.current == gachaMachines.name || isMobile) {
                                        lastArtTouchedRef.current = gachaMachines.name;
                                    }
                                }}
                                onPointerUp={(e) => {
                                    console.log('pointer up on ' + gachaMachines.name + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                    if (lastArtTouchedRef.current == gachaMachines.name || isMobile) {
                                        showArtFromClick(gachaMachines.name);
                                        e.stopPropagation();
                                    }
                                }}
                                onPointerLeave={(e) => {
                                    if (lastArtTouchedRef.current == gachaMachines.name) {
                                        lastArtTouchedRef.current = "";
                                    }
                                }}
                                onPointerEnter={() => {
                                    showGrapple(gachaMachines.name);
                                }}
                                onPointerOut={
                                    () => {
                                        hideGrapple(gachaMachines.name);
                                    }}
                                ref={(element) => {
                                    if (element == null) return;
                                    setupArtwork(gachaMachines.name, element, true, false);
                                }}
                            />

                        </SelectCrosshair>
                    )}

                    {signpostObjects.map((obj, i) => {
                        let prefix = "signpost_";
                        let prefixLen = prefix.length;
                        let pureName = obj.name.substring(obj.name.indexOf(prefix) + prefixLen, obj.name.length);

                        return (
                            <group key={i}>
                                <mesh
                                    castShadow
                                    geometry={signpostGLB.nodes.signpost.geometry}
                                    position={obj.position}
                                    rotation={obj.rotation}
                                    scale={obj.scale}
                                    material={signpostGLB.nodes.signpost.material}
                                >
                                    <SelectCrosshair key={i} name={pureName}>
                                        <mesh
                                            onPointerDown={(e) => {
                                                console.log('pointer down on ' + pureName + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                                if (crosshairArtworkRef.current == pureName || isMobile) {
                                                    lastArtTouchedRef.current = pureName;
                                                }
                                            }}
                                            onPointerUp={(e) => {
                                                console.log('pointer up on ' + pureName + ' lastArtTouchedRef: ' + lastArtTouchedRef.current)

                                                if (lastArtTouchedRef.current == pureName || isMobile) {
                                                    showArtFromClick(pureName);
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onPointerLeave={(e) => {
                                                if (lastArtTouchedRef.current == pureName) {
                                                    lastArtTouchedRef.current = "";
                                                }
                                            }}
                                            onPointerEnter={() => {
                                                showGrapple(pureName);
                                            }}
                                            onPointerOut={
                                                () => {
                                                    hideGrapple(pureName);
                                                }}
                                            key={i}
                                            geometry={signpostGLB.nodes.artwork.geometry}
                                            position={signpostGLB.nodes.artwork.position}
                                            rotation={signpostGLB.nodes.artwork.rotation}
                                            scale={signpostGLB.nodes.artwork.scale}
                                            ref={(element) => {
                                                if (element == null) return;
                                                setupArtwork(pureName, element, true, true);
                                            }}
                                        />
                                    </SelectCrosshair>
                                </mesh>
                            </group>
                        )
                    })}
                </group>
            )}

        </>
    );


}
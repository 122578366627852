import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { Box, Plane } from "@react-three/drei";
import { useGLTF, Stats, shaderMaterial, Environment, Text, Html, Billboard, AdaptiveDpr, OrbitControls, useVideoTexture, useTexture } from "@react-three/drei"
import { useControls, Leva, folder } from 'leva';
import NomeScene from './NomeScene';
import Multiplayer from './Multiplayer';
import { Selection } from '@react-three/postprocessing';
import './Nome.css';
import ARTWORK_INFO from './artworkinfo.json';

import NomePostProcessing from './NomePostProcessing';
import NomeOverlay from './Overlay';

import {isMobile} from 'react-device-detect';
import { Joystick } from 'react-joystick-component';

import { Physics, Debug } from "@react-three/rapier";

export const SceneContext = createContext();

export default function Nome(props) {

    const artworkPath = "/textures/nome_artwork/";
    const modelPath = "/models/nome/";

    const waterTimescaleRef = useRef(0.5);
    const sceneLoadedRef = useRef(false);

    const joystickRef = useRef(null);
    const jumpRef = useRef(null);

    const joystickXRef = useRef(0);
    const joystickYRef = useRef(0);

    const userAddressRef = useRef("");
    const userENSRef = useRef("");
    const numUserHatsRef = useRef(0);
    const avatarDataRef = useRef({});
    const avatarVisibleRef = useRef(false);
    const messageRef = useRef("");
    const gigiMessageRef = useRef("");

    const cameraPosRef = useRef(new THREE.Vector3(0, 0, 0));
    const tutorialVisibleRef = useRef(true);
    const tutorialRef = useRef(null);
    const curColliderNameRef = useRef("");
    const artObjectsRef = useRef([]);
    const curPlaneIndexRef = useRef(0);
    const crosshairArtworkRef = useRef("");
    const crosshairRef = useRef(null);
    const switchArtInstantRef = useRef(false);
    const touchJumpRef = useRef(false);
    const fullDisplayRef = useRef("");

    const artworkInfoRef = useRef(ARTWORK_INFO.artworks);

    const historicalHatDataRef = useRef({});

    useEffect(() => {
        document.title = props.title;
    });

    function handleJoystickMove(event) {
        joystickXRef.current = event.x;
        joystickYRef.current = event.y;
    }  

    function handleJoystickStop(event) {
        joystickXRef.current = 0;
        joystickYRef.current = 0;
    }

    function handleJump(jump) {
        touchJumpRef.current = jump;            
    }

    function hideTutorial() {
        tutorialVisibleRef.current = false;
        tutorialRef.current.classList.add("fadeOut");
        
        setTimeout(() => {
            tutorialRef.current.style.display = "none";
        }, 500);
    }

    return(
        <div className="Nome">
            <div ref={crosshairRef} className={isMobile ? "Crosshair Crosshair-mobile" : "Crosshair Crosshair-desktop"} />

            <div className={isMobile ? "TutorialMobile" : "Tutorial"} ref={tutorialRef} onClick={(e) => hideTutorial()}></div>

            <SceneContext.Provider value={{
                        waterTimescaleRef: waterTimescaleRef,
                        sceneLoadedRef: sceneLoadedRef,
                        cameraPosRef: cameraPosRef,
                        joystickXRef: joystickXRef,
                        joystickYRef: joystickYRef,
                        touchJumpRef: touchJumpRef,
                        artObjectsRef: artObjectsRef,
                        curPlaneIndexRef: curPlaneIndexRef,
                        crosshairRef: crosshairRef,
                        crosshairArtworkRef: crosshairArtworkRef,
                        switchArtInstantRef: switchArtInstantRef,
                        artworkInfoRef: artworkInfoRef,
                        colliderLayer: 5,
                        artLayer: 6,
                        tutorialVisibleRef: tutorialVisibleRef,
                        curColliderNameRef: curColliderNameRef,          
                        tutorialRef: tutorialRef,
                        fullDisplayRef: fullDisplayRef,
                        artworkPath: artworkPath,
                        modelPath: modelPath,
                        userAddressRef: userAddressRef,
                        userENSRef: userENSRef,
                        numUserHatsRef: numUserHatsRef,
                        avatarDataRef: avatarDataRef,
                        avatarVisibleRef: avatarVisibleRef,
                        messageRef: messageRef,
                        gigiMessageRef: gigiMessageRef,
                        historicalHatDataRef: historicalHatDataRef
            }}>

            <NomeOverlay/>

            <Leva
                collapsed
                hidden
            /> 

            <div className="Nome-container">
                {isMobile &&
                    <div className="Nome-joystick" ref={joystickRef}>
                        <Joystick 
                        size={100} 
                        sticky={false} 
                        move={handleJoystickMove} 
                        stop={handleJoystickStop}
                        stickImage={process.env.PUBLIC_URL + '/textures/joystick/stick.png'}
                        baseImage={process.env.PUBLIC_URL + '/textures/joystick/base.png'}
                        />
                </div>}
                
                {isMobile &&
                <button className="Nome-jump-button Jump" ref={jumpRef} onTouchStart={() => {handleJump(true)}} onTouchEnd={() => {handleJump(false)}} />}

                <Canvas 
                dpr={[0.5, 0.8]}
                // shadows
                linear={true}
                camera={{
                    far: 5000,
                    fov: 130
                }}
                >

                    {/* <OrbitControls /> */}
                    <Selection>
                        <ambientLight intensity={0.6} castShadow />
                        <directionalLight 
                        intensity={2.0} 
                        position={[50,50,10]} 
                        // castShadow 
                        // shadow-mapSize-width={4096}
                        // shadow-mapSize-height={4096}
                        // shadow-camera-far={1000.0}
                        // shadow-camera-near={0.1}
                        // shadow-camera-left={-100}
                        // shadow-camera-right={100}
                        // shadow-camera-top={100}
                        // shadow-camera-bottom={-100}            
                        />
                        <Physics>
                            <Multiplayer />
                            <NomeScene />
                        </Physics>
                        <NomePostProcessing/>
                    </Selection>

                </Canvas>        
            </div>

            </SceneContext.Provider>

        </div>
    );


}


import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import './Tantrum.css';
import ChatWindow from './ChatWindow';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import TantrumGigi from './TantrumGigi';
import { Physics, Debug } from "@react-three/rapier";
import * as THREE from 'three'
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import { DepthOfField } from '@react-three/postprocessing'
import { BlendFunction } from "postprocessing";
import { useControls, Leva, folder } from 'leva';

/*
transparent scrollable pane over webgl canvas 

pane populated by message components, each message has an avatar and a message
*/
function Tantrum(props) {

    const bgTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/tantrum_bg_3_blur.jpg');

    useEffect(() => {
        document.title = props.title;
    }, []);

    return(
        <>
            <NavLink to="/tantrummint" className="TantrumSpinner">
                <img src={process.env.PUBLIC_URL + "/textures/avatar_tokens/spinner.png"}/>
            </NavLink>


            <div className="chatContainer">
                <ChatWindow />
            </div>
        
            <div className="tantrumLoading">
                🎩⭐️ Yeche Chat Loading 💄🌪
            </div>

            <div className="gigiContainer">
                <Canvas camera={{ position: [0, 6, 5], fov: 130}}>
                    {/* add a plane with a MeshBasicMaterial and bgTex */}
                    <mesh position={[0,0,0]}>
                        <sphereBufferGeometry attach="geometry" args={[30, 30, 30]} />
                        <meshBasicMaterial attach="material" map={bgTex} side={THREE.BackSide} depthWrite={false} depthTest={false} />
                    </mesh>

                    <Physics>
                        <group>
                            <TantrumGigi/>
                        </group>
                    </Physics>
                    <ambientLight />
                    <directionalLight position={[0, 10, 0]} intensity={0.5} />
                    {/* <EffectComposer>
                    <DepthOfField
                        focusDistance={0} // where to focus
                        focalLength={0.02} // focal length
                        bokehScale={2} // bokeh size
                    />
                    </EffectComposer> */}

                </Canvas>
                {/* <Leva/> */}
            </div>
        </>
    );
}

export default Tantrum;
import React, { useRef, useContext, useMemo, useEffect } from "react";
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { useControls } from "leva";
import * as THREE from "three";
import { SceneContext } from "./Lunar.js";
import { useGLTF } from '@react-three/drei';
import { Water } from "three/examples/jsm/objects/Water.js";

extend({ Water });

export default function Ocean({waves}) {
  const ref = useRef();
  const waveMatSet = useRef(false);
  const gl = useThree((state) => state.gl);

  const { waterCol, sunCol, foamThreshold, foamDistortionAmt, foamDistortionScale, waterTimeScale }  = useControls('waves', {
    waterCol: {
        value: '#00c0d9',
        onChange: (value) => {
            ref.current.material.uniforms.waterColor.value.set(value);
        }
    },
    sunCol: {
        value: '#ffffff',
        onChange: (value) => {
            ref.current.material.uniforms.sunColor.value.set(value);
        }
    },
    foamThreshold: {
        value: 0.8,
        min: 0.0,
        max: 10.0,
        step: 0.001
    },
    foamDistortionScale: {
        value: 1.5,
        min: 0.0,
        max: 50.0,
        step: 0.01
    },
    foamDistortionAmt: {
        value: 9.0,
        min: 0.0,
        max: 50.0,
        step: 0.01
    },
    waterTimeScale: {
        value: 0.5,
        min: 0.0,
        max: 1.0,
        step: 0.01,
        onChange: (value) => {
          waterTimescaleRef.current = value;
        }
    },
  });

  const { waterTimescaleRef } = useContext(SceneContext);

  //todo load this locally
  const waterNormals = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/waternormals.jpeg');
    
  // const [ target ] = React.useMemo(() => {
  //   const target = new THREE.WebGLRenderTarget(
  //       window.innerWidth,
  //       window.innerHeight,
  //       {
  //         minFilter: THREE.LinearFilter,
  //         magFilter: THREE.LinearFilter,
  //         format: THREE.RGBFormat,
  //         stencilBuffer: false,
  //         depthBuffer: true,
  //         depthTexture: new THREE.DepthTexture()
  //     }
  //   );
  //   return [ target ];
  // }, []);

  // const depthMaterial = useMemo(() => {
  //   const depthMaterial = new THREE.MeshDepthMaterial();
  //   depthMaterial.depthPacking = THREE.RGBADepthPacking;
  //   depthMaterial.blending = THREE.NoBlending;
  //   return depthMaterial;
  // }, []);
  
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  waterNormals.repeat.set(5, 5);
  const circleGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/circle.glb');

  // const geom = useMemo(() => new THREE.PlaneGeometry(800, 800, 256, 256), []);

  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xeb8934,
      waterColor: 0x00c0d9,
      distortionScale: 4.0,
      fog: true,
      format: gl.encoding,
      // transparent: true,
      // opacity: 0.8,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [waterNormals]
  );

  //update wave mat uniforms when they change
  useEffect(() => {
    if(waveMatSet.current) {
        ref.current.material.uniforms.waveA.value = [
            Math.sin((waves[0].direction * Math.PI) / 180),
            Math.cos((waves[0].direction * Math.PI) / 180),
            waves[0].steepness,
            waves[0].wavelength,
        ];

        ref.current.material.uniforms.waveB.value = [
            Math.sin((waves[1].direction * Math.PI) / 180),
            Math.cos((waves[1].direction * Math.PI) / 180),
            waves[1].steepness,
            waves[1].wavelength,
        ];

        ref.current.material.uniforms.waveC.value = [
            Math.sin((waves[2].direction * Math.PI) / 180),
            Math.cos((waves[2].direction * Math.PI) / 180),
            waves[2].steepness,
            waves[2].wavelength,
        ];

        // console.log('updated waves');
    }
  }, [waves]);

  useEffect(() => {
    // ref.current.material.side = THREE.DoubleSide;

    ref.current.material.onBeforeCompile = (shader) => {

        shader.uniforms.depthTexture = { value: null };

        shader.uniforms.foamDistortionAmt = { value: 0.0 };
        shader.uniforms.foamDistortionScale = { value: 0.0 };

        shader.uniforms.threshold = { value: foamThreshold };

        shader.uniforms.resolution = { value: new THREE.Vector2(window.innerWidth, window.innerHeight) };

        shader.uniforms.cameraNear = { value: 0 };
        shader.uniforms.cameraFar = { value: 0 };

        shader.uniforms.offsetX = { value: 0 }
        shader.uniforms.offsetZ = { value: 0 }
        shader.uniforms.waveA = {
            value: [
                Math.sin((waves[0].direction * Math.PI) / 180),
                Math.cos((waves[0].direction * Math.PI) / 180),
                waves[0].steepness,
                waves[0].wavelength,
            ],
        }
        shader.uniforms.waveB = {
            value: [
                Math.sin((waves[1].direction * Math.PI) / 180),
                Math.cos((waves[1].direction * Math.PI) / 180),
                waves[1].steepness,
                waves[1].wavelength,
            ],
        }
        shader.uniforms.waveC = {
            value: [
                Math.sin((waves[2].direction * Math.PI) / 180),
                Math.cos((waves[2].direction * Math.PI) / 180),
                waves[2].steepness,
                waves[2].wavelength,
            ],
        }

        // Add a custom vertex shader
        shader.vertexShader = `
        varying vec2 vUv;
        varying vec4 mvPosition;
        uniform mat4 textureMatrix;
        uniform float time;

        varying vec4 mirrorCoord;
        varying vec4 worldPosition;

        #include <common>
        #include <fog_pars_vertex>
        #include <shadowmap_pars_vertex>
        #include <logdepthbuf_pars_vertex>

        uniform vec4 waveA;
        uniform vec4 waveB;
        uniform vec4 waveC;

        uniform float offsetX;
        uniform float offsetZ;

        vec3 GerstnerWave (vec4 wave, vec3 p) {
            float steepness = wave.z;
            float wavelength = wave.w;
            float k = 2.0 * PI / wavelength;
            float c = sqrt(9.8 / k);
            vec2 d = normalize(wave.xy);
            float f = k * (dot(d, vec2(p.x, p.y)) - c * time);
            float a = steepness / k;

            return vec3(
                d.x * (a * cos(f)),
                d.y * (a * cos(f)),
                a * sin(f)
            );
        }

        void main() {

            mirrorCoord = modelMatrix * vec4( position, 1.0 );
            worldPosition = mirrorCoord.xyzw;
            mirrorCoord = textureMatrix * mirrorCoord;

            vUv = uv;

            vec3 gridPoint = position.xyz;
            vec3 tangent = vec3(1, 0, 0);
            vec3 binormal = vec3(0, 0, 1);
            vec3 p = gridPoint;
            gridPoint.x += offsetX;//*2.0;
            gridPoint.y -= offsetZ;//*2.0;
            p += GerstnerWave(waveA, gridPoint);
            p += GerstnerWave(waveB, gridPoint);
            p += GerstnerWave(waveC, gridPoint);
            gl_Position = projectionMatrix * modelViewMatrix * vec4( p.x, p.y, p.z, 1.0);
            mvPosition = modelViewMatrix * vec4( p.x, p.y, p.z, 1.0 );

            #include <beginnormal_vertex>
            #include <defaultnormal_vertex>
            #include <logdepthbuf_vertex>
            #include <fog_vertex>
            #include <shadowmap_vertex>
        }
        `;

        // Add a custom fragment shader
        shader.fragmentShader = `  
        varying vec2 vUv;

        uniform sampler2D depthTexture;
        uniform sampler2D mirrorSampler;

        uniform float foamDistortionAmt;

        uniform float alpha;
        uniform float time;
        uniform float size;
        uniform float distortionScale;
        uniform float foamDistortionScale;
        uniform float cameraNear;
        uniform float cameraFar;
        uniform float threshold;
        uniform sampler2D normalSampler;
        uniform vec2 resolution;
        uniform vec3 sunColor;
        uniform vec3 sunDirection;
        uniform vec3 eye;
        uniform vec3 waterColor;

        varying vec4 mirrorCoord;
        varying vec4 worldPosition;

        uniform mat4 projectionMatrixInverse;
        uniform mat4 viewMatrixInverse;    

        uniform float offsetX;
        uniform float offsetZ;

        vec4 getNoise( vec2 uv ) {
            vec2 uv0 = ( uv / 103.0 ) + vec2(time / 17.0, time / 29.0);
            vec2 uv1 = uv / 107.0-vec2( time / -19.0, time / 31.0 );
            vec2 uv2 = uv / vec2( 8907.0, 9803.0 ) + vec2( time / 101.0, time / 97.0 );
            vec2 uv3 = uv / vec2( 1091.0, 1027.0 ) - vec2( time / 109.0, time / -113.0 );
            vec4 noise = texture2D( normalSampler, uv0 ) +
                texture2D( normalSampler, uv1 ) +
                texture2D( normalSampler, uv2 ) +
                texture2D( normalSampler, uv3 );
            return noise * 0.5 - 1.0;
        }

        void sunLight( const vec3 surfaceNormal, const vec3 eyeDirection, float shiny, float spec, float diffuse, inout vec3 diffuseColor, inout vec3 specularColor ) {
            vec3 reflection = normalize( reflect( -sunDirection, surfaceNormal ) );
            float direction = max( 0.0, dot( eyeDirection, reflection ) );
            specularColor += pow( direction, shiny ) * sunColor * spec;
            diffuseColor += max( dot( sunDirection, surfaceNormal ), 0.0 ) * sunColor * diffuse;
        }

        #include <common>
        #include <packing>
        #include <bsdfs>
        #include <fog_pars_fragment>
        #include <logdepthbuf_pars_fragment>
        #include <lights_pars_begin>
        #include <shadowmap_pars_fragment>
        #include <shadowmask_pars_fragment>

        float getDepth( const in vec2 screenPosition ) {
          // return unpackRGBAToDepth( texture2D( depthTexture, screenPosition ) );
          return texture2D( depthTexture, screenPosition ).x;
        }

        float pDepthToViewZ( const in float invClipZ, const in float near, const in float far ) {
          return ( near * far ) / ( ( far - near ) * invClipZ - far );
        }
        
  
        float getViewZ( const in float depth ) {
          return pDepthToViewZ( depth, cameraNear, cameraFar );
        }  

        vec3 worldCoordinatesFromDepth(float depth) {
          float z = depth * 2.0 - 1.0;
      
          vec4 clipSpaceCoordinate = vec4(vUv * 2.0 - 1.0, z, 1.0);
          vec4 viewSpaceCoordinate = projectionMatrixInverse * clipSpaceCoordinate;
      
          viewSpaceCoordinate /= viewSpaceCoordinate.w;
      
          vec4 worldSpaceCoordinates = viewMatrixInverse * viewSpaceCoordinate;
      
          return worldSpaceCoordinates.xyz;
        }
    

        void main() {

            #include <logdepthbuf_fragment>

            vec4 noise = getNoise( (worldPosition.xz) + vec2(offsetX/12.25,offsetZ/12.25) * size );
            vec3 surfaceNormal = normalize( noise.xzy * vec3( 1.5, 1.0, 1.5 ) );

            vec3 diffuseLight = vec3(0.0);
            vec3 specularLight = vec3(0.0);

            vec3 worldToEye = eye-worldPosition.xyz;
            vec3 eyeDirection = normalize( worldToEye );
            sunLight( surfaceNormal, eyeDirection, 100.0, 2.0, 0.5, diffuseLight, specularLight );

            float distance = length(worldToEye);

            vec2 distortion = surfaceNormal.xz * ( 0.001 + 1.0 / distance ) * distortionScale;
            vec3 reflectionSample = vec3( texture2D( mirrorSampler, mirrorCoord.xy / mirrorCoord.w + distortion ) );

            float theta = max( dot( eyeDirection, surfaceNormal ), 0.0 );
            float rf0 = 0.3;
            float reflectance = rf0 + ( 1.0 - rf0 ) * pow( ( 1.0 - theta ), 5.0 );
            vec3 scatter = max( 0.0, dot( surfaceNormal, eyeDirection ) ) * waterColor;
            vec3 albedo = mix( ( sunColor * diffuseLight * 0.3 + scatter ) * getShadowMask(), ( vec3( 0.1 ) + reflectionSample * 0.9 + reflectionSample * specularLight ), reflectance);

            //FOAM
            // vec2 screenUV = gl_FragCoord.xy / resolution;

            // float fragmentLinearEyeDepth = getViewZ( gl_FragCoord.z );
            // float linearEyeDepth = getViewZ( getDepth( screenUV ) );
    
            // //low diff = foam, high diff = no foam
            // float diff = saturate( fragmentLinearEyeDepth - linearEyeDepth );

            // vec2 foamDistortion = surfaceNormal.xz * ( 0.001 + 1.0 / distance ) * foamDistortionScale;

            // vec2 displacement = foamDistortion * foamDistortionAmt;
            // diff += abs(displacement.x) + abs(displacement.y);

            // float foamFadeWidth = 0.3;

            // vec3 foamCol = mix(albedo.rgb, vec3(1.0), 0.1);

            // float foamAmt = smoothstep(threshold - foamFadeWidth, threshold + foamFadeWidth, diff * 1.5);
            // foamAmt = clamp(foamAmt, 0.0, 1.0);
            
            // albedo = mix( foamCol, albedo.rgb, foamAmt );
            //

            vec3 outgoingLight = albedo;
            gl_FragColor = vec4( outgoingLight, alpha );        

          
            #include <tonemapping_fragment>
            #include <fog_fragment>
        }
        `;
        shader.uniforms.size.value = 10.0;
        waveMatSet.current = true;
    };
  }, []);

  useFrame((state, delta) => {
    let scene = state.scene;
    let camera = state.camera;

    if (ref.current && waveMatSet.current) {

      // console.log(depthMaterial);

      //set water visible to false

      //FOAM
      // ref.current.visible = false;
      // scene.overrideMaterial = depthMaterial;

      // state.gl.setRenderTarget(target);
      // state.gl.render(scene, camera);
      // state.gl.setRenderTarget(null);

      // ref.current.material.uniforms.depthTexture.value = target.depthTexture;
      //
      
      ref.current.material.uniforms.cameraNear.value = camera.near;
      ref.current.material.uniforms.cameraFar.value = camera.far;

      ref.current.material.uniforms.time.value = state.clock.getElapsedTime() * waterTimescaleRef.current;
      ref.current.material.uniforms.threshold.value = foamThreshold;
      ref.current.material.uniforms.foamDistortionAmt.value = foamDistortionAmt;
      ref.current.material.uniforms.foamDistortionScale.value = foamDistortionScale;
      
      //FOAM - set water visible to true
      // ref.current.visible = true;
      // scene.overrideMaterial = null;
      //

      //resize target if the window size changes
      // if (target.width !== window.innerWidth || target.height !== window.innerHeight) {

      //   target.setSize(window.innerWidth, window.innerHeight);

      //   //dispose of previous depth texture
      //   target.depthTexture.dispose();

      //   target.depthTexture = new THREE.DepthTexture();

      //   //resize resolution
      //   ref.current.material.uniforms.resolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
      // }

    }


  });

  return (
    <water
      ref={ref}
      args={[circleGLB.nodes.Circle.geometry, config]}
      rotation-x={-Math.PI / 2}
      position={[0, 0, 0]}
    />
  );
}

var vert = `
varying vec3 vWorldPosition;
varying vec2 myUv;

void main() {
    vWorldPosition = (modelMatrix * vec4( position, 1.0 )).xyz;
    myUv = uv;
    csm_Position = position;
}
`;

var frag = `
varying vec2 myUv;
varying vec3 vWorldPosition;

float fogNear = 80.0;
float fogFar = 150.0;

uniform float time;
uniform float noiseScaleBase;

uniform bool useNoise;

uniform vec3 fogColBase;

float rand(float n){return fract(sin(n) * 43758.5453123);}

float noise(float p){
    float fl = floor(p);
    float fc = fract(p);
    return mix(rand(fl), rand(fl + 1.0), fc);
}
    
float noise(vec2 n) {
    const vec2 d = vec2(0.0, 1.0);
    vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
    return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
}

void main() {

    if(useNoise) {
        //calculate distance from camera
        float distance = length( vWorldPosition - cameraPosition );

        //calculate a fog value based on distance
        float fogFactor = smoothstep( fogNear, fogFar, distance );

        float noiseScale = noiseScaleBase * rand(myUv.x + myUv.y + time);

        //create rainbow static noise based on time, uv and noiseScale
        vec2 randomUv = vec2( fract( sin( dot( myUv.xy, vec2( 12.9898, 78.233 ) ) ) * 43758.5453 ) );
        float randomTime = fract( sin( dot( myUv.xy, vec2( 12.9898, 78.233 ) ) ) * 43758.5453 );

        float timeX = ( time + randomTime ) * 10.0;
        float timeY = ( time + randomTime ) * 20.0;

        float staticR = noise( vec2( myUv.x * noiseScale + timeX + randomUv.x, myUv.y * noiseScale - timeY + randomUv.y ) );
        staticR += noise( vec2( myUv.x * noiseScale * 2.0 + timeX * 2.0 + randomUv.x * 2.0, myUv.y * noiseScale * 2.0 - timeY * 2.0 + randomUv.y * 2.0 ) ) * 0.5;
        staticR += noise( vec2( myUv.x * noiseScale * 4.0 + timeX * 4.0 + randomUv.x * 4.0, myUv.y * noiseScale * 4.0 - timeY * 4.0 + randomUv.y * 4.0 ) ) * 0.25;

        float staticG = noise( vec2( myUv.x * noiseScale - timeX + randomUv.x + 1000.0, myUv.y * noiseScale + timeY + randomUv.y + 1000.0 ) );
        staticG += noise( vec2( myUv.x * noiseScale * 2.0 - timeX * 2.0 + randomUv.x * 2.0 + 1000.0, myUv.y * noiseScale * 2.0 + timeY * 2.0 + randomUv.y * 2.0 + 1000.0 ) ) * 0.5;
        staticG += noise( vec2( myUv.x * noiseScale * 4.0 - timeX * 4.0 + randomUv.x * 4.0 + 1000.0, myUv.y * noiseScale * 4.0 + timeY * 4.0 + randomUv.y * 4.0 + 1000.0 ) ) * 0.25;

        float staticB = noise( vec2( myUv.x * noiseScale + timeX + randomUv.x + 2000.0, myUv.y * noiseScale - timeY + randomUv.y + 2000.0 ) );
        staticB += noise( vec2( myUv.x * noiseScale * 2.0 + timeX * 2.0 + randomUv.x * 2.0 + 2000.0, myUv.y * noiseScale * 2.0 - timeY * 2.0 + randomUv.y * 2.0 + 2000.0 ) ) * 0.5;
        staticB += noise( vec2( myUv.x * noiseScale * 4.0 + timeX * 4.0 + randomUv.x * 4.0 + 2000.0, myUv.y * noiseScale * 4.0 - timeY * 4.0 + randomUv.y * 4.0 + 2000.0 ) ) * 0.25;    

        //create a fog color based on the rainbow static noise
        vec3 fogCol = vec3( staticR, staticG, staticB ) * fogColBase;

        // csm_DiffuseColor = vec4( mix( csm_DiffuseColor.rgb, fogCol, fogFactor ), csm_DiffuseColor.a );
        csm_DiffuseColor = mix(csm_DiffuseColor, vec4(fogCol, csm_DiffuseColor.a), fogFactor);
        csm_Emissive = mix(vec3(0.0), fogCol, fogFactor);
    }
}
`;

export { vert, frag };
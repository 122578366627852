import React from 'react';

import { useState, useEffect } from 'react';

import { Contract, providers } from 'ethers';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import yecheCakesABI from './yeche-cakes-abi.json';
import yecheGoldenABI from './yeche-golden-abi.json';

import giveawayWhitelist from './giveawaylist.json';

import Web3Modal from 'web3modal';

import WalletConnectProvider from '@walletconnect/web3-provider';

import truncateEthAddress from 'truncate-eth-address';

import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import './Cakes.css';

const goldenAddress = "0x44a207f5f4880f0f3ed3fbf340249d688ad12861";

const cakesAddress = "0xd5027b602b50f18ee2a5d95dd141ee7bde5aa476";
const targetChainName = "mainnet";
const targetChain = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

const numGiveaway = 111;

function Cakes(props) {

  const [showGolden, setShowGolden] = useState(false);

  const [giveawayTree, setGiveawayTree] = useState(null);

  const [web3Modal, setWeb3Modal] = useState(null);

  const [address, setAddress] = useState("");

  const [humanAddress, setHumanAddress] = useState("");

  const [connected, setConnected] = useState(false);

  const [connectText, setConnectText] = useState("wallet connect");

  const [userHasPie, setUserHasPie] = useState(false);

  const [eligibleForGiveaway, setEligibleForGiveaway] = useState(false);
  const [giveawayAvailable, setGiveawayAvailable] = useState(false);
  const [giveawayRemaining, setGiveawayRemaining] = useState(0);

  const [numMinted, setNumMinted] = useState(-1);

  const [lightboxActive, setLightboxActive] = useState(false);
  const [curLightboxImage, setCurLightboxImage] = useState(0);

  const [mintText, setMintText] = useState({
    1: "MINT 1",
    5: "MINT 5",
    10: "MINT 10",
    15: "MINT 15"
  });

  const [giveawayText, setGiveawayText] = useState("MINT GIVEAWAY");

  const [goldenMintText, setGoldenMintText] = useState("MINT");

  const [ethersProvider, setEthersProvider] = useState(null);
  const [web3ModalProvider, setWeb3ModalProvider] = useState(null);

  async function initMerkleTree() {
    const leafNodes = giveawayWhitelist.map(address => keccak256(address));

    const tree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});

    const rootHash = tree.getRoot();
    const rootHashHex = MerkleTree.bufferToHex(rootHash);

    setGiveawayTree(tree);
  }

  useEffect(() => {
    document.title = props.title;

    initMerkleTree();

    //todo infura id
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "0d1547daa6c64457b47a9b92678f8a14",
        }
      }  
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    setWeb3Modal(web3Modal);
      
  }, []);

  useEffect(() => {
    if(web3Modal && web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal]);

  useEffect(() => {
    if(web3ModalProvider) {
      web3ModalProvider.on("accountsChanged", (accounts) => {
        console.log(accounts);

        if(accounts.length > 0) {
          connectWallet();
        }

        else {
          setConnected(false);
          setConnectText("wallet connect");
        }
      });

      web3ModalProvider.on("chainChanged", (chainId) => {
        console.log("chain changed, id is " + chainId);
        setConnected(false);

        if(connected) {
          setConnectText(wrongNetworkMessage);
        }
        
        connectWallet();
      });    
    }
  }, [web3ModalProvider, connected]);

  async function connectWallet() {
    const provider = await web3Modal.connect();

    //addListeners(provider);
    setWeb3ModalProvider(provider);

    const ethersProvider = new providers.Web3Provider(provider);

    const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
    console.log("chain id is " + chainId);

    if(chainId !== targetChain) {
      setConnected(false);
      setConnectText(wrongNetworkMessage);
      return;
    }
    
    setEthersProvider(ethersProvider);

    const userAddress = await ethersProvider.getSigner().getAddress();

    const name = await ethersProvider.lookupAddress(userAddress);

    const eligible = giveawayWhitelist.some(addr => {
      return addr.toLowerCase() === userAddress.toLowerCase();
    });

    //if(eligible) console.log("user on giveaway whitelist");
    //else console.log("user is not on giveaway whitelist");

    setEligibleForGiveaway(eligible);    

    setAddress(userAddress);

    if(name) {
      setHumanAddress(name);
    }

    else {
      let truncatedAddress = truncateEthAddress(userAddress);
      setHumanAddress(truncatedAddress);
    }

    setConnected(true);
  }


  useEffect(() => { 
    if(ethersProvider && address != "") {
      checkPie();
      //checkGiveaway();
      checkNumMinted();
    }

    async function checkNumMinted() {
      let signer = ethersProvider.getSigner();

      const contract = new Contract(cakesAddress, yecheCakesABI, signer);

      const numGiveawayMinted = await contract.totalSupply();
      setNumMinted(numGiveawayMinted);
    }

    async function checkGiveaway() {
      let signer = ethersProvider.getSigner();

      const contract = new Contract(cakesAddress, yecheCakesABI, signer);

      const numGiveawayMinted = await contract.numGiveawayMinted();

      const numRemaining = numGiveaway - numGiveawayMinted;
      setGiveawayRemaining(numRemaining);

      const userClaimed = await contract.giveawayClaimed(address);

      if(numGiveawayMinted < numGiveaway && !userClaimed) {
        setGiveawayAvailable(true);
      }

      else {
        setGiveawayAvailable(false);
      }
    }

    async function checkPie() {
      let signer = ethersProvider.getSigner();

      const contract = new Contract(cakesAddress, yecheCakesABI, signer);
      
      console.log("checking pie, user address is " + address);
  
      let hasPie = await contract.checkPieHolder(address).then(hasPie => hasPie);

      if(hasPie) console.log("user has pie");
      else console.log("user does not have pie");
  
      setUserHasPie(hasPie);  
    }
  }, [ethersProvider, address]);

  async function mintGiveaway() {
    try {
      let signer = ethersProvider.getSigner();

      const contract = new Contract(cakesAddress, yecheCakesABI, signer);  

      const hexProof = giveawayTree.getHexProof(keccak256(address));
      const tx = await contract.mintGiveaway(hexProof);

      setGiveawayText("MINTING...");

      await tx.wait();

      setGiveawayRemaining(giveawayRemaining - 1);

      setGiveawayText("MINTED!");
    }

    catch(err) {
      console.log(err);
    }
  }

  async function mintCake(quantity) {
    try {
      console.log('minting ' + quantity + ' cakes');

      let signer = ethersProvider.getSigner();

      const contract = new Contract(cakesAddress, yecheCakesABI, signer);

      const price = await contract.checkPrice(quantity, address);

      console.log("price for " + quantity + " cakes is " + price);

      //minting 
      const tx = await contract.mint(quantity, {
        value: price
      });

      //make a copy of mintLoading
      let newMintText = {...mintText};
      newMintText[quantity] = "MINTING...";    
      setMintText(newMintText);

      await tx.wait();

      let newMintText2 = {...mintText};
      newMintText2[quantity] = "MINTED!";
      setMintText(newMintText2);

      console.log('minting complete');

      //increment num minted
      let newNumMinted = numMinted + quantity;
      setNumMinted(newNumMinted);
    }
    
    catch(err) {
      console.log(err.message);

      if(err.message.includes("insufficient funds")) {
        alert("Insufficient funds! Make sure you have enough ETH in your wallet.");
      }
    }
  }

  async function showGoldenPage() {
    setShowGolden(true);
    window.scrollTo(0, 0);
  }

  async function hideGoldenPage() {
    setShowGolden(false);
    window.scrollTo(0, 0);
  }

  async function mintGolden() {
    try {
      console.log('minting golden opportunity');

      let signer = ethersProvider.getSigner();

      const contract = new Contract(goldenAddress, yecheGoldenABI, signer);

      const artworkID = 0;

      const tx = await contract.mint(artworkID);

      setGoldenMintText("MINTING...");

      await tx.wait();

      setGoldenMintText("MINTED!");
    }
    
    catch(err) {
      console.log(err);
    }
  }
  
  function openLightbox(img) {
    setCurLightboxImage(img);
    setLightboxActive(true);
  }

  function closeLightbox() {
    setLightboxActive(false);
  }

  return (
    <>
    {connected && <div className="address-pill">{humanAddress}</div>}
    {!connected && <button className="connect-btn" onClick={() => connectWallet()}>{connectText}</button>}

    {showGolden && <div className="Golden">
      <div className="bg"></div>

      <button className="Golden-back" onClick={() => { hideGoldenPage(); }}>
      ← back to cakes
      </button>

      <BrowserView className="down-arrow">
						<div className="arrow-L">⇩ ⇩</div>
						<div className="arrow-R">⇩ ⇩</div><br /><br />
						<div className="arrow-L">⇩ ⇩</div>
						<div className="arrow-R">⇩ ⇩</div><br /><br />
            <div className="arrow-L">⇩ ⇩</div>
						<div className="arrow-R">⇩ ⇩</div><br /><br />
			</BrowserView>

      <div className="Golden-mint">
        <div className="Golden-preview">
          <img src={require('./images/Yeche_Cakes_Golden_Opportunity.webp')} alt="Golden Opportunity Midnight Kitchen Yeche Cake" className="Golden-img" />
          <div className="Golden-title">
          Golden Opportunity Midnight Kitchen Yeche Cake<br />
          <a href="https://arweave.net/k0XjDO0UneAS4xpFzbSeyRzMBmg2FfXG1xHw-cfVT38" target="_blank">full resolution image 6400x8384</a>
          </div> 
        </div>

        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected && userHasPie) { mintGolden(); }}}>{goldenMintText}</button>
      </div>
      
    </div>}

    <div className="bg"></div>

    {lightboxActive && 

    <div className="lightbox" onClick={() => setLightboxActive(false)}>
      {curLightboxImage == 1 && <img src={require('./images/1-Red_Velvet-1_Walnut_She_Had_Cloud_Sundae-1.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 2 && <img src={require('./images/2-Sugar_Lace-4_Pine_Stand_Up_Lip_Stump_Torte-1.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 3 && <img src={require('./images/3-Strawberry_Buttercream-2_Sucupira_Where_Did_You_Get_Great_White_Shark_Fin-3.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 4 && <img src={require('./images/4-Turbo_Candy-1_Maple_Where_Did_You_Get_time-4.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 5 && <img src={require('./images/5-Chocolate_Ganache-1_Pine_Accidently_Got_Marzipan_Ricotta_Hibiscus_Stump-3.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 6 && <img src={require('./images/6-Puff_Pastry-2_Elm_Sweet_Little_Gold_Leaf_Hazelnut_Ziggurat_Stump-1.webp')} alt="cake" className="cake-img-lightbox" />}

      {curLightboxImage == 7 && <img src={require('./images/10-Strawberry_Crunch-4_Maple_Sweet_Sweet_Sweetie_Mediterranean_Grape_Olive.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 8 && <img src={require('./images/11-Red_Velvet_Buttercream-4_Sucupira_Saw_Right_Through_European_Night_Crawler-1.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 9 && <img src={require('./images/12-Puff_Pastry-3_Hickory_Long_Awaited_Smoked_Watermelon_Loaf-13.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 10 && <img src={require('./images/13-Fairy_Sprinkle-5_Ash_Fullness_Of_Light_Mossberry_Pudding_Stump-4.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 11 && <img src={require('./images/14-Chocolate_Ganache-4_Larch_Shining_And_Fantastic_Blackberry_Bubble_Gelato_Pudding_Cloud.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 12 && <img src={require('./images/15-Caramilk_Mud-3_Pine_Sweet_Little_Balayage_Stump-4.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 13 && <img src={require('./images/16-Cream_Pie-4_Pine_Stand_Up_Blubber_Park.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 14 && <img src={require('./images/17-Red_Velvet-1_Larch_In_Her_Eyes_Dehydrated_Mollusk_Fossil-1.webp')} alt="cake" className="cake-img-lightbox" />}
      {curLightboxImage == 15 && <img src={require('./images/18-Strawberry_Buttercream-1_Larch_Dreams_Come_True_Jellyfish_Gurt_Stump-3.webp')} alt="cake" className="cake-img-lightbox" />}
    </div>}

    {!showGolden && <div className="Cakes">
      
      <div className="yechecakes-logo"> 
        <img src={require('./images/yechecakes.webp')} alt="yeche cakes logo" />
      </div>

      <div className="img-grid-3x2">
        <div className="cake-preview">
          <img src={require('./images/1-Red_Velvet-1_Walnut_She_Had_Cloud_Sundae-1.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(1)} />
          <div className="cake-title-3x2">
          She Had Red Velvet Cloud Sundae Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/2-Sugar_Lace-4_Pine_Stand_Up_Lip_Stump_Torte-1.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(2)} />
          <div className="cake-title-3x2">
          Stand Up Sugar Lace Lip Stump Torte Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/3-Strawberry_Buttercream-2_Sucupira_Where_Did_You_Get_Great_White_Shark_Fin-3.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(3)} />
          <div className="cake-title-3x2">
          Where Did You Get Strawberry Buttercream Great White Shark Fin Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/4-Turbo_Candy-1_Maple_Where_Did_You_Get_time-4.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(4)} />
          <div className="cake-title-3x2">
          Where Did You Get Turbo Candy Time Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/5-Chocolate_Ganache-1_Pine_Accidently_Got_Marzipan_Ricotta_Hibiscus_Stump-3.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(5)} />
          <div className="cake-title-3x2">
          Accidently Got Chocolate Ganache Marzipan Ricotta Hibiscus Stump Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/6-Puff_Pastry-2_Elm_Sweet_Little_Gold_Leaf_Hazelnut_Ziggurat_Stump-1.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(6)} />
          <div className="cake-title-3x2">
          Sweet Little Puff Pastry Gold Leaf Hazelnut Ziggurat Stump Cake
          </div>
        </div>

      </div>

      <div className="mint-and-pricing">
        <div className="mint-ui-2x2">
          <button className={connected ? "mint-button-connected-2x2" : "mint-button-disconnected-2x2"} onClick={() => {if(connected) { mintCake(1); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[1]}</button>
          <button className={connected ? "mint-button-connected-2x2" : "mint-button-disconnected-2x2"} onClick={() => {if(connected) { mintCake(5); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[5]}</button>
          <button className={connected ? "mint-button-connected-2x2" : "mint-button-disconnected-2x2"} onClick={() => {if(connected) { mintCake(10); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[10]}</button>
          <button className={connected ? "mint-button-connected-2x2" : "mint-button-disconnected-2x2"} onClick={() => {if(connected) { mintCake(15); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[15]}</button>
        </div>

        <div className='price-box'>
          1 cake = 0.07 ETH, 10% off with pie<br />
          5 cakes: 5% off, 15% off with pie<br />
          10 cakes: 10% off, 20% off with pie<br />
          15 cakes: 15% off, 25% off with pie
        </div>
      </div>

      <div className="info-box">
        Yeche Cakes are an NFT collection of 777 uniquely generated Cake Works from Yeche Lange gallery founders <a href={require("./pdf/WRETCHED_WORM_BIO_YECHE_LANGE.pdf")} target="_blank">@wretched_worm</a> and <a href={require("./pdf/JARED_MADERE_BIO_YECHE_LANGE.pdf")} target="_blank">Jared Madere</a>. Each mint offers its collector a singular cake of exclusive rarity encased in one of many custom flavored frame toppings—set eternal by a series of wood variety backed dog tag plaques.<br /><br />

        Our cakes arrive in an endless array of forms, with ingredients defying categorical definition—from sapote pudding, brown sugar milk tea & cherry marzipan to jellyfish shark tooth, rusted car key lobster claw & aperol plasma. The Cake itself becomes a site where all form is transfigured into a celebration of its essence.<br /><br />

        Our custom flavored frame toppings provide both the metaphorical and literal icing on the cake to encase our myriad of Yeche Cakes in an opulent elevation of their splendor. Offered in the flavors: Caramilk Mud, Chocolate Ganache, Cream Pie, Fairy Sprinkle, Puff Pastry, Red Velvet Buttercream, Red Velvet, Strawberry Buttercream, Strawberry Crunch, Sugar Lace and Candied Fruit. Each individual  flavor is rendered in multiple sculptural moments capturing a depth of baroque beauty in these lavish dollopings.<br /><br />

        A series of Poetic exaltations, embossed in tailor-made distressed stainless steel dog tags affixed to wooden backings from an assortment of wood types—finalize the visual display of Yeche Cakes with a plaque-set meditation on the eternalising of transient edible beauty on chain, forever.<br /><br />

        What is a cake without a name? Titles of Yeche Cakes are also generative, formed from the input syntax of [Dog Tag]. [Frame Flavor] and [Cake Name.]—so each Yeche Cake receives a distinct title, befitting its uniqueness.<br /><br />

        Rarity for Yeche Cakes is established across multiple traits. Whilst each cake itself is a singular work, Cakes will occasionally belong to a flavor-type grouping. Further rarity is defined by Frame flavors with a series of variable renderings of each flavor also existing. Lastly the Dog Tag and Wood-Type used in its backing are emblazoned upon the Yeche Cakes and impart another dimension of rarity. This assemblage of Cake, Frame, Dog Tag and Wood into a single work is a random generative process weighted only to ensure all assets are eventually used.<br /><br />

        Recipes for Yeche Cakes are not simple, Jared and Worm spent months developing and perfecting the ultimate techniques for producing masterwork cake displays that appear not only as a celebratory initiation to their ongoing collaboration—but as an impetus for a sweeter life. We all deserve a treat. 

        {numMinted != -1 ? numMinted + "/777 minted" : ""}

        <div className="links">
          <a href="https://opensea.io/collection/yechecakes" target="_blank">OpenSea</a>
          <a href="https://etherscan.io/address/0xd5027b602b50f18ee2a5d95dd141ee7bde5aa476#code" target="_blank">Contract</a>
        </div>
      </div>

      <button className="golden-promo" onClick={() => { showGoldenPage(); }}>
            <div className="emoji">
            🛎 💥 🥄 🥧 🔑<br />
            </div> 
            Pie Key holders, click here to claim<br />
            Yeche Lange Golden Opportunity<br /> 
            ✨BONUS✨ free mint
      </button>

      {/* <div className="logo-and-info">
        <div className="column-text">
          <div className="info-box">
              Yeche Cakes are an NFT collection of 777 uniquely generated Cake Works from Yeche Lange gallery founders <a href={require("./pdf/WRETCHED_WORM_BIO_YECHE_LANGE.pdf")} target="_blank">@wretched_worm</a> and <a href={require("./pdf/JARED_MADERE_BIO_YECHE_LANGE.pdf")} target="_blank">Jared Madere</a>. Each mint offers its collector a singular cake of exclusive rarity encased in one of many custom flavored frame toppings—set eternal by a series of wood variety backed dog tag plaques.<br /><br />

              Our cakes arrive in an endless array of forms, with ingredients defying categorical definition—from sapote pudding, brown sugar milk tea & cherry marzipan to jellyfish shark tooth, rusted car key lobster claw & aperol plasma. The Cake itself becomes a site where all form is transfigured into a celebration of its essence.<br /><br />

              Our custom flavored frame toppings provide both the metaphorical and literal icing on the cake to encase our myriad of Yeche Cakes in an opulent elevation of their splendor. Offered in the flavors: Caramilk Mud, Chocolate Ganache, Cream Pie, Fairy Sprinkle, Puff Pastry, Red Velvet Buttercream, Red Velvet, Strawberry Buttercream, Strawberry Crunch, Sugar Lace and Candied Fruit. Each individual  flavor is rendered in multiple sculptural moments capturing a depth of baroque beauty in these lavish dollopings.<br /><br />

              A series of Poetic exaltations, embossed in tailor-made distressed stainless steel dog tags affixed to wooden backings from an assortment of wood types—finalize the visual display of Yeche Cakes with a plaque-set meditation on the eternalising of transient edible beauty on chain, forever.<br /><br />

              What is a cake without a name? Titles of Yeche Cakes are also generative, formed from the input syntax of [Dog Tag]. [Frame Flavor] and [Cake Name.]—so each Yeche Cake receives a distinct title, befitting its uniqueness.<br /><br />

              Rarity for Yeche Cakes is established across multiple traits. Whilst each cake itself is a singular work, Cakes will occasionally belong to a flavor-type grouping. Further rarity is defined by Frame flavors with a series of variable renderings of each flavor also existing. Lastly the Dog Tag and Wood-Type used in its backing are emblazoned upon the Yeche Cakes and impart another dimension of rarity. This assemblage of Cake, Frame, Dog Tag and Wood into a single work is a random generative process weighted only to ensure all assets are eventually used.<br /><br />

              Recipes for Yeche Cakes are not simple, Jared and Worm spent months developing and perfecting the ultimate techniques for producing masterwork cake displays that appear not only as a celebratory initiation to their ongoing collaboration—but as an impetus for a sweeter life. We all deserve a treat. 

              {numMinted != -1 ? numMinted + "/777 minted" : ""}

              <div className="links">
                <a href="https://opensea.io/collection/yechecakes" target="_blank">OpenSea</a>
                <a href="https://etherscan.io/address/0xd5027b602b50f18ee2a5d95dd141ee7bde5aa476#code" target="_blank">Contract</a>
              </div>
          </div>
        </div>

        <div className='column-logo'>
          <div className="yeche-logo"> 
            <img src={require('./images/yeche.webp')} alt="yeche cakes logo" />
          </div>

          <button className="golden-promo" onClick={() => { showGoldenPage(); }}>
            <div className="emoji">
            🛎 💥 🥄 🥧 🔑<br />
            </div> 
            Pie Key holders, click here to claim<br />
            Yeche Lange Golden Opportunity<br /> 
            ✨BONUS✨ free mint
          </button>

          <div className='price-box'>
          1 cake = 0.07 ETH, 10% off with pie<br />
          5 cakes: 5% off, 15% off with pie<br />
          10 cakes: 10% off, 20% off with pie<br />
          15 cakes: 15% off, 25% off with pie
          </div>
        </div>

      </div> */}

      {/* <div className="mint-ui">
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(1); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[1]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(5); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[5]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(10); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[10]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(15); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[15]}</button>
      </div> */}

      <div className="img-grid">

        <div className="cake-preview">
          <img src={require('./images/10-Strawberry_Crunch-4_Maple_Sweet_Sweet_Sweetie_Mediterranean_Grape_Olive.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(7)} />
          <div className="cake-title">
          Sweet Sweet Sweetie Strawberry Crunch Mediterranean Grape Olive Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/11-Red_Velvet_Buttercream-4_Sucupira_Saw_Right_Through_European_Night_Crawler-1.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(8)} />
          <div className="cake-title">
          Saw Right Through Red Velvet Buttercream European Night Crawler Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/12-Puff_Pastry-3_Hickory_Long_Awaited_Smoked_Watermelon_Loaf-13.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(9)} />
          <div className="cake-title">
          Long Awaited Puff Pastry Smoked Watermelon Loaf Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/13-Fairy_Sprinkle-5_Ash_Fullness_Of_Light_Mossberry_Pudding_Stump-4.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(10)} />
          <div className="cake-title">
          Fullness Of Light Fairy Sprinkle Mossberry Pudding Stump Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/14-Chocolate_Ganache-4_Larch_Shining_And_Fantastic_Blackberry_Bubble_Gelato_Pudding_Cloud.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(11)} />
          <div className="cake-title">
          Shining And Fantastic Chocolate Ganache Blackberry Bubble Gelato Pudding Cloud Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/15-Caramilk_Mud-3_Pine_Sweet_Little_Balayage_Stump-4.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(12)} />
          <div className="cake-title">
          Sweet Little Caramilk Mud Balayage Stump Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/16-Cream_Pie-4_Pine_Stand_Up_Blubber_Park.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(13)} />
          <div className="cake-title">
          Stand Up Cream Pie Blubber Park Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/17-Red_Velvet-1_Larch_In_Her_Eyes_Dehydrated_Mollusk_Fossil-1.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(14)} />
          <div className="cake-title">
          In Her Eyes Red Velvet Dehydrated Mollusk Fossil Cake
          </div>
        </div>

        <div className="cake-preview">
          <img src={require('./images/18-Strawberry_Buttercream-1_Larch_Dreams_Come_True_Jellyfish_Gurt_Stump-3.webp')} alt="cake" className="cake-img" onClick={() => openLightbox(15)} />
          <div className="cake-title">
          Dreams Come True Strawberry Buttercream Jellyfish Gurt Stump Cake
          </div>
        </div>

      </div>

      <div className="mint-ui">
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(1); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[1]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(5); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[5]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(10); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[10]}</button>
        <button className={connected ? "mint-button-connected" : "mint-button-disconnected"} onClick={() => {if(connected) { mintCake(15); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>{mintText[15]}</button>
      </div>

    </div>}
    </>
  );
}

export default Cakes;

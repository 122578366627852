import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { useControls, Leva, folder } from 'leva';
import CustomShaderMaterial from 'three-custom-shader-material'

import { viewZToOrthographicDepth } from 'postprocessing';
// import { vert, frag } from './SteelPatchwork.js'
import { vert, frag } from './NoiseFog.js'
import { MeshStandardMaterial } from 'three';

export const SteelMat = forwardRef(({tiling}, ref) => {

    const testBaseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL +     "/textures/bark/material_BaseColor.jpg");
    const testMetallicTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/bark/material_Metallic.jpg");
    const testNormal = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL +      "/textures/bark/material_Normal.jpg");
    const testRoughness = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL +   "/textures/bark/material_Roughness.jpg");


    const [texturesReady, setTexturesReady] = useState(false);

    // const matRef = useRef();

    const { roughnessAmt, metalnessAmt, normalStrength, noiseScale, noiseTimeScale, fogColBase } = useControls('bark material',{
        roughnessAmt: { value: 0.0, min: 0, max: 5, step: 0.01 },
        metalnessAmt: { value: 5.0, min: 0, max: 5, step: 0.01 },
        normalStrength: { value: 1.87, min: 0, max: 5, step: 0.01 },
        noiseScale: { value: 2739.0, min: 0.0, max: 5000.0, step: 0.1 },
        fogColBase: '#ffabd7',
        noiseTimeScale: { value: 80.0, min: 0.0, max: 500.0, step: 0.1 },
        
    });

    useEffect(() => {
        let tiling = 16;
        if (testBaseTex.image && testRoughness.image && testNormal.image && testMetallicTex.image) {

            // console.log(tiling);

            testBaseTex.wrapS = testBaseTex.wrapT = THREE.RepeatWrapping;
            testBaseTex.repeat.set( tiling, tiling );

            testMetallicTex.wrapS = testMetallicTex.wrapT = THREE.RepeatWrapping;
            testMetallicTex.repeat.set( tiling, tiling );

            testRoughness.wrapS = testRoughness.wrapT = THREE.RepeatWrapping;
            testRoughness.repeat.set( tiling, tiling );
            
            testNormal.wrapS = testNormal.wrapT = THREE.RepeatWrapping;
            testNormal.repeat.set( tiling, tiling );

            if(!texturesReady) setTexturesReady(true);
        }


    }, [testBaseTex, testRoughness, testNormal, testMetallicTex]);


    // useFrame(({ clock }) => {
    //     if(matRef.current) {
    //         matRef.current.uniforms.time.value = clock.getElapsedTime() * noiseTimeScale;
    //         matRef.current.uniforms.noiseScaleBase.value = noiseScale;
    //         matRef.current.uniforms.fogColBase.value = new THREE.Color(fogColBase);
    //         // matRef.current.uniforms.uvScale.value = uvScale;
    //     }
    // });

    return (
        <>
        
        {texturesReady &&


        (
            <CustomShaderMaterial
            baseMaterial={THREE.MeshStandardMaterial}
            vertexShader={vert}
            fragmentShader={frag}
            ref={ref}
            // uniforms={{
            //     time: { value: 0 },
            //     noiseScaleBase: { value: noiseScale },
            //     fogColBase: { value: new THREE.Color(fogColBase) },
            //     useNoise: { value: true }
            // }}    
            map={testBaseTex}
            normalMap={testNormal}
            normalMap-encoding={THREE.LinearEncoding}
            normalScale={[normalStrength,normalStrength]}
            roughness={roughnessAmt}
            roughnessMap={testRoughness}
            roughnessMap-encoding={THREE.LinearEncoding}
            metalness={metalnessAmt}
            metalnessMap={testMetallicTex}
            metalnessMap-encoding={THREE.LinearEncoding}
            fog={false}
            />
        )}
        </>
    );

});
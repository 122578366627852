import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrthographicCamera } from '@react-three/drei';
import './Ball.css';
import { useNavigate } from "react-router-dom";

import ARTWORK_INFO from './BallWorks.json';

import BALL_ABI from './BallAbi.json';

import {isMobile, isTablet} from 'react-device-detect';

import { Contract, providers } from 'ethers';
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

//need tokenID, title, artist, description, image filename

//just put things in json file and use tokenID

export default function BallMint({tokenID}) {

    const [title, setTitle] = useState('');
    const [artist, setArtist] = useState('');
    const [description, setDescription] = useState('');
    const [filename, setFilename] = useState('');
    const [price, setPrice] = useState('');
 
    const [connected, setConnected] = useState(false);
    const [mintText, setMintText] = useState("mint");

    const [minted, setMinted] = useState(false);

    const [workAlreadyMinted, setWorkAlreadyMinted] = useState(false);

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [connectText, setConnectText] = useState("wallet connect");

    const imageBaseURI = "https://arweave.net/fIcJ_ftFIrTFaF_7IwJpFjzCo7Vx6UJoSUL-zxYTBmg/";

    const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

    const BALL_ADDRESS = '0x9d9816b8c0Eb56bD04Ca0bb962abDC7D6BDA6424';
    const targetChainName = 'mainnet';
    const targetChainID = 1;
    const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

    async function checkWorkAlreadyMinted() {
        console.log("checking if work already minted");

        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

        const ballContract = new Contract(BALL_ADDRESS, BALL_ABI, infuraProviderTemp);

        const tokenMinted = await ballContract.tokenMinted(tokenID);

        setWorkAlreadyMinted(tokenMinted);

        if(tokenMinted) {
            setMintText("sold");
        }
    }

    useEffect(() => {
        let artworks = ARTWORK_INFO.artworks;

        //find artwork in artworks with tokenID that matches tokenID
        let artwork = artworks.find(artwork => artwork.tokenID === tokenID);

        //set state variables
        setTitle(artwork.title);
        setArtist(artwork.artist);
        setDescription(artwork.description);
        setFilename(artwork.filename);
        setPrice(artwork.price);

        document.title = artwork.title + " by " + artwork.artist;

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);


        checkWorkAlreadyMinted();
    }, []);
 
    async function mintBall() {
        if(workAlreadyMinted) {
            alert("this work has already been minted");
            return;
        }

        const signer = ethersProvider.getSigner();

        const ballContract = new Contract(BALL_ADDRESS, BALL_ABI, signer);

        //check if this token already exists, in which case we don't want to mint it again
        // const exists = await ballContract.exists(tokenID);

        // if(exists) {
        //     alert("this work has already been minted");
        //     return;
        // }

            //check the price of the work=
        const price = await ballContract.price();

        let tx = await ballContract.mint(tokenID, {value: price});

        console.log('minting');
        setMintText("minting...");

        await tx.wait();

        setMintText("minted");
        setMinted(true);        
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();

        //TODO - check if this work has already been minted and change text if so

        setMintText("MINT ");
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }


    return(<>

        <div className="bg-ball"/>

        {connected && <div className="Address-pill-ball">{humanAddress}</div>}
        {!connected && <button className="Connect-button-ball" onClick={() => connectWallet()}>{connectText}</button>}

    
        <div className={`Ball-container${isMobile && !isTablet ? "-mobile" : ""}`}>
                <div className={`Ball-preview${isMobile && !isTablet ? "-mobile" : "-desktop"}`}>
                    <img src={process.env.PUBLIC_URL + '/textures/ball_works/' + filename} />
                </div>

                <div className={`Ball-info${isMobile && !isTablet ? "-mobile" : "-desktop"}`}>

                    <div className="Ball-header">
                        <div className="Ball-info-item">
                            <div className="Ball-title">
                                {title}<br/>
                                {artist}<br/>
                            </div>
                        </div>

                        <div className='Ball-info-item'>
                            <div className="Etherscan-link">
                                <a href="https://etherscan.io/address/0x9d9816b8c0eb56bd04ca0bb962abdc7d6bda6424" target="_blank">
                                    <img src={require('./images/etherscan_black.png')} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="Ball-info-item">
                        <div className="BallMintZone">

                            {connected && <button className="Mint-button-active-ball" onClick={() => {mintBall(); } }>{mintText}</button>}
                            {!connected && <button className="Mint-button-inactive-ball" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                        </div>
                    </div>

                    <div className="Ball-info-item">
                        <div className="Ball-fullres-link">
                            <a href={imageBaseURI + filename} target="_blank">
                                view full resolution on Arweave
                            </a>
                        </div>
                    </div>


                    <div className="Ball-info-item">
                        <div className="WanderlustPieKeyNote">
                            {price}
                        </div>
                    </div>

                    <div className="Ball-info-item">
                        <div className="BallText">
                        {description}
                        </div>
                    </div>
                </div>
            </div>
    
    
    </>)
}
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Contract, providers } from 'ethers';
import Slider from "react-slick";
import './HolidayGiveaway.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import GOLDEN_V2_ABI from './YecheGoldenOpportunitiesV2-abi.json';

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const GIVEAWAY_ADDRESS = '0x1648fA5836951a30705F85d857C4256238178dF4';
const targetChainName = 'mainnet';
const targetChainID = 1;
const giveawayIndex = 0;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

function CountdownTimer(props) {
    const { endTime } = props;
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Calculate the time left until the end time
        const timeLeft = Math.max(0, endTime - Date.now());
  
        // Update the state with the new time left value
        setTimeLeft(timeLeft);
      }, 1000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, [endTime]);
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the hours, minutes, and seconds left
    const hours = Math.floor(timeLeft / 1000 / 60 / 60);
    const minutes = Math.floor(timeLeft / 1000 / 60) % 60;
    const seconds = Math.floor(timeLeft / 1000) % 60;
  
    return (
      <div>
        {hours} hours {minutes} minutes {seconds} seconds left
      </div>
    );
}  

/* carousel of 3 images, mint button, connect wallet button */
function HolidayGiveaway(props) {
    const carouselSettings = {
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const lightboxRef = useRef(null);
    const lightboxImgRef = useRef(null);

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");

    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [giveawayEnd, setGiveawayEnd] = useState(null);

    const [mouseDown, setMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const [minted, setMinted] = useState(false);

    const handleMouseMove = () => {
        if (mouseDown && !isDragging) {
          setIsDragging(true);
        }
    };
      
    const handleMouseDown = () => {
        setTimeout(() => {
            setMouseDown(true);
        }, 100);
    };
    
    const handleMouseUp = () => {
        //wait 100ms before setting mouseDown to false
        setTimeout(() => {
            setMouseDown(false);
            setIsDragging(false);
        }, 100);
    };
    
    //Prevent the lightbox from opening when dragging
    useEffect(() => {
        document.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        //add events for touch
        document.addEventListener("touchstart", handleMouseDown);
        document.addEventListener("touchmove", handleMouseMove);
        document.addEventListener("touchend", handleMouseUp);
        
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);

            //remove events for touch
            document.removeEventListener("touchstart", handleMouseDown);
            document.removeEventListener("touchmove", handleMouseMove);
            document.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging, mouseDown]);


    useEffect(() => {
        document.title = props.title;
    

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);

        //set end to 1672739640 in utc time
        const end = new Date(0);
        end.setUTCSeconds(1672722000);

        setGiveawayEnd(end);
    }, []);

    async function mintGiveaway() {
        const signer = ethersProvider.getSigner();

        const giveawayContract = new Contract(GIVEAWAY_ADDRESS, GOLDEN_V2_ABI, signer);
        
        const giveaway = await giveawayContract.giveaways(giveawayIndex);

        const tx = await giveawayContract.mint(giveawayIndex);

        console.log('minting giveaway');
        setMintText("minting...");

        await tx.wait();

        setMintText("minted!");    
        setMinted(true);
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();
        const giveawayContract = new Contract(GIVEAWAY_ADDRESS, GOLDEN_V2_ABI, signer);
        const numEligible = await giveawayContract.maxEligibleToMint(userAddress, giveawayIndex);
        setMintText("MINT " + numEligible);
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    function openLightbox(src) {
        lightboxRef.current.classList.add('visible');
        lightboxRef.current.style.pointerEvents = 'all';

        //remove current src
        lightboxImgRef.current.src = "";

        lightboxImgRef.current.src = src; // display actual image
    }

    function closeLightbox() {
        //check if current opacity is less than 1
        lightboxRef.current.classList.remove('visible');
        lightboxRef.current.style.pointerEvents = 'none';
    }

    return( 
        <>
            {connected && <div className="Address-pill-giveaway">{humanAddress}</div>}
            {!connected && <button className="Connect-button-giveaway" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="Giveaway-lightbox" ref={lightboxRef} onClick={(() => closeLightbox())}>
                <img ref={lightboxImgRef}/>
            </div>

            <div className="GiveawayContainer">
                <div className="bg-giveaway"></div>

                <div className="Preview-carousel-giveaway">
                    <Slider {...carouselSettings}>
                        <div className="GiveawayCarouselItem">
                            <img src={require('./images/newyear_nomemene.jpg')} onClick={() => { if(!isDragging) openLightbox(process.env.PUBLIC_URL + "/textures/nye_lightbox/nye_nomemene_lightbox.jpg")}} />
                            <div className="GiveawayCarouselItemText">
                            The alchemist who tried to put a soul in the homunculus didn't have a soul either.<br /><br />
                            Nomemene<br /><br />

                            <a href="https://arweave.net/IReFKt3JkBOvRD5klh-giJf_stNM5XKpHrnWdzIgZhk/newyears_nomemene.jpeg" target="_blank">view full resolution</a>
                            </div>
                        </div>
                        <div className="GiveawayCarouselItem">
                            <img src={require('./images/newyear_worm.jpg')} onClick={() => { if(!isDragging) openLightbox(process.env.PUBLIC_URL + "/textures/nye_lightbox/nye_worm_lightbox.jpg")}}/>
                            <div className="GiveawayCarouselItemText">
                            I Wanted To Be Free<br /><br />
                            Wretched Worm<br /><br />

                            <a href="https://arweave.net/IReFKt3JkBOvRD5klh-giJf_stNM5XKpHrnWdzIgZhk/newyears_worm.png" target="_blank">view full resolution</a>
                            </div>
                        </div>
                        <div className="GiveawayCarouselItem">
                            <img src={require('./images/newyear_jared.jpg')} onClick={() => { if(!isDragging) openLightbox(process.env.PUBLIC_URL + "/textures/nye_lightbox/nye_jared_lightbox.jpg")}}/>
                            <div className="GiveawayCarouselItemText">
                            Door made of damp pillowcases and soda tabs by lemonheads<br /><br/>
                            Jared Madere<br /><br />

                            <a href="https://arweave.net/IReFKt3JkBOvRD5klh-giJf_stNM5XKpHrnWdzIgZhk/newyears_jared.jpg" target="_blank">view full resolution</a>
                            </div>
                        </div>
                    </Slider>
                </div>

                {connected && <button className="Mint-button-active-giveaway" onClick={() => {if(!minted) mintGiveaway(); } }>{mintText}</button>}
                {!connected && <button className="Mint-button-inactive-giveaway" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}

                <div className="Countdown">
                    <CountdownTimer endTime={giveawayEnd} />
                </div>

                <div className="GiveawayText">
                Yeche Lange New Years Eve 2023 Golden Opportunity 48 Hour Open Mint. From us to you, for 48 hours, Jan 1st 12am—Jan 3rd 12am, mint once per wallet or twice for Pie Key Holders to randomly receive work from either Nomemene, Wretched Worm or Jared Madere.
                </div>
            </div>
        </>
    );
}

export default HolidayGiveaway;
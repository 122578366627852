import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrthographicCamera, useGLTF } from '@react-three/drei';
import './Ball.css';
import { useNavigate } from "react-router-dom";

import { Contract, providers } from 'ethers';
import truncateEthAddress from 'truncate-eth-address';

import BALL_ABI from './BallAbi.json';

function BouncingSphere() {
  const mesh = useRef();
  const { viewport } = useThree();

  const [position, setPosition] = useState([0, 0, 0]);
  const [velocity, setVelocity] = useState([3, 4, 0]);
  const [rotation, setRotation] = useState([0, 0, 0]);

  const ballRad = 40;

  const cubeGLB = useGLTF(process.env.PUBLIC_URL + '/models/ball.glb');

  useFrame(({camera}) => {
    if (!mesh.current) return;

    // Update position and velocity
    const newPosition = position.map((pos, index) => pos + velocity[index]);
    setPosition(newPosition);

    // Update rotation
    setRotation((prevRotation) => [
      prevRotation[0] + 0.013,
      prevRotation[1] + 0.02,
      prevRotation[2] + 0.017,
    ]);

    // Bounce ball against window boundaries
    const radius = ballRad;
    const { left, right, top, bottom } = camera;

    // X-axis
    if (
      newPosition[0] + radius >= Math.abs(right) ||
      newPosition[0] - radius <= left
    ) {
      setVelocity((prevVelocity) => {
        const newVelocity = [...prevVelocity];
        newVelocity[0] = -newVelocity[0];
        return newVelocity;
      });
    }

    // Y-axis
    if (
      newPosition[1] + radius >= Math.abs(top) ||
      newPosition[1] - radius <= bottom
    ) {
      setVelocity((prevVelocity) => {
        const newVelocity = [...prevVelocity];
        newVelocity[1] = -newVelocity[1];
        return newVelocity;
      });
    }


    // Update mesh position and rotation
    mesh.current.position.set(...newPosition);
    mesh.current.rotation.set(...rotation);
  });

  return (
    // <mesh ref={mesh}>
    //   <sphereGeometry args={[ballRad, 32, 32]} />
    //   <meshStandardMaterial color={'orange'} />
    // </mesh>

    <primitive object={cubeGLB.scene} ref={mesh}  scale={[ballRad, ballRad, ballRad]}/>
  );
}

export default function Ball(props) {
    const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

    const BALL_ADDRESS = '0x9d9816b8c0Eb56bD04Ca0bb962abDC7D6BDA6424';
    const targetChainName = 'mainnet';
    const targetChainID = 1;
    const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

    const [numRemaining, setNumRemaining] = useState(0);

    async function loadNumMinted() {
        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

        const ballContract = new Contract(BALL_ADDRESS, BALL_ABI, infuraProviderTemp);

        const numMinted = await ballContract.mintedCount();

        const numRemaining = await ballContract.maxSupply() - numMinted;

        console.log('num remaining: ' + numRemaining);

        setNumRemaining(numRemaining);
    }


    useEffect(() => {

        loadNumMinted();


    }, []);
  
    const pageKeys = 
    {
      'drei': 'drei',
      'lettucetini': 'lettucetini',
      'dirt': 'dirt',
      'xmas': 'xmas',
      'trout': 'trout',
      'fantasist': 'fantasist',
      'bloop': 'bloop',
      'whistle': 'whistle',
      'rect1': 'rect1',
      'rect2': 'rect2',
      'rect3': 'rect3',
      'rect4': 'rect4',
      'rect5': 'rect5',
      'aesthetic': 'aesthetic'
    };
  
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
  
    const handleInputChange = (e) => {
      e.target.value = e.target.value.toLowerCase();

      setInputValue(e.target.value);
    };
  
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        const value = pageKeys[inputValue];
        if (value) {
          navigate(`/ball/${value}`);
        } else {
          setInputValue(''); // Clear the input field if the key is incorrect
        }
      };    
        
    return (
      <>

      <div className="bg-ball"/>
      <div className="BallLandingContainer">
      <div className="InputBox">
          <input
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSubmit}>submit</button>
       </div>

       <div className="ShillMessage">
        Jeffrey has {numRemaining} works left to shill
       </div>
  
        <Canvas orthographic>
          <OrthographicCamera
              makeDefault
              zoom={1}
              near={0.1} // Adjusted near and far
              far={5000}
              position={[0, 0, 200]} // Adjusted Z position
              aspect={window.innerWidth / window.innerHeight}
            />        
            <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <BouncingSphere />
        </Canvas>
      </div>
      </>
    );
  }
  
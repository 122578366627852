import './Leche.css';
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, Suspense } from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useControls, Leva, folder } from 'leva';
import { Box, Plane, useGLTF, OrthographicCamera, Environment, OrbitControls, useAspect, useVideoTexture, useTexture, PerspectiveCamera } from "@react-three/drei";
import { Stats } from '@react-three/drei'

import Cow from './Cow';

function VideoMaterial({ url }) {
    const texture = useVideoTexture(url)
    return <meshBasicMaterial map={texture} toneMapped={false} />
}

function FallbackMaterial({ url }) {
    const texture = useTexture(url)
    return <meshBasicMaterial map={texture} toneMapped={false} />
}

export default function Leche(props) {

    const width = 600;
    const height = 400;

    const [pastureTexture] = useLoader(THREE.TextureLoader, [process.env.PUBLIC_URL + '/textures/leche/pasturetex.jpg']);

    useEffect(() => {
        console.log("Leche mounted")
        document.title = "LECHE YANG"
    }, []);

    return (
        <div className='Leche'>

            <div className='LecheContainer'>
                <Canvas
                    dpr={[0.5, 1.0]}
                    gl={{ antialias: true, toneMapping: THREE.NoToneMapping }}
                    linear
                >
                    {/* todo get better env */}
                    <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/industrial_workshop_foundry_1k.hdr" />
                    {/* <OrbitControls  minZoom={15.0} /> */}
                    <ambientLight intensity={.0} />
                    {/* <directionalLight position={[5, 10, 0]} intensity={0.5} /> */}
                    {/* <pointLight position={[10, 10, 10]} /> */}

                    <Cow/>

                    {/*add plane with texture*/}
                    <mesh
                        rotation={[0, 0, 0]}
                        position={[0, 20, -10]}
                        receiveShadow
                        visible={true}
                    >
                        <planeBufferGeometry attach="geometry" args={[150, 150]} />
                        <meshBasicMaterial attach="material" map={pastureTexture} />
                        {/* <Suspense fallback={<FallbackMaterial url={process.env.PUBLIC_URL + '/textures/leche/pasture.jpg'} />}>
                            <VideoMaterial url={process.env.PUBLIC_URL + '/textures/leche/pasture_loop.mp4'} />
                        </Suspense> */}

                    </mesh>

                    <PerspectiveCamera
                        makeDefault
                        zoom={1.0}
                        near={0.1}
                        far={2000}
                        position={[0, 15, 80]}
                    />
                    <Stats />
                </Canvas>

            </div>

        </div>
    )
}
import React from 'react';
import './Nav.css';
import { NavLink } from 'react-router-dom';

import { ReactComponent as TwitterIcon } from './images/twitter.svg';
import { ReactComponent as DiscordIcon } from './images/discord.svg';
import { ReactComponent as InstagramIcon } from './images/instagram.svg';

function Nav() {

  return (
    <>
        <div className="Nav">
            
        <div className="Home-button">
          <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/">
                <img className='Home-button' src={require('./images/yeche_vector_dark.webp')} alt="yeche logo" />
          </NavLink>
        </div>

          <div className="Nav-items">
              <a href="https://yl.baby" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>
              Home
              </a>
              <NavLink  className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/collections">Collections</NavLink>
              <NavLink  className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/about">About</NavLink>

              <div className="Nav-spacer"></div>

              <a href="https://twitter.com/yeche_lange/" target="_blank">
                <TwitterIcon alt="twitter" />
              </a>
              <a href="https://discord.gg/srvd4vq9Bn" target="_blank">
                <DiscordIcon alt="discord" />
              </a>
              <a href="https://www.instagram.com/yeche_lange/" target="_blank">
                <InstagramIcon alt="instagram" />
              </a>
          </div>
        </div>  

    </>
  );
}

export default Nav;

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Contract, providers } from 'ethers';
import './CalendarClaim.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import AUCTION_CALENDAR_ABI from './auction-calendar-abi.json';

import { isMobile } from 'react-device-detect';

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const CALENDAR_ADDRESS = '0x1882c27446dC14fCB5eAD7Df68a09251b78ae074';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

function CountdownTimer(props) {
    const { endTime } = props;
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Calculate the time left until the end time
        const timeLeft = Math.max(0, endTime - Date.now());
  
        // Update the state with the new time left value
        setTimeLeft(timeLeft);
      }, 1000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, [endTime]);
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the hours, minutes, and seconds left
    const hours = Math.floor(timeLeft / 1000 / 60 / 60);
    const minutes = Math.floor(timeLeft / 1000 / 60) % 60;
    const seconds = Math.floor(timeLeft / 1000) % 60;
  
    return (
      <div>
        {hours} hours {minutes} minutes {seconds} seconds left
      </div>
    );
}  

/* carousel of 3 images, mint button, connect wallet button */
function YecheCalendar(props) {

    const lightboxRef = useRef(null);
    const lightboxImgRef = useRef(null);

    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");

    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [mintEnd, setMintEnd] = useState(null);

    const [minted, setMinted] = useState(false);

    useEffect(() => {
        document.title = props.title;
    

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);

        const end = new Date(0);
        end.setUTCSeconds(1677171600);

        setMintEnd(end);
    }, []);

    async function mintCalendar() {
        const signer = ethersProvider.getSigner();

        const calendarContract = new Contract(CALENDAR_ADDRESS, AUCTION_CALENDAR_ABI, signer);

        const userAddress = await signer.getAddress();

        //check if user is eligible to mint
        const eligible = await calendarContract.userBidOnAuction(address);

        if(!eligible) {
            setMintText("not eligible");
            alert("Please bid on a work in School of Truth to be eligible to mint a calendar.")
        }

        else {
            const tx = await calendarContract.mint();

            console.log('minting calendar');
            setMintText("minting...");
    
            await tx.wait();
    
            setMintText("minted");
            setMinted(true);    
        }
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();
        const calendarContract = new Contract(CALENDAR_ADDRESS, AUCTION_CALENDAR_ABI, signer);

        const claimedNFT = await calendarContract.claimed(userAddress);

        if(claimedNFT) {
            //TODO disable mint button
        }

        setMintText("MINT ");
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    function openLightbox(src) {
        lightboxRef.current.classList.add('visible');
        lightboxRef.current.style.pointerEvents = 'all';

        //remove current src
        lightboxImgRef.current.src = "";

        lightboxImgRef.current.src = src; // display actual image
    }

    function closeLightbox() {
        //check if current opacity is less than 1
        lightboxRef.current.classList.remove('visible');
        lightboxRef.current.style.pointerEvents = 'none';
    }

    return( 
        <>
            {connected && <div className="Address-pill-calendar">{humanAddress}</div>}
            {!connected && <button className="Connect-button-calendar" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="Calendar-lightbox" ref={lightboxRef} onClick={(() => closeLightbox())}>
                <img ref={lightboxImgRef}/>
            </div>

            <div className="bg-calendar"></div>

            {isMobile &&
                <div className="Calendar-container-mobile">
                    <div className="Calendar-preview-mobile">
                        <img src={require('./images/auction_calendar.jpg')} />
                    </div>

                    <div className="Calendar-info-mobile">
                        <div className="Calendar-header">
                            <div className="Calendar-info-item">
                                <div className="Calendar-title">
                                    Yeche Lange Calendar<br/>
                                    New Art Program and Wretched Worm
                                </div>
                            </div>

                            <div className='Calendar-info-item'>
                                <div className="Etherscan-link">
                                    <a href="https://etherscan.io/address/0x1882c27446dc14fcb5ead7df68a09251b78ae074#code" target="_blank">
                                        <img src={require('./images/etherscan_black.png')} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="Calendar-info-item">
                            <div className="Calendar-fullres-link">
                                <a href="https://arweave.net/7mqT6FS6O8hIYR3STis2KdQUELVb9Qli_ykMed_Q_qg" target="_blank">
                                    view full resolution
                                </a>
                            </div>
                        </div>

                        <div className="Calendar-info-item">
                            {connected && <button className="Mint-button-active-calendar" onClick={() => {if(!minted) mintCalendar(); } }>{mintText}</button>}
                            {!connected && <button className="Mint-button-inactive-calendar" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                        </div>

                        {/* <div className="Calendar-info-item">
                            <div className="Countdown">
                                <CountdownTimer endTime={mintEnd} />
                            </div>
                        </div> */}

                        <div className="Calendar-info-item">
                            <div className="CalendarText">
                            Celebrate the Year of the Rabbit by claiming your Yeche Lange Calendar, available exclusively to auction bidders. The calendar documents select work from School of Truth on custom layouts by Wretched Worm and New Art Program. What happens when calendars end? Only Time can Tell.
                            </div>
                        </div>
                    </div>
                </div>
            }


            {!isMobile &&
                <>
                <div className="Calendar-info-desktop">
                    <div className="Calendar-header">
                        <div className="Calendar-info-item">
                            <div className="Calendar-title">
                                Yeche Lange Calendar<br/>
                                New Art Program and Wretched Worm
                            </div>
                        </div>

                        <div className='Calendar-info-item'>
                            <div className="Etherscan-link">
                                <a href="https://etherscan.io/address/0x1882c27446dc14fcb5ead7df68a09251b78ae074#code" target="_blank">
                                    <img src={require('./images/etherscan_black.png')} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="Calendar-info-item">
                        {connected && <button className="Mint-button-active-calendar" onClick={() => {if(!minted) mintCalendar(); } }>{mintText}</button>}
                        {!connected && <button className="Mint-button-inactive-calendar" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                    </div>

                    <div className="Calendar-info-item">
                        <div className="Calendar-fullres-link">
                            <a href="https://arweave.net/7mqT6FS6O8hIYR3STis2KdQUELVb9Qli_ykMed_Q_qg" target="_blank">
                                view full resolution
                            </a>
                        </div>
                    </div>

                    <div className="Calendar-info-item">
                        {/* <div className="Countdown">
                            <CountdownTimer endTime={mintEnd} />
                        </div> */}
                    </div>

                    <div className="Calendar-info-item">
                        <div className="CalendarText">
                        Celebrate the Year of the Rabbit by claiming your Yeche Lange Calendar, available exclusively to auction bidders. The calendar documents select work from School of Truth on custom layouts by Wretched Worm and New Art Program. What happens when calendars end? Only Time can Tell.                       
                        </div>
                    </div>
                </div>

                <div className="Calendar-preview-desktop">
                    <img src={require('./images/auction_calendar.jpg')} />
                </div>
                </>
            }
        </>
    );
}
 
export default YecheCalendar;
import { Uniform } from 'three'
import { BlendFunction, Effect, EffectAttribute } from 'postprocessing'
import { wrapEffect } from './util.tsx'
import { EffectComposer } from '@react-three/postprocessing'
import { useEffect } from 'react'
import * as THREE from 'three'

const WatercolorShader = {
    fragmentShader: [
        "uniform sampler2D tDiffuse;",
        "uniform sampler2D tPaper;",

        "uniform vec2 texel;",
        "uniform float scale;",
        "uniform float threshold;",
        "uniform float darkening;",
        "uniform float pigment;",

        "float sobel(sampler2D tex, vec2 uv) {",

            "vec3 hr = vec3(0., 0., 0.);",
            "hr += texture2D(tex, (uv + vec2(-1.0, -1.0) * texel)).rgb *  1.0;",
            "hr += texture2D(tex, (uv + vec2( 0.0, -1.0) * texel)).rgb *  0.0;",
            "hr += texture2D(tex, (uv + vec2( 1.0, -1.0) * texel)).rgb * -1.0;",
            "hr += texture2D(tex, (uv + vec2(-1.0,  0.0) * texel)).rgb *  2.0;",
            "hr += texture2D(tex, (uv + vec2( 0.0,  0.0) * texel)).rgb *  0.0;",
            "hr += texture2D(tex, (uv + vec2( 1.0,  0.0) * texel)).rgb * -2.0;",
            "hr += texture2D(tex, (uv + vec2(-1.0,  1.0) * texel)).rgb *  1.0;",
            "hr += texture2D(tex, (uv + vec2( 0.0,  1.0) * texel)).rgb *  0.0;",
            "hr += texture2D(tex, (uv + vec2( 1.0,  1.0) * texel)).rgb * -1.0;",

            "vec3 vt = vec3(0., 0., 0.);",
            "vt += texture2D(tex, (uv + vec2(-1.0, -1.0) * texel)).rgb *  1.0;",
            "vt += texture2D(tex, (uv + vec2( 0.0, -1.0) * texel)).rgb *  2.0;",
            "vt += texture2D(tex, (uv + vec2( 1.0, -1.0) * texel)).rgb *  1.0;",
            "vt += texture2D(tex, (uv + vec2(-1.0,  0.0) * texel)).rgb *  0.0;",
            "vt += texture2D(tex, (uv + vec2( 0.0,  0.0) * texel)).rgb *  0.0;",
            "vt += texture2D(tex, (uv + vec2( 1.0,  0.0) * texel)).rgb *  0.0;",
            "vt += texture2D(tex, (uv + vec2(-1.0,  1.0) * texel)).rgb * -1.0;",
            "vt += texture2D(tex, (uv + vec2( 0.0,  1.0) * texel)).rgb * -2.0;",
            "vt += texture2D(tex, (uv + vec2( 1.0,  1.0) * texel)).rgb * -1.0;",

            "return sqrt(dot(hr, hr) + dot(vt, vt));",
        "}",

        "vec2 wobble(sampler2D tex, vec2 uv) {",
            "return uv + (texture2D(tex, uv).xy - 0.5) * scale;",
        "}",

        "vec4 edgeDarkening(vec4 inputColor) {",
            "vec4 c = inputColor;",
            "return c * (1.0 - (1.0 - c) * (darkening - 1.0));",
        "}",

        "float granulation(sampler2D tex, vec2 uv, float beta) {",
            "vec4 c = texture2D(tex, uv);",
            "float intensity = (c.r + c.g + c.b) / 3.0;",
            "return 1.0 + beta * (intensity - 0.5);",
        "}",

        "void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {",
            "vec2 myUv = wobble(tPaper, uv);",

            "float pd = granulation(tPaper, uv, pigment);", // pigment dispersion

            "float edge = sobel(tDiffuse, myUv);",
            "if (edge > threshold) {",
                "outputColor = pd * edgeDarkening(inputColor);",
            "} else {",
                "outputColor = pd * inputColor;",
            "}",
        "}"

    ].join("\n")        
}

export class WatercolorEffect extends Effect {
  constructor({
    tPaper = null,
    texel = new THREE.Vector2(1.0 / 512, 1.0 / 512),
    scale = 0.03,
    threshold = 0.7,
    darkening = 1.75,
    pigment = 1.2,
  } = {}) {
    super('WatercolorEffect', WatercolorShader.fragmentShader, {
      uniforms: new Map([
        ['tPaper', new Uniform(tPaper)],
        ['texel', new Uniform(texel)],
        ['scale', new Uniform(scale)],
        ['threshold', new Uniform(threshold)],
        ['darkening', new Uniform(darkening)],
        ['pigment', new Uniform(pigment)]
      ])
    })
  }
}

const Watercolor = wrapEffect(WatercolorEffect)

export { Watercolor };
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Contract, providers } from 'ethers';
import './AuctionPaddle.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import truncateEthAddress from 'truncate-eth-address';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import AUCTION_PADDLE_ABI from './auction-paddle-abi.json';

import { isMobile } from 'react-device-detect';

const INFURA_KEY = '0d1547daa6c64457b47a9b92678f8a14';

const PADDLE_ADDRESS = '0x8b39DbA886c2b75fA2f9A03351f7E7b2e5cF023A';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

function CountdownTimer(props) {
    const { endTime } = props;
    const [timeLeft, setTimeLeft] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Calculate the time left until the end time
        const timeLeft = Math.max(0, endTime - Date.now());
  
        // Update the state with the new time left value
        setTimeLeft(timeLeft);
      }, 1000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, [endTime]);
  
    if (timeLeft === null) {
      return null;
    }
  
    // Calculate the hours, minutes, and seconds left
    const hours = Math.floor(timeLeft / 1000 / 60 / 60);
    const minutes = Math.floor(timeLeft / 1000 / 60) % 60;
    const seconds = Math.floor(timeLeft / 1000) % 60;
  
    return (
      <div>
        {hours} hours {minutes} minutes {seconds} seconds left
      </div>
    );
}  

/* carousel of 3 images, mint button, connect wallet button */
function AuctionPaddle(props) {

    const lightboxRef = useRef(null);
    const lightboxImgRef = useRef(null);

    const [connectText, setConnectText] = useState("wallet connect");
    
    const [connected, setConnected] = useState(false);
    const [mintText, setMintText] = useState("mint");

    const [humanAddress, setHumanAddress] = useState("");
    const [address, setAddress] = useState("");

    const [web3Modal, setWeb3Modal] = useState(null);

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [mintEnd, setMintEnd] = useState(null);

    const [minted, setMinted] = useState(false);

    useEffect(() => {
        document.title = props.title;
    

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };

        const web3Modal = new Web3Modal({
            network: targetChainName, // optional   
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);

        const end = new Date(0);
        end.setUTCSeconds(1677171600);

        setMintEnd(end);
    }, []);

    async function mintPaddle() {
        const signer = ethersProvider.getSigner();

        const paddleContract = new Contract(PADDLE_ADDRESS, AUCTION_PADDLE_ABI, signer);

        const tx = await paddleContract.mint();

        console.log('minting paddle');
        setMintText("minting...");

        await tx.wait();

        setMintText("minted");
        setMinted(true);        
    }

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
        
        const signer = ethersProvider.getSigner();
        const paddleContract = new Contract(PADDLE_ADDRESS, AUCTION_PADDLE_ABI, signer);

        const claimedNFT = await paddleContract.claimed(userAddress);

        if(claimedNFT) {
            //TODO disable mint button
        }

        setMintText("MINT ");
    
        const name = await ethersProvider.lookupAddress(userAddress);
            
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    function openLightbox(src) {
        lightboxRef.current.classList.add('visible');
        lightboxRef.current.style.pointerEvents = 'all';

        //remove current src
        lightboxImgRef.current.src = "";

        lightboxImgRef.current.src = src; // display actual image
    }

    function closeLightbox() {
        //check if current opacity is less than 1
        lightboxRef.current.classList.remove('visible');
        lightboxRef.current.style.pointerEvents = 'none';
    }

    return( 
        <>
            {connected && <div className="Address-pill-paddle">{humanAddress}</div>}
            {!connected && <button className="Connect-button-paddle" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="Paddle-lightbox" ref={lightboxRef} onClick={(() => closeLightbox())}>
                <img ref={lightboxImgRef}/>
            </div>

            <div className="bg-paddle"></div>

            {isMobile &&
                <div className="Paddle-container-mobile">
                    <div className="Paddle-preview-mobile">
                        <img src={require('./images/auction_paddle.jpg')} />
                    </div>

                    <div className="Paddle-info-mobile">
                        <div className="Paddle-header">
                            <div className="Paddle-info-item">
                                <div className="Paddle-title">
                                    Yeche Biscuit Auction Paddle<br/>
                                    Wretched Worm and Jared Madere
                                </div>
                            </div>

                            <div className='Paddle-info-item'>
                                <div className="Etherscan-link">
                                    <a href="https://etherscan.io/address/0x8b39dba886c2b75fa2f9a03351f7e7b2e5cf023a" target="_blank">
                                        <img src={require('./images/etherscan_black.png')} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="Paddle-info-item">
                            <div className="Paddle-fullres-link">
                                <a href="https://jjtsfpjhqyfaouvwfchdcpyj7w4y3eszomnqckh3ghx3hnjjxd7q.arweave.net/SmcivSeGCgdStiiOMT8J_bmNkllzGwEo-zHvs7UpuP8/Yeche_Biscuit_Auction_Paddle.png" target="_blank">
                                    view full resolution
                                </a>
                            </div>
                        </div>

                        <div className="Paddle-info-item">
                            {connected && <button className="Mint-button-active-paddle" onClick={() => {if(!minted) mintPaddle(); } }>{mintText}</button>}
                            {!connected && <button className="Mint-button-inactive-paddle" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                        </div>

                        <div className="Paddle-info-item">
                            <div className="Countdown">
                                <CountdownTimer endTime={mintEnd} />
                            </div>
                        </div>

                        <div className="Paddle-info-item">
                            <div className="PaddleText">
                            Yeche Biscuit Auction Paddle: a sugar cookie with a splattering of jam and moon rabbit icing. a wooden handle adds a rustic charm. open edition, one per wallet.
                            </div>
                        </div>
                    </div>
                </div>
            }


            {!isMobile &&
                <>
                <div className="Paddle-info-desktop">
                    <div className="Paddle-header">
                        <div className="Paddle-info-item">
                            <div className="Paddle-title">
                                Yeche Biscuit Auction Paddle<br/>
                                Wretched Worm and Jared Madere
                            </div>
                        </div>

                        <div className='Paddle-info-item'>
                            <div className="Etherscan-link">
                                <a href="https://etherscan.io/address/0x8b39dba886c2b75fa2f9a03351f7e7b2e5cf023a" target="_blank">
                                    <img src={require('./images/etherscan_black.png')} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="Paddle-info-item">
                        {connected && <button className="Mint-button-active-paddle" onClick={() => {if(!minted) mintPaddle(); } }>{mintText}</button>}
                        {!connected && <button className="Mint-button-inactive-paddle" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}
                    </div>

                    <div className="Paddle-info-item">
                        <div className="Paddle-fullres-link">
                            <a href="https://jjtsfpjhqyfaouvwfchdcpyj7w4y3eszomnqckh3ghx3hnjjxd7q.arweave.net/SmcivSeGCgdStiiOMT8J_bmNkllzGwEo-zHvs7UpuP8/Yeche_Biscuit_Auction_Paddle.png" target="_blank">
                                view full resolution
                            </a>
                        </div>
                    </div>

                    <div className="Paddle-info-item">
                        <div className="Countdown">
                            <CountdownTimer endTime={mintEnd} />
                        </div>
                    </div>

                    <div className="Paddle-info-item">
                        <div className="PaddleText">
                        Yeche Biscuit Auction Paddle: a sugar cookie with a splattering of jam and moon rabbit icing. a wooden handle adds a rustic charm. open edition, one per wallet.
                        </div>
                    </div>
                </div>

                <div className="Paddle-preview-desktop">
                    <img src={require('./images/auction_paddle.jpg')} />
                </div>
                </>
            }

            {/* <div className="PaddleContainer">

                <div className="Paddle-preview">
                    <img src={require('./images/auction_paddle.jpg')} />
                </div>

                <div className="Paddle-info">
                    {connected && <button className="Mint-button-active-paddle" onClick={() => {if(!minted) mintPaddle(); } }>{mintText}</button>}
                    {!connected && <button className="Mint-button-inactive-paddle" onClick={() => { alert("Wallet not connected, connect your wallet to mint"); }}>MINT</button>}

                    <div className="Countdown">
                        <CountdownTimer endTime={mintEnd} />
                    </div>

                    <div className="PaddleText">
                    Lorem ipsum auction paddle.
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default AuctionPaddle;
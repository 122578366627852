import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { SceneContext } from "./Wanderlust.js";
import { useGLTF } from '@react-three/drei';

export default function BookStack(props) {

    const bookStack = useGLTF(process.env.PUBLIC_URL + '/models/wanderlust/book_stack_both.glb');

    const bressonTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/bresson.jpg');
    const dragonTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/dragon.jpg');
    const goetzTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/goetz.jpg');
    const hydroplutonicTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/hydroplutonic.jpg');
    const moradoTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/morado.jpg');
    const occidentaleschatologyTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/occidentaleschatology.jpg');
    const posthistoryTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/posthistory.jpg');
    const rabindranathTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/rabindranath.jpg');
    const ramanujanTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/ramanujan.jpg');
    const umbraTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/umbra.jpg');
    const videogramsTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/videograms.jpg');

    const arakawaTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/arakawa.jpg');
    const dinosaursTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/dinosaurs.jpg');
    const faerieTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/faerie.jpg');
    const logicTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/books/logic.jpg');

    useEffect(() => {
        const textures = [bressonTex, dragonTex, goetzTex, hydroplutonicTex, moradoTex, occidentaleschatologyTex, posthistoryTex, rabindranathTex, ramanujanTex, umbraTex, videogramsTex, arakawaTex, dinosaursTex, faerieTex, logicTex];
        textures.forEach(tex => {
            if (tex.image) {
                tex.flipY = false;
                tex.minFilter = THREE.NearestFilter;
                tex.magFilter = THREE.NearestFilter;
            }
        });

        console.log('bookstack nodes');
        console.log(bookStack.nodes);

        bookStack.nodes.bresson.material.map = bressonTex;
        bookStack.nodes.dragon.material.map = dragonTex;
        bookStack.nodes.goetz.material.map = goetzTex;
        bookStack.nodes.hydroplutonic.material.map = hydroplutonicTex;
        bookStack.nodes.morado.material.map = moradoTex;
        bookStack.nodes.occidentaleschatology.material.map = occidentaleschatologyTex;
        bookStack.nodes.posthistory.material.map = posthistoryTex;
        bookStack.nodes.rabindranath.material.map = rabindranathTex;
        bookStack.nodes.ramanujan.material.map = ramanujanTex;
        bookStack.nodes.umbra.material.map = umbraTex;
        bookStack.nodes.videograms.material.map = videogramsTex;
        
        bookStack.nodes.arakawa.material.map = arakawaTex;
        bookStack.nodes.dinosaurs.material.map = dinosaursTex;
        bookStack.nodes.faerie.material.map = faerieTex;
        bookStack.nodes.logic.material.map = logicTex;

    }, [bressonTex, dragonTex, goetzTex, hydroplutonicTex, moradoTex, occidentaleschatologyTex, posthistoryTex, rabindranathTex, ramanujanTex, umbraTex, videogramsTex, arakawaTex, dinosaursTex, faerieTex, logicTex]);

    return (
        <>
            {bookStack &&
                <>
                    <mesh geometry={bookStack.nodes.bresson.geometry}>
                        <meshBasicMaterial map={bressonTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.dragon.geometry}>
                        <meshBasicMaterial map={dragonTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.goetz.geometry}>
                        <meshBasicMaterial map={goetzTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.hydroplutonic.geometry}>
                        <meshBasicMaterial map={hydroplutonicTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.morado.geometry}>
                        <meshBasicMaterial map={moradoTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.occidentaleschatology.geometry}>
                        <meshBasicMaterial map={occidentaleschatologyTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.posthistory.geometry}>
                        <meshBasicMaterial map={posthistoryTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.rabindranath.geometry}>
                        <meshBasicMaterial map={rabindranathTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.ramanujan.geometry}>
                        <meshBasicMaterial map={ramanujanTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.umbra.geometry}>
                        <meshBasicMaterial map={umbraTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.videograms.geometry}>
                        <meshBasicMaterial map={videogramsTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.arakawa.geometry}>
                        <meshBasicMaterial map={arakawaTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.dinosaurs.geometry}>
                        <meshBasicMaterial map={dinosaursTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.faerie.geometry}>
                        <meshBasicMaterial map={faerieTex} />
                    </mesh>
                    <mesh geometry={bookStack.nodes.logic.geometry}>
                        <meshBasicMaterial map={logicTex} />
                    </mesh>
                </>
            }
        </>
    );
}
import React from 'react';
import * as THREE from 'three'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF } from "@react-three/drei";
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { lerp } from 'three/src/math/MathUtils';
import { useControls, Leva, folder } from 'leva';
import CustomShaderMaterial from 'three-custom-shader-material'


export default function NomeSkyDome(props) {

    const skyScale = 15;

    const skyBase = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/nome/sky_base_3.jpg');

    const domeGLB = useGLTF(process.env.PUBLIC_URL + '/models/nome/skydome.glb');

    const domeRef = useRef();

    const [texturesReady, setTexturesReady] = useState(false);

    // const { introCamPctRef, darkYecheEnabled } = useContext(SceneContext);

    const curDomeAlpha = useRef(0.0);

    const { cloudAnimSpeed, 
            cloudPow, 
            metallicAmt, 
            roughnessAmt, 
            envMapIntensity, 
            brightenAmt,
            cloudscale,
            renderOrder,
            distortNoiseStrength,
            distortNoiseScale,
            distortNoiseSpeed,
            // offset
        } = useControls('sky dome',{
        cloudAnimSpeed: { value: 0.01, min: 0.0, max: 10.0, step: 0.0001 },
        cloudPow: { value: 1.87, min: 0, max: 5.0, step: 0.01 },
        metallicAmt: { value: 0.62, min: 0.0, max: 1.0, step: 0.01 },
        roughnessAmt: { value: 0.0, min: 0.0, max: 1.0, step: 0.01 },
        envMapIntensity: { value: 3.0, min: 0.0, max: 10.0, step: 0.01 },
        brightenAmt: { value: 0.0, min: 0.0, max: 1.0, step: 0.01 },
        cloudscale: { value: 5.0, min: 0.0, max: 50.0, step: 0.01 },
        renderOrder: { value: -2, min: -10, max: 10, step: 1 },
        distortNoiseStrength: { value: 0.61, min: 0.0, max: 1.0, step: 0.01 },
        distortNoiseScale: { value: 12.1, min: 0.0, max: 80.0, step: 0.01 },
        distortNoiseSpeed: { value: 0.45, min: 0.0, max: 10.0, step: 0.01 },
        // offset: { value: 0.001, min: 0.0, max: 0.1, step: 0.0001 },
    });

    useEffect(() => {
        if( skyBase.image) {
            skyBase.flipY = false; 
            skyBase.minFilter = THREE.LinearFilter;
            skyBase.magFilter = THREE.LinearFilter;

   
            setTexturesReady(true);
        }

    }, [skyBase]);

    useFrame(({ clock }) => {
        if (domeRef.current) {
            //update time uniform
            domeRef.current.material.uniforms.time.value = clock.getElapsedTime() * cloudAnimSpeed;
            domeRef.current.material.uniforms.noiseTime.value = clock.getElapsedTime();
            domeRef.current.material.uniforms.cloudPow.value = cloudPow;
        }
    });

    return(
        <>        
        {texturesReady && 
            (<mesh
            position={[0, 30, 0]}
            scale={[skyScale, skyScale, skyScale]}
            geometry={domeGLB.nodes.Sphere.geometry}
            renderOrder={renderOrder}
            ref={domeRef}
            >
            <CustomShaderMaterial
                vertexShader={
                    `
                    varying vec2 myUv;

                    void main() {
                        myUv = uv;

                        csm_Position = position;
                    }                        
                    `
                }
                fragmentShader={
                ` 
                uniform float time;
                uniform float cloudPow;

                uniform sampler2D skyBase;

                uniform float brightenAmt;

                uniform float cloudscale;

                varying vec2 myUv;

                // const float cloudscale = 1.1;
                const float speed = 0.03;
                const float clouddark = 0.5;
                const float cloudlight = 0.3;
                const float cloudcover = 0.2;
                const float cloudalpha = 8.0;
                const float skytint = 0.5;

                uniform float globalAlpha;

                const mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );

                vec2 hash( vec2 p ) {
                    p = vec2(dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)));
                    return -1.0 + 2.0*fract(sin(p)*43758.5453123);
                }

                float noise( in vec2 p ) {
                    const float K1 = 0.366025404; // (sqrt(3)-1)/2;
                    const float K2 = 0.211324865; // (3-sqrt(3))/6;
                    vec2 i = floor(p + (p.x+p.y)*K1);	
                    vec2 a = p - i + (i.x+i.y)*K2;
                    vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0); //vec2 of = 0.5 + 0.5*vec2(sign(a.x-a.y), sign(a.y-a.x));
                    vec2 b = a - o + K2;
                    vec2 c = a - 1.0 + 2.0*K2;
                    vec3 h = max(0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
                    vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
                    return dot(n, vec3(70.0));	
                }

                float fbm(vec2 n) {
                    float total = 0.0, amplitude = 0.1;
                    for (int i = 0; i < 7; i++) {
                        total += noise(n) * amplitude;
                        n = m * n;
                        amplitude *= 0.4;
                    }
                    return total;
                }
                
                vec3 getCloudStrength(vec2 uvIn) {
                    vec2 uv = uvIn;

                    float q = fbm(uv * cloudscale * 0.5);
                    float speed = 0.03;
                    float cloudTime = time * speed; 
                    
                    //ridged noise shape
                    float r = 0.0;
                    uv *= cloudscale;
                    uv -= q - cloudTime;
                    float weight = 0.8;
                    for (int i=0; i<8; i++){
                        r += abs(weight*noise( uv ));
                        uv = m*uv + cloudTime;
                        weight *= 0.7;
                    }
                    
                    //noise shape
                    float f = 0.0;
                    uv = uvIn;

                    uv *= cloudscale;
                    uv -= q - time;
                    weight = 0.7;
                    for (int i=0; i<8; i++){
                        f += weight*noise( uv );
                        uv = m*uv + time;
                        weight *= 0.6;
                    }
                    
                    f *= r + f;
                    
                    //noise colour
                    float c = 0.0;
                    cloudTime = time * speed * 2.0;
                    uv = uvIn;

                    uv *= cloudscale*2.0;
                    uv -= q - cloudTime;
                    weight = 0.4;
                    for (int i=0; i<7; i++){
                        c += weight*noise( uv );
                        uv = m*uv + cloudTime;
                        weight *= 0.6;
                    }
                    
                    //noise ridge colour
                    float c1 = 0.0;
                    cloudTime = time * speed * 3.0;
                    uv = uvIn;

                    uv *= cloudscale*3.0;
                    uv -= q - cloudTime;
                    weight = 0.4;
                    for (int i=0; i<7; i++){
                        c1 += abs(weight*noise( uv ));
                        uv = m*uv + cloudTime;
                        weight *= 0.6;
                    }
                    
                    c += c1;

                    return vec3(f, c, r);
                }


                void main() {
                    vec4 skyBaseCol = texture2D(skyBase, myUv);
                    vec3 cloudCol = texture2D(skyBase, vec2(0.5, 1.0)).rgb;
                    
                    vec3 cloudStrength = getCloudStrength(myUv);

                    //BLEND NEAR SEAM
                    float blendWidth = 0.01;
                    if(myUv.x < blendWidth || myUv.x > 1.0  - blendWidth) {
                        // color = vec4(1.0, 0.0, 0.0, 1.0);

                        //get distance from seam
                        float dist = 0.0;
                        if(myUv.x < blendWidth) {
                            dist = myUv.x;
                        } else {
                            dist = 1.0 - myUv.x;
                        }

                        vec2 inverseUV = vec2(1.0 - myUv.x, myUv.y);
                        vec3 inverseUVStrength = getCloudStrength(inverseUV);

                        //average the two based on distance from seam, a distance of 0.0 is completely averaged
                        float avgAmt = (1.0 - smoothstep(0.0, blendWidth, dist)) * 0.5;

                        cloudStrength = mix(cloudStrength, inverseUVStrength, avgAmt);

                    }

                    vec3 skycolour = skyBaseCol.rgb;
                    skycolour = mix(skycolour, vec3(1.0), brightenAmt);
                   
                    float f = cloudcover + cloudalpha*cloudStrength.x*cloudStrength.z;
                
                    float fadeFactor = 1.0;

                    float fadeTopStart = 0.7;
                    float fadeTopEnd = 0.9;

                    float fadeBottomStart = 0.3;
                    float fadeBottomEnd = 0.1;

                    if(1.0 - myUv.y > fadeTopStart) {
                        fadeFactor = 1.0 - smoothstep(fadeTopStart, fadeTopEnd, 1.0 - myUv.y);
                    } 

                    else if(1.0 - myUv.y < fadeBottomStart) {
                        //fade down near horizon
                        fadeFactor = smoothstep(fadeBottomEnd, fadeBottomStart, 1.0 - myUv.y);
                    }

                    f = pow(f, cloudPow);
                    f *= fadeFactor;
                    //clamp to 0.0 - 1.0
                    f = clamp(f, 0.0, 1.0);

                    vec3 c = mix(cloudCol, vec3(1.0), cloudStrength.z * 0.5);

                    vec4 color = vec4(mix(skycolour, c, f), 1.0);

                    csm_DiffuseColor = color;
                    csm_DiffuseColor.a = globalAlpha;
                }
                `
                }
                uniforms={{
                    skyBase: {
                        value: skyBase
                    },
                    time: {
                        value: 0
                    },
                    noiseTime: {
                        value: 0.0
                    },
                    cloudPow: {
                        value: cloudPow
                    },
                    brightenAmt: {
                        value: brightenAmt
                    },
                    cloudscale: {
                        value: cloudscale
                    },
                    globalAlpha : {
                        value: 1.0
                    },
                    distortNoiseStrength: {
                        value: distortNoiseStrength
                    },
                    distortNoiseScale: {
                        value: distortNoiseScale
                    },
                    distortNoiseSpeed: {
                        value: distortNoiseSpeed
                    },
                    offset: {
                        value: 0.001
                    }
                }}
                baseMaterial={THREE.MeshBasicMaterial}
                flatShading={true}
                depthWrite={false}
                fog={false}
                side={THREE.BackSide}
                color={0xffffff}
                // normalMap={normalTex}
                // metalness={metallicAmt}
                // roughness={roughnessAmt}
                // envMapIntensity={envMapIntensity}
                // opacity={0.0}
                transparent={true}
                attach="material" 
                // ...
            />
        </mesh>)}
        </>
    );
}
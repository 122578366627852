
import * as THREE from 'three';
import React, { useEffect } from 'react';
import { forwardRef, useRef, useState, useContext } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useControls, Leva, folder } from 'leva';
import { BlendFunction, Resizer, KernelSize } from "postprocessing";
import { PerformanceMonitor } from '@react-three/drei';
import { EffectComposer, Noise, ColorAverage, Outline, SSR, DotScreen, DepthOfField, SSAO, GodRays, Bloom, SMAA, HueSaturation, BrightnessContrast, Pixelation } from '@react-three/postprocessing';  
import { Water } from 'three/examples/jsm/objects/Water';
// import { WatercolorPass } from './WatercolorPass.js';
import { Watercolor } from './WatercolorEffect.js';
import { Effects } from '@react-three/drei';
import { SceneContext } from './Wanderlust';
import { isMobile, isTablet } from 'react-device-detect';
import { lerp } from 'three/src/math/MathUtils.js';

export default function WanderlustPostProcessing(props) {

    // extend({ WatercolorPass });
    // const setDisabledEffectsRef = useRef(false);
    
    const [disableEffects, setDisableEffects] = useState(false);

    const curFocalLengthRef = useRef(0.0);
    const curBokehRef = useRef(0.0);

    const {
        outlineEdgeStrength
    } = useControls('outline', {
        outlineEdgeStrength: {
            value: 5.0,
            min: 0.0,
            max: 100.0,
            step: 0.1
        },
    });

    const {
        scale, 
        threshold,
        darkening,
        pigment,
    } = useControls('watercolor', {
        scale: {
            value: 0.01,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        threshold: {
            value: 0.6,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        darkening: {
            value: 1.3,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        pigment: {
            value: 0.0,
            min: 0.0,
            max: 10.0,
        }
    });

    const {
        AOActive,
        AOintensity, 
        AOBias, 
        AOradius, 
        AODistThresh, 
        AODistFallOff, 
    } = useControls('lighting',{
        AOActive: {
            value: false
        },
        AOintensity: {
            value: 12,
            min: 0.0,
            max: 20.0,
            step: 0.1,
        },  
        AOBias: {
            value: 0.3,
            min: 0,
            max: 1,
            step: 0.01,
        },
        AOradius: {
            value: 0.08,
            min: 0,
            max: 1,
            step: 0.01,
        },
        AODistThresh: {
            value: 179.0,
            min: 0,
            max: 500.0,
            step: 0.01,
        },
        AODistFallOff: {
            value: 85.0,
            min: 0.0,
            max: 500.0,
            step: 0.01,
        },
    });

    const moonGLB = useGLTF(process.env.PUBLIC_URL + '/models/lunar/moon.glb');

    const {samples, density, weight, decay, exposure} = useControls('moon rays', {
        samples: {
            value: 10,
            min: 0,
            max: 100,
            step: 1,
        },
        density: {
            value: 0.7,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        weight: {
            value: 1.0,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        decay: {
            value: 0.7,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        exposure: {
            value: 0.8,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
    });

    const { SSRIntensity, SSRBlend, SSRFade, SSRDistance } = useControls('SSR', {
        SSRIntensity: {
            value: 0.67,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        SSRBlend: {
            value: 0.58,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        SSRFade: {
            value: 0.0,
            min: 0.0,
            max: 100.0,
            step: 0.01,
        },
        SSRDistance: {
            value: 2000.0,
            min: 0.0,
            max: 100000.0,
            step: 1,
        }
    });

    const { focusDistance, focalLength, bokehScale } = useControls('depth of field', {
        focusDistance: {
            value: 0.0,
            min: 0.0,
            max: 100.0,
            step: 0.001,
        },
        focalLength: {
            value: 0.03,
            min: 0.0,
            max: 1.0,
            step: 0.001,
        },
        bokehScale: {
            value: 1.0,
            min: 0.0,
            max: 100.0,
            step: 0.001,
        },
    });

    const paperTex = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/textures/paper.jpg');

    const frameCountRef = useRef(0);

    const { introCamPctRef } = useContext(SceneContext);


    // const { pixelationGranularity } = useControls('pixelation', {
    //     pixelationGranularity: {
    //         value: 8,
    //         min: 0,
    //         max: 100,
    //         step: 1,
    //     },
    // });

    // const sunRef = useRef();

    const [sunRef, setSunRef] = useState(null);
    const { scene } = useThree();

    const outlineRef = useRef();

    const dofRef = useRef();

    useEffect(() => {
        if(isMobile && !isTablet) {
            setDisableEffects(true);
        }
    }, []);

    useEffect(() => {
        console.log(moonGLB);
        if(!moonGLB) return;

        let moonGeo = moonGLB.nodes.moon.geometry;
        //create a sphere for the sun and basic material 
        const sun = new THREE.Mesh(
            moonGeo,
            new THREE.MeshBasicMaterial({ color: "#ffffff" })
        );

        sun.material.fog = false;

        //set the position of the sun
        sun.position.set(0, 270, -500);
        //add the sun to the scene
        sun.scale.set(500, 500, 500);

        sun.updateMatrixWorld();
        scene.add(sun);
        setSunRef(sun);
        console.log('set sun');
    }, [moonGLB]);

    useFrame(({state, clock}) => {
        frameCountRef.current++;

        if(introCamPctRef.current > 0.6 && introCamPctRef.current < 0.95 && dofRef.current) {
            let amt = lerp(curBokehRef.current, 5.0, 0.005);
            dofRef.current.bokehScale = amt;

            curBokehRef.current = amt;

        }

        else if(introCamPctRef.current >= 0.95 && introCamPctRef.current < 1.0 && dofRef.current) {
            let amt = lerp(curBokehRef.current, 1.0, 0.1);
            dofRef.current.bokehScale = amt;

            curBokehRef.current = amt;
        }

        else if(dofRef.current && introCamPctRef.current >= 1.0) {
            dofRef.current.bokehScale = 1.0;
        }

        //animate outlineRef visibleColor through rainbow colors
        if(!outlineRef.current) return;

        let t = (clock.getElapsedTime() % 5) / 5;

        //create a color that cycles through the rainbow using clock
        let edgeColor = new THREE.Color();
        edgeColor.setHSL(
            t,
            1.0,
            0.5
        );

        outlineRef.current.uniforms.get('visibleEdgeColor').value = edgeColor;

        // let edgeColorUniform = { value: edgeColor };

        // outlineRef.current.uniforms.set('visibleEdgeColor', edgeColorUniform);
    });

    return(
        <>

        {/* <PerformanceMonitor
        iterations={20}
        onChange={(({fps, averages}) =>{

            if(!setDisabledEffectsRef.current) return;

            //console.log(fps) 
            //check if the average fps is below 30
            let mostRecentAverage = averages[0];
            let disableThresh = 30;

            if(frameCountRef.current > 40 && fps < disableThresh && mostRecentAverage < disableThresh && !setDisabledEffectsRef.current) {
                console.log('disabling effects..');
                //disable effects
                setDisabledEffectsRef.current = true;
                setDisableEffects(true);
            }
        })}
        /> */}



        {sunRef &&
        (<EffectComposer autoClear={false}>
                {!disableEffects && 
                (<>
                    <DepthOfField 
                        focusDistance={focusDistance}       
                        focalLength={0.03}
                        bokehScale={0.0}
                        ref={dofRef}
                    />

                    <GodRays
                        sun={sunRef}
                        blendFunction={BlendFunction.Screen}
                        samples={samples}
                        density={density}
                        decay={decay}
                        weight={weight}
                        exposure={exposure}
                        clampMax={1}
                        width={Resizer.AUTO_SIZE}
                        height={Resizer.AUTO_SIZE}
                        kernelSize={KernelSize.SMALL}
                        blur={true}
                    />

                    <Watercolor 
                        tPaper={paperTex}
                        scale={scale}
                        threshold={threshold}
                        darkening={darkening}
                        pigment={pigment}
                    />
                </>)}

                <Outline 
                ref = {outlineRef}
                blur 
                edgeStrength={outlineEdgeStrength}
                />
            </EffectComposer>)
        }

        {/* <Effects>
            <watercolorPass args={[paperTex]} />
        </Effects> */}
        </>
    );
}
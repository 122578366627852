import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext, forwardRef } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { useControls, Leva, folder } from 'leva';
import CustomShaderMaterial from 'three-custom-shader-material'
import { vert, frag } from './NoiseFog.js'


export const MudMat = forwardRef(({useNoise}, ref) => {
// export default function MudMat({useNoise}) {
    const baseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudlunar_basecolor.jpg");
    const normalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudlunar_normal.jpg");
    const roughnessTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudlunar_specular.jpg");

    const [texturesReady, setTexturesReady] = useState(false);

    // const matRef = useRef();

    const { 
        roughness, 
        metalness, 
        matCol, 
        clearcoat, 
        clearcoatRoughness, 
        normalMapStrength, 
        envMapIntensity, 
        noiseTimeScale, 
        noiseScale, 
        fogColBase,
        // transmission,
        // ior
    } = useControls('mud material',{
        roughness: { value: 0.38, min: 0, max: 1, step: 0.01 },
        metalness: { value: 0.97, min: 0, max: 1, step: 0.01 },
        clearcoat: { value: 0.0, min: 0, max: 1, step: 0.01 },
        clearcoatRoughness: { value: 0.34, min: 0, max: 1, step: 0.01 },
        normalMapStrength: { value: 3.43, min: 0, max: 5, step: 0.01 },
        envMapIntensity: { value: 2.24, min: 0, max: 10, step: 0.01 },
        matCol: '#ce8383',
        noiseTimeScale: { value: 80.0, min: 0.0, max: 500.0, step: 0.1 },
        noiseScale: { value: 2739.0, min: 0.0, max: 5000.0, step: 0.1 },
        fogColBase: '#e0e2c1',
        // transmission: { value: 0.8, min: 0.0, max: 1.0, step: 0.01 },
        // ior: { value: 1.5, min: 0.0, max: 10.0, step: 0.01 }
    });

    // useFrame(({ clock }) => {
    //     if(matRef.current) {
    //         matRef.current.uniforms.time.value = clock.getElapsedTime() * noiseTimeScale;
    //         matRef.current.uniforms.noiseScaleBase.value = noiseScale;
    //         matRef.current.uniforms.fogColBase.value = new THREE.Color(fogColBase);
    //     }
    // });

    useEffect(() => {
        const mudTiling = 30;
        //check if textures are loaded
        if (!texturesReady && baseTex.image && normalTex.image && roughnessTex.image) {
            baseTex.wrapS = baseTex.wrapT = THREE.RepeatWrapping;
            baseTex.repeat.set( mudTiling, mudTiling );

            normalTex.wrapS = normalTex.wrapT = THREE.RepeatWrapping;
            normalTex.repeat.set( mudTiling, mudTiling );

            roughnessTex.wrapS = roughnessTex.wrapT = THREE.RepeatWrapping;
            roughnessTex.repeat.set( mudTiling, mudTiling );

            setTexturesReady(true);
        }
    }, [baseTex, normalTex, texturesReady]);

    return (
        <>
        {texturesReady && 

        <CustomShaderMaterial
            baseMaterial={THREE.MeshStandardMaterial}
            vertexShader={vert}
            fog={false}
            fragmentShader={frag}
            ref={ref}

            // uniforms={{
            //     time: { value: 0 },
            //     noiseScaleBase: { value: 2739 },
            //     fogColBase: { value: new THREE.Color('#e0e2c1') },
            //     useNoise: { value: true }
            // }}

            // ref={matRef}
            map={baseTex}
            map-flipY={false}
            normalMap={normalTex} 
            normalMap-flipY={false} 
            normalMap-encoding={THREE.LinearEncoding}
            normalScale={[normalMapStrength,normalMapStrength]} 
            roughnessMap={roughnessTex}
            roughnessMap-flipY={false}
            roughnessMap-encoding={THREE.LinearEncoding}
            roughness={roughness} 
            metalness={metalness} 
            clearcoat={clearcoat} 
            clearcoatRoughness={clearcoatRoughness} 
            color={matCol} 
            envMapIntensity={envMapIntensity}

        />
        }
        </>
    );
});

import { create } from 'zustand';

export const useStore = create((set) => ({
  avatars: [],
  avatarIndex: 0,
  addAvatar: (socketID, avatarGLB, denimTexture, wlgTexture, isSelf) => {
    set((state) => ({ avatarIndex: state.avatarIndex + 1 }));
    set((state) => ({ avatars: [...state.avatars, 
        {
            socketID: socketID, 
            name: state.avatarIndex, 
            avatarGLB: avatarGLB, 
            denimTexture: denimTexture, 
            wlgTexture: wlgTexture,
            key: state.avatarIndex,
            isSelf: isSelf
        }]}));
  },
  removeAvatar: (avatarIndex) => {
    set((state) => ({ avatars: [...state.avatars.filter(item => item.key !== avatarIndex)]}));
    // set((state) => ({ avatarIndex: state.avatarIndex - 1 })); //maybe ? 
  }
}))

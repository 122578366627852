import React from 'react';
import { useEffect, useState } from 'react';
import './Collections.css';
import './Splash.css';
import { NavLink } from 'react-router-dom';

function Collections(props) {

    useEffect(() => {
        document.title = props.title;
    }, []);    
        
    return(
        <>
        <div className="bg-splash"></div>

        <div className="collections-container">

            <div className="collections">

                <div className="collections-group">
                    <div className="collection-type">Collections</div>
                    <div className="collection-item">
                        <NavLink to="/nomedegen">
                            <img src={require('./images/grensplash.jpg')} alt="Nome"/><br />
                            <div className='collection-link'>The alchemist who tried to put a soul in a homunculus didn't have a soul either</div>
                        </NavLink>
                    </div>

                    <div className="collection-item">
                        <a href="https://obaa.life" target="_blank">
                            <img src={require('./images/obaa_banner_square.jpg')} alt="OBAA"/><br />
                            <div className='collection-link'>OBAA</div>
                        </a>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/wanderlust">
                            <img src={require('./images/wlg_thumb.jpg')} alt="Wanderlust Girls"/><br />
                            <div className='collection-link'>Wanderlust Girls</div>
                        </NavLink>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/creation-babies">
                            <img src={require('./images/cb_thumb.jpg')} alt="Creation Babies"/><br />
                            <div className='collection-link'>Creation Babies</div>
                        </NavLink>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/newyear">
                            <img src={require('./images/nye_giveaway_thumb.jpg')} alt="NYE Giveaway"/><br />
                            <div className='collection-link'>New Years Eve 2023 Golden Opportunity 48 Hour Open Mint</div>
                        </NavLink>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/yeche-cakes">
                            <img src={require('./images/1-Red_Velvet-1_Walnut_She_Had_Cloud_Sundae-1.webp')} alt="Yeche Cakes"/><br />
                            <div className='collection-link'>Yeche Cakes</div>
                        </NavLink>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/yeche-pies">
                            <img src={require('./images/pies_thumb.jpg')} alt="Yeche Pies"/><br />
                            <div className='collection-link'>Yeche Lange Pie Key Un</div>
                        </NavLink>
                    </div>
                </div>

                <div className="collections-group">
                    <div className="collection-type">Exhibitions</div>
                    <div className="collection-item">
                        <NavLink to="/schooloftruth">
                            <img src={require('./images/truth_thumb.jpg')} alt="School of Truth"/><br />
                            <div className='collection-link'>School of Truth</div>
                        </NavLink>
                    </div>
                    <div className="collection-item">
                        <NavLink to="/swollen-hearts">
                            <img src={require('./images/nomemene_thumb.jpg')} alt="Tournament for Swollen Hearts"/><br />
                            <div className='exhibition-link'>Tournament for Swollen Hearts</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Collections;
import React, { useState, useEffect } from 'react';
import { isTablet } from 'react-device-detect';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowWidth;
};

export const useIsWideView = () => {
    const windowWidth = useWindowWidth();
    const isPortrait = windowWidth < window.innerHeight;
    const isLandscapeTablet = !isPortrait && isTablet;
    const isDesktop = windowWidth > 1024;

    return isLandscapeTablet || isDesktop;
};


import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Select } from '@react-three/postprocessing';
import { SceneContext } from "./Nome.js";

export default function SelectCrosshair({children, name}) {
    const selectEnabledRef = useRef(false);
    const [selectEnabled, setSelectEnabled] = useState(false);
    const { crosshairArtworkRef, artObjectsRef } = useContext(SceneContext);

    // const tokenIDRef = useRef(-1);

    useFrame(() => {
        // if(artObjectsRef.current.length > 0 && tokenIDRef.current == -1) {

        //     let planeIndex = artObjectsRef.current.findIndex((plane) => plane && plane.artName == name);

        //     let artworkPlane = artObjectsRef.current[planeIndex];

        //     if(artworkPlane) {
        //         tokenIDRef.current = artworkPlane.tokenID;
        //     }
        // }

        // else if(tokenIDRef.current != -1) {
        //     return;
        // }

        // console.log('checking if ' + crosshairArtworkRef.current + ' is ' + name);

        if(crosshairArtworkRef.current == name && !selectEnabledRef.current) {
            setSelectEnabled(true);
            selectEnabledRef.current = true;
            // console.log("select enabled");
        }
        else if(crosshairArtworkRef.current != name && selectEnabledRef.current){
            setSelectEnabled(false);
            selectEnabledRef.current = false;
            // console.log("select disabled");
        }
    });


    return(
        <Select enabled={selectEnabled}>
            {children}
        </Select>
    )
}

import React, { useEffect, useState } from 'react';
import { useFrame, useThree, useLoader } from '@react-three/fiber';
import * as THREE from 'three'
import { Html } from '@react-three/drei';
import { useRef, forwardRef, useContext } from "react";
import { useControls } from 'leva';
import VanillaCustomShaderMaterial from 'three-custom-shader-material/vanilla';
import CustomShaderMaterial from 'three-custom-shader-material'
import truncateEthAddress from 'truncate-eth-address';
import { lerp } from 'three/src/math/MathUtils';
import { useGLTF } from '@react-three/drei';
import { RigidBody, Attractor, vec3, MeshCollider, BallCollider, InstancedRigidBodies } from '@react-three/rapier';
import { Sphere } from '@react-three/drei';

export default function TantrumGigi({}) {

    const numBalls = 43;

    const { scene } = useThree();

    const relativeCamPos = useRef(null);

    const attractorRef = useRef(null);

    const bobAng = useRef(0);
    const rotTarget = useRef(0);

    //FOR ATTRACTOR
    const angleRef = useRef(0);

    const gigiRef = useRef(null);

    const targetPosRef = useRef(null);

    const tornadoMatRef = useRef();
    const tornadoMat2Ref = useRef();
    const tornadoMat3Ref = useRef();

    const ballRefs = useRef([]);

    const [balls, setBalls] = useState();


    const tokenSpritesheet = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/tokens_spritesheet_2.png');

    const gigiCapTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/gigicap.jpg');

    const gigiGLB = useGLTF(process.env.PUBLIC_URL + '/models/gigi_tantrum.glb');
    const tokenGLB = useGLTF(process.env.PUBLIC_URL + '/models/tantrum_token.glb');

    // const keyGLB = useGLTF(process.env.PUBLIC_URL + '/models/wanderlust/keynew.glb');

    // const keyDiffuse = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/textures/key_diffuse.jpg');

    const gigiCurPosRef = useRef(new THREE.Vector3(0, 0, 0));

    const vertexShader = `
    uniform float time;
    
    uniform float layerIndex;

    varying vec2 myUV;

    float noiseScaleX = 20.0;
    float noiseScaleY = 20.0;
    float distortAmp = 0.5;
    float twistIntensity = 3.0;
    float swirlSpeed = 5.5;

    float hash(float n) { return fract(sin(n) * 1e4); }
    float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }

    float noise(float x) {
        float i = floor(x);
        float f = fract(x);
        float u = f * f * (3.0 - 2.0 * f);
        return mix(hash(i), hash(i + 1.0), u);
    }

    float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);

        float a = hash(i);
        float b = hash(i + vec2(1.0, 0.0));
        float c = hash(i + vec2(0.0, 1.0));
        float d = hash(i + vec2(1.0, 1.0));

        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    float blendNoise(vec2 uv) {
        float blendWidth = 0.2; // Adjust to suit your needs
        float blend = smoothstep(1.0 - blendWidth, 1.0, uv.x);
        
        vec2 uv1 = vec2(uv.x, uv.y);
        vec2 uv2 = vec2(uv.x - 1.0, uv.y);
        
        return mix(noise(uv1), noise(uv2), blend);
    }

    void main() {
        myUV = uv;

        vec3 noiseDisplacement1 = normal * noise(vec2(uv.x * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp;
        vec3 noiseDisplacement2 = normal * noise(vec2((1.0-uv.x) * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp;
        vec3 noiseDisplacement = (noiseDisplacement1 + noiseDisplacement2) * 0.5;

        vec3 upDisplacement1 = vec3(0.0, 1.0, 0.0) * noise(vec2(uv.x * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp * 0.8;
        vec3 upDisplacement2 = vec3(0.0, 1.0, 0.0) * noise(vec2((1.0-uv.x) * noiseScaleX + time * 100.0, uv.y * noiseScaleY - time * 5.0)) * distortAmp * 0.8;
        vec3 upDisplacement = (upDisplacement1 + upDisplacement2) * 0.5;

        // Tornado animation
        float twist = twistIntensity * uv.y * sin(time * swirlSpeed);
        vec3 tornadoDisplacement = vec3(sin(twist), 0.0, cos(twist));

        vec3 chaos = vec3(
            blendNoise(vec2(0.0, uv.y * 3.0 + time * 5.0)),
            blendNoise(vec2(0.0 + time, uv.y * 4.0)),
            blendNoise(vec2(0.0 - time, uv.y * 6.0))
        );

        vec3 finalPosition = position + noiseDisplacement + upDisplacement + tornadoDisplacement * chaos;

        csm_Position = finalPosition;
    }
    `;

    const fragShader = `
    varying vec2 myUV;
    uniform float time;

    uniform float layerIndex;

    float hash(float n) { return fract(sin(n) * 1e4); }
    float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }
    
    float noise(float x) {
        float i = floor(x);
        float f = fract(x);
        float u = f * f * (3.0 - 2.0 * f);
        return mix(hash(i), hash(i + 1.0), u);
    }
    
    float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);
    
        // Four corners in 2D of a tile
        float a = hash(i);
        float b = hash(i + vec2(1.0, 0.0));
        float c = hash(i + vec2(0.0, 1.0));
        float d = hash(i + vec2(1.0, 1.0));
    
        // Simple 2D lerp using smoothstep envelope between the values.
        // return vec3(mix(mix(a, b, smoothstep(0.0, 1.0, f.x)),
        //			mix(c, d, smoothstep(0.0, 1.0, f.x)),
        //			smoothstep(0.0, 1.0, f.y)));
    
        // Same code, with the clamps in smoothstep and common subexpressions
        // optimized away.
        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    vec3 colorSpectrum(float x) {
        const vec3 R = vec3(1.0, 0.0, 0.0);
        const vec3 O = vec3(1.0, 0.5, 0.0);
        const vec3 Y = vec3(1.0, 1.0, 0.0);
        const vec3 G = vec3(0.0, 1.0, 0.0);
        const vec3 B = vec3(0.0, 0.0, 1.0);
        const vec3 I = vec3(0.3, 0.0, 0.5);
        const vec3 V = vec3(0.93, 0.51, 0.93);
    
        if (x < 1.0 / 6.0) {
            return mix(R, O, x * 6.0);
        } else if (x < 2.0 / 6.0) {
            return mix(O, Y, (x - 1.0 / 6.0) * 6.0);
        } else if (x < 3.0 / 6.0) {
            return mix(Y, G, (x - 2.0 / 6.0) * 6.0);
        } else if (x < 4.0 / 6.0) {
            return mix(G, B, (x - 3.0 / 6.0) * 6.0);
        } else if (x < 5.0 / 6.0) {
            return mix(B, I, (x - 4.0 / 6.0) * 6.0);
        } else {
            return mix(I, V, (x - 5.0 / 6.0) * 6.0);
        }
    }    
    
    void main() {
        float tornadoScaleY = 45.0;
        float tornadoScaleX = 12.0;

        float tornadoSpeedX = 90.0;
        float tornadoSpeedY = 20.0;

        //get noise value at uv offset by time

        //rotate UVs by 30 degrees
        float angle = sin(time)*-20.0 * 3.1415926535897932384626433832795 / 180.0;
        float s = sin(angle);
        float cc = cos(angle);
        mat2 rotationMatrix = mat2(cc, -s, s, cc);
        vec2 rotatedUV = rotationMatrix * myUV;


        float n1 = noise(vec2(rotatedUV.x * tornadoScaleX + time * tornadoSpeedX * (layerIndex + 1.0), rotatedUV.y * tornadoScaleY + time * tornadoSpeedY * (layerIndex + 1.0)));
        float n2 = noise(vec2((1.0 - rotatedUV.x) * tornadoScaleX + time * tornadoSpeedX * (layerIndex + 1.0), (1.0-rotatedUV.y) * tornadoScaleY + time * tornadoSpeedY * (layerIndex + 1.0)));

        float n = (n1 + n2) / 2.0;

        if(layerIndex == 1.0) {
            n = pow(n, 1.3);
        }
        float c = clamp((layerIndex + 1.0) * 0.7, 0.0, 1.0);

        float opacity = pow(n, 3.0 - layerIndex) * 0.8;

        vec3 col = colorSpectrum(c + n * 0.1);

        csm_DiffuseColor = vec4(col, opacity); 

        if(opacity < 0.05) {
            discard;
        }
    }
    `;
    
    function mapRange(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }

    function smoothstep(min, max, value) {
        let x = Math.max(0, Math.min(1, (value - min) / (max - min)));
        return x * x * (3 - 2 * x);
    }

    function animateAttractor() {
        if(attractorRef.current) {
            angleRef.current += 0.01;
            let offsetY = Math.sin(angleRef.current * 10.0) * 0.5 + 0.5;
            offsetY *= 3.0;

            //spin position around in a circle of radius 10
            let x = Math.cos(angleRef.current * 100.0) * 3;
            let z = Math.sin(angleRef.current * 100.0) * 3;

            attractorRef.current.position.set(x, offsetY + 2.0, z);
        }
    }
      
    useEffect(() => {
        if(tokenGLB.nodes && tokenSpritesheet) {

            const instances = [];
    
            let scale = 0.35;
            for (let i = 0; i < numBalls; i++) {
                const radius = 5; // adjust to your needs
                const theta = Math.random() * Math.PI; // polar angle
                const phi = Math.random() * 2 * Math.PI; // azimuthal angle
              
                const startPos = new THREE.Vector3(
                  radius * Math.sin(theta) * Math.cos(phi), // x
                  radius * Math.sin(theta) * Math.sin(phi), // y
                  radius * Math.cos(theta) // z
                );
              
                let randMass = Math.random() * 10.0 + 15.0;
                let randLinearDamping = Math.random() * 0.3 + 0.7;
                let randScale = Math.random() * 0.6 + 0.2;
              
                instances.push({
                  key: `instance_${i}`,
                  position: startPos,
                  linearDamping: randLinearDamping,
                  mass: randMass,
                  scale: randScale,
                });
            }
              
            const plasticMat = new THREE.MeshStandardMaterial({
            color: new THREE.Color(0.1, 0.1, 0.1),
            roughness: 1.0,
            metalness: 0.0,
            });
    
            const metalMat = new THREE.MeshStandardMaterial({
            color: new THREE.Color(1.0, 1.0, 1.0),
            roughness: 0.1,
            metalness: 0.9,
            });
    
            const instanceIndices = new Float32Array(numBalls);
            for (let i = 0; i < numBalls; i++) {
                instanceIndices[i] = i;
            }
            
            const tokenGeometry = tokenGLB.scene.children[0].geometry;

            // const tokenGeometry = new THREE.PlaneBufferGeometry(1.0, 1.0);
            //scale down to 0.1
            tokenGeometry.scale(1.0,1.0,1.0);
            
    
            tokenGeometry.setAttribute('instanceIndex', new THREE.InstancedBufferAttribute(instanceIndices, 1));
    

            tokenSpritesheet.minFilter = THREE.NearestFilter;
            tokenSpritesheet.magFilter = THREE.NearestFilter;
            tokenSpritesheet.flipY = false;

            const tokenSwarmMat = new VanillaCustomShaderMaterial({
                baseMaterial: THREE.MeshBasicMaterial,
                // roughness: 0.0,
                // metalness: 0.3,
                transparent: true,
                uniforms: {
                    tokensTex: { value: tokenSpritesheet },
                },
                vertexShader: `
                    varying vec2 vUv;
                    attribute float instanceIndex;
                    varying float vInstanceIndex;

                    void main() {
                        vUv = uv;
                        vec4 worldPosition = instanceMatrix * vec4(position, 1.0);
                        vec4 modelViewPosition = modelViewMatrix * worldPosition;
                        
                        vInstanceIndex = instanceIndex;
        
                        csm_PositionRaw = projectionMatrix * modelViewPosition;
                    }
                `,
                fragmentShader: `
                    varying vec2 vUv;
                    varying float vInstanceIndex;

                    float gridDimen = 8.0;
                    uniform sampler2D tokensTex;

                    vec2 instanceIndexToUV(float idx, vec2 uv) {
                        float cols = gridDimen;
                        float rows = gridDimen;
                
                        float y = floor((idx + 0.5) / cols);
                        float x = idx - cols * y;
                
                        return vec2(x / cols + uv.x / cols, 1.0 - (y / rows + (uv.y) / rows));
                    }
                            
        
                    void main() {
                        vec2 gridUV = instanceIndexToUV(vInstanceIndex, vUv);
                    
                        vec4 texColor = texture2D(tokensTex, gridUV);
                
                        // csm_FragColor = vec4(vec3(1.0,0.0,0.0), texColor.a);
                        csm_FragColor = vec4(texColor.rgb, texColor.a);

                        if(csm_FragColor.a < 0.01) {
                            discard;
                        }
                    }
                `,
                // side: THREE.DoubleSide, // render both sides of the plane
            });
    
            // const sphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
    
            setBalls(
                <InstancedRigidBodies 
                ref={ballRefs} 
                instances={instances}
                colliderNodes={[
                    <BallCollider args={[scale*3]} />,
                ]}
              >
                <instancedMesh
                  args={[tokenGeometry, tokenSwarmMat, numBalls]}
                  count={numBalls}
                />
              </InstancedRigidBodies>
            );

        }    

      }, [tokenGLB, tokenSpritesheet]);
      
    useFrame(({camera}) => {

        if(tornadoMatRef.current) {
            tornadoMatRef.current.uniforms.time.value += 0.01;
        }

        if(tornadoMat2Ref.current) {
            tornadoMat2Ref.current.uniforms.time.value += 0.01;
        }

        if(tornadoMat3Ref.current) {
            tornadoMat3Ref.current.uniforms.time.value += 0.01;
        }

        animateAttractor();          

        
        /* slowly rotate gigiRef around the y axis, and lerp camera in front of her */
        if(gigiRef.current != null) {
            if(relativeCamPos.current == null) {
                //save local pos of cam relative to gigiRef
                relativeCamPos.current = camera.position.clone();
                gigiRef.current.worldToLocal(relativeCamPos.current);
            }
            
            else {
                //lerp camera in front of gigiRef
                let newWorldPos = relativeCamPos.current.clone();

                //offset newWorldPos on the y axis by sin(bobAng) * 0.1
                newWorldPos.y += Math.sin(bobAng.current) * 0.5;


                gigiRef.current.localToWorld(newWorldPos);


                camera.lookAt(gigiRef.current.position);
                camera.position.lerp(newWorldPos, 0.01);
                //look at gigiRef
    
                //rotate by a random amount on the y axis
                // gigiRef.current.rotation.y += 0.005;

                if(Math.random() < 0.005) {
                    rotTarget.current = Math.random() * Math.PI * 2;
                }

                gigiRef.current.rotation.y = lerp(gigiRef.current.rotation.y, rotTarget.current, 0.005);

                //bob up and down using a sine wave on the x axis
                bobAng.current += 0.03;

                gigiRef.current.rotation.x = Math.sin(bobAng.current) * 0.05;
                gigiRef.current.rotation.z = Math.cos(bobAng.current) * 0.05;

                //move gigi in a circle on the x z axis based on bobAng and a radius
                gigiRef.current.position.x = Math.sin(bobAng.current) * 0.5;
                gigiRef.current.position.z = Math.cos(bobAng.current) * 0.5;
            }
        }


    });

    return(
        <>
        
        {balls}

        {gigiGLB &&
        <group
        ref={gigiRef}
        scale={[0.9, 0.9, 0.9]}
        >
            {/* <Html
                ref={messageRef}
                zIndexRange={[0, 5]}
                center
                position={[0, 3, 0]}
                style={{
                    color: '#47003f',
                    fontFamily: 'Duke',
                    background: 'rgba(255,255,255,0.5)',
                    width: '250px',
                    height: 'auto',
                    borderRadius: '10px',
                    fontSize: '1.0rem',
                    filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',
                    padding: '10px',
                    textAlign: 'left',
                    lineHeight: '1.0',
                    fontWeight: 'normal',
                    pointerEvents: 'none',
                    textShadow: '0px 0px 5px rgba(255,255,255,1.0), 0px 0px 10px rgba(255,255,255,1.0), 0px 0px 15px rgba(255,255,255,1.0)',
                    transform: "translate(-50%, -50%)",
                    WebkitTouchCallout: "none", 
                    transition: "opacity 2.0s",
                    WebkitUserSelect: "none", 
                    KhtmlUserSelect: "none",
                    MozUserSelect: "none", 
                    MsUserSelect: "none", 
                    UserSelect: "none",
                    opacity: 0.0
                }}
            /> */}


            <mesh ref={attractorRef}>
            <Attractor range={40} strength={2.5} type="linear"/>
            </mesh>

            {/* add 100 rigidbody componentts */}
            <mesh 
            renderOrder={1000}
            geometry={gigiGLB.nodes.cap.geometry}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            >
                <meshStandardMaterial 
                color="pink" 
                map={gigiCapTex} 
                map-flipY={false} 
                
                roughness={0.8}
                metalness={0.5}

                // depthTest={false}
                // depthWrite={false}
                // transparent={true}
                />
            </mesh>
            <mesh 
            renderOrder={1}
            geometry={gigiGLB.nodes.tophat.geometry}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            >
                <meshStandardMaterial 
                color="#540451"                 
                roughness={0.8}
                metalness={0.5}
                />
            </mesh>

            <mesh 
            renderOrder={0}
            geometry={gigiGLB.nodes.cap.geometry}
            position={[0, -0.1, 0]}
            rotation={[0, 0, 0]}
            scale={[1.02, 1.02, 1.02]}
            >
                <meshBasicMaterial 
                color="black"             
                depthTest={false}
                depthWrite={false}         
                />
            </mesh>
            <mesh 
            renderOrder={0}
            geometry={gigiGLB.nodes.tophat.geometry}
            position={[0, -0.15, 0]}
            rotation={[0, 0, 0]}
            scale={[1.02, 1.02, 1.02]}
            >
                <meshBasicMaterial 
                color="black"            
                depthTest={false}
                depthWrite={false}     
                />
            </mesh>

            <mesh 
            geometry={gigiGLB.nodes.tophatband.geometry}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            >
                <meshStandardMaterial 
                color="#bb40c2" 
                map={gigiCapTex} 
                roughness={0.8}
                metalness={0.5}
                />
            </mesh>

            <mesh
            geometry={gigiGLB.nodes.tornado.geometry}
            position={[0, 0, 0]}
            renderOrder={1}
            >
                {/* <meshStandardMaterial></meshStandardMaterial> */}
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader={vertexShader}
                fragmentShader={fragShader}
                ref={tornadoMatRef}
                transparent={true}
                side={THREE.DoubleSide}
                depthWrite={false}
                // depthTest={false}
                uniforms={{
                    time: { value: 0 },
                    layerIndex: { value: 0.0 }
                }}
                />
            </mesh>
            <mesh
            geometry={gigiGLB.nodes.tornado.geometry}
            position={[0, 0, 0]}
            scale={[0.8, 0.9, 0.8]}
            renderOrder={1}
            >
                {/* <meshStandardMaterial></meshStandardMaterial> */}
                <CustomShaderMaterial
                baseMaterial={THREE.MeshBasicMaterial}
                vertexShader={vertexShader}
                fragmentShader={fragShader} 
                ref={tornadoMat2Ref}
                transparent={true}
                side={THREE.DoubleSide}
                depthWrite={false}
                // depthTest={false}
                uniforms={{
                    time: { value: 0 },
                    layerIndex: { value: 1.0 }
                }}
                />
            </mesh>
        </group>}
        </>
    );
}
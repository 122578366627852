import { useState, useEffect, useRef } from 'react';

function useCarousel(carouselRef, loadingBarRef, delay = 500) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const carouselInner = carouselRef.current;
        const loadingBar = loadingBarRef.current;
        if (!carouselInner || !loadingBar) return;

        console.log('setting up carousel');

        const items = carouselInner.children;

        document.documentElement.style.setProperty('--number-of-items', items.length);

        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % items.length;
                const offset = -newIndex * 100;
                carouselInner.style.transform = `translateX(${offset}%)`;
                return newIndex;
            });

            console.log('switching to slide', index);

            // Reset the loading bar animation
            loadingBar.style.animation = 'none';
            // This forces a reflow, triggering the animation again
            void loadingBar.offsetWidth;
            loadingBar.style.animation = `slide ${delay}ms linear, oreo-color-anim-bar 4s infinite`;
        }, delay);

        return () => clearInterval(interval);
    }, [carouselRef, loadingBarRef, delay]);

    return index;
}

export default useCarousel;

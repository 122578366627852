import React, { useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { useRef, forwardRef, useContext } from "react";
import { useControls } from 'leva';
import * as THREE from 'three'
import { SceneContext } from './Nome';
import CustomShaderMaterial from 'three-custom-shader-material'
import truncateEthAddress from 'truncate-eth-address';
import { lerp } from 'three/src/math/MathUtils';
import { RigidBody } from '@react-three/rapier';

export const Avatar = forwardRef(({socketID, name, avatarGLB, denimTexture, wlgTexture, isSelf}, ref) => {

    const rigidbodyRef = useRef(null);

    const MAX_ALPHA = 0.4;

    const curAlphaRef = useRef(0.0);

    const zeroScale = new THREE.Vector3(0.0, 1.5, 0.0);
    const oneScale = new THREE.Vector3(1.0, 1.0, 1.0);

    const { 
        avatarDataRef,
        avatarVisibleRef, 
        fullDisplayRef,
        hisotricalHatDataRef,
    } = useContext(SceneContext);

    const { camera } = useThree();

    const randCol = new THREE.Color(Math.random(), Math.random(), Math.random());

    const tagRef = useRef();
    const messageRef = useRef();

    const matRef = useRef();

    const frameCount = useRef(0);

    const curNumHats = useRef(0);

    const numHats = useRef(0);

    const hatsGroupRef = useRef();
    const hatsMaterialsRef = useRef([]);

    const groupRefForSelf = useRef();

    const messageFadeTimer = useRef(null);


    const { selfOffsetY } = useControls({
        selfOffsetY: {
            value: 0,
            min: -10.0,
            max: 10.0,
            step: 0.01
        }
    });

    const hatMat = new THREE.MeshStandardMaterial({
        map: denimTexture,
        transparent: true,
    });

    const wlgHatMat = new THREE.MeshStandardMaterial({
        map: wlgTexture,
        // mapFlipY: false,
        transparent: true,
    });

    const vert = `
    precision highp float;

    varying vec2 myUv;
    varying vec3 vNorm;
    varying vec3 vEye;

    void main() {
        vNorm = normalize(normalMatrix * normal);
        vEye = normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);
        myUv = uv;
    }
    `;

    const frag = `
    precision highp float;

    varying vec2 myUv;
    varying vec3 vNorm;
    varying vec3 vEye;

    uniform float maxAlpha;
    
    void main() {
        float fresnelPowTemp = 2.0;

        float fresnel = dot(vEye, vNorm);
        // fresnel = pow(fresnel, fresnelPow) * fresnelBoost;
        fresnel = pow(fresnel, fresnelPowTemp);
        fresnel = clamp(fresnel, 0.0, 1.0);

        //make this work on all openGL versions
        csm_DiffuseColor.a = min(fresnel, maxAlpha);
    }
    `;

    function updateHats() {
        //clear all children of hatsGroupRef.current, for every bid add avatarGLB.nodes.cap as a child of hatsGroupRef.current

        while (hatsGroupRef.current.children.length > 0) {
            hatsGroupRef.current.remove(hatsGroupRef.current.children[0]);
        }
        
        let hatScale = 0.7;

        // let numWLGHats = curNumHats.current - numHats.current;

        hatsMaterialsRef.current = [];

        let numWLGHats = curNumHats.current - numHats.current;

        for(let i = 0; i < curNumHats.current; i++) {
            let hat = avatarGLB.nodes.cap.clone();

            hat.renderOrder = 301;

            let hatStartPos = avatarGLB.nodes.cap.position;

            let hatYOffset = (i * 0.55) + 1.2;

            hat.position.set(hatStartPos.x, hatStartPos.y + hatYOffset, hatStartPos.z);
            hat.scale.set(hatScale, hatScale, hatScale);

            if(i > 0) {
                hat.rotation.set(0, Math.random() * Math.PI * 2.0, 0);
            }

            else {
                hat.rotation.set(0, Math.PI, 0);
            }

            //create random color from hsl
            let randHue = Math.random();
            let randSat = 0.5 + Math.random() * 0.5;
            let randLum = 0.5 + Math.random() * 0.5;

            if(i < numWLGHats) {
                hat.material = wlgHatMat.clone();
                randLum = 0.9;
            }

            else {
                hat.material = hatMat.clone();
            }


            let randHatCol = new THREE.Color().setHSL(randHue, randSat, randLum);
            hat.material.color = randHatCol;
            hatsGroupRef.current.add(hat);
            hatsMaterialsRef.current.push(hat.material);
        }
    }

    function updateHatAlpha(newAlpha) {
        for(let i = 0; i < hatsMaterialsRef.current.length; i++) {
            hatsMaterialsRef.current[i].opacity = newAlpha;
        }
    }

    useFrame(() => {

        if(rigidbodyRef.current != null) {
            if(isSelf) {
                rigidbodyRef.current.setNextKinematicTranslation(groupRefForSelf.current.position);
            }

            else if(ref.current) {
                rigidbodyRef.current.setNextKinematicTranslation(ref.current.position);
            }
        }

        let myData = avatarDataRef.current[socketID];

        //HANDLE MESSAGE
        if(myData !== undefined) {
            let message = myData.message;

            if(message != undefined && messageRef.current && messageRef.current.innerText != message) {
                //message changed
                messageRef.current.innerText = message;

                //set opacity to 1
                messageRef.current.style.opacity = 1.0;

                if(messageFadeTimer.current != null) {
                    //clear last timer
                    clearTimeout(messageFadeTimer.current);
                }

                //in 20 seconds, animate opacity to 0
                messageFadeTimer.current = setTimeout(() => {

                    console.log("fade timer");
                    
                    if(messageRef.current != null) {
                        messageRef.current.style.opacity = 0.0; 
                    }

                    else {
                        console.log("message ref is null?");
                    }

                }, 20000);
            }
        }

        if(isSelf && groupRefForSelf.current) {
            let avatarPos = new THREE.Vector3(camera.position.x, camera.position.y - 1.5 - selfOffsetY, camera.position.z);

            let cameraDirection = new THREE.Vector3(0, 0, -1);
            cameraDirection.applyQuaternion(camera.quaternion);

            //offset avatar pos by camera direction so you can see yourself
            avatarPos.add(cameraDirection.multiplyScalar(3.0));

            groupRefForSelf.current.position.lerp(avatarPos, 0.5);
            groupRefForSelf.current.quaternion.slerp(camera.quaternion, 0.25);

            if(matRef.current && tagRef.current) {
                if(avatarVisibleRef.current == true && !fullDisplayRef.current) {
                    //fade avatar in by increasing maxAlpha to MAX_ALPHA
                    curAlphaRef.current = lerp(curAlphaRef.current, MAX_ALPHA, 0.1);
                    matRef.current.uniforms.maxAlpha.value = curAlphaRef.current;

                    //fade tag in style.opacity
                    tagRef.current.style.opacity = lerp(tagRef.current.style.opacity, 1.0, 0.1);

                    //scale hatsGroupRef to 0
                    hatsGroupRef.current.scale.lerp(oneScale, 0.1);
                    //set y rotation of hatsGroupRef to curAlphaRef * Math.PI * 2.0

                    const fadeInAmt = curAlphaRef.current / MAX_ALPHA;

                    hatsGroupRef.current.rotation.y = fadeInAmt * Math.PI * 4.0;

                    updateHatAlpha(fadeInAmt);
                }
                
                //also do this if in full art view
                else if(avatarVisibleRef.current == false || fullDisplayRef.current) {
                    //fade avatar out by decreasing maxAlpha to 0
                    curAlphaRef.current = lerp(curAlphaRef.current, 0.0, 0.1);
                    matRef.current.uniforms.maxAlpha.value = curAlphaRef.current;
                    
                    //fade tag out style.opacity
                    tagRef.current.style.opacity = lerp(tagRef.current.style.opacity, 0.0, 0.1);
                    
                    //scale hatsGroupRef to 1
                    hatsGroupRef.current.scale.lerp(zeroScale, 0.1);

                    const fadeInAmt = curAlphaRef.current / MAX_ALPHA;

                    hatsGroupRef.current.rotation.y = fadeInAmt * Math.PI * 4.0;

                    updateHatAlpha(fadeInAmt);
                }
            }
        }

        else if(matRef.current) {
            matRef.current.uniforms.maxAlpha.value = MAX_ALPHA;

        }

        frameCount.current++;

        if(frameCount.current % 60 === 0 && myData !== undefined) {

            // console.log("myData " + myData);

            let myAddress = myData.address;
            let myENS = myData.ens;
            let myTag = myData.tag;
            let landed = myData.landed;
            let numHats = myData.numHats;

            // if(myAddress == `0x31CD63C73E74808793F134f8146a1E3AA1637B5d`) {
            //     numHats = 5;
            // }

            //if tagRef.current.innerHTML is empty, set it to anon or ens or address
            if(landed) {
                if(curNumHats.current !== numHats) {
                    curNumHats.current = numHats;

                    //check if myAddress key is in hisotricalHatDataRef dict, you only need to do this once 
                    if(hisotricalHatDataRef.current[myAddress] !== undefined && numHats.current == 0) {
                        numHats.current = hisotricalHatDataRef.current[myAddress];
                        console.log("numHats " + numHats.current);
                    }

                    updateHats();
                }

                let currentTag = tagRef.current.innerHTML;

                let newTag = "";

                if(myTag !== undefined && myTag !== "") {
                    // console.log("setting tag to " + myTag)
                    // tagRef.current.innerHTML = myTag;
                    newTag = myTag;
                } else if(myENS !== undefined && myENS !== "") {
                    // console.log("setting tag to " + myENS)
                    // tagRef.current.innerHTML = myENS;
                    newTag = myENS;
                } else if(myAddress !== undefined && myAddress !== "") {
                    // console.log("setting tag to " + myAddress)
                    // tagRef.current.innerHTML = truncateEthAddress(myAddress);
                    // newTag = truncateEthAddress(myAddress);
                    newTag = myAddress;

                } else {
                    // console.log("setting tag to " + "anon" + name)
                    // tagRef.current.innerHTML = "anon" + name;
                    newTag = "anon" + name;
                }

                if(currentTag !== newTag) {
                    console.log("setting tag to " + newTag);
                    tagRef.current.innerHTML = newTag;
                }
            }

            return;
        }
    });

    return (
    <>
    {avatarGLB &&

    <group ref={isSelf ? groupRefForSelf : ref} name={socketID.toString()}>
        {/* <sphereGeometry args={[1.0, 32, 32]} />
        <meshStandardMaterial color={0x00ff55} transparent opacity={0.7} /> */}

        <RigidBody ref={rigidbodyRef} type={'kinematicPosition'}>
            <mesh
            geometry={avatarGLB.nodes.body.geometry}
            position={avatarGLB.nodes.body.position}
            visible={true}            
            >
                <meshBasicMaterial transparent={true} opacity={0.0} />
            </mesh>
        </RigidBody>

        <mesh
            geometry={avatarGLB.nodes.body.geometry}
            position={avatarGLB.nodes.body.position}
            renderOrder={300}
        >
                <CustomShaderMaterial
                    baseMaterial={THREE.MeshBasicMaterial}
                    vertexShader={vert}
                    fragmentShader={frag}
                    uniforms={{
                        maxAlpha: {value: 0.0},
                    }}
                    color={randCol}
                    transparent
                    ref={matRef}
                    depthWrite={false}
                    depthTest={false}
                    blending={THREE.AdditiveBlending}
                    />
        </mesh>



        <group ref={hatsGroupRef}/>

        <Html
            ref={messageRef}
            zIndexRange={[0, 5]}
            center
            position={isSelf ? [0, 2, 0] : [0, 5, 0]}
            style={{
                color: randCol,
                fontFamily: 'Duke',
                background: 'rgba(255,255,255,0.5)',
                maxWidth: '250px',
                height: 'auto',
                borderRadius: '10px',
                fontSize: '1.0rem',
                filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',
                padding: '10px',
                textAlign: 'left',
                lineHeight: '1.0',
                fontWeight: 'normal',
                pointerEvents: 'none',
                textShadow: '0px 0px 5px rgba(255,255,255,1.0), 0px 0px 10px rgba(255,255,255,1.0), 0px 0px 15px rgba(255,255,255,1.0)',
                transform: "translate(-50%, -50%)",
                WebkitTouchCallout: "none", 
                transition: "opacity 2.0s",
                WebkitUserSelect: "none", 
                KhtmlUserSelect: "none",
                MozUserSelect: "none", 
                MsUserSelect: "none", 
                UserSelect: "none",
                opacity: 0.0
        }}
        />
        
        <Html
            ref={tagRef}
            zIndexRange={[0, 5]}
            center
            style={{
                color: randCol,
                fontFamily: 'Duke',
                fontSize: '0.8rem',
                fontWeight: 'normal',
                pointerEvents: 'none',
                textShadow: '0px 0px 5px rgba(255,255,255,1.0), 0px 0px 10px rgba(255,255,255,1.0), 0px 0px 15px rgba(255,255,255,1.0)',
                transform: "translate(-50%, 50%)",
                WebkitTouchCallout: "none", 
                WebkitUserSelect: "none", 
                KhtmlUserSelect: "none",
                MozUserSelect: "none", 
                MsUserSelect: "none", 
                UserSelect: "none" 
            }}
        />
    </group>}
    </>
    )
});

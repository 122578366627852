import React from 'react';
import { useEffect } from 'react';

function SwollenHearts(props) {

  useEffect(() => {
    document.title = props.title;
  }, []);    

  return (
    <iframe src="https://mileshiroo.github.io/yeche-swollenhearts/" title="TOURNAMENT FOR SWOLLEN HEARTS" style={{position: "absolute", top: "4vh"}} onLoad={window.scroll(0,0)} width="100%" height="100%" frameBorder="0" scrolling="yes" allowFullScreen></iframe>
  );
}

export default SwollenHearts;

import React, { Suspense } from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useContext, useState, createContext } from 'react';
import { Water } from 'three/examples/jsm/objects/Water'
import { useFrame, useThree, useLoader } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useControls, Leva, folder } from 'leva';
import Ocean from './Ocean';
import NomeSky from './NomeSky';
import Gallery from './Gallery';
import FirstPersonControls from './FirstPersonControls';
import { SceneContext } from './Nome.js';

export default function NomeScene() {

    const { waveSteepness, wavelengthA, wavelengthB, wavelengthC } = useControls('waves', {
        waveSteepness: {
            value: 0.03,
            min: 0.0,
            max: 50000.0,
            step: 0.005
        },
        wavelengthA: {
            value: 50.0,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
        wavelengthB: {
            value: 90.0,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
        wavelengthC: {
            value: 70,
            min: 0.0,
            max: 200.0,
            step: 0.1
        },
    });

    const waves = [
        { direction: 0, steepness: waveSteepness, wavelength:  wavelengthA },
        { direction: 30, steepness: waveSteepness, wavelength: wavelengthB },
        { direction: 60, steepness: waveSteepness, wavelength: wavelengthC },
    ];

    const { 
        sceneLoadedRef,
        cameraPosRef 
    } = useContext(SceneContext);

    const frameRef = useRef(0);

    const collisionObjectsRef = useRef(null);

    useFrame(({ clock, camera }) => {

        frameRef.current += 1;

        cameraPosRef.current = camera.position;

        //todo: find a better way to do this
        if(frameRef.current == 40) {
            sceneLoadedRef.current = true;
        }

    });

    useEffect(() => {
    },[]);

    return(
    <>
        <Ocean waves={waves}/>  
        <FirstPersonControls ref={collisionObjectsRef}/>
        <group ref={collisionObjectsRef}>
            <Gallery waves={waves}/>
        </group>
        <NomeSky />
    </>
    );
}
import React from 'react';
import { useEffect, useState } from 'react';
import './Splash.css';
import './Thesis.css';
import { NavLink } from 'react-router-dom';

function Thesis(props) {

    useEffect(() => {
        document.title = props.title;
    }, []);    
        
    return(
        <>
            <div className="bg-splash"></div>

            <div className="thesis-container">
                <div className="thesis-statement">
                We have just decided this will be written in a fast style because we are working on many things.<br /><br />

                We have been working all summer.<br /><br />

                Please understand we will be curt not to be rude but to be quick and clear.<br /><br />

                We come from art.<br /><br />

                By this we mean that before starting Galerie Yeche Lange we ran a series of artist-run galleries including Bed-Stuy Love Affair in New York and Mother Culture in Los Angeles, Berlin, and Istanbul. In addition to running our own galleries we have been dedicated to curating exhibitions and showing our own artwork in various commercial galleries and institutions in the US and EU.<br /><br />

                We say this not to assert a claim to know how things should be done, but to begin with openness about our history, trajectory, mobility etc.<br /><br />

                In crypto there is a very strong impulse to burn down archaic stagnant institutions and structures, governmental and otherwise, that have grown outdated or are based on an old-world value set which prevents a new guard of more relevant and agile ideas from taking hold.<br /><br />

                What people describe as the Art World is a network of institutions and commercial dealers, the collectors who buy from them and the artists that they show, the writers who critique, the auctioneers who preside over the secondary market, and so on.<br /><br />

                The Art World, as it is parodied, is a prime target for the crosshairs of crypto ideologies. Many pieces of popular media such as The Square, the British TV show Absolutely Fabulous or Tom Ford's Nocturnal Animals depict the Art World as opulent and out-of-touch arena where cultural elites amuse themselves by transforming the plain and simple into the overly complex, unapproachable, and needlessly precious. It is a universe held together by a slightly updated but ultimately old-world social decorum whose pleasantries can be traced back to archaic patronage structures and social hierarchies of the era of the European Enlightenment if not further. The elusive metric used to evaluate the caliber of artworks alienates those who are not educated in its fickle language and excludes artists and artworks that do not wink at the correct moment to signal that they are in on the joke. There is a tradition of art that critiques art, and even this feels stale<br /><br />

                Art is old.<br /><br />

                Art is gray.<br /><br />

                Art is this and that, and art is stretched thin and dying, and with its frail bones creaking under the weight of its rotten red pitted alcoholic nose compressing its spine in a soiled calfskin armchair, it is just the type of irrelevant old man that crypto would love to put out of its misery - but there is much left to learn~ this is not a day for babies and bath water…<br /><br />

                The realm of NFTs faces many challenges in defining its standards and practices as a new arena with the aim of securing long term value in artistic assets.<br /><br />

                Jump on NFT Twitter for 30 seconds and you will be flooded with posts speaking about utility, whitelists, staking, OG status roles, comic books for holders, and token gated social gatherings that transform the image embedded in the IPFS of your NFT from an object of aesthetic contemplation into a club house access card~ very quickly we see projects attempting to entice collectors without much regard for the art itself.<br /><br />

                Why is it that traditional art is more fit to stand on its own merits, and NFTs feel the need to insecurely pander to their collectors with goodie bags full of bonus treats?<br /><br />

                You never see a Picasso with a sticker attached to the side that says 'the owner of this painting is entitled to visit a time share in Tulum' - despite the fact that owning a Picasso may have engendered the respect and admiration necessary to receive an invitation to summer on an estate in Tulum.<br /><br />

                Some critics cite a lack of quality in the art, others will say it is because of the collecting culture of a high volatility & more immediately liquid market than traditional art - but neither of these are the true reason.<br /><br />

                The true reason is found in the way that NFTs are formatted and marketed in relation to their author.<br /><br />

                NFTs are largely formatted as projects independent of the artist, and the way collectors relate to the work is in many ways isolated from the totality of the artist's lifetime of output. This gives rise to many strange practices that are not particularly well thought through.<br /><br />

                There is a large difference between building legacy and building frenzy, and the majority of NFT projects actively aim to obscure the difference.<br /><br />

                A project launches, it mints out - everyone posts their NFT to twitter, the secondary market pumps from FOMO on the mint, the discord goes wild~ everyone is happy, and everyone treats themselves to dinner + dessert…<br /><br />

                ...13 months later the population of the same discord is FURIOUS~ the price has sunk since the mint, everyone who hasn't sold is left with an illiquid NFT in a ghost town of a discord where nobody cares about the project~ the faceless artist, the dev team - they all seem to have disappeared - the vibrant garden which was once the project, and its community has not been watered in many moons.<br /><br />

                The expectation for many NFT collectors is that the team should have been more active! They should have continued to water the garden, giving away freebies, comics, arranging meetups + raffles + prizes and anything else they can think of to create tangible incentives to own the NFT and thus maintain a desirable floor price.<br /><br />

                Is this expectation sustainable?<br /><br />

                Did Picasso or Man Ray manage a discord for the collectors that bought their work in the 1920s for 60 years to maintain the price of their works until the 1980s? NooOooooooOO they didn't!!<br /><br />

                It may be tempting to attribute this to something lofty like 'well maybe that's because Picasso was a turbo genius, he had wealthy parents, or he was divinely blessed and infinitely lucky' but again~ nooooo this is not the reason why the longevity of traditional artists' careers are not dependent on providing utility and constant engagement to collectors beyond the continued creation of artwork.<br /><br />

                The reason is because the gallery system itself and the patronage and publishing models it relies on very elegantly solve so many of the problems facing NFT creators and collectors in conceiving of systems that successfully store value long term. Galleries, along with the criticism circuits they are enmeshed with, are active producers of long-term value, and continually revitalize the reception of their artists' oeuvres by managing their legacies through personal, social, and historical contextualization.<br /><br />

                Where the old art world galleries focus on representing their artists and their work, new NFT projects focus on manipulating collectors. The model currently employed by the majority of NFT projects depends on a team to babysit and stoke the flames of its fandom indefinitely when the reality is that most creations on earth no matter how amazing never reach the level of sustained fandom as Barbie, Marvel. If you make a club for people who are obsessed with Van Gogh, it will have many more members than if you make a club for fans of his singular painting The Potato Eaters. Of course it is tempting to say 'oh but a club for fans of Van Gogh's most famous painting - Starry Night, that club would have many many members!” and yes, in a certain sense this observation is true however it ignores the structural reality that the value of each of these paintings is mutually sustained by each other's value and that rather than these prices existing in a vacuum of speculation around a singular work they are determined by the totality of Van Gogh's work produced over the course of his entire life.<br /><br />

                Beyond the scope of an artist's singular output, the value of their work is also contextually secured in relationship to the historical significance of their peers. Galleries and record labels, modeling agencies and publishing houses, all of these structures rely on communally reinforced value structures where the credentials and accomplishments of everyone on the team mutually supports the value of their other team members.<br /><br />

                Most traditional art world galleries have a roster of artists that they represent. Being represented by a gallery traditionally means that the gallery is responsible for storing, maintaining and installing finished artworks, fronting production money for the fabrication of new artworks, selling artworks and maintaining relationships with collectors, fielding press inquiries, maintaining the price of work by gallery artists whose artwork goes up for auction on the secondary market, coordinating shipping, finding studio assistants to help the artist create new work, coordinating with other galleries and institutions where the artist has exhibitions, and finally~ managing the artist's estate posthumously.<br /><br />

                What is the difference between the life of an artwork and the life of an NFT? Can we speak about the life of an NFT? What happens after its managers die?<br /><br />

                It is a commonly expressed sentiment by art world outsiders as well as those who have not worked within these systems that the 50% sales cut taken by galleries is egregious, however as an artist writing this I can tell you - if a gallerist is able to do all of the above impeccably I would gladly give them a 70% cut of my sales as I am confident that 30% of sales from a dealer this talented will far outweigh a mediocre dealer I am giving 50% or less. This is similar to the logic of why people pay to live in New York or Paris, more expensive rent balanced with the potential of earning much more. That said - I can also tell you that there are many dead-beat gallerists that fail to succeed in many of the above responsibilities which is why you hear many complaints suggesting that those in the role of gallerist do not even deserve to receive 50%😉.<br /><br />

                The co-sign of a gallery is a stamp on the name of an artist that the gallery believes in their long-term success - it is a sign that the gallery has committed to maintain and manage the career of that artist til death do us part. But again, the value of that artist's work does not exist within the vacuum of their relationship with the gallerist and their collectors - this is a complex interconnected feedback system of value generation and storage in which the prestige of other artists on the same roster all serve to provide context and legitimacy to each other. One artist may be a market darling who is not taken seriously by curators, writers, and art historians (this may sound fine from a market perspective, but any artist who fits this profile is likely to be a flash-in-the-pan whose career will quickly dissolve without the long term support of art historians and writers) ~ another artist may be an extremely well respected character with a long history of outrageous and memorable projects and public experiences yet despite being commercially successful they are not an absolute market titan ~ it is in this sort of balance where artists benefit from the commingling of their strengths and achievements such that when a writer comes to write about nectarines they may notice the zucchini sitting next to it and choose to also write about this OR the shopper hunting for zucchini, strolling into the shop and seeing the shine of the nectarines they simply cannot leave without grabbing a few in addition to the zucchini they originally set out for. Galleries provide opportunity for contextual discovery and mutually hedged contextual value storage as the artists within that roster collectively gain notoriety in their respective endeavors over time, their achievements only get added to the collective pool of the gallery.<br /><br />

                HBO does a TV adaptation of Chris Kraus' novel I Love Dick - that makes all of Chris' books sell more, esp Dick~ but the recognition of this singular work also ripples out increasing the sales and perceived significance of her other books. These ripples do not end there, authors working with the same publisher, graduates of the same school, and Chris' protégés will all enjoy the trickle-across increase in their perceived value as Chris prospers.<br /><br />

                As we move further away from 2021 and witness a stabilization in NFT pricing and volume it will become increasingly essential to view artworks within the context of the artist's entire oeuvre, rather than briefly glancing at the floor price of a single project on an aggregator like OpenSea, to determine the significance and long-term potential of any given artist.<br /><br />

                Not every individual artwork or project can sustain the fandom of cultural phenomena like Barbie or Marvel, nor should this even be the apex or goal of art on the internet, and this is exactly why we must think of the larger picture beyond individual projects and rather begin to approach NFTs in terms of the arc of the artist's entire creative output across a lifetime and their context within a larger group of peers. Creating a culture and structure to make this possible will take time, conscious effort, and experimentation.<br /><br />

                ~<br /><br />
                We recently spoke with an NFT collector regarding the purchase of a few 1/1 artworks by an artist that we represent. The collector raised a very interesting question, they asked if we felt that the prices we were asking for our 1/1 works were reasonable at 2ETH per artwork (ETH was priced at 1500USD at the time). The collector cited a very interesting logic~<br /><br />

                “I am confident that one day in the not so distant future ETH will be valued at 100k USD, when I buy an NFT for an artist for 1ETH I often feel like I am making an absurdly large donation rather than a purchase, as the 1500 I give them now could easily be worth 100k if I hold on to it - Yeche, as someone with more experience with traditional art world collecting than I do, can you please tell me how you see this?”<br /><br />

                This is a very compelling argument, and we can certainly recognize the logic within it - however there are two things that it does not fully take into account.<br /><br />

                1. What circumstances will lead ETH to be valued at 100k?~ the answer is simple, mainstream adoption and in turn radically increased network traffic<br /><br />

                2. If an artist sells their work for 5-200k at a traditional commercial gallery ~ why would they ever be motivated to participate in an ecosystem where work of the same quality is expected to sell for less than $1500.00?<br /><br />

                We understand Ethereum and crypto in general as a living currency, and for it to fully flourish as a tool that underpins aesthetic experience and transaction on the internet, its culture and practice must develop to support thoughtful and rigorous art and discussion. This not only means that there must be serious effort to create forums for real time conversation and criticism, but also that it must at least demonstrate that it can match valuations comparable to those of traditional art markets or it will forever be a cultural ghetto relegated to the social standing of a digital marketplace for selling cute pixel art cartoons of animals wearing 3D glasses.<br /><br />

                Yeche Lange is not a project to pump a few times and abandon, it represents a lifelong mission for its founders to create viable long-term infrastructures for artists and collectors to prosper while enabling the creation of radical digital art.<br /><br />

                Cool NFT winter, as Worm says.<br /><br />

                The integration of traditional art markets and blockchain derived markets will be the single most transformative shift in high culture during this century and we have put all our chips in this basket.<br /><br />

                With a team coming from a diverse background of artistic and curatorial experience in the traditional art world as well as crypto native spaces and full stack development, we position Yeche Lange as a fluid ambassador or messenger between the world of traditional art with its legacies and decorum and the world of web3 ripe with possibility and freedom of play that has the potential to shape the future.<br /><br />

                This is embodied in our approach to curation where we create a bridge between the artists and discourses of both physical and digital art worlds. It is our belief that for both worlds to truly thrive they must become integrated within each other's respective boundaries, assuming that these borders do not completely disintegrate in the process.<br /><br />

                ~<br /><br />

                With new technology comes new forms to inhabit and modes to operate within. With art's debut on the blockchain we witnessed the birth of new genres each with their own set of structural parameters expectations. The 10k PFP collection is a completely new scale of art production, enabled by generative image creation techniques and batch processing scripts executed within Adobe Photoshop, or the project's software of choice. The scale of these collections quickly showed a structural reality of art on chain that would define the way the art itself was conceived - that the unique circumstance provided by these high-liquidity environments encouraged art to exist in an edition size large enough that it allows individual units of the edition to be traded as if the series of artwork were itself a speculative currency. This is both thrilling and terrifying as it creates a completely new vein for art to inhabit with wildly different parameters than anything that existed prior, while also ushering in a disturbing reality where the artistic merits of an artwork can easily become secondary as the appreciation of the artwork is directly correlated to its performance in an extremely rapidly fluctuating highly reactive hyper liquid market~ that said, if you wanna make an omelette gotta break some eggs.<br /><br />

                For most artists working with traditional media, it's not unlikely to go to their grave without ever having produced 10,000 unique artworks, since to create meaningful artwork takes contemplation, dedication, and patience. There are many examples of masterworks in the history of art where the artist took more than a decade to complete the piece after they first began working on it. We want to see web3 become an ecosystem capable of supporting a variety of scales of artwork the most meaningful and historically significant art being produced by humanity and not merely a haven for fast-food jpegs.<br /><br />

                To test a solution to the scale problem, and create a vehicle for artists who work at a lower volume to have a structure for showing their work in web3 without the limitations of the 1/1, Yeche Lange has created a technique debuting with Jared Madere's Creation Babies that will allow artists from non-code based backgrounds to create thoughtful works through a custom workflow using Machine Learning augmentation that allows artists to transform a singular highly labored masterwork into a larger edition with unique elements in each re-imagining of the base artwork. The result is a single artwork with distributed ownership, but each iteration has a distinguishing mark generated by the Machine Learning augmentation that allows them to maintain their individual and differentiated character.<br /><br />

                Should the blockchain be the exclusive domain of code-literate artists preoccupied with ideas about accelerationism? NO, that would be extremely boring, the world is bigger than that.<br /><br />


                This is why Yeche is committed to representing a breadth of artists. Offering artists already working in the traditional market a pathway into web3, and as a gallery where web3 native artists receive all the benefits of institutional backing. That means maintaining infrastructures to present, publicize, contextualize and maintain the value of an artists work over a lifetime and beyond—whilst also pressing the young yet rigid conventions already set in Web3, from scale, to mint experience, WebGL display cabinets for collectors, physical art tie-ins and developing virtual exhibition architecture. It's Art on the internet for the first time
                </div>
            </div>
        </>
    );
}

export default Thesis;